import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { AppStateModel } from "./root.model";
import { AppState } from "./root.state";
import { SetAppLoading, SetExpandLevel } from "./root.action";
@State<AppStateModel>({
  name: 'app',
  defaults: AppState,
})
@Injectable()
export class AppStore{
  constructor(){}

  @Selector()
  static isLoading(state: AppStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static getExpandLevel(state: AppStateModel): number | null {
    return state.expandLevel;
  }


  @Action(SetAppLoading)
  isLoading(ctx: StateContext<AppStateModel>, { isLoading }: SetAppLoading) {
    ctx.patchState({
      isLoading,
    });
  }

  @Action(SetExpandLevel)
  setExpandLevel(ctx: StateContext<AppStateModel>, { level }: SetExpandLevel) {
    ctx.patchState({
      expandLevel : level,
    });
  }
}
