import { DestroyRef, inject, Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import {  finalize, last, switchMap, tap } from "rxjs";
import { FormatResponse } from "../../core/interfaces/response.interface";
import { PgTabStore } from "../pg-tab/pg-tab.store";
import { MainService } from "../../core/services/main-service/main.service";
import { ContextActions } from "../../core/constants/menu-bar/page/page";
import { AddChildCol, AddChildColFailure, AddChildColSuccess, AddNextCol, AddNextColFailure, AddNextColSuccess, AddPrevCol, AddPrevColFailure, AddPrevColSuccess } from "./cols.action";
import { ColsService } from "../../core/services/cols.service";
import { ColState } from "./cols.state";
import { ColStateModel } from "./cols.model";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { SheetState } from "../page/page.store";
import { Sheet } from "../page/page.actions";
import { RowPageData } from "../../shared/models/edit-dd-dailogs/dd-dailog.models";

@State<ColStateModel>({
  name: 'colRow',
  defaults: ColState,
})

@Injectable()
export class ColRowStore {
  destroyRef = inject(DestroyRef);
  constructor(
    private tColService : ColsService,
    private store : Store,
    private mainService : MainService
  ) {}

  @Action(AddNextCol)
  AddNextCol(ctx: StateContext<ColStateModel>, action: AddNextCol) {
    ctx.patchState({
      isLoading: true,
    });

    return this.tColService.addPgCols(action.data.row).pipe(
      takeUntilDestroyed(this.destroyRef), // Ensures cleanup when the component/service is destroyed
      tap((row: FormatResponse<any>) => {
        if (row.success) {
          const data = row.data.createdRow;
          ctx.dispatch(
            new AddNextColSuccess({
              row: data,
              prevRowId: action.data.prevRowId ? Number(action.data.prevRowId) : null,
              nextRowId: action.data.nextRowId ? Number(action.data.nextRowId) : null,
              currentRowId: action.data.currentRowId ? Number(action.data.currentRowId) : null,
              collId : row.data.createdCol.Col
            })
          );
        } else {
          ctx.dispatch(new AddNextColFailure(row.error));
        }
      }),
      finalize(() => {
        // Set loading to false after the operation completes
        ctx.patchState({
          isLoading: false,
        });
      })
    );
  }


  @Action(AddNextColSuccess)
  AddNextColSuccess(ctx: StateContext<ColStateModel>, action: AddNextColSuccess) {
    const getCurrentPgId = this.store.selectSnapshot(PgTabStore.getSelectedPgTab);
    const pgId = typeof getCurrentPgId === 'object' ? Number(getCurrentPgId.page_id) : getCurrentPgId;
    const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    const collId = action.data.collId ?? '';
    const updatedTabsData = this.mainService.addNewRow(
      pgId,
      action.data.row,
      allTabsData,
      action.data.currentRowId,
      ContextActions.ADD_NEXT_ROW,
      collId
    )
    ctx.patchState({
      isLoading : false,
    })
    this.store.dispatch(
      new Sheet.UpdatePageData({
        allTabsData: updatedTabsData,
        allTabsFlatData: allTabsFlatData,
        defaultPageId : pgId
      })
    );
  }

  @Action(AddNextColFailure)
  AddNextColFailure(ctx: StateContext<ColStateModel>, { error }: AddNextColFailure) {
   ctx.patchState({
     error : error,
     isLoading : false,
   })
 }


 @Action(AddPrevCol)
  AddPrevCol(ctx: StateContext<ColStateModel>, action: AddPrevCol) {
   ctx.patchState({
     isLoading : true,
   })
   return this.tColService.addPgCols(action.data.row).pipe(
    takeUntilDestroyed(this.destroyRef),
    last(),
    tap((row : FormatResponse<any>) => {
      if(row.success){
        let data = row.data.createdRow;
       return ctx.dispatch(new AddPrevColSuccess({
        row : data,
        prevRowId : action.data.prevRowId ? Number(action.data.prevRowId) : null,
        nextRowId : action.data.nextRowId ? Number(action.data.nextRowId) : null,
        currentRowId : action.data.currentRowId ? Number(action.data.currentRowId) : null,
        collId : row.data.createdCol.Col
        }));
      }else{
        return ctx.dispatch(new AddPrevColFailure(row.error));
      }
    }),
   )
 }

 @Action(AddPrevColSuccess)
  AddPrevColSuccess(ctx: StateContext<ColStateModel>, action: AddPrevColSuccess) {
    const getCurrentPgId = this.store.selectSnapshot(PgTabStore.getSelectedPgTab);
    const pgId = typeof getCurrentPgId === 'object' ? Number(getCurrentPgId.page_id) : getCurrentPgId;
    const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    const collId = action.data.collId ?? '';
    const updatedTabsData = this.mainService.addNewRow(
      pgId,
      action.data.row,
      allTabsData,
      action.data.currentRowId,
      ContextActions.ADD_PREV_ROW,
      collId
    )
    ctx.patchState({
      isLoading : false,
    })
    this.store.dispatch(
      new Sheet.UpdatePageData({
        allTabsData: updatedTabsData,
        allTabsFlatData: allTabsFlatData,
        defaultPageId : pgId
      })
    );
  }


 @Action(AddPrevColFailure)
 AddPrevRowFailure(ctx: StateContext<ColStateModel>, { error }: AddPrevColFailure) {
  ctx.patchState({
    error : error,
    isLoading : false,
  })
}

@Action(AddChildCol)
 AddChildCol(ctx: StateContext<ColStateModel>, action: AddChildCol) {
  ctx.patchState({
    isLoading : true,
  })
  return this.tColService.addPgCols(action.data.row).pipe(
    takeUntilDestroyed(this.destroyRef),
    last(),
    tap((row : FormatResponse<any>) => {
      if(row.success){
        let data = row.data.createdRow;
       return ctx.dispatch(new AddChildColSuccess({
        row : data,
        prevRowId : action.data.prevRowId ? Number(action.data.prevRowId) : null,
        nextRowId : action.data.nextRowId ? Number(action.data.nextRowId) : null,
        currentRowId : action.data.currentRowId ? Number(action.data.currentRowId) : null,
        collId : row.data.createdCol.Col
        }));
      }else{
        return ctx.dispatch(new AddChildColFailure(row.error));
      }
    }),
   )

}

@Action(AddChildColSuccess)
AddChildRowSuccess(ctx: StateContext<ColStateModel>, action: AddChildColSuccess) {
  const getCurrentPgId = this.store.selectSnapshot(PgTabStore.getSelectedPgTab);
  const pgId = typeof getCurrentPgId === 'object' ? Number(getCurrentPgId.page_id) : getCurrentPgId;
  const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
  const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
  const collId = action.data.collId ?? '';
  const updatedTabsData = this.mainService.addNewRow(
    pgId,
    action.data.row,
    allTabsData,
    action.data.currentRowId,
    ContextActions.ADD_CHILD_ROW,
    collId
  )
  ctx.patchState({
    isLoading : false,
  })
  this.store.dispatch(
    new Sheet.UpdatePageData({
      allTabsData: updatedTabsData,
      allTabsFlatData: allTabsFlatData,
      defaultPageId : pgId
    })
  );
}

@Action(AddChildColFailure)
AddChildRowFailure(ctx: StateContext<ColStateModel>, { error }: AddChildColFailure) {
 ctx.patchState({
   error : error,
   isLoading : false,
 })
}

@Selector()
  static isRowLoading(state: ColStateModel): boolean {
    return state.isLoading;
  }
}
