import {
  Component,
  DestroyRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import { map, Observable, skip, Subject, withLatestFrom } from 'rxjs';
import { LoadPgTabs, SelectPgTab } from '../../../store/pg-tab/pg-tab.actions';
import { PgTab } from '../../../store/pg-tab/pg-tab.model';
import { MainService } from '../../../core/services/main-service/main.service';
import {
  All_Pages_ID,
  UNICODE_ADD_ICON,
  UNICODE_CIRCLE,
  UNICODE_CROSS_ICON,
  UNICODE_MULTIPLY,
  UNICODE_PLUS,
} from '../../../core/constants/app.contants';
import { Subscription } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TooltipService } from '../../../core/services/tooltip.service';
import { GetValueFromKeyPipe } from '../../pipes/get-value-from-key.pipe';
import { PgTabFacadeService } from '../../../core/services/pg-tab/pg-tab-facade.service';
import { getValueFromObject } from '../../../utils/utils';
import { SystemInitials } from '../../../constant';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
  providers: [GetValueFromKeyPipe]
})
export class TabBarComponent implements OnInit, OnDestroy {
  destroyRef = inject(DestroyRef);
  pgTabs$: Observable<PgTab[]> = inject(Store).select(PgTabStore.getPgTabs);
  defaultPgTab$: Observable<PgTab> = inject(Store).select(
    PgTabStore.getSelectedPgTab
  );
  isLoading$: Observable<boolean> = inject(Store).select(PgTabStore.isLoading);
  addDefaultTab$ = new Subject<PgTab>();
  tabBars: PgTab[] = [];
  currentSheetIndex = 0;
  defaultPgTab: PgTab = {} as PgTab;
  closeTab = UNICODE_MULTIPLY;
  plusIcon = UNICODE_PLUS;
  private openPageSubscription!: Subscription; // To manage subscription
  circle = UNICODE_CIRCLE;
  cross_icon = UNICODE_CROSS_ICON;
  pageName: string = '';
  add_icon = UNICODE_ADD_ICON;
  constructor(
    private store: Store,
    public tooltipService: TooltipService,
    private mainService: MainService,
    private pgTabFacadeService : PgTabFacadeService
  ) {}
  ngOnInit(): void {
    this.mainService.pageName$.next('All Pages');
    this.openPageSubscription = this.mainService.openPage$.subscribe(
      (updatedOpenPage: any) => { 
        this.addDefaultTab$.next(updatedOpenPage);
      }
    );
    this.defaultPgTab$
      .pipe(
        skip(2),
        takeUntilDestroyed(this.destroyRef),
        withLatestFrom(this.pgTabs$)
      )
      .subscribe(([activeTab, allTabs]) => {
        this.onSelectPgTab(activeTab, allTabs.length - 1);
      });
    this.addDefaultTab$
      .pipe(takeUntilDestroyed(this.destroyRef), withLatestFrom(this.pgTabs$))
      .subscribe(([tab, allTabs]) => {
        if (!tab) return;
        const newTab = {
          ...tab,
          tab_id : Number(getValueFromObject(tab.page_id)) + allTabs.length.toString()
        }
        if(newTab){
          this.pgTabFacadeService.addNewTabWithLabels(newTab);
        }
        this.tabBars.push(newTab);
        this.onSelectPgTab(newTab, allTabs.length - 1);
      });
    this.initializeTabs();
  }

  public getValueFromObject(obj: {[key: number]: string} | undefined): string {
    return obj? getValueFromObject(obj) : '';
  }

  ngOnDestroy(): void {
    if (this.openPageSubscription) {
      this.openPageSubscription.unsubscribe();
    }
  }
  private initializeTabs(): void {
    this.pgTabs$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((tabs) => {
        this.tabBars = tabs;
        if (tabs[0]?.page_name[SystemInitials.English] === "All Pages"){
          this.defaultPgTab = tabs[0];
        }
      });
  }

  onSelectPgTab(pgTab: PgTab, index: number) {
    this.mainService.isTabSwitch$.next();
    this.store.dispatch(new SelectPgTab(pgTab));
    this.currentSheetIndex = index;
    this.mainService.pageName$.next(pgTab.page_name[SystemInitials.English]);
  }

  deleteSheet(index: number): void {
    if (this.tabBars.length <= 1) {
      alert("Can't close tab");
      return;
    }
    const deletedTab =  this.tabBars.splice(index, 1);
    this.pgTabFacadeService.deleteTabWithLabels(deletedTab[0]);
    if (index >= this.tabBars.length) {
      const previousTab = this.tabBars[index - 1];
      this.onSelectPgTab(previousTab, index - 1);
    } else {
      const nextTab = this.tabBars[index];
      this.onSelectPgTab(nextTab, index);
    }
    this.tooltipService.destroyToolTip();
  }

  addSheet(tabs: PgTab[]): void {
    this.addDefaultTab$.next(this.defaultPgTab);
  }
  @HostListener('document:keydown', ['$event'])
  public handleTabNavigation(event: KeyboardEvent): void {
    if (event && event.key === 'Tab') {
      event.stopPropagation();
      event.preventDefault();
      if (event.shiftKey) {
        if (this.currentSheetIndex > 0) {
          this.currentSheetIndex -= 1;
          this.onShiftTabKeyPress(event, this.tabBars[this.currentSheetIndex], this.currentSheetIndex);
      }
      } else {
        if (this.currentSheetIndex < this.tabBars.length - 1) {
          this.currentSheetIndex += 1;
          this.onKeyDown(event, this.tabBars[this.currentSheetIndex], this.currentSheetIndex);
      }
      }
    }
  }

  onKeyDown(event: any, pgTab: PgTab, index: number): void {
    if (!this.tabBars[index]) {
      event.preventDefault();
      return;
    }

    if (event.key === 'Tab') {
      this.onSelectPgTab(pgTab, index);
    }
  }

  onShiftTabKeyPress(event: any, pgTab: PgTab, index: number): void {
    if (!this.tabBars[index]) {
      event.preventDefault();
      return;
    }
    this.onSelectPgTab(pgTab, index);
  }
}
