import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SheetState } from '../../../store/page/page.store';
import { map, Observable, tap } from 'rxjs';
import { RowPageData } from '../../../shared/models/edit-dd-dailogs/dd-dailog.models';
import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import { PgTab } from '../../../store/pg-tab/pg-tab.model';
import { AppStore } from '../../../store/root/root.store';
import { PgRowStore } from '../../../store/row/row.store';
import { ColRowStore } from '../../../store/cols/cols.store';
import { ItemState } from '../../../store/item/item.store';
import { MainService } from '../main-service/main.service';

@Injectable({
  providedIn: 'root'
})
export class PageFacadeService {
  flatData: any[] = [];
  data$ : Observable<RowPageData[]> = inject(Store).select(
    SheetState.getSheetDataById
  )
  .pipe(
    map((data) => { 
      const processObject = (obj: any): any => {
        if (obj._children && Array.isArray(obj._children)) {
          obj._children = obj._children.map(processObject);
        }
 
        const flatDataObj: any = {};

        for (const key in obj) {
          const value = obj[key];
          if (typeof value === 'string' || typeof value === 'number') flatDataObj[key] = value;
          if (value?.cell?.items) {
            obj[key] = this.mainService.extractItemValue(value.cell.items);
            flatDataObj[key] = this.mainService.extractItemValue(value.cell.items);
          } else if (value?.format?.items) {
            obj[key] = this.mainService.extractItemValue(value.format.items);
            flatDataObj[key] = this.mainService.extractItemValue(value.format.items);
          }
        }

        this.flatData.push(flatDataObj);
        return obj;
      };
 
      const deepCopy = (obj: any): any => {
        if (obj === null || typeof obj !== 'object') {
          return obj;
        }
 
        if (Array.isArray(obj)) {
          return obj.map(deepCopy);
        }
 
        const copy: any = {};
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            copy[key] = deepCopy(obj[key]);
          }
        }
 
        return copy;
      };
 
      this.flatData = [];
      const dataCopy = deepCopy(data);
      return Array.isArray(dataCopy) ? dataCopy.map(processObject) : processObject(dataCopy);
    }),
  );
  
  getFlatData() {
    return this.flatData.sort((a, b) => Number(a.row) - Number(b.row));
  }

  tabFlatData$ : Observable<any> = inject(Store).select(
    SheetState.getSheetFlatDataById
  )
  columns$ : Observable<any> = inject(Store).select(
    SheetState.getSheetColumnsById
  )
  languages$ : Observable<any> = inject(Store).select(
    PgTabStore.getLanguages
  )
  fontStyle$ : Observable<any> = inject(Store).select(
    PgTabStore.getFontStyle
  )
  res$ : Observable<any> = inject(Store).select(
    SheetState.formatLocalCol
  )
  visited$ : Observable<any> = inject(Store).select(
    SheetState.getVisitedData
  )
  defaultPgTab$: Observable<PgTab> = inject(Store).select(
    PgTabStore.getSelectedPgTab
  );
  isLoading$: Observable<boolean> = inject(Store).select(
    PgTabStore.isLoading
  );
  expandLevel$: Observable<number | null> = inject(Store).select(
    AppStore.getExpandLevel
  );
  isRowLoading$: Observable<boolean> = inject(Store).select(
    PgRowStore.isRowLoading
  );
  isColLoading$: Observable<boolean> = inject(Store).select(
    ColRowStore.isRowLoading
  );
  isItemLoading$: Observable<boolean> = inject(Store).select(
    ItemState.isLoading
  );
  constructor(private mainService : MainService){}
}
