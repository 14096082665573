import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../interfaces/response.interface';

@Injectable({
  providedIn: 'root'
})
export class LabelsService {
  private apiUrl = 'tPg';
  constructor(private apiService: ApiService) {}

  getCurrentObjects(): Observable<ApiResponse> {
    return this.apiService.get<ApiResponse>(`${this.apiUrl}/DDLs/All Objects`);
  }
}
