import { Component, DestroyRef, inject, model, ModelSignal, OnDestroy, OnInit } from '@angular/core';
import { UNICODE_CROSS_ICON } from '../../../../core/constants/app.contants';
import { Observable } from 'rxjs';
import { PgTab } from '../../../../store/pg-tab/pg-tab.model';
import { Store } from '@ngxs/store';
import { SheetState } from '../../../../store/page/page.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Sheet } from '../../../../store/page/page.actions';

@Component({
  selector: 'app-edit-dd-dialog-tab-bar',
  templateUrl: './edit-dd-dialog-tab-bar.component.html',
  styleUrl: './edit-dd-dialog-tab-bar.component.scss'
})
export class EditDdDialogTabBarComponent implements OnInit, OnDestroy {

  public tabCounts: ModelSignal<number> = model<number>(0);

  constructor(private store: Store) { }
  getDdlTypesData$: Observable<PgTab[]> = inject(Store).select(SheetState.getDdlTypesData);
  destroyRef = inject(DestroyRef);
  editDdTabBar: string[] = [];
  currentSheetIndex = 0;
  cross_icon = UNICODE_CROSS_ICON;

  ngOnInit(): void {
    this.getDdlTypesData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => {
        this.editDdTabBar = Object.keys(res);
        this.tabCounts.set(this.editDdTabBar.length ?? 0); 
      });
  }

  onSelectEditDDTab(ddlTab: string, index: number) {
    this.currentSheetIndex = index;
    this.store.dispatch(new Sheet.PickDdlTypeData(ddlTab));
  }

  onDeleteTab(ddlTab: string, index: number) {
    if (this.editDdTabBar.length <= 1) {
      return;
    }
    if (index === this.editDdTabBar.length - 1) {
      this.currentSheetIndex -= 1;
    }
    this.store.dispatch(new Sheet.DeleteDdlTabData(ddlTab));
  }
  ngOnDestroy(): void {
    this.currentSheetIndex = 0;
    this.store.dispatch(new Sheet.ResetDdlTabData());
  }
}
