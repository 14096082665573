import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { SystemInitials } from '../../constant';
import { FormGroup } from '@angular/forms';
import { KeyValue } from '../../shared/models/edit-dd-dailogs/dd-dailog.models';
import { FORMAT_FORM } from '../constants/app.contants';
import { UpdateFormat, UpdateLocalRow } from '../../shared/models/format/format.models';

@Injectable({
  providedIn: 'root',
})
export class FormatService {
  private apiUrl = 'tFormat';

  constructor(private apiService: ApiService) {}

  // View Page
  viewPage(pg: any): Observable<any[]> {
    return this.apiService.get<any[]>(`${this.apiUrl}/viewPg/${pg}`);
  }

  // Get Column
  viewColumn(colId: any): Observable<any[]> {
    return this.apiService.get<any[]>(`${this.apiUrl}/viewCol/${colId}`);
  }

  // Fetch all items
  getItems(): Observable<any[]> {
    return this.apiService.get<any[]>(this.apiUrl);
  }

  // Update page
  updatePage(pageId: number, form: any): Observable<any[]> {
    // API end point
    const url = `${this.apiUrl}/updatePg/${pageId}`;

    const formData = {
      ...form,
      [FORMAT_FORM.Comment]: form[FORMAT_FORM.CommentObject],
      [FORMAT_FORM.TxList]: form[FORMAT_FORM.TxList] ? form[FORMAT_FORM.TxList] : [],
    };

    // Ensure excluded keys are removed but allow empty values
    const excludedKeys = [FORMAT_FORM.PgId, FORMAT_FORM.RowId, FORMAT_FORM.CommentObject];
    const payload = Object.fromEntries(
      Object.entries(formData).filter(([key]) => !excludedKeys.includes(key))
    );

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any[]>(url, payload, headers);
  }

  // Update column
  updateColumn(pgId: number, colId: number, form: any): Observable<any[]> {
    // API end point
    const url = `${this.apiUrl}/localcol/${colId}`;

    const formData = {
      ...form,
      [FORMAT_FORM.Status]: form[FORMAT_FORM.Status] 
        ? form[FORMAT_FORM.Status].map((status:any) => Number(Object.keys(status)[0])) : [],
      [FORMAT_FORM.Comment]: form[FORMAT_FORM.CommentObject],
      [FORMAT_FORM.TxList]: form[FORMAT_FORM.TxList] ? form[FORMAT_FORM.TxList] : [],
    };

    // Ensure excluded keys are removed but allow empty values
    const excludedKeys = [FORMAT_FORM.ColId, FORMAT_FORM.RowId, FORMAT_FORM.CommentObject];
    const payload = Object.fromEntries(
      Object.entries(formData).filter(([key]) => !excludedKeys.includes(key))
    );

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any[]>(url, payload, headers);
  }

  // Update row
  updateRow(pgId: number, rowId: number, form: any): Observable<any[]> {
    // API end point
    const url = `${this.apiUrl}/localrow`;
 
    const formData = {
      ...form,
      [FORMAT_FORM.Status]: form[FORMAT_FORM.Status] ? form[FORMAT_FORM.Status]
        .map((status:any) => Number(Object.keys(status)[0])) : [],
      [FORMAT_FORM.Comment]: form[FORMAT_FORM.CommentObject],
      [FORMAT_FORM.TxList]: form[FORMAT_FORM.TxList] ? form[FORMAT_FORM.TxList] : [],
    };

    // Ensure excluded keys are removed but allow empty values
    const excludedKeys = [FORMAT_FORM.ColId, FORMAT_FORM.RowId, FORMAT_FORM.CommentObject];
    const payload = Object.fromEntries(
      Object.entries(formData).filter(([key]) => !excludedKeys.includes(key))
    );

    return this.apiService.post<any[]>(url, { 
        [FORMAT_FORM.Row]: [Number(rowId)], 
        updateData: payload
      }, 
    );
  }

  // Update item
  updateItem(
    itemId: number,
    form: any
  ): Observable<any[]> {
    // API end point
    const url = `${this.apiUrl}/localitem/${itemId}`;

    const formData = {
      ...form,
      [FORMAT_FORM.Status]: form[FORMAT_FORM.Status] 
        ? form[FORMAT_FORM.Status].map((status:any) => Number(Object.keys(status)[0])) : [],
      [FORMAT_FORM.Comment]: form[FORMAT_FORM.CommentObject],
      [FORMAT_FORM.TxList]: form[FORMAT_FORM.TxList] ? form[FORMAT_FORM.TxList] : [],
    };

    // Ensure excluded keys are removed but allow empty values
    const excludedKeys = [ FORMAT_FORM.ItemId, FORMAT_FORM.RowId, FORMAT_FORM.CommentObject ];
    const payload = Object.fromEntries(
      Object.entries(formData).filter(([key]) => !excludedKeys.includes(key))
    );

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any[]>(url, payload, headers);
  }

  // Update cell
  updateCell(
    pgId: number,
    colId: number,
    rowId: number,
    form: any
  ): Observable<any[]> {
    // API end point
    const url = `${this.apiUrl}/localcell/${colId}/${rowId}`;

    const formData = {
      ...form,
      [FORMAT_FORM.Status]: form[FORMAT_FORM.Status] 
        ? form[FORMAT_FORM.Status].map((status:any) => Number(Object.keys(status)[0])) : [],
      [FORMAT_FORM.Comment]: form[FORMAT_FORM.CommentObject],
      [FORMAT_FORM.TxList]: form[FORMAT_FORM.TxList] ? form[FORMAT_FORM.TxList] : [],
    };

    // Ensure excluded keys are removed but allow empty values
    const excludedKeys = [ FORMAT_FORM.CellId, FORMAT_FORM.RowId, FORMAT_FORM.CommentObject ];
    const payload = Object.fromEntries(
      Object.entries(formData).filter(([key]) => !excludedKeys.includes(key))
    );

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any[]>(url, payload, headers);
  }

  // Get page format
  getPageFormat(pageId: number) {
    // API end point
    const url = `${this.apiUrl}/viewPg/${pageId}`;

    return this.apiService.get<any>(url);
  }

  // Get column format
  getLocalColumn(colId: number) {
    // API end point
    const url = `${this.apiUrl}/viewCol/${colId}`;
    return this.apiService.get<any>(url);
  }

  // Get cell format
  getLocalCell(colId: number, rowId: number) {
    // API end point
    const url = `${this.apiUrl}/viewCell/${colId}/${rowId}`;
    return this.apiService.get<any>(url);
  }

  // Get item format
  getLocalItem(itemId: number) {
    // API end point
    const url = `${this.apiUrl}/viewItem/${itemId}`;
    return this.apiService.get<any>(url);
  }

  // Get column format
  getLocalRow(rowId: number) {
    // API end point
    const url = `${this.apiUrl}/viewRow/${rowId}`;
    return this.apiService.get<any>(url);
  }

  // Delete column
  deleteColumn(pgId: number, colId: number, payload: any) {
    // API end point
    const url = `${this.apiUrl}/recycledcol/${colId}`;

    return this.apiService.patch<any>(url, payload);
  }

  // Delete a page
  deletePage(pgId: number, payload: any): Observable<any> {
    // API end point
    const url = `tFormat/recycledPg/${pgId}`;

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any>(url, payload, headers);
  }

  // Delete a row
  deleteRow(pgId: number, rowId: number, payload: any): Observable<any> {
    // API end point
    const url = `tFormat/recycledrow`;

    return this.apiService.post<any>(url, payload);
  }

  // Delete a row
  deleteItem(itemId: number, payload: any): Observable<any> {
    // API end point
    const url = `tFormat/recycleditem/${itemId}`;

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.apiService.patch<any>(url, payload, headers);
  }

  // Get Row
  viewRow(rowId: any): Observable<any[]> {
    return this.apiService.get<any[]>(`${this.apiUrl}/viewRow/${rowId}`);
  }

  // Get Cell
  viewCell(colId: any, rowId: any): Observable<any[]> {
    return this.apiService.get<any[]>(
      `${this.apiUrl}/viewCell/${colId}/${rowId}`
    );
  }

  // Get Item
  viewItem(itemId: string): Observable<any[]> {
    return this.apiService.get<any[]>(
      `${this.apiUrl}/viewItem/${itemId}`
    );
  }
}
