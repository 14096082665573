import { Component, ViewChild, OnInit, Inject, ElementRef, HostListener, signal, WritableSignal, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DragService } from '../../../core/services/drag-service/drag.service';
import { ResizeService } from '../../../core/services/resize-service/resize.service';
import { ASCII_EXPAND_ICON } from '../../../core/constants/app.contants';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorResponse } from '../../../core/interfaces/ck-editor.interface';
import { CK_EDITOR_DEFAULT_TEXT } from '../../../core/constants/ck-editor.const';
import { DialogAction } from '../../../core/enums/ck-editors/ck-editors.enum';
@Component({
  selector: 'dialog-font',
  templateUrl: './dialog-font.component.html',
  styleUrls: ['./dialog-font.component.scss']
})
export class DialogFontComponent implements OnInit {

  public defaultEditorValues: WritableSignal<CKEditorResponse> = signal<CKEditorResponse>({text: CK_EDITOR_DEFAULT_TEXT, style: ''});
  public expandIcon: WritableSignal<string> = signal<string>(ASCII_EXPAND_ICON);
  public dialogActionEnum: WritableSignal<typeof DialogAction> = signal<typeof DialogAction>(DialogAction);
  private modalRef!: NgbModalRef;
  
  @ViewChild('fontPageContainer', { static: true })
  public popupContainer!: ElementRef<HTMLDivElement>;
  
  public fontForm: FormGroup = new FormGroup({
    edit_input: new FormControl(this.defaultEditorValues())
  });


  private dragService: DragService = inject(DragService);
  private resizeService: ResizeService = inject(ResizeService);

  public constructor( 
    public dialogRef: MatDialogRef<DialogFontComponent>,
    @Inject(MAT_DIALOG_DATA) public injectedData: any
  ) {}

  public ngOnInit(): void {
    if(this.injectedData){
      this.defaultEditorValues().style = this.injectedData.fontStyle;
    }
  }

  // Function to handle drag start
  public onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContainer);
  }
  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  private onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContainer);
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  private onMouseUp() {
    this.dragService.onMouseUp();
  }
  // Resizing functionality
  public onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContainer);
  }
  public onSave(value: string = DialogAction.SaveClick): void {
    const styleString: string = this.fontForm.value.edit_input.style;
    this.dialogRef.close(styleString);
    this.modalRef?.dismiss(value);
  }
  
}
