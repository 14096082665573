import { Component, ViewChild, OnInit, Inject, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UNICODE_MULTIPLY } from '../../core/constants/app.contants';
@Component({
  selector: 'app-add-edit-text',
  templateUrl: './add-edit-text.component.html',
  styleUrls: ['./add-edit-text.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class AddEditTextComponent {
  form!: FormGroup;
  closeResult: string = '';
  private modalRef!: NgbModalRef;
  valueChanged: string | null = null;
  selectedValue: string | null = null;
  selectedIndex: number = -1;
  finalValue: string = '';
  isEmpty:boolean = true;
  @ViewChild('content', { static: true }) content: any;
  closeTab=UNICODE_MULTIPLY;
  constructor(
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<AddEditTextComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.open(this.content);
    this.form = this.fb.group({
      languageGroups: this.fb.array([this.createLanguageGroup('en',this.data?.value)])
    });
    console.log(this.data);
  }
  
  public isLayoutReady = false;
  public ngAfterViewInit(): void {
    this.isLayoutReady = true;
    this.changeDetector.detectChanges();
  }

  onValueChange({ editor }: any, index: number): void {
    this.checkValues(index);
  }

  onSelect(value: string, index: number): void {
    this.checkValues(index);
    this.updateRichTextField(index);
  }

  checkValues(index: number): void {
    // Only add a new group if the current group is valid
    if (this.isCurrentGroupValid()) {
      this.addLanguageGroup();
    }
  }

  get languageGroups(): FormArray {
    return this.form.get('languageGroups') as FormArray;
  }

  createLanguageGroup(language:string,value:string): FormGroup {
    const group = this.fb.group({
      language: [language, Validators.required],
      richText: [{ value: value, disabled: true }, Validators.required] // Initialize as disabled
    });

    // Update richText field based on language value
    group.get('language')?.valueChanges.subscribe(value => {
      if (value) {
        group.get('richText')?.enable();
      } else {
        group.get('richText')?.disable();
      }
    });

    return group;
  }

  // Check if the current form group is valid
  isCurrentGroupValid(): boolean {
    const currentGroup = this.languageGroups.at(this.languageGroups.length - 1) as FormGroup;
    return currentGroup.valid;
  }

  addLanguageGroup() {
    // Add a new language group only if the current group is valid
    if (this.isCurrentGroupValid()) {
      this.languageGroups.push(this.createLanguageGroup('',''));
    }
  }

  removeLanguageGroup(index: number) {
    if (this.languageGroups.length > 1) {
      this.languageGroups.removeAt(index);
    }
  }

  updateRichTextField(index: number) {
    const group = this.languageGroups.at(index) as FormGroup;
    const languageValue = group.get('language')?.value;

    if (languageValue) {
      group.get('richText')?.enable();
    } else {
      group.get('richText')?.disable();
    }
  }

  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        this.onCancel();
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        this.onCancel();
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }

  onSave(): void {
    this.dialogRef.close(this.form.value);
    this.modalRef.dismiss('Save click');
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }
}
