import {
  Component,
  ViewChild,
  ElementRef,
  HostListener,
  inject,
  OnInit,
  Inject,
  model,
  ModelSignal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DragService } from '../../../../core/services/drag-service/drag.service';
import { ResizeService } from '../../../../core/services/resize-service/resize.service';
import {
  UNICODE_CROSS_ICON,
  UNICODE_EXPAND_ICON,
} from '../../../../core/constants/app.contants';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AppStore } from '../../../../store/root/root.store';
import { MainService } from '../../../../core/services/main-service/main.service';
@Component({
  selector: 'edit-dd-dialog-main',
  templateUrl: './edit-dd-dialog-main.component.html',
  styleUrls: ['./edit-dd-dialog-main.component.scss'],
})
export class editDdDialogMainComponent implements OnInit {
  public tabCounts: ModelSignal<number> = model<number>(0);
  public isLoading: ModelSignal<boolean> = model<boolean>(true);
  closeTab = UNICODE_CROSS_ICON;
  offsetX = 0;
  offsetY = 0;
  isResizing: boolean = false;
  initialMouseX: number = 0;
  initialMouseY: number = 0;
  initialWidth: number = 0;
  initialHeight: number = 0;
  newWidth: number = 0;
  newHeight: number = 0;
  isDragging: boolean = false;
  scrollbarWidth = this.getScrollbarWidth();
  expandIcon = UNICODE_EXPAND_ICON;
  isLoading$: Observable<boolean> = inject(Store).select(AppStore.isLoading);
  @ViewChild('popupContainer') popupContainer!: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<editDdDialogMainComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dragService: DragService,
    private resizeService: ResizeService,
    private mainService: MainService
  ) {}
  ngOnInit(): void {
    this.mainService.setWidth.subscribe((value)=>{
      if(!this.popupContainer || !this.popupContainer.nativeElement) return;
      this.popupContainer.nativeElement.style.width = `${value}` ;
    })
  }
  handleBooleanEvent(value: boolean): void {
    if (value) {
      this.popupContainer.nativeElement.style.height = `${this.popupContainer.nativeElement.offsetHeight}px`
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
  // Function to calculate the side scrollbar width
  getScrollbarWidth(): number {
    return window.innerWidth - document.documentElement.clientWidth;
  }

  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContainer);
  }

  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContainer);
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragService.onMouseUp(); // Use the service method
  }
  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContainer);
  }
}
