// format-row-dialog.component.ts
import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogFontComponent } from '../dialog-font/dialog-font.component';
import { FontSelectServiceService } from '../../services/font-select-service/font-select-service.service';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UNICODE_MULTIPLY } from '../../core/constants/app.contants';

@Component({
  selector: 'app-format-row-dialog',
  templateUrl: './format-row-dialog.component.html',
  styleUrls: ['./format-row-dialog.component.css'],
})
export class FormatRowDialogComponent {
  Format_Row!: FormGroup;
  dataTypes = ['DDT', 'OtherType1', 'OtherType2'];
  ddtSources = ['Statuses', 'Source1', 'Source2'];
  private modalRef!: NgbModalRef;
  closeResult: string = '';  
  rowColorId:{}={};
  closeTab=UNICODE_MULTIPLY;
  @ViewChild('content', { static: true }) content: any;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FormatRowDialogComponent>,
    public dialog: MatDialog,
    public service:FontSelectServiceService,
    private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.open(this.content);
    this.Format_Row = this.fb.group({
      Format_Id:[''],
      Format_User: [''],
      Local_Row_ID: [''],
      R_Status: [''],
      R_Type: [''],
      R_Font_Style: [''],
      R_Comment: [''],
      R_Transactions: [''],
    });

  }

  onCancel() {
    if (this.modalRef) {
      this.modalRef.close();
    }
    if(this.modalService){
      this.modalService.dismissAll();
    }
    this.modalRef.close();
    this.modalService.dismissAll();
  }
  openFontDialog() {
    const dialogRef = this.dialog.open(DialogFontComponent, {
      width: '400px',
      data: this.data
    });     
    dialogRef.afterClosed().subscribe((result) => {
      
      let alignment;
        switch (this.service.getTextAlignment()) {
          case '0':
            alignment = 'justify';
            break;
          case '1':
            alignment = 'left';
            break;
          case '2':
            alignment = 'right';
            break;
          default:
            alignment='';
            break;
        }
        let type;
        switch (this.service.getSelectedFontType()) {
          case '0':
            type = '0';
            break;
          case '1':
            type = '1';
            break;
          case '2':
            type = '2';
            break;
          default:
            type='0';
            break;
        }
        
        this.Format_Row = this.fb.group({
          Format_Id:[''],
          Format_User: [''],
          Local_Row_ID: [''],
          R_Status: [''],
          R_Type: [''],
          R_Font_Style: this.service.getTextColor()+';'+this.service.getFontFamily()+';'+this.service.getFontSize()+';'+type+';'+alignment+';'+this.service.getBackgroundColor()+';'+this.service.getFontStyle(),
          R_Comment: [''],
          R_Transactions: [''],
        });
    });
}
onSave() {
  if (this.Format_Row.valid) {
    this.dialogRef.close(this.Format_Row.value['R_Font_Style']);
    this.modalRef.dismiss();
    this.modalService.dismissAll();
  } else {
    console.log('Form is invalid');
  }
}
open(content: any) {
  if (this.modalRef) {
    this.modalRef.close();
  }
  if(this.modalService){
    this.modalService.dismissAll();
  }
  this.modalRef = this.modalService.open(content, {
    ariaLabelledBy: 'modal-basic-title',
  });
  this.modalRef.result.then(
    (result) => {
      this.closeResult = `Closed with: ${result}`;
    },
    (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    }
  );
}
private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    this.onCancel();
    this.dialog.closeAll();
    return 'by pressing ESC';
    
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    this.onCancel();
    this.dialog.closeAll();
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}
}
