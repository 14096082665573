import { AddRowModel } from "../../shared/models/row/row.models";

export enum ActionTypes {
  ADD_NEXT_COLS_ROW = '[COLS] Add Next Cols Row',
  ADD_NEXT_COLS_ROW_SUCCESS = '[COLS] Add Next Cols Row Success',
  ADD_NEXT_COLS_ROW_FAILURE = '[COLS] Add Next Cols Row Failure',

  ADD_PREV_COLS_ROW = '[COLS] Add Prev Cols',
  ADD_PREV_COLS_ROW_SUCCESS = '[COLS] Add Prev Cols Row Success',
  ADD_PREV_COLS_ROW_FAILURE = '[COLS] Add Prev Cols Row Failure',

  ADD_CHILD_COLS_ROW = '[COLS] Add Child Cols',
  ADD_CHILD_COLS_ROW_SUCCESS = '[COLS] Add Child Cols Row Success',
  ADD_CHILD_COLS_ROW_FAILURE = '[COLS] Add Child Cols Row Failure',
}

export class AddNextCol {
  static readonly type = ActionTypes.ADD_NEXT_COLS_ROW;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null,
      currentRowId : number | null,
      collId? : string | null
    }
  ) {}
}

export class AddNextColSuccess {
  static readonly type = ActionTypes.ADD_NEXT_COLS_ROW_SUCCESS;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null
      currentRowId : number | null,
      collId? : string | null
    }
  ) {}
}

export class AddNextColFailure {
  static readonly type = ActionTypes.ADD_NEXT_COLS_ROW_FAILURE;
  constructor(public error? : any) {}
}


export class AddPrevCol {
  static readonly type = ActionTypes.ADD_PREV_COLS_ROW;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null,
      currentRowId : number | null,
      collId? : string | null
    }
  ) {}
}

export class AddPrevColSuccess {
  static readonly type = ActionTypes.ADD_PREV_COLS_ROW_SUCCESS;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null
      currentRowId : number | null,
      collId? : string | null
    }
  ) {}
}

export class AddPrevColFailure {
  static readonly type = ActionTypes.ADD_PREV_COLS_ROW_FAILURE;
  constructor(public error? : any) {}
}

export class AddChildCol {
  static readonly type = ActionTypes.ADD_CHILD_COLS_ROW;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null,
      currentRowId : number | null,
      collId? : string | null
    }){}
}

export class AddChildColSuccess {
  static readonly type = ActionTypes.ADD_CHILD_COLS_ROW_SUCCESS;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null
      currentRowId : number | null,
      collId? : string | null
    }
  ) {}
}

export class AddChildColFailure {
  static readonly type = ActionTypes.ADD_CHILD_COLS_ROW_FAILURE;
  constructor(public error? : any) {}
}



