import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, forkJoin, map, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../interfaces/response.interface';

@Injectable({
  providedIn: 'root',
})
export class tPgService {
  private apiUrl = 'tPg';

  constructor(private apiService: ApiService, private http: HttpClient) {}

  // Fetch page by ID
  getPages(pg: any): Observable<any[]> {
    return this.apiService.get<any[]>(`${this.apiUrl}/content/${pg}`);
  }

  getAllPages(): Observable<any> {
    return this.apiService.get<any>(`${this.apiUrl}/Pg/all/cells`);
  }

  // Fetch all pages
  getDDL(DDL: any): Observable<ApiResponse> {
    return this.apiService.get<ApiResponse>(`${this.apiUrl}/DDLs/${DDL}`);
  }

  // Fetch page by ID
  getPageById(id: number): Observable<any> {
    return this.apiService.get<any>(`${this.apiUrl}/${id}`);
  }

  // Create a new page
  createPage(page: any): Observable<any> {
    return this.apiService.post<any>(this.apiUrl, page);
  }

  // Update an existing page
  updatePage(id: number, page: any): Observable<any> {
    return this.apiService.patch<any>(`${this.apiUrl}/${id}`, page);
  }

  getLanguages(): Observable<[]> {
    const url = 'tPg/DDLs/All Languages'; // Relative endpoint path
    return this.http.get<[]>(url);
  }
  addItem(payload: any): Observable<any> {
    const url = 'api/items/createitemupdatecell'; // Relative endpoint path

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    // Make the POST request with payload in the body
    return this.http.post<any>(url, payload, { headers });
  }
  editItem(payload: any): Observable<any> {
    const url = 'api/items/getcellandupdateitem'; // Relative endpoint path

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    // Make the POST request with payload in the body
    return this.http.post<any>(url, payload, { headers });
  }
  orderColumns(pageId: number, payload: any): Observable<any> {
    const url = `api/page/${pageId}/updatePageColsOrder`;

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put<any>(url, payload, { headers });
  }

  // Clear specific page cache
  clearPageCache(pageId: number): Observable<any> {
    const url = `api/page/clearcache/${pageId}`;
    return this.http.put(url, null);
  }

  //add Row
  addRow(data: any): Observable<any> {
    const url = 'api/row/PgRow';
    return this.http
      .post(url, data)
      .pipe(map((resp: any) => resp?.data?.['Add-Row']));
  }

  addColumn(data: any): Observable<any> {
    const url = 'api/col/createColAndRow';
    return this.http
      .post(url, data)
      .pipe(map((resp: any) => resp?.data?.['Add-Row']));
  }

  //Delete Row
  deleteRow(data: any): Observable<any> {
    let userId = {
      userId: '3000000099',
    };
    const url = 'api/formats/delete-row/' + data?.PageId + '/' + data?.RowId;
    return this.http.put(url, userId);
  }

  // Delete column
  deleteColumn(data: any): Observable<any> {
    let payload = { userId: '3000000099' };
    const url = `api/formats/delete-Col/${data.pageId}/${data.colId}`;

    // Define the two API calls
    const clearCacheObservable = this.clearPageCache(data.pageId);
    const deleteColumnObservable = this.http.put<any>(url, payload);

    // Use forkJoin to call both APIs simultaneously
    return forkJoin({
      //clearCache: clearCacheObservable,
      deleteColumn: deleteColumnObservable,
    });
  }

  // Delete page
  deletePg(pageId: number): Observable<any> {
    let payload = { userId: '3000000099' };
    const url = 'api/formats/delete-Pg/' + pageId;

    return this.http.put<any>(url, payload);
  }

  // Update page format
  formatLocalCell(data: any): Observable<any> {
    const payload = {
      Status: [3000000223, 3000000211],
      MinWidth: data.minWidth,
      FontStyle: JSON.stringify(data.fontStyle?.FontStyle),
      Comment: { '3000000100': data.comment },
      TxList: data.txList,
    };

    // API end point
    const url = `api/formats/local-cell/${data.pageId}/${data.colId}/${data.rowId}`;

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put<any>(url, payload, { headers });
  }

  // Get item dropdown data
  getItemDropdown(colId?: any): Observable<any> {
    return of([]);
  }

  // Update item through dropdown
  updateItemThroughDropdown(payload: any): Observable<any> {
    const url = `api/items/getcellandupdateitem`;

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(url, payload, { headers });
  }

  // Add item through dropdown
  addItemThroughDropdown(payload: any): Observable<any> {
    const url = `api/items/createitemupdatecell`;

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(url, payload, { headers });
  }
  formatLocalCol(payload: any, page_id: any): Observable<any> {
    const url = `api/formats/local-col/` + page_id + '/' + payload.colid;

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.put<any>(url, payload, { headers });
  }
  deleteItemData(payload: any, page_id: any): Observable<any> {
    const url = `api/formats/delete-item/` + page_id;

    // Set headers if needed (for example, to specify content type)
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post<any>(url, payload, { headers });
  }

  // Get Context Menus
  getContextMenus(): Observable<any[]> {
    return this.apiService.get<any[]>(
      `${this.apiUrl}/DDLs/Menus`
    );
  }
}
