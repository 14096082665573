import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../envirnoments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  /**
   * Intercepts an HTTP request, modifies the URL to include the base URL, and
   * passes the modified request to the next handler in the chain.
   *
   * @param req The outgoing HTTP request.
   * @param next The next handler in the HTTP request chain.
   * @returns An observable of the HTTP event.
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Define the base URL from the environment configuration
    const baseUrl = environment.backendUrl;

    // Clone the request and modify the URL by appending the base URL to the relative URL
    const apiReq = req.clone({
      url: `${baseUrl}/api/${req.url}`,
      responseType: 'json',
    });

    // Pass the modified request to the next handler in the chain
    return next.handle(apiReq);
  }
}
