import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, Renderer2, AfterViewInit, DestroyRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogFontComponent } from '../../dialog-font/dialog-font.component';
import { MatDialog } from '@angular/material/dialog';
import { FontSelectServiceService } from '../../../../core/services/font-select-service/font-select-service.service';
import { DDL_Statuses, FORMAT_FORM } from '../../../../core/constants/app.contants';
import { PickColDialogComponent } from '../../../../modals/pickCol-Dialog/pickcol-dialog.component';
import { Store } from '@ngxs/store';
import { SystemInitials } from '../../../../constant';
import { Format } from '../../../../store/format/format.actions';
import { ContextMenu, ROLES } from '../../../../core/constants/menu-bar/page/page';
import { editDdDialogMainComponent } from '../../edit-dd-dialog/edit-dd-dialog-main/edit-dd-dialog-main.component';
import { CellData, DDResponse, KeyValue, RowModeData, ItemData } from '../../../models/edit-dd-dailogs/dd-dailog.models';
import { SheetState } from '../../../../store/page/page.store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FormatData, FormatFormData } from '../../../models/format/format.models';
import { DialogEditComponent } from '../../dialog-edit/dialog-edit.component';
import { PgTabStore } from '../../../../store/pg-tab/pg-tab.store';
import { SetFormatFormFields } from '../../../../store/format/format.model';
import { MainService } from '../../../../core/services/main-service/main.service';
import { inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormatStore } from '../../../../store/format/format.store';
import { Actions, DbObjects, Message } from '../../../../core/enums/tokens/tokens.enum';

@Component({
  selector: 'app-format-page-form',
  templateUrl: './format-page-form.component.html',
  styleUrls: ['./format-page-form.component.scss'],
})
export class FormatPageFormComponent implements OnChanges, AfterViewInit {
  private languageArray: [{}] = [{}];
  readonly FORM = FORMAT_FORM;
  private contextMenus: any[] = [];
  private unsubscribe$ = new Subject<void>();
  formData: FormatFormData = {};
  form!: FormGroup;
  formatLocalRowForm! : FormGroup;
  dynamicForm: FormGroup = this.fb.group({});
  formatId!: string;
  currentPgId!: string;
  columnID: any = "";
  formFields: Array<any> = [];
  pageFormatData: any = [];
  editDdiData: any = [];
  languagesList : any = {};

  @ViewChild('textFieldReference',) fixedWidthContainer!: ElementRef<HTMLDivElement>;
  styledvalue = "<spna>styledvalue</span>";
  @Input() dialogData: any;
  @Output() formDataChange = new EventEmitter<any>(); // To emit form data to parent
  
  destroyRef = inject(DestroyRef);
  editDdiData$: Observable<any> | undefined = inject(Store).select(SheetState.PickDdiData);
  languages$: Observable<any> = inject(Store).select(PgTabStore.getLanguages);
  pageFormatData$ = inject(Store).select(FormatStore.getPageFormat);
  localRowFormatData$ = inject(Store).select(FormatStore.getLocalRowFormat);
  localColFormatData$ = inject(Store).select(FormatStore.getLocalColumnFormat);
  localCellFormatData$ = inject(Store).select(FormatStore.getLocalCellFormat);
  localItemFormatData$ = inject(Store).select(FormatStore.getLocalItemFormat);
  isLoading$: Observable<boolean> = inject(Store).select(FormatStore.isLoading);
  currentPgId$ = inject(Store).select(PgTabStore.getCurrentPgId);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dialogData'] && this.dialogData?.context) { }
  }

  ngAfterViewInit(): void {
    const containerWidth = this.fixedWidthContainer?.nativeElement?.offsetWidth;

    // Select all elements with the 'fixed-width' class
    const fixedWidthElements = document.querySelectorAll('.fixed-width');

    // Apply the width to each of the 'fixed-width' elements
    fixedWidthElements.forEach((element) => {
      this.renderer.setStyle(element, 'width', `${containerWidth}px`);
    });
  }

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public service: FontSelectServiceService,
    private store: Store,
    private renderer: Renderer2,
    private mainService: MainService,
  ) {
    this.form = this.fb.group({});
    this.formatLocalRowForm = this.fb.group({});
  }

  ngOnInit() {
    this.initSubscriptions();
    this.populateFormData();
  }

  createForm(formData: FormatFormData) {
    let formatFormGroup: Record<string, any> = {};
    if (!formData.FormatData) return this.fb.group({});

    formData.FormatData.forEach((field: FormatData) => {
      if (field.Name) {
        formatFormGroup[field.Name] = [
          { value: field.Value, disabled: field.Formula?.includes(ROLES.Admin) ? true : false },
          [
            field.Label?.includes('*') ? Validators.required : null,
            Validators.pattern('')
          ].filter(Boolean)
        ];
      }

      if (field.Name === FORMAT_FORM.Comment) {
        formatFormGroup[field.Name] = field.Value?.[SystemInitials.English] || '';
        formatFormGroup[FORMAT_FORM.CommentObject] = field.Value;
      }
    });

    // Initialize both forms
    formatFormGroup[FORMAT_FORM.RowId] = [''];
    return this.fb.group(formatFormGroup);
  }

  openFontDialog() {
    const dialogRef = this.dialog.open(DialogFontComponent, {
      width: '400px',
      data: {
       title: this.dialogData.title,
       objectId: this.dialogData.objectId,
       fontStyle: this.form.controls[FORMAT_FORM.FontStyle].value
      },
    });

    dialogRef.afterClosed().subscribe((fontStyle: string) => {
      this.dialogData.fontStyleParsed = fontStyle;
      this.form.patchValue({
        [FORMAT_FORM.FontStyle]: fontStyle ?? this.form.controls[FORMAT_FORM.FontStyle].value
      });
    });
  }

  openColDialog() {
    const dialogRef = this.dialog.open(PickColDialogComponent, {
      width: '750px',
      data: { selectedId: '1000000001' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.columnID = result;
      this.form.patchValue({
        [FORMAT_FORM.PgNestedCol]: this.columnID,
      });
    });
  }

  async openEditDdDialog(statusKey?: string | unknown) {
    const dialogRef = this.dialog.open(editDdDialogMainComponent, {
      width: 'auto',
      panelClass: 'pick-ddl',
      data: {
        title: this.dialogData.title,
        objectId: this.dialogData.objectId,
        token: DDL_Statuses
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Safely retrieve the row selected from the dialog result
        const rowSelectedKey = Object.keys(result).find((key) => key.endsWith('RowSelected')) as keyof DDResponse;
        const rowSelected: RowModeData = result[rowSelectedKey];
        const rowSelectedObj: Record<string, string> = { [rowSelected.row]:  rowSelected.token }

        if (rowSelected?.token) {
          let isDuplicate = false;
          const currentStatuses: Array<Record<string, string>> = this.form.controls[FORMAT_FORM.Status].value || [];
          if (currentStatuses.length > 0) {
            isDuplicate = currentStatuses?.some(status => Object.keys(status)[0] === Object.keys(rowSelectedObj)[0]);
          }
          
          const updatedStatuses: Array<Record<string, string>> = statusKey && !isDuplicate
            ? currentStatuses.map((status) =>
              Object.keys(status)[0] === statusKey ? rowSelectedObj : status
              )
            : !isDuplicate
            ? [...currentStatuses, rowSelectedObj]
            : currentStatuses;

          // Update the form control
          const statusData = this.formData.FormatData?.find(data => data.Name === FORMAT_FORM.Status);
          statusData ? statusData.Value = updatedStatuses : '';
          this.form.patchValue({
            [FORMAT_FORM.Status]: updatedStatuses,
          });
        } else {
          console.warn('No valid row selected token found.');
        }
      }
    });
  }

  openMlTextDialog() {
    const dialogRef = this.dialog.open(DialogEditComponent, {
      data: {
        title: this.dialogData.title,
        objectId: this.dialogData.objectId,
        language: this.languagesList,
        value: this.form.controls[FORMAT_FORM.Comment].value,
        context: ContextMenu.FormatLocalColumn
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      const mlText = result;
      if (typeof result === 'object') {
        this.form.patchValue({
          [FORMAT_FORM.Comment]: mlText?.[SystemInitials.English],
          [FORMAT_FORM.CommentObject]: mlText,
        });
      }
    });
  }

  async populateFormData() {
    if (this.dialogData?.context === ContextMenu.FormatPage) {
      this.getPageFormat();
      
    } else if (this.dialogData?.context === ContextMenu.FormatLocalRow) {
      this.getLocalRow();

    } else if (this.dialogData?.context === ContextMenu.FormatLocalColumn) {
      this.getLocalColumn();

    } else if (this.dialogData?.context === ContextMenu.FormatLocalCell) {
      this.getLocalCell();

    } else if (this.dialogData?.context === ContextMenu.FormatLocalItem) {
      this.getLocalItem();
    }
  }

  getFormLabels(token: string) {
    const row: any = this.contextMenus.find((menu: any) => menu.token === token);
    const menu =  this.contextMenus.filter((menu: any) => menu.ParentRow.Row === row.row);
    return menu.map((entry: any) => {
      const [ role, column ] = this.mainService.transformTokenFormula(entry.token_formula);
      const field = {
        label: entry.token,
        name: column,
        readonly: role === 'System' ? true : false,
        clickAction: entry.token.includes('Style') || entry.token.includes('Comment') || entry.token.includes('Status'),
        pattern: '',
      }

      return field;
    });
  }

  getPageFormat() {
    this.store.dispatch(new Format.GetPage()).subscribe();
    this.pageFormatData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data?.FormatData) {
          this.formData.FormatData = data.FormatData;
          this.formData.PageData = data.PageData;
          this.form = this.createForm(this.formData)
          this.formDataChange.emit({ form: this.form });
          this.form.valueChanges.subscribe((updatedValues) => {
            this.formDataChange.emit({
              form: this.form
            });
          });

          this.dialogData.rowId = (data.PageData
            .find((field: FormatData) => field.Name === FORMAT_FORM.Row) as FormatData)?.Value
        }
      });
  }

  getLocalColumn() {
    this.store.dispatch(new Format.GetLocalColumn(this.dialogData.objectId)).subscribe();
    this.localColFormatData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data?.FormatData) {
          this.formData.FormatData = data.FormatData;
          this.formData.PageData = data.PageData;
          this.form = this.createForm(this.formData);
          this.formDataChange.emit({ form: this.form });
          this.form.valueChanges.subscribe((updatedValues) => {
            this.formDataChange.emit({
              form: this.form
            });
          });

          this.dialogData.rowId = (data.PageData
            .find((field: FormatData) => field.Name === FORMAT_FORM.Row) as FormatData)?.Value
        }
      });
  }

  getLocalRow() {
    this.store.dispatch(new Format.GetLocalRow(this.dialogData.objectId)).subscribe();
    this.localRowFormatData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data?.FormatData) {
          this.formData.FormatData = data.FormatData;
          this.formData.PageData = data.PageData;
          this.form = this.createForm(this.formData);
          this.formDataChange.emit({ form: this.form });
          this.form.valueChanges.subscribe((updatedValues) => {
            this.formDataChange.emit({
              form: this.form
            });
          });
        }
      });
  }

  getLocalCell() {
    const colId: number = this.dialogData.colId;
    const rowId: number = this.dialogData.rowId;

    this.store.dispatch(new Format.GetLocalCell(colId, rowId))
    this.localCellFormatData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data?.FormatData) {
          this.formData.FormatData = data.FormatData;
          this.formData.PageData = data.PageData;
          this.form = this.createForm(this.formData);
          this.formDataChange.emit({ form: this.form });
          this.form.valueChanges.subscribe((updatedValues) => {
            this.formDataChange.emit({
              form: this.form
            });
          });

          // Set dialog title
          const objectEntry: FormatData | undefined = this.getObjectEntry(this.formData);
          this.dialogData.title = this.mainService.getMessage({
            Action: Actions.Format,
            Object: DbObjects.LocalCell,
            ID: objectEntry ? Number(objectEntry.Value) : 0,
            Message: Message.TitleMessage
          });
        }
      });
  }

  getLocalItem() {
    const colId: number = this.dialogData.colId;
    const rowId: number = this.dialogData.rowId;
    const item: string = this.dialogData.item;
    const itemId: number = this.dialogData.objectId;
    if (!itemId) throw new Error(`Target item not found: ${item}`);
    this.mainService.CurrentItemId$.next(itemId);
    this.store.dispatch(new Format.GetLocalItem(Number(itemId))).subscribe();
    this.localItemFormatData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data?.FormatData) {
          this.formData.FormatData = data.FormatData;
          this.formData.PageData = data.PageData;
          this.form = this.createForm(this.formData);
          this.formDataChange.emit({ form: this.form });
          this.form.valueChanges.subscribe((updatedValues) => {
            this.formDataChange.emit({
              form: this.form
            });
          });
        }
      });
  }

  setFormFields(formFields: SetFormatFormFields) {
    // Iterate through form fields and dynamically update the form values
    formFields.FormFields.forEach((field) => {
      let value: any = '';
      if (field.name == FORMAT_FORM.ColId || field.name == FORMAT_FORM.PgId 
          || field.name == FORMAT_FORM.ItemId
        ) {
        value = formFields.ObjectId || '';
      } else if (field.name === FORMAT_FORM.Status) {
        // Convert the status object into array of objects
        value = Object.entries(formFields.FormatData[field.name]).reduce((acc: any, [key, value]) => {
          acc.push({ 
            key: Number(key),
            value: value,
          });
          return acc;
        }, []);

      } else if (field.name === FORMAT_FORM.TxList) {
        value = formFields.FormatData[field.name]?.[0] || '';
      } else {
        value = formFields.FormatData[field.name] || '';
      }

      this.form.patchValue({
        [field.name]: value,
      });
    });
  }

  transformKeyValue(array: []) {
    return this.mainService.transformKeyValue(array);
  }

  trackByStatus(index: number, status: any): string {
    return status.key;
  }
  
  handleEditDD(event: Event, key: string): void {
    event.stopPropagation();
    this.openEditDdDialog(key);
  }

  getObjectEntry(formData: FormatFormData): FormatData | undefined {
    if (formData.FormatData) {
      const objectEntry: FormatData | undefined = formData.FormatData
        .find((field) => field.Name === FORMAT_FORM.Object);

      return objectEntry;
    }
    return undefined
  }

  initSubscriptions() {
    this.editDdiData$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data != undefined) {
        this.editDdiData = data;
      }
    });

    this.currentPgId$.subscribe((pg: any) => {
      this.currentPgId = pg;
    });

    this.languages$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.languagesList = data;
      data?.data['ALL Languages']?.map((item: any, index: number) => {
        this.languageArray[index] = {
          language: Object.values(item),
          row: Object.keys(item),
        };
      });
    });
  }
}
