import { Component, ViewChild, ElementRef, HostListener, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DragService } from '../../../../core/services/drag-service/drag.service';
import { ResizeService } from '../../../../core/services/resize-service/resize.service';
import { UNICODE_MULTIPLY } from '../../../../core/constants/app.contants';
import { ASCII_EXPAND_ICON, UNCICODE_CROSS_ICON } from '../../../../core/constants/app.contants';
@Component({
  selector: 'edit-dd-dialog-main',
  templateUrl: './edit-dd-dialog-main.component.html',
  styleUrls: ['./edit-dd-dialog-main.component.scss']
})
export class editDdDialogMainComponent{
  closeTab = UNCICODE_CROSS_ICON;
  offsetX = 0;
  offsetY = 0;
  isResizing: boolean = false;
  initialMouseX: number = 0;
  initialMouseY: number = 0;
  initialWidth: number = 0;
  initialHeight: number = 0;
  newWidth: number = 0;
  newHeight: number = 0;
  isDragging: boolean = false;
  scrollbarWidth = this.getScrollbarWidth();
  expandIcon = ASCII_EXPAND_ICON;
  constructor(
    public dialogRef: MatDialogRef<editDdDialogMainComponent>,
    private dragService: DragService,
    private resizeService: ResizeService
  ) {
  }

  onClose(): void {
    this.dialogRef.close();
  }
  // Function to calculate the side scrollbar width
  getScrollbarWidth(): number {
    return window.innerWidth - document.documentElement.clientWidth;
  }
  @ViewChild('popupContainer') popupContainer!: ElementRef;

  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContainer);
  }

  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContainer);
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragService.onMouseUp(); // Use the service method
  }
  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContainer);
  }
}
