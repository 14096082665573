import { Pipe, PipeTransform } from '@angular/core';
import { PgTab } from '../../store/pg-tab/pg-tab.model';

@Pipe({
  name: 'pageName',
})
export class PageNamePipe implements PipeTransform {

  transform(sheet: PgTab): string {
    return sheet?.page_name?.trim() || '';
  }

}
