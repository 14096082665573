import {
  Component,
  ViewChild,
  OnInit,
  Inject,
  ElementRef,
  HostListener,
  inject,
  DestroyRef,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ResizeService } from '../../../core/services/resize-service/resize.service';
import { DragService } from '../../../core/services/drag-service/drag.service';
import {
  ENGLISH,
  FORMAT_FORM,
  UNICODE_CROSS_ICON,
  UNICODE_EXPAND_ICON,
} from '../../../core/constants/app.contants';
import { Store } from '@ngxs/store';
import { FormatStore } from '../../../store/format/format.store';
import { Format } from '../../../store/format/format.actions';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import { Observable } from 'rxjs';
import { SheetState } from '../../../store/page/page.store';
import { CellData, RowModeData, ItemData } from '../../models/edit-dd-dailogs/dd-dailog.models';
import { FormatFormData, FormatData } from '../../models/format/format.models';
import { ContextMenu, FormFieldsChip } from '../../../core/constants/menu-bar/page/page';
import { SystemInitials } from '../../../constant';
import { MainService } from '../../../core/services/main-service/main.service';
import { Actions, DbObjects, Message } from '../../../core/enums/tokens/tokens.enum';

@Component({
  selector: 'app-dialog-view',
  templateUrl: './dialog-view.component.html',
  styleUrls: ['./dialog-view.component.scss'],
})
export class DialogViewComponent implements OnInit {
  private modalRef!: NgbModalRef;
  readonly FORM = FORMAT_FORM;
  destroyRef = inject(DestroyRef);
  data: any;
  expandIcon = UNICODE_EXPAND_ICON;
  closeTab = UNICODE_CROSS_ICON;
  dynamicForm: FormGroup = this.fb.group({});
  contextMenus: RowModeData[] = [];
  formData: FormatFormData = {};
  currentPgId!: number;
  viewedID = '';

  @ViewChild('viewPageContainer', { static: false })
  popupContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef<HTMLDivElement>;
  ngAfterViewInit() {
    setTimeout(() => {
      this.scrollContainer.nativeElement.scrollTop = 0; // Scroll to top
      this.scrollContainer.nativeElement.scrollLeft = 0; // Scroll to top
    }, 200); // 200ms delay
  }

  viewPageData$ = inject(Store).select(FormatStore.viewPageData);
  viewColumnData$ = inject(Store).select(FormatStore.viewColumnData);
  viewRowData$ = inject(Store).select(FormatStore.viewRowData);
  viewCellData$ = inject(Store).select(FormatStore.viewCellData);
  viewItemData$ = inject(Store).select(FormatStore.viewItemData);
  currentPgId$ = inject(Store).select(PgTabStore.getCurrentPgId);
  isLoading$: Observable<boolean> = inject(Store).select(FormatStore.isLoading);
  contextMenus$ = inject(Store).select(SheetState.getContextMenus); 

  constructor(
    public dialogRef: MatDialogRef<DialogViewComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private fb: FormBuilder,
    private resizeService: ResizeService,
    private dragService: DragService,
    private mainService: MainService,
    private store: Store,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initSubscriptions();
    this.viewFormatData();
  }

  viewFormatData(): void {
    this.currentPgId$.subscribe((pg: any) => {
      this.currentPgId = pg;
    });

    if (this.dialogData.context === ContextMenu.ViewPage) {
      this.dialogData.objectId = this.currentPgId;
      this.getViewFormatPageData();
    } else if (this.dialogData.context === ContextMenu.ViewColumn) {
      this.getViewFormatColData();
    } else if (this.dialogData.context === ContextMenu.ViewRow) {
      this.getViewFormatRowData();
    } else if (this.dialogData.context === ContextMenu.ViewCell) {
      this.getViewFormatCellData();
    } else if (this.dialogData.context === ContextMenu.ViewItem) {
      this.getViewFormatItemData();
    }
  }

  private createDynamicForm(formData: FormatFormData): FormGroup {
    if (!formData) return this.fb.group({});

    const formGroup: { [key: string]: any } = {};

    // Add form fields from sections
    if (formData.PageData) {
      formData.PageData.forEach((section: FormatData) => {
        section.Name
          ? formGroup[section.Name] = section.Value
          : '';
      });
    }

    // Add form fields from formFields array
    if (formData.FormatData) {
      formData.FormatData.forEach((field: FormatData) => {
        if (field.Name === FORMAT_FORM.Comment && field.Value) field.Value = field.Value?.[SystemInitials.English];
        if (field.Name === FORMAT_FORM.Status && field.Value) {
          field.Value = this.mainService.transformKeyValue(field?.Value)
            .map(keyValue => keyValue.value).join(';');
        }
      
        field.Name ? formGroup[field.Name] = field.Value : '';
      });
    }

    return this.fb.group(formGroup);
  }

  getObjectEntry(formData: FormatFormData): FormatData | undefined {
    if (formData.FormatData) {
      const objectEntry: FormatData | undefined = formData.FormatData
        .find((field) => field.Name === FORMAT_FORM.Object);

      return objectEntry;
    }
    return undefined
  }

  getViewFormatPageData(): void {
    this.store.dispatch(new Format.ViewPage());
    this.viewPageData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data?.FormatData) {
          this.formData.FormatData = data.FormatData;
          this.formData.PageData = data.PageData;
          this.dynamicForm = this.createDynamicForm(this.formData);
        }
      });
  }

  getViewFormatColData(): void {
    this.store.dispatch(new Format.ViewColumn(this.dialogData.objectId));
    this.viewColumnData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data) {
          this.formData.FormatData = data.FormatData;
          this.formData.PageData = data.PageData;
          this.dynamicForm = this.createDynamicForm(this.formData);
        }
      });
  }

  getViewFormatRowData(): void {
    this.store.dispatch(new Format.ViewRow(this.dialogData.objectId));
    this.viewRowData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data) {
          this.formData.FormatData = data.FormatData;
          this.formData.PageData = data.PageData;
          this.dynamicForm = this.createDynamicForm(this.formData);
        }
      });
  }

  getViewFormatCellData(): void {
    this.store.dispatch(new Format.ViewCell(this.dialogData.data.colId, this.dialogData.data.rowId));
    this.viewCellData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data) {
          this.formData.FormatData = data.FormatData;
          this.dynamicForm = this.createDynamicForm(this.formData);
          
          // Set dialog title
          const objectEntry: FormatData | undefined = this.getObjectEntry(this.formData);
          this.dialogData.title = this.mainService.getMessage({
            Action: Actions.View,
            Object: DbObjects.LocalCell,
            ID: objectEntry ? Number(objectEntry.Value) : 0,
            Message: Message.TitleMessage
          });
        }
      });
  }

  getViewFormatItemData(): void {
    const cell: CellData = this.mainService.getCell(this.currentPgId, this.dialogData.data.colId, this.dialogData.data.rowId);
    const item: string = this.dialogData.data.item;
    let targetItems: ItemData[] = [];
    if (cell.hasOwnProperty('cell')) targetItems = cell.cell.items;
    if (cell.hasOwnProperty('format') && cell.format) targetItems = cell.format.items;

    const targetItem = targetItems.find(cellItem => 
      Object.values(cellItem.item)[0]?.trim() === item.trim().replace(/-$/, "")
    ) as ItemData | undefined;
    if (!targetItem) throw new Error(`Target item not found: ${item}`);
    
    this.store.dispatch(new Format.ViewItem(targetItem.id));
    this.viewItemData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: any) => {
        if (data) {
          this.formData.FormatData = data.FormatData;
          this.formData.PageData = data.PageData;
          this.dynamicForm = this.createDynamicForm(this.formData);

          // Set dialog title
          this.dialogData.title = this.mainService.getMessage({
            Action: Actions.View,
            Object: DbObjects.LocalItem,
            ID: Number(targetItem.id),
            Message: Message.TitleMessage
          });
        }
      });
  }

  checkChip(fieldName: string | undefined): boolean {
    return this.mainService.checkChip(fieldName);
  }

  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContainer);
  }

  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContainer);
  }

  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContainer);
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragService.onMouseUp(); // Use the service method
  }

  onDelete(): void {
    this.dialogRef.close(true);
    this.modalRef.dismiss('Save click');
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }

  initSubscriptions() { }
}
