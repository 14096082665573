import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideStore, Store } from '@ngxs/store';
import { SheetState } from './store/page/page.store';
import { ApiInterceptor } from './core/interceptors/api.interceptor';
import { ItemState } from './store/item/item.store';
import { FormatStore } from './store/format/format.store';
import { PgTabStore } from './store/pg-tab/pg-tab.store';
import { PgRowStore } from './store/row/row.store';
import { ColRowStore } from './store/cols/cols.store';
import { AppStore } from './store/root/root.store';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { LabelsStore } from './store/labels/labels.store';
import { FetchLanguages, LoadPgTabs } from './store/pg-tab/pg-tab.actions';
import { All_Pages_ID } from './core/constants/app.contants';
import { MenuBarStore } from './store/menu-bar/menu-bar.store';
import { GetMenuBar } from './store/menu-bar/menu-bar.actions';
import { GetCurrentObjectDefinitions } from './store/labels/labels.action';
import { MessageStore } from './store/message/message.store';

export function appInitializerFactory(store: Store) {
  return () => {
    store.dispatch(new GetCurrentObjectDefinitions());
    store.dispatch(new LoadPgTabs(All_Pages_ID));
    store.dispatch(new GetMenuBar('Menu Bar'));
    store.dispatch(new FetchLanguages());
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideStore([
      AppStore,
      SheetState,
      ItemState,
      FormatStore,
      PgTabStore,
      PgRowStore,
      ColRowStore,
      LabelsStore,
      MenuBarStore,
      MessageStore,
    ], withNgxsReduxDevtoolsPlugin()),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [Store],
      multi: true,
    }
  ],
};
