import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PgTabStateModel, PgTab } from './pg-tab.model';
import { tPgService } from '../../core/services/tPg.service';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {
  AddPgTab,
  LoadPgTabs,
  SelectPgTab,
  SetFontStyle,
  FetchLanguages,
  DeletePgCol,
  UpdatePgTabs,
  AutoTranslation,
} from './pg-tab.actions';
import {
  All_Pages_ID,
} from '../../core/constants/app.contants';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { Sheet } from '../page/page.actions';
import { PageTabState } from './pg-tab.state';
import { MainService } from '../../core/services/main-service/main.service';
import { SheetState } from '../page/page.store';
import { RowPageData } from '../../shared/models/edit-dd-dailogs/dd-dailog.models';
import { getKeyFromValue, getValueFromObject } from '../../utils/utils';
import { PgTabFacadeService } from '../../core/services/pg-tab/pg-tab-facade.service';

@State<PgTabStateModel>({
  name: 'pgTabs',
  defaults: PageTabState,
})
@Injectable()
export class PgTabStore {
  constructor(
    private _tPgService: tPgService,
    private store: Store,
    private mainService: MainService,
    private pgTabFacadeService : PgTabFacadeService,
  ) {}

  @Selector()
  static getPgTabs(state: PgTabStateModel): PgTab[] {
    return state.pgTabs;
  }

  @Selector()
  static getDefaultPgTab(state: PgTabStateModel): PgTab {
    return state.defaultPgTab;
  }

  @Selector()
  static getSelectedPgTab(state: PgTabStateModel): PgTab {
    return state.selectedPgTab;
  }

  @Selector()
  static getCurrentPgId(state: PgTabStateModel): number {
    return state.defaultPageId;
  }

  @Selector()
  static isLoading(state: PgTabStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static getError(state: PgTabStateModel): string | null {
    return state.error;
  }

  @Selector()
  static getSheetData(state: PgTabStateModel): any {
    return state.sheetData;
  }

  @Selector()
  static getSheetColumns(state: PgTabStateModel): any {
    return state.sheetColumns;
  }

  @Selector()
  static getFontStyle(state: PgTabStateModel) {
    return state.fontStyle;
  }

  @Selector()
  static getLanguages(state: PgTabStateModel) {
    return state.languages;
  }

  @Action(AutoTranslation)
  autoTranslation(ctx: StateContext<PgTabStateModel>, action: AutoTranslation) {
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
    const pgTab = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as PgTab;
    ctx.dispatch(
      new Sheet.UpdatePageData({
          allTabsData: allTabsData,
          allTabsFlatData: allTabsFlatData,
          defaultPageId: pgTab.page_id,
      })
    );
  }

  @Action(LoadPgTabs)
  async loadPgTabs(ctx: StateContext<PgTabStateModel>, action: LoadPgTabs) {
    const { page_id } = action;
    ctx.patchState({
      loading: true,
    });
    this.loadContextMenus();
    return this._tPgService.getAllPages().pipe(
      tap((data: any) => {
        const flatData = this.mainService.mapAllPagesFlatData(data);
        const mappedValue = this.mainService.mapAllPagesData(data);
        this.store.dispatch(
          new Sheet.UpdatePageData({
            allTabsData: mappedValue.allTabsData,
            allTabsFlatData: flatData.allTabsData,
            defaultPageId: mappedValue?.defaultPageId,
          })
        );
        if (page_id === All_Pages_ID) {
          const tabs = flatData.allTabsData.filter(
            (obj) => All_Pages_ID && obj[All_Pages_ID] !== undefined
          )[0];
          if(!tabs[Number(All_Pages_ID)]) return;
          let pgTabs = tabs[Number(All_Pages_ID)].pageData.filter(
            (res: RowPageData) => {
              const id = Number(res.page_id.cell.items[0].item);
              const tab = flatData.allTabsData.filter(
                (obj) => id && obj[id] !== undefined
              )[0];
              return tab[id]?.pageData && tab[id].pageData.length > 0;
            }
          );
          pgTabs = pgTabs.map((element: PgTab, index : number) => {
            const transformedElement = Object.fromEntries(
              Object.entries(element).map(([key, value]) => {
                if (value.cell || value.format) {
                  return [key, getKeyFromValue(value)];
                }
                return [key, value,];
              })
            );
            return {
              ...element,
              ...transformedElement,
              tab_id : Number(transformedElement['page_id'] + index)
            };
          });
          this.pgTabFacadeService.setCurrentObjectsWithSession(pgTabs);
          const defaultPgTab = pgTabs[0];
          ctx.patchState({
            pgTabs,
            loading: false,
            defaultPageId: defaultPgTab?.page_id || 0,
            selectedPgTab: defaultPgTab,
          });
        }
      }),
      catchError((error) => {
        ctx.patchState({ error: 'Failed to load pgTabs', loading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(SelectPgTab)
  selectPgTab(ctx: StateContext<PgTabStateModel>, { pgTab }: SelectPgTab) {
    const allTabsFlatData = this.store.selectSnapshot(
      SheetState.getSheetFlatData
    );
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    ctx.patchState({
      selectedPgTab: pgTab,
      defaultPageId: Number(pgTab.page_id),
    });
    ctx.dispatch(
      new Sheet.UpdatePageData({
        allTabsData: allTabsData,
        allTabsFlatData: allTabsFlatData,
        defaultPageId: pgTab.page_id,
      })
    );
  }

  @Action(AddPgTab)
  addPgTab(ctx: StateContext<PgTabStateModel>, { pgTab }: SelectPgTab) {
    const state = ctx.getState();
    const newTab = {
      ...pgTab,
      tab_id : Number(getValueFromObject(pgTab.page_id)) + state.pgTabs.length.toString()
    }

    if(newTab){
      this.pgTabFacadeService.addNewTabWithLabels(newTab);
    }

    ctx.patchState({
      pgTabs: [...state.pgTabs, newTab],
      defaultPgTab: newTab,
      selectedPgTab: newTab,
    });
    ctx.dispatch(
      new Sheet.UpdatePageData({
        defaultPageId: pgTab.page_id,
      })
    );
  }

  @Action(UpdatePgTabs)
  updatePgTabs(
    ctx: StateContext<PgTabStateModel>,
    { pgTabs, defaultPgTabId }: UpdatePgTabs
  ) {
    const state = ctx.getState();
    ctx.patchState({
      pgTabs: pgTabs,
      defaultPageId: defaultPgTabId,
    });
  }

  @Action(DeletePgCol)
  deletePgCol(ctx: StateContext<PgTabStateModel>, { colId }: DeletePgCol) {
    // select the snapshot state from PgTabStore
    const pgTab = this.store.selectSnapshot(
      PgTabStore.getSelectedPgTab
    ) as PgTab;
    const pgId = Number(pgTab.page_id);
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);

    // Delete page column from store
    this.mainService.deletePageColumnById(pgId, colId, allTabsData);
  }

  @Action(SetFontStyle)
  setFontStyle(ctx: StateContext<PgTabStateModel>, action: SetFontStyle) {
    // Call the service method which performs a side effect (e.g., updating font styles) but does not return data
    this.mainService.setFontStyles(action.payload);

    // Retrieve the current state
    const state = ctx.getState();

    // Prepare the new state model
    const stateModel = {
      fontStyle: action.payload, // Directly use action.payload
    };

    // Update the state with the new font style
    ctx.setState({
      ...state,
      ...stateModel,
    });
  }

  @Action(FetchLanguages)
  FetchLanguages(ctx: StateContext<PgTabStateModel>) {
    return this._tPgService.getLanguages().pipe(
      tap((data: any) => {
        const state = ctx.getState();
        // Update the existing state
        ctx.setState({
          ...state,
          languages: data,
        });
      })
    );
  }

  loadContextMenus() {
    this.store.dispatch(
      new Sheet.GetContextMenus()
    );
  }
}
