export enum Notify {
  Alert = 'Alert',
  Error = 'Error',
  Notice = 'Notice',
  Success = 'Success'
}

export enum Actions {
  Add = 'Add',
  Edit = 'Edit',
  Format = 'Format',
  View = 'View',
  Delete = 'Delete',
}

export enum DbObjects {
  Page = 'Page',
  Row = 'Row',
  Column = 'Column',
  Cell = 'Cell',
  Item = 'Item', 
  LocalRow = 'Local Row',
  LocalColumn = 'Local Column',
  LocalCell = 'Local Cell',
  LocalItem = 'Local Item',
}

export enum SystemObjects {
  DbObjects = 'DB Objects',
  ActionObjects = 'Action Objects',
  Notify = 'Notify',
  Reason = 'Reason',
  Message = 'Message'
}

export enum Reason {
  Actioned = 3000085040,
  Delete = 3000085060
}

export enum Message {
  TitleMessage = 3000085230,
  ResponseMessage = 3000085240,
}
