export enum TabulatorEvents {
  TableBuilt = "tableBuilt",
  TableDestroyed = "tableDestroyed",
  DataLoading = "dataLoading",
  DataLoaded = "dataLoaded",
  DataChanged = "dataChanged",
  DataFiltered = "dataFiltered",
  DataSorted = "dataSorted",
  RowClick = "rowClick",
  RowDblClick = "rowDblClick",
  RowContext = "rowContext",
  RowAdded = "rowAdded",
  RowDeleted = "rowDeleted",
  RowUpdated = "rowUpdated",
  RowSelectionChanged = "rowSelectionChanged",
  CellClick = "cellClick",
  CellDblClick = "cellDblClick",
  CellContext = "cellContext",
  CellEdited = "cellEdited",
  CellEditing = "cellEditing",
  ColumnResized = "columnResized",
  ColumnVisibilityChanged = "columnVisibilityChanged",
  PageLoaded = "pageLoaded",
  RenderStarted = "renderStarted",
  RenderComplete = "renderComplete",
  ColumnMoved = "columnMoved",
  RowSelection = "rowSelection"
}

export enum TabulatorFormatters {
  Date = "date",
  Datetime = "datetime",
  Money = "money",
  Progress = "progress",
  Link = "link",
  TickCross = "tickCross",
  Color = "color",
  Star = "star",
  ButtonTick = "buttonTick",
  ButtonCross = "buttonCross",
  RowSelection = "rowSelection",
  JSON = "json",
  Plaintext = "plaintext",
  TEXT_AREA = "textarea",
  HTML = "HTML",
}
