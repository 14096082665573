import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { PgTabStateModel, PgTab, MenuItem } from './pg-tab.model';
import { tPgService } from '../../core/services/tPg.service';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AddPgTab, LoadMenuBar, LoadPgTabs, SelectPgTab } from './pg-tab.actions';
import {
  All_Pages_ID,
  EXCLUDED_PAGE_IDS,
} from '../../core/constants/app.contants';
import { SessionStorageService } from '../../core/services/session-storage.service';
import { Sheet } from '../page/sheet.actions';
import { PageTabState } from './pg-tab.state';

@State<PgTabStateModel>({
  name: 'pgTabs',
  defaults: PageTabState,
  })
@Injectable()
export class PgTabStore {
  constructor(
    private _tPgService: tPgService,
    private store: Store,
    private sessionStorageService: SessionStorageService
  ) {}

  @Selector()
  static getPgTabs(state: PgTabStateModel): PgTab[] {
    return state.pgTabs;
  }

  @Selector()
  static getDefaultPgTab(state: PgTabStateModel): PgTab {
    return state.defaultPgTab;
  }
  @Selector()
  static getSelectedPgTab(state: PgTabStateModel): PgTab  {
    return state.selectedPgTab;
  }

  @Selector()
  static isLoading(state: PgTabStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static getError(state: PgTabStateModel): string | null {
    return state.error;
  }

  static getSheetData(state: PgTabStateModel): any {
    return state.sheetData;
  }

  @Selector()
  static getSheetColumns(state: PgTabStateModel): any {
    return state.sheetColumns;
  }

  @Selector()
  static getMenuBar(state: PgTabStateModel): any {
    return state.menuBars;
  }

  // Actions
  @Action(LoadPgTabs)
  async loadPgTabs(ctx: StateContext<PgTabStateModel>, action: LoadPgTabs) {
    const { page_id } = action;
    ctx.patchState({ loading: true, error: null });
    const cachedData = await this.sessionStorageService.getPageData(page_id);
    if (cachedData) {
      const pgTabs = cachedData?.pageData
        .filter(
          (item: { page_id: string }) =>
            !EXCLUDED_PAGE_IDS.includes(item.page_id)
        )
        ?.filter((el: { RowLevel: number }) => el.RowLevel == 1);
      const defaultPgTab = pgTabs[0];
      ctx.patchState({
        pgTabs: pgTabs,
        loading: false,
        defaultPgTab: defaultPgTab,
        selectedPgTab: defaultPgTab
      });
      this.store.dispatch(
        new Sheet.UpdatePageData({
          pageData: cachedData?.pageData,
          pageColumns: cachedData?.pageColumns,
          sheetPageId: page_id,
        })
      );
      return;
    } else {
      return this._tPgService.getPages(page_id).pipe(
        tap((data: any) => {
          this.store.dispatch(
            new Sheet.UpdatePageData({
              pageData: data?.data?.pageData,
              pageColumns: data?.data?.pageColumns,
            })
          );
          this.sessionStorageService.savePageData(page_id, {
            pageData: data?.data?.pageData,
            pageColumns: data?.data?.pageColumns,
          });
          if (page_id === All_Pages_ID) {
            const pgTabs = data?.data?.pageData
              .filter(
                (item: { page_id: string }) =>
                  !EXCLUDED_PAGE_IDS.includes(item.page_id)
              )
              ?.filter((el: { RowLevel: number }) => el.RowLevel == 1);
            const defaultPgTab = pgTabs[0];
            ctx.patchState({
              pgTabs,
              loading: false,
              sheetData: data?.data?.pageData,
              sheetColumns: data?.data?.pageColumns,
            });
          }
        }),
        catchError((error) => {
          ctx.patchState({ error: 'Failed to load pgTabs', loading: false });
          return throwError(() => error);
        })
      );
    }
  }

  @Action(LoadMenuBar)
  loadMenuBar(ctx: StateContext<PgTabStateModel>, action: LoadMenuBar) {
    const { DDL } = action;
    return this._tPgService.getDDL(DDL).pipe(
      tap((data: any) => {
        ctx.patchState({
          menuBars: data?.data,
        });
      })
    );
  }

  @Action(SelectPgTab)
  selectPgTab(ctx: StateContext<PgTabStateModel>, { pgTab }: SelectPgTab) {
    ctx.patchState({ selectedPgTab: pgTab });
  }

  @Action(AddPgTab)
  addPgTab(ctx : StateContext<PgTabStateModel>, { pgTab } : SelectPgTab){
    const state = ctx.getState();
    ctx.patchState({
      pgTabs:  [...state.pgTabs, pgTab],
      defaultPgTab : pgTab
    });
  }


  @Action(SelectPgTab)
    selectPageTab(ctx : StateContext<PgTabStateModel>, { pgTab } : SelectPgTab){
    const state = ctx.getState();
    ctx.patchState({
      selectedPgTab : pgTab
    });
  }
}
