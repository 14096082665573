import { Component, ViewChild, OnInit, Inject, ElementRef, HostListener, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { DragService } from '../../../core/services/drag-service/drag.service';
import { ResizeService } from '../../../core/services/resize-service/resize.service';
import { ASCII_EXPAND_ICON, ObjectType } from '../../../core/constants/app.contants';
import { Format } from '../../../store/format/format.actions';
import { SystemInitials } from '../../../constant';
import { RowComponent, Tabulator } from 'tabulator-tables';
import { TabUIService } from '../page/services/tab-ui.service';
import { DeletePgCol } from '../../../store/pg-tab/pg-tab.actions';
import { MainService } from '../../../core/services/main-service/main.service';
import { Observable } from 'rxjs';
import { FormatStore } from '../../../store/format/format.store';
import { CellData, ItemData } from '../../models/edit-dd-dailogs/dd-dailog.models';
import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import { PgTab } from '../../../store/pg-tab/pg-tab.model';
import { GetCellPaylaod, UpdateItemPayload } from '../../models/format/format.models';
import { Actions, DbObjects, Message, Notify, Reason } from '../../../core/enums/tokens/tokens.enum';

@Component({
  selector: 'app-dialog-delete',
  templateUrl: './dialog-delete.component.html',
  styleUrls: ['./dialog-delete.component.scss'],
})
export class DialogDeleteComponent implements OnInit {
  private modalRef!: NgbModalRef;
  public isFormValid: boolean = true;
  public failureMessage: string = '';
  public successMessage: string = '';
  public apiSuccessFlag: boolean = false;
  public closeResult: string = '';
  public expandIcon = ASCII_EXPAND_ICON;
  @ViewChild('content') popupContent!: ElementRef;

  isLoading$: Observable<boolean> = inject(Store).select(FormatStore.isLoading);

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<DialogDeleteComponent>,
    private store: Store,
    private dragService: DragService,
    private resizeService: ResizeService,
    private tabUIService: TabUIService,
    private mainService: MainService,
  ) { }

  ngOnInit() {}

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onCancel();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onCancel();
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContent);
  }

  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContent);
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragService.onMouseUp(); // Use the service method
  }
  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContent);
  }

  onDelete(): void {
    if (this.dialogData.name == ObjectType.DataObject.Item) {
      this.deleteItem();
    } else if (this.dialogData.name == ObjectType.DataObject.Page) this.deletePage();
      else if (this.dialogData.name == ObjectType.DataObject.Row) this.deleteRow();
      else if (this.dialogData.name == ObjectType.DataObject.Column) this.deleteColumn();
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef?.dismiss('Cancel click');
  }

  deleteColumn() {
    const colId = this.dialogData.colId;
    const colField = this.dialogData.colField;
    const tabulator: Tabulator = this.dialogData.tabulatorTable;
    const payload = {
      RecycledBy: SystemInitials.RecycledUser
    };

    // Dispatch an action to delete the column
    this.store.dispatch(new Format.DeleteColumn(colId, payload)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.store.dispatch(new DeletePgCol(colId)).subscribe(res => {});
          this.handleSuccess(this.mainService.getMessage({
            Notify: Notify.Success,
            Action: Actions.Delete,
            Object: DbObjects.LocalColumn,
            ID: colId,
            Reason: Reason.Actioned,
            Message: Message.ResponseMessage
          }));
          tabulator.deleteColumn(colField);

        } else {
          this.handleFailure(`${data.format.apiStatus.message}`);
        }
      },      
    });    
  }

  deleteRow() {
    const rowComponent: RowComponent = this.dialogData.row;
    const rowId: number = this.dialogData.rowId;
    const pgId = rowComponent.getData()['page_id'];
    const payload = {
      Row: [rowId],
      RecycledBy: SystemInitials.RecycledUser
    };

    // Dispatch an action to delete the row
    this.store.dispatch(new Format.DeleteRow(rowId, payload)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          // this.tabUIService.deleteRow(row);
          this.store.dispatch(new Format.DeleteRowSuccess(pgId, rowId)).subscribe(res => {});
          this.handleSuccess(this.mainService.getMessage({
            Notify: Notify.Success,
            Action: Actions.Delete,
            Object: DbObjects.LocalRow,
            ID: rowId,
            Reason: Reason.Actioned,
            Message: Message.ResponseMessage
          }));

        } else {
          this.handleFailure(`${data.format.apiStatus.message}`);
        }
      },      
    });    
  }

  deleteItem() {
    const pgTab = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as PgTab;
    const payload = { RecycledBy: SystemInitials.RecycledUser };
    const pgId = Number(pgTab.page_id);
    const colId: number = this.dialogData.colId;
    const rowId: number = this.dialogData.rowId;
    const item: string = this.dialogData.item;
    
    const cell: CellData = this.mainService.getCell(pgId, colId, rowId);
    let targetItems: ItemData[] = [];
    if (cell.hasOwnProperty('cell')) targetItems = cell.cell.items;
    if (cell.hasOwnProperty('format') && cell.format) targetItems = cell.format.items;

    const targetItem = targetItems.find(cellItem => 
      Object.values(cellItem.item)[0]?.trim() === item.trim().replace(/-$/, "")
    ) as ItemData | undefined;
    if (!targetItem) throw new Error(`Target item not found: ${item}`);

    const deleteItemPayload: UpdateItemPayload = {
      pgId: pgId,
      rowId: rowId,
      colId: colId,
      itemId: Number(targetItem.id),
      value: []
    };

    // Dispatch an action to delete the row
    this.store.dispatch(new Format.DeleteItem(Number(targetItem.id), payload)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.store.dispatch(new Format.DeleteItemSuccess(deleteItemPayload)).subscribe(res => {});
          this.handleSuccess(this.mainService.getMessage({
            Notify: Notify.Success,
            Action: Actions.Delete,
            Object: DbObjects.LocalItem,
            ID: Number(targetItem.id),
            Reason: Reason.Actioned,
            Message: Message.ResponseMessage
          }));

        } else {
          this.handleFailure(`${data.format.apiStatus.message}`);
        }
      },      
    });    
  }

  deletePage() {
    const pgId = Number(this.dialogData.pgId);
    const payload = {
      RecycledBy: SystemInitials.RecycledUser
    };

    // Dispatch an action to delete the column
    this.store.dispatch(new Format.DeletePage(pgId, payload)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.store.dispatch(new Format.DeletePageSuccess(pgId)).subscribe(res => {});
          this.handleSuccess(this.mainService.getMessage({
            Notify: Notify.Success,
            Action: Actions.Delete,
            Object: DbObjects.Page,
            ID: pgId,
            Reason: Reason.Actioned,
            Message: Message.ResponseMessage
          }));

        } else {
          this.handleFailure(`${data.format.apiStatus.message}`);
        }
      },      
    });    
  }

  handleSuccess(message: string) {
    this.isFormValid = true;
    this.apiSuccessFlag = true;
    this.successMessage = message;
  }

  handleFailure(message: string) {
    this.isFormValid = false;
    this.apiSuccessFlag = false;
    this.failureMessage = message;
  }
}
