import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from './components/page/page.component';
import { EditDdDialogTabBarComponent } from './components/edit-dd-dialog/edit-dd-dialog-tab-bar/edit-dd-dialog-tab-bar.component'; 
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogEditComponent } from './components/dialog-edit/dialog-edit.component';
import { AngularWindowComponent } from '../modals/angular-window/angular-window.component';
import { DialogDeleteComponent } from './components/dialog-delete/dialog-delete.component';
import { EditionDialogComponent } from '../modals/edition-dialog/edition-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogViewComponent } from './components/dialog-view/dialog-view.component';
import { DialogFormatComponent } from '../modals/dialog-format/dialog-format.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { DialogFontComponent } from './components/dialog-font/dialog-font.component';
import { ColorPickerModule } from 'ngx-color-picker';
// import { MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { FormatLocalSharedColComponent } from '../modals/format-local-shared-col/format-local-shared-col.component';
import { ViewColDialogComponent } from '../modals/view-col-dialog/view-col-dialog.component';
import { EditionPGNameDialogComponent } from '../modals/edition-pgname-dialog/edition-pgname-dialog.component';
import { PGEditionDialogComponent } from '../modals/pgedition-dialog/pgedition-dialog.component';
import { OpenEditionDialogComponent } from '../modals/open-edition-dialog/open-edition-dialog.component';
import { FormatRowDialogComponent } from '../modals/format-row-dialog/format-row-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { FormatPageDialogComponent } from '../modals/format-page-dialog/format-page-dialog.component';
import { PGUserDialogComponent } from '../modals/pguser-dialog/pguser-dialog.component';
import { PGColDialogComponent } from '../modals/pgcol-dialog/pgcol-dialog.component';
import { PickColDialogComponent } from '../modals/pickCol-Dialog/pickcol-dialog.component';
import { ViewPageDialogComponent } from '../modals/view-page-dialog/view-page-dialog.component';
import { AddRowDialogComponent } from '../modals/add-row-dialog/add-row-dialog.component';
import { DialogEditUrlComponent } from '../modals/dialog-edit-url/dialog-edit-url.component';
import { AllPagesDialogComponent } from '../modals/all-pages-dialog/all-pages-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DropdownDialogComponent } from '../modals/dropdown-dialog/dropdown-dialog.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { EditAmountDialogComponent } from '../modals/edit-amount-dialog/edit-amount-dialog.component';
import { EditJSONDialogComponent } from '../modals/edit-json-dialog/edit-json-dialog.component';
import { AddEditTextComponent } from '../modals/add-edit-text/add-edit-text.component';
import { AddEditFormulaComponent } from '../modals/add-edit-formula/add-edit-formula.component';
import { AutoAdjustWidthDirective } from './directives/auto-adjust-width.directive';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { editDdContentComponent } from './components/edit-dd-dialog/edit-dd-dialog-content/edit-dd-dialog-content.component';
import { editDdDialogMainComponent } from './components/edit-dd-dialog/edit-dd-dialog-main/edit-dd-dialog-main.component';
import { editDdDialogMenuBarComponent } from './components/edit-dd-dialog/edit-dd-dialog-menu-bar/edit-dd-dialog-menu-bar.component';
import { FormatPageComponent } from './components/format-page-dialog/format-page/format-page-main.component';
import { FormatPageFormComponent } from './components/format-page-dialog/format-page-form/format-page-form.component';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { MenuBarComponent } from './components/menu-bar/menu-bar.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { PageNamePipe } from './pipes/page-name.pipe';
import { DecimalFieldComponent } from './components/decimal-field/decimal-field.component';
import { IsLanguageDisabledPipe } from './pipes/is-language-disabled.pipe';
import { DynamicInputWidthDirective } from './directives/dynamic-input-width.directive';
import { FreezeFieldComponent } from './components/freeze-field/freeze-field.component';
import { ControlShiftDirective } from './directives/control-shift.directive';
import { GetValueFromKeyPipe } from './pipes/get-value-from-key.pipe';
import { LabelsPipe } from './pipes/labels.pipe';
import { CkEditorComponent } from './components/ck-editor/ck-editor.component';
import { GetValueFormObjectPipe } from './pipes/get-value-form-object.pipe';

@NgModule({ declarations: [
        TabBarComponent,
        MenuBarComponent,
        PageComponent,
        DialogEditComponent,
        DropdownDialogComponent,
        EditAmountDialogComponent,
        AngularWindowComponent,
        DialogDeleteComponent,
        EditionDialogComponent,
        DialogViewComponent,
        DialogFormatComponent,
        DialogFontComponent,
        EditJSONDialogComponent,
        FormatLocalSharedColComponent,
        ViewColDialogComponent,
        AddEditFormulaComponent,
        EditionPGNameDialogComponent,
        PGEditionDialogComponent,
        OpenEditionDialogComponent,
        FormatRowDialogComponent,
        FormatPageDialogComponent,
        PGUserDialogComponent,
        PGColDialogComponent,
        ViewPageDialogComponent,
        DialogEditUrlComponent,
        AllPagesDialogComponent,
        AddEditTextComponent,
        AutoAdjustWidthDirective,
        editDdContentComponent,
        editDdDialogMainComponent,
        editDdDialogMenuBarComponent,
        FormatPageComponent,
        FormatPageFormComponent,
        PickColDialogComponent,
        EditDdDialogTabBarComponent,
        TooltipDirective,
        DynamicInputWidthDirective,
        PageNamePipe,
        IsLanguageDisabledPipe,
        AddRowDialogComponent,
        DecimalFieldComponent,
        FreezeFieldComponent,
        ControlShiftDirective,
        GetValueFromKeyPipe,
        LabelsPipe,
        CkEditorComponent
    ],
    exports: [
        TabBarComponent,
        MenuBarComponent,
        PageComponent,
        AngularWindowComponent,
        editDdContentComponent,
        editDdDialogMainComponent,
        editDdDialogMenuBarComponent,
        FormatPageFormComponent,
        TooltipDirective,
        PageNamePipe,
        IsLanguageDisabledPipe,
        MatProgressSpinnerModule,
        GetValueFromKeyPipe,
        LabelsPipe,
        CkEditorComponent
    ], imports:
      [
        DragDropModule,
        CommonModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatCheckboxModule,
        FormsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        ColorPickerModule,
        MatCardModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatOptionModule,
        MatTableModule,
        MatExpansionModule,
        NgxMaskDirective,
        ReactiveFormsModule,
        FormsModule,
        NgxMaskPipe,
        CKEditorModule,
        GetValueFormObjectPipe
    ], providers: [
        // { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }
        provideNgxMask(),
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class SharedModule { }
