import { Injectable } from '@angular/core';
import { LabelsFacadeService } from '../labels/labels-facade.service';
import { PgTab } from '../../../store/pg-tab/pg-tab.model';
import { TabCurrentLabels } from '../../interfaces/session.interface';
import { ObjectFilterKey } from '../../enums/pg-tabs/pg-tabs.enum';
import { SessionStorageService } from '../session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PgTabFacadeService {
  constructor(
    private labelsFacadeService : LabelsFacadeService,
    private sessionStorage : SessionStorageService
  ) {}

  mapDataFromCurrentObjects(pgTabs : PgTab[], key : ObjectFilterKey) : TabCurrentLabels{
    return this.labelsFacadeService.mapDataFromCurrentObjects(pgTabs, key)
  }

  setCurrentObjects(
    mapDataFromCurrentObjectsOnTab : TabCurrentLabels,
    mapDataFromCurrentObjectsOnUser : TabCurrentLabels,
    mapDataFromCurrentObjectsOnSession : TabCurrentLabels,
    withOutSession : boolean
  ){
    this.labelsFacadeService.setCurrentObjectsOnTabs(
      mapDataFromCurrentObjectsOnTab,
      mapDataFromCurrentObjectsOnUser,
      mapDataFromCurrentObjectsOnSession,
      withOutSession
    );
  }

  setCurrentObjectsWithSession(pgTabs : PgTab[]){
    if(
      this.sessionStorage.isDataInSession(ObjectFilterKey.TAB) &&
      this.sessionStorage.isDataInSession(ObjectFilterKey.USER) &&
      this.sessionStorage.isDataInSession(ObjectFilterKey.SESSION)
    ){
      const mapDataFromCurrentObjectsOnTabs = this.sessionStorage.getData(ObjectFilterKey.TAB);
      const mapDataFromCurrentObjectsOnSession = this.sessionStorage.getData(ObjectFilterKey.SESSION);
      const mapDataFromCurrentObjectsOnUser = this.sessionStorage.getData(ObjectFilterKey.USER);
      this.setCurrentObjects(
        mapDataFromCurrentObjectsOnTabs,
        mapDataFromCurrentObjectsOnUser,
        mapDataFromCurrentObjectsOnSession,
        true
      )
    }else{
      const mapDataFromCurrentObjectsOnTabs = this.mapDataFromCurrentObjects(pgTabs, ObjectFilterKey.TAB);
      const mapDataFromCurrentObjectsOnSession = this.mapDataFromCurrentObjects(pgTabs, ObjectFilterKey.SESSION);
      const mapDataFromCurrentObjectsOnUser = this.mapDataFromCurrentObjects(pgTabs, ObjectFilterKey.USER);
      this.setCurrentObjects(
        mapDataFromCurrentObjectsOnTabs,
        mapDataFromCurrentObjectsOnUser,
        mapDataFromCurrentObjectsOnSession,
        false
      )
    }
  }

  addCurrentObjectsOnTab(mapDataFromCurrentObjectsOnTab : TabCurrentLabels){
    this.labelsFacadeService.addCurrentObjectsOnTab(mapDataFromCurrentObjectsOnTab);
  }

  addCurrentObjectsOnUser(mapDataFromCurrentObjectsOnUsers : TabCurrentLabels){
    this.labelsFacadeService.addCurrentObjectsOnUser(mapDataFromCurrentObjectsOnUsers);
  }

  addCurrentObjectsOnSession(mapDataFromCurrentObjectsOnSession : TabCurrentLabels){
    this.labelsFacadeService.addCurrentObjectsOnSession(mapDataFromCurrentObjectsOnSession);
  }

  getCurrentObject(key : ObjectFilterKey){
    return this.labelsFacadeService.getCurrentObject(key) as TabCurrentLabels;
  }

  addNewTabWithLabels(pgTab : PgTab){
    this.labelsFacadeService.addNewTabWithLabels(pgTab)
  }

  deleteTabWithLabels(pgTab : PgTab){
    this.labelsFacadeService.deleteTabWithLabels(pgTab);
  }
}
