import { Component, Inject, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DragService } from '../../../../core/services/drag-service/drag.service';
import { ResizeService } from '../../../../core/services/resize-service/resize.service';
import { FontSelectServiceService } from '../../../../core/services/font-select-service/font-select-service.service';
import { UNICODE_CROSS_ICON, ASCII_EXPAND_ICON, COL_IDS, FORMAT_FORM } from '../../../../core/constants/app.contants';
import { tPgService } from '../../../../core/services/tPg.service';
import { Store } from '@ngxs/store';
import { Format } from '../../../../store/format/format.actions';
import { ContextMenu } from '../../../../core/constants/menu-bar/page/page';
import { MainService } from '../../../../core/services/main-service/main.service';
import { SheetState } from '../../../../store/page/page.store';
import { PgTabStore } from '../../../../store/pg-tab/pg-tab.store';
import { PgTab } from '../../../../store/pg-tab/pg-tab.model';
import { PAGE_IDS, SystemInitials } from '../../../../constant';
import { CellData, ItemData } from '../../../models/edit-dd-dailogs/dd-dailog.models';
import { GetCellPaylaod, UpdateItemPayload } from '../../../models/format/format.models';
import { BehaviorSubject } from 'rxjs';
import { Actions, DbObjects, Message, Notify, Reason } from '../../../../core/enums/tokens/tokens.enum';
@Component({
  selector: 'app-format-page-main',
  templateUrl: './format-page-main.component.html',
  styleUrls: ['./format-page-main.component.scss']
})
export class FormatPageComponent implements AfterViewInit{
  headerText: string = 'Format Page';
  height: any = 0;
  width: any = 0;
  expandIcon = ASCII_EXPAND_ICON;
  newWidth: number = 0;
  newHeight: number = 0;
  formatId!: string; // Assuming this is populated
  pageId!: string; // Assuming this is populated
  formatPageForm!: FormGroup;
  closeResult: string = '';
  closeTab = UNICODE_CROSS_ICON ;
  isFormValid: boolean = true;
  failureMessage: string = '';
  successMessage: string = ''; 
  apiSuccessFlag: boolean = false;
  headerTextWidth: number = 0;
  onFormDataChange(updatedFormData: any): void {
    // Saved the updated form data in the `formatPageForm` variable
    this.formatPageForm = updatedFormData.form;
  }
  @ViewChild('headerText') header!: ElementRef;
  @ViewChild('scrollContainer',) scrollContainer!: ElementRef<HTMLDivElement>;

  ngAfterViewInit() {
    setTimeout(() => {
      this.scrollContainer.nativeElement.scrollTop = 0; // Scroll to top
      this.scrollContainer.nativeElement.scrollLeft = 0; // Scroll to top
    }, 200); // 200ms delay
    this.headerTextWidth = this.header.nativeElement.offsetWidth;

  }

  private modalRef!: NgbModalRef;
  private dialogDataSubject = new BehaviorSubject<any>(null);
  dialogData$ = this.dialogDataSubject.asObservable(); // Expose it as an Observable

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FormatPageComponent>,
    public dialog: MatDialog,
    private dragService: DragService,
    private resizeService: ResizeService,
    public service: FontSelectServiceService,
    private store: Store,
    public pageService: tPgService,
    private modalService: NgbModal,
    private mainService: MainService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
  }

  /**
   * Handles the save action when the user submits the form.
   * 1. Closes the dialog and passes the form data.
   * 2. Dispatches the updated form data to the global store.
   */
  onSave(): void {
    this.formatPageForm.markAllAsTouched();

    // Dispatch an action to update the data
    if (this.formatPageForm.valid) {
      switch (this.dialogData.context) {
        case ContextMenu.FormatPage:
          this.updatePageFormat();
          break;
        case ContextMenu.FormatLocalColumn: 
          this.updateLocalColumnFormat();
          break;
        case ContextMenu.FormatLocalRow: 
          this.updateLocalRowFormat();
          break;
        case ContextMenu.FormatLocalCell: 
          this.updateLocalCellFormat();
          break;
        case ContextMenu.FormatLocalItem: 
          this.updateLocalItemFormat();
          break;
        default:
          break;
      }
       // Emit data instead of closing the dialog
      if (this.apiSuccessFlag) {
        this.dialogDataSubject.next(this.dialogData);
      }
    }
  }

  updatePageFormat() {
    const pgId: number = this.dialogData.objectId;
    const formatForm = this.formatPageForm.value;
    const getCellPayload: GetCellPaylaod = {
      rowId: this.dialogData.rowId,
      pgId: this.dialogData.objectId,
      colId: COL_IDS.PAGE_COMMENT.toString(),
      value: Object.entries(formatForm[FORMAT_FORM.CommentObject])
        .map(([key, value]) => ({ [key]: value }))
    };

    // Dispatch an action to update page
    this.store.dispatch(new Format.UpdatePage(formatForm)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.store.dispatch(new Format.UpdatePageSuccess(getCellPayload)).subscribe(res => {});
          const message: string = this.mainService.getMessage({
            Notify: Notify.Success,
            Action: Actions.Format,
            Object: DbObjects.Page,
            ID: pgId,
            Reason: Reason.Actioned,
            Message: Message.ResponseMessage
          });
          this.handleSuccess(message);
          this.dialogDataSubject.next(this.dialogData);

        } else {
          this.handleFailure(`${data.format.apiStatus.message}`);
        }
      },      
    });
  }

  updateLocalColumnFormat() {
    const colId: number = this.dialogData.objectId;
    const rowId: number = this.dialogData.rowId;
    const pgId = PAGE_IDS.ALL_COLS;
    const formatForm = this.formatPageForm.value;

    const commentPayload: GetCellPaylaod = {
      rowId: rowId.toString(),
      pgId: pgId.toString(),
      colId: COL_IDS.COL_COMMENT.toString(),
      value: Object.entries(formatForm[FORMAT_FORM.CommentObject])
        .map(([key, value]) => ({ [key]: value }))
    };

    const statusPayload: GetCellPaylaod = {
      rowId: rowId.toString(),
      pgId: pgId.toString(),
      colId: COL_IDS.COL_STATUS.toString(),
      value: formatForm[FORMAT_FORM.Status]
        ?.map((status: any) => Object.values(status)[0])
        ?.map((status: string) => ({[SystemInitials.English]: status}))
    };

    // Dispatch an action to update the column
    this.store.dispatch(new Format.UpdateColumn(colId, formatForm)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.store.dispatch(new Format.UpdateColumnSuccess(commentPayload, statusPayload)).subscribe(res => {});
          this.handleSuccess(this.mainService.getMessage({
            Notify: Notify.Success,
            Action: Actions.Format,
            Object: DbObjects.LocalColumn,
            ID: colId,
            Reason: Reason.Actioned,
            Message: Message.ResponseMessage
          }));
          this.dialogDataSubject.next(this.dialogData);
        } else {
          this.handleFailure(`${data.format.apiStatus.message}`);
        }
      },      
    });
  }

  updateLocalRowFormat() {
    const rowId: number = this.dialogData.objectId;
    const formatForm = this.formatPageForm.value;
    const pgTab = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as PgTab;
    const pgId = Number(pgTab.page_id);

    const commentPayload: GetCellPaylaod = {
      rowId: rowId.toString(),
      pgId: pgId.toString(),
      colId: COL_IDS.ROW_COMMENT.toString(),
      value: Object.entries(formatForm[FORMAT_FORM.CommentObject])
        .map(([id, value]) => ({
          [id]: value
        }))
    };

    const statusPayload: GetCellPaylaod = {
      rowId: rowId.toString(),
      pgId: pgId.toString(),
      colId: COL_IDS.ROW_STATUS.toString(),
      value: formatForm[FORMAT_FORM.Status]
        ?.map((status: any) => Object.values(status)[0])
        ?.map((status: string) => ({[SystemInitials.English]: status}))
    };

    // Dispatch an action to update the Row
    this.store.dispatch(new Format.UpdateRow(rowId, formatForm)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.store.dispatch(new Format.UpdateRowSuccess(commentPayload, statusPayload)).subscribe(res => {});
          this.handleSuccess(this.mainService.getMessage({
            Notify: Notify.Success,
            Action: Actions.Format,
            Object: DbObjects.LocalRow,
            ID: rowId,
            Reason: Reason.Actioned,
            Message: Message.ResponseMessage
          }));
          this.dialogDataSubject.next(this.dialogData);
        } else {
          this.handleFailure(`${data.format.apiStatus.message}`);
        }
      },      
    });
  }

  updateLocalCellFormat() {
    const pgTab = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as PgTab;
    const pgId = Number(pgTab.page_id);
    const cellId: number = this.dialogData.objectId;
    const colId: number = this.dialogData.colId;
    const rowId: number = this.dialogData.rowId;
    const formatForm = this.formatPageForm.value;

    const statusPayload: GetCellPaylaod = {
      rowId: rowId.toString(),
      pgId: pgId.toString(),
      colId: colId.toString(),
      value: formatForm[FORMAT_FORM.Status]
        ?.map((status: any) => Object.values(status)[0])
        ?.map((status: string) => ({[SystemInitials.English]: status}))
    };

    // Dispatch an action to update the Cell
    this.store.dispatch(new Format.UpdateCell(colId, rowId, formatForm)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.store.dispatch(new Format.UpdateCellSuccess(statusPayload)).subscribe(res => {});
          this.handleSuccess(this.mainService.getMessage({
            Notify: Notify.Success,
            Action: Actions.Format,
            Object: DbObjects.LocalCell,
            ID: cellId,
            Reason: Reason.Actioned,
            Message: Message.ResponseMessage
          }));
          this.dialogDataSubject.next(this.dialogData);
        } else {
          this.handleFailure(`${data.format.apiStatus.message}`);
        }
      },
    });
  }

  updateLocalItemFormat() {
    const pgTab = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as PgTab;
    const item: string = this.dialogData.item;
    const pgId = Number(pgTab.page_id);
    const colId: number = this.dialogData.colId;
    const rowId: number = this.dialogData.rowId;
    const formatForm = this.formatPageForm.value;

    const cell: CellData = this.mainService.getCell(pgId, colId, rowId);
    let targetItems: ItemData[] = [];
    if (cell.hasOwnProperty('cell')) targetItems = cell.cell.items;
    if (cell.hasOwnProperty('format') && cell.format) targetItems = cell.format.items;

    const targetItem = targetItems.find(cellItem => 
      Object.values(cellItem.item)[0]?.trim() === item.trim().replace(/-$/, "")
    ) as ItemData | undefined;
    if (!targetItem) throw new Error(`Target item not found: ${item}`);

    const statusPayload: UpdateItemPayload = {
      pgId: pgId,
      rowId: rowId,
      colId: colId,
      itemId: Number(targetItem.id),
      value: formatForm[FORMAT_FORM.Status]
        ?.map((status: any) => Object.values(status)[0])
        ?.map((status: string) => ({[SystemInitials.English]: status}))
    };

    // Dispatch an action to update the Row
    this.store.dispatch(new Format.UpdateItem(Number(targetItem.id), formatForm)).subscribe({
      next: (data: any) => {
        if (data.format.apiStatus.success) {
          this.store.dispatch(new Format.UpdateItemSuccess(statusPayload)).subscribe(res => {});
          this.handleSuccess(this.mainService.getMessage({
            Notify: Notify.Success,
            Action: Actions.Format,
            Object: DbObjects.LocalItem,
            ID: Number(targetItem.id),
            Reason: Reason.Actioned,
            Message: Message.ResponseMessage
          }));
          this.dialogDataSubject.next(this.dialogData);
        } else {
          this.handleFailure(`${data.format.apiStatus.message}`);
        }
      },
    });
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef?.dismiss('Cancel click');
  }
  @ViewChild('formatPageContainer', { static: true })
  popupContainer!: ElementRef<HTMLDivElement>; // to get the reference of the popup container for further manipulaiton

  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContainer);
  }
  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContainer);
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragService.onMouseUp(); // Use the service method
  }
  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContainer);
  }

  handleSuccess(message: string) {
    this.isFormValid = true;
    this.apiSuccessFlag = true;
    this.successMessage = message;
  }

  handleFailure(message: string) {
    this.isFormValid = false;
    this.apiSuccessFlag = false;
    this.failureMessage = message;
  }
}
