import { Component, forwardRef, input, InputSignal, OnInit, output, OutputEmitterRef, Signal, signal, viewChild, ViewEncapsulation, WritableSignal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { CKEditorConfig } from '../../../core/constants/ck-editor.const';
import { CKEditorResponse } from '../../../core/interfaces/ck-editor.interface';
import { ClassicEditor, DocumentSelection, Editor, EditorConfig, RootElement, Writer } from 'ckeditor5';

@Component({
  selector: 'app-ck-editor',
  templateUrl: './ck-editor.component.html',
  styleUrls: ['./ck-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CkEditorComponent),
      multi: true
    }
  ]
})
export class CkEditorComponent implements ControlValueAccessor, OnInit {
  // Inputs
  public defaultValue: InputSignal<CKEditorResponse | null> = input<CKEditorResponse | null>(null);
  public readOnly: InputSignal<boolean> = input<boolean>(false);
  public isMML: InputSignal<boolean> = input<boolean>(true);
  public placeholder: InputSignal<string> = input<string>('');

  // Data Members
  public editorResponse: WritableSignal<CKEditorResponse> = signal<CKEditorResponse>({ text: '', style: '' });

  public editor: WritableSignal<typeof ClassicEditor> = signal<typeof ClassicEditor>(ClassicEditor);
  public config: WritableSignal<EditorConfig> = signal<EditorConfig>(CKEditorConfig);
  private editorObject: WritableSignal<ClassicEditor | null> = signal<ClassicEditor | null>(null)

  public cdEditor: Signal<CKEditorComponent<Editor>> = viewChild.required<CKEditorComponent<Editor>>(CKEditorComponent);

  public onChangeValue:OutputEmitterRef<ChangeEvent> = output<ChangeEvent>();


  // ControlValueAccessor Functions
  public onChange: (value: CKEditorResponse) => void = () => { };
  onTouched: () => void = () => { };

  public writeValue(value: CKEditorResponse): void {
    this.editorResponse.set(value);
  }

  public registerOnChange(fn: (value: CKEditorResponse) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public onUpdateChange(value: CKEditorResponse): void {
    this.editorResponse.set(value);
    this.onChange(this.editorResponse());
  }

  public onEditorBlur(): void {
    this.onTouched();
  }

  // Editor Related Functions
  public ngOnInit(): void {
    if (this.defaultValue()?.style) {
      setTimeout(() => {
        this.applyStyleString(this.defaultValue()!.style);
      });
    }
  }

  public getEditorResponse(event:ChangeEvent): void {
    if(this.isMML()){this.onChangeValue.emit(event);}
    const selection: DocumentSelection = this.cdEditor().editorInstance!.model.document.selection;
    const appliedStyles: any = {};
    if (selection.hasAttribute('fontSize')) {
      appliedStyles.fontSize = selection.getAttribute('fontSize');
    }
    if (selection.hasAttribute('fontFamily')) {
      appliedStyles.fontFamily = selection.getAttribute('fontFamily');
    }
    if (selection.hasAttribute('fontColor')) {
      appliedStyles.fontColor = selection.getAttribute('fontColor');
    }
    if (selection.hasAttribute('fontBackgroundColor')) {
      appliedStyles.fontBackgroundColor = selection.getAttribute('fontBackgroundColor');
    }
    if (selection.hasAttribute('alignment')) {
      appliedStyles.alignment = selection.getAttribute('alignment');
    }
    appliedStyles.bold = selection.hasAttribute('bold');
    appliedStyles.italic = selection.hasAttribute('italic');
    appliedStyles.underline = selection.hasAttribute('underline');
    appliedStyles.strikethrough = selection.hasAttribute('strikethrough');
    appliedStyles.subscript = selection.hasAttribute('subscript');
    appliedStyles.superscript = selection.hasAttribute('superscript');
    appliedStyles.textWrap = selection.hasAttribute('wrapText');
    appliedStyles.textBorder = selection.hasAttribute('textBorder');

    const inlineStyle = this.convertFormatStyleObjToString(appliedStyles);
    this.onUpdateChange({ text: this.cdEditor().editorInstance?.getData() ?? '', style: inlineStyle });
  }

  public convertFormatStyleObjToString(appliedStyles: any): string {
    const styleParts: string[] = [];
    if (appliedStyles.fontSize) {
      styleParts.push(`font-size: ${appliedStyles.fontSize};`);
    }
    if (appliedStyles.fontFamily) {
      styleParts.push(`font-family: ${appliedStyles.fontFamily};`);
    }
    if (appliedStyles.fontColor) {
      styleParts.push(`color: ${appliedStyles.fontColor};`);
    }
    if (appliedStyles.fontBackgroundColor) {
      styleParts.push(`background-color: ${appliedStyles.fontBackgroundColor};`);
    }
    if (appliedStyles.alignment) {
      styleParts.push(`text-align: ${appliedStyles.alignment};`);
    }
    if (appliedStyles.bold) {
      styleParts.push('font-weight: bold;');
    }
    if (appliedStyles.italic) {
      styleParts.push('font-style: italic;');
    }
    if (appliedStyles.underline) {
      styleParts.push('text-decoration: underline;');
    }
    if (appliedStyles.strikethrough) {
      styleParts.push('text-decoration: line-through;');
    }
    if (appliedStyles.subscript) {
      styleParts.push('vertical-align: sub;');
    }
    if (appliedStyles.superscript) {
      styleParts.push('vertical-align: super;');
    }
    if (appliedStyles.textWrap) {
      styleParts.push('white-space: normal;');
    }
    if (appliedStyles.textBorder) {
      styleParts.push('border: 1px solid black;');
    }
    return styleParts.join(' ');
  }

  public applyStyleString(styleString: string): void {
    const appliedStyles: { [key: string]: string | boolean } = {};
    // Split the style string into individual properties
    const styles = styleString.split(';').map(style => style.trim()).filter(Boolean);
    styles.forEach(style => {
      const [key, value] = style.split(':').map(item => item.trim());

      // Convert each CSS property into the appropriate appliedStyles format
      switch (key) {
        case 'font-size':
          appliedStyles['fontSize'] = value;
          break;
        case 'font-family':
          appliedStyles['fontFamily'] = value;
          break;
        case 'color':
          appliedStyles['fontColor'] = value;
          break;
        case 'background-color':
          appliedStyles['fontBackgroundColor'] = value;
          break;
        case 'text-align':
          appliedStyles['alignment'] = value;
          break;
        case 'font-weight':
          appliedStyles['bold'] = value === 'bold';
          break;
        case 'font-style':
          appliedStyles['italic'] = value === 'italic';
          break;
        case 'text-decoration':
          if (value === 'underline') {
            appliedStyles['underline'] = true;
          } else if (value === 'line-through') {
            appliedStyles['strikethrough'] = true;
          }
          break;
        case 'vertical-align':
          if (value === 'sub') {
            appliedStyles['subscript'] = true;
          } else if (value === 'super') {
            appliedStyles['superscript'] = true;
          }
          break;
        case 'white-space':
          appliedStyles['textWrap'] = value === 'normal';
          break;
        case 'border':
          appliedStyles['textBorder'] = value.includes('solid');
          break;
        default:
          // Any other styles can be added here if needed
          break;
      }
    });
    this.applyStylesToEditor(appliedStyles);
  }

  public applyStylesToEditor(appliedStyles: { [key: string]: string | boolean }): void {
    const editor = this.cdEditor().editorInstance;
    const model = editor!.model;
    const selection = model.document.selection;
    const ranges = Array.from(selection.getRanges());
    model.change(writer => {
      // Apply the styles to each range in the selection
      ranges.forEach(range => {
        // Apply font size
        if (appliedStyles['fontSize']) {
          writer.setAttribute('fontSize', appliedStyles['fontSize'], range);
        }

        // Apply font family
        if (appliedStyles['fontFamily']) {
          writer.setAttribute('fontFamily', appliedStyles['fontFamily'], range);
        }

        // Apply font color
        if (appliedStyles['fontColor']) {
          writer.setAttribute('fontColor', appliedStyles['fontColor'], range);
        }

        // Apply background color
        if (appliedStyles['fontBackgroundColor']) {
          writer.setAttribute('fontBackgroundColor', appliedStyles['fontBackgroundColor'], range);
        }

        // Apply alignment
        if (appliedStyles['alignment']) {
          writer.setAttribute('alignment', appliedStyles['alignment'], range);
        }

        // Apply bold
        if (appliedStyles['bold']) {
          writer.setAttribute('bold', true, range);
        } else if (appliedStyles['bold'] === false) {
          writer.removeAttribute('bold', range);
        }

        // Apply italic
        if (appliedStyles['italic']) {
          writer.setAttribute('italic', true, range);
        } else if (appliedStyles['italic'] === false) {
          writer.removeAttribute('italic', range);
        }

        // Apply underline
        if (appliedStyles['underline']) {
          writer.setAttribute('underline', true, range);
        } else if (appliedStyles['underline'] === false) {
          writer.removeAttribute('underline', range);
        }

        // Apply strikethrough
        if (appliedStyles['strikethrough']) {
          writer.setAttribute('strikethrough', true, range);
        } else if (appliedStyles['strikethrough'] === false) {
          writer.removeAttribute('strikethrough', range);
        }

        // Apply subscript
        if (appliedStyles['subscript']) {
          writer.setAttribute('subscript', true, range);
        } else if (appliedStyles['subscript'] === false) {
          writer.removeAttribute('subscript', range);
        }

        // Apply superscript
        if (appliedStyles['superscript']) {
          writer.setAttribute('superscript', true, range);
        } else if (appliedStyles['superscript'] === false) {
          writer.removeAttribute('superscript', range);
        }

        // Apply text wrapping (if applicable, using the white-space CSS property)
        if (appliedStyles['textWrap']) {
          writer.setAttribute('wrapText', true, range);
        } else if (appliedStyles['textWrap'] === false) {
          writer.removeAttribute('wrapText', range);
        }

        // Apply text border (custom attribute, adjust as needed)
        if (appliedStyles['textBorder']) {
          writer.setAttribute('textBorder', true, range);
        } else if (appliedStyles['textBorder'] === false) {
          writer.removeAttribute('textBorder', range);
        }
      });
    });
  }

  public onReady(editor: ClassicEditor) {
    if (this.readOnly()) {
      editor?.editing.view.document.on("keydown", (event, data) => {
        data.preventDefault();
      });
    }
    this.selectAllTextFormEditor(editor);
  }
  public onTextSelect() {
    if (!this.isMML()) {
      this.selectAllTextFormEditor(this.editorObject()!);
    }
  }

  private selectAllTextFormEditor(editor: ClassicEditor) {
    this.editorObject.set(editor);
    
    editor.model.change((writer: Writer) => {
      const document = editor.model.document;
      const root: RootElement | null = document.getRoot();
      if (!root) return;
  
      // Select entire range
      const range = writer.createRange(
        writer.createPositionAt(root, 0),
        writer.createPositionAt(root, root.childCount)
      );
  
      writer.setSelection(range);
  
      // Ensure alignment is applied to block elements only
      for (const block of range.getItems()) {
        if (editor.model.schema.isBlock(block)) {
          writer.setAttribute("alignment", "justify", block);  // Apply alignment to block
        }
      }
    });
  }
  

  private getPlainText(): string {
    const htmlString = this.cdEditor().editorInstance?.getData();
    if (!htmlString) return "";
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent?.trim() || "";
  }



}
