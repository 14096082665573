import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Input,
  AfterViewInit,
  HostListener,
  ChangeDetectorRef,
  inject,
  DestroyRef,
} from '@angular/core';
import { MatMenuPanel } from '@angular/material/menu';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { Edition, User } from '../../../modals/page.modal';
import { MainService } from '../../../core/services/main-service/main.service';
import { PGEditionDialogComponent } from '../../../modals/pgedition-dialog/pgedition-dialog.component';
import { FormatePageComponent } from '../format-page-dialog/format-page/formate-page-main.component';
import { ViewPageDialogComponent } from '../../../modals/view-page-dialog/view-page-dialog.component';
import { DialogDeleteComponent } from '../dialog-delete/dialog-delete.component';
import { PGColDialogComponent } from '../../../modals/pgcol-dialog/pgcol-dialog.component';
import { PGUserDialogComponent } from '../../../modals/pguser-dialog/pguser-dialog.component';
import { AllPages_columns } from '../../../core/storage/AllPages_Columns.storage';
import { AllPagesDialogComponent } from '../../../modals/all-pages-dialog/all-pages-dialog.component';
import { Select, Store } from '@ngxs/store';
import { SheetState } from '../../../store/page/sheet.store';
import {
  debounceTime,
  distinctUntilChanged,
  Observable,
  skip,
  Subject,
  takeUntil,
} from 'rxjs';
import { FontStyle } from '../../../store/fontStyle/font.actions';
import { Sheet } from '../../../store/page/sheet.actions';
import { EditAmountDialogComponent } from '../../../modals/edit-amount-dialog/edit-amount-dialog.component';
import { EditJSONDialogComponent } from '../../../modals/edit-json-dialog/edit-json-dialog.component';
import { AddEditTextComponent } from '../../../modals/add-edit-text/add-edit-text.component';
import { AddEditFormulaComponent } from '../../../modals/add-edit-formula/add-edit-formula.component';
import { AutoAdjustWidthDirective } from '../../../shared/directives/auto-adjust-width.directive';
import { editDdDialogMainComponent } from '../../../shared/components/edit-dd-dialog/edit-dd-dialog-main/edit-dd-dialog-main.component';

import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import {
  All_Pages_ID,
  Countries,
  DDL_All_Pages,
  DDL_Currencies,
  DDL_Languages,
  DDL_Page_Edition,
  DDL_Page_Modes,
  UNICODE_GLOBE,
  UNICODE_LANGUAGE,
  UNICODE_LEFT_RIGHT_ARROW,
  UNICODE_RELOAD,
} from '../../../core/constants/app.contants';
import { DialogViewComponent } from '../dialog-view/dialog-view.component';
import {
  DDResponse,
  RowCurrencyData,
  RowLanguageData,
  RowPageData,
  RowRegionData,
} from '../../models/edit-dd-dailogs/dd-dailog.models';
import { TooltipService } from '../../../services/tooltip-service/tooltip.service';
import {
  MenuBar,
  SelectedMenuBar,
} from '../../../core/constants/menu-bar/menu-bar.contants';
import { AddPgTab } from '../../../store/pg-tab/pg-tab.actions';
import { PgTab } from '../../../store/pg-tab/pg-tab.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
interface ContextMenuItem {
  name: string;
  mode: 'View' | 'Format' | 'Edit'; // Restrict to specific modes
  isDimmed?: boolean; // Optional property to track dimmed state
}
@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
})
export class MenuBarComponent implements OnInit, AfterViewInit {
  destroyRef = inject(DestroyRef);
  @Input() sheetSelected: any;
  @Output() dataExported: EventEmitter<void> = new EventEmitter<void>();
  @Output() columnFreezeToggled: EventEmitter<void> = new EventEmitter<void>();
  @Output() dataSorted: EventEmitter<void> = new EventEmitter<void>();
  @Input() currentSheetIndex!: number;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger | undefined; // Access matMenuTrigger via ViewChild
  firstIncrement: boolean = true;
  @Select(SheetState.regions) regions$!: Observable<[]>;
  selectPgTab$ : Observable<PgTab> = inject(Store).select(PgTabStore.getSelectedPgTab)
  sortStatus: string = 'Sort is Off';
  filterStatus: string = 'Filter is Off';
  sessionId!: string;
  uiCurrency: any = 'EU€';
  columns: any[] = [];
  sheetData: any[] = [];
  pageFreezeColumn: any = 0;
  filteredLanguages: string[] = [];
  selectedLanguage: {} = { language: 'English', type: 'language' };
  tooltip: any = '';
  pageMenu: boolean = false;
  isChecked: boolean = false;
  EditionCheckbox: boolean = false; // Tracks the state of the checkbox
  edition: string = '';
  refresh = UNICODE_RELOAD;
  language = UNICODE_LANGUAGE;
  globe = UNICODE_GLOBE;
  expand = UNICODE_LEFT_RIGHT_ARROW;
  frozen: any = 0;
  expandWidth: any = null;
  private expandLevelSubject = new Subject<string>();
  @Select(SheetState.getFrozen) frozen$: Observable<any> | undefined;
  @Select(SheetState.getExpandWidth) expandWidth$: Observable<any> | undefined;
  @Select(SheetState.getPageId) pageId$: Observable<any> | undefined;
  private unsubscribe$ = new Subject<void>();
  @ViewChild(AutoAdjustWidthDirective)
  widthDirective!: AutoAdjustWidthDirective;
  tooltipContent: string = '';
  height: any = 0;
  width: any = 0;
  previousFontSize: number;
  currentFontSize: number;
  newFontSize: number = 0;
  @Input() set sheet(data: any) {
    this.sheetData = data;
  }
  ExpandLevels: any = { n: '99.', x: null, y: null, z: null };
  @Select(PgTabStore.getMenuBar) manuBars$: Observable<[]> | undefined;
  menuBar = MenuBar.data;
  isEditionPg: boolean = false;

  selectedMenuBar = SelectedMenuBar;

  constructor(
    private mainService: MainService,
    public dialog: MatDialog,
    private store: Store,
    private cdr: ChangeDetectorRef,
    public tooltipService: TooltipService
  ) {
    this.previousFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.currentFontSize = this.previousFontSize;
  }

  ngOnInit() {
    this.selectPgTab$
    .pipe(
      skip(1),
      takeUntilDestroyed(this.destroyRef)
    )
    .subscribe((selectedPgTab) => {
      const tooltipContent = `Page Name: ${selectedPgTab.page_name || 'N/A'}
        Page Edition: ${selectedPgTab?.page_edition || 'N/A'}
        Page Comment: ${selectedPgTab?.page_comment || 'N/A' }`
      this.selectedMenuBar = {
        ...this.selectedMenuBar,
        currentPage : tooltipContent,
        currentEdition : tooltipContent
      }
    });

    this.mainService.sortStatus.subscribe(
      (sortStatus) => (this.sortStatus = sortStatus)
    );
    this.mainService.filterStatus.subscribe(
      (filterStatus) => (this.filterStatus = filterStatus)
    );

    if (!this.isChecked) {
      const bodyElement = document.body;
      bodyElement.classList.add('notranslate');
    }
    this.tooltip = Object.values(this.selectedLanguage)[0];
    let obj1 = { pageExpand: this.ExpandLevels };
    this.mainService.pageFormate.next(obj1);
    this.mainService.pageFormateReg.next(obj1);
    this.expandLevelSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged() // Prevent processing the same value multiple times
      )
      .subscribe((value) => {
        this.onExpandChange(value);
      });
    this.adjustCheckboxSize();
    window.addEventListener('resize', this.adjustCheckboxSize.bind(this));
  }

  changeFontSize(newSize: number): void {
    this.previousFontSize = this.currentFontSize;
    this.currentFontSize = newSize;
    document.documentElement.style.fontSize = `${newSize}px`;
  }

  private adjustCheckboxSize() {
    const bodyFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.height = bodyFontSize / this.previousFontSize - 0.2;
    this.width = bodyFontSize / this.previousFontSize - 0.2;
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const newValue = input?.value || '0';
    this.expandLevelSubject.next(newValue);
  }

  onExpandToggle(event: MouseEvent): void {
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    const y = event.clientY - rect.top;

    let nValue =
      this.ExpandLevels.n !== null ? this.ExpandLevels.n.toString() : '0';
    if (!nValue || isNaN(parseFloat(nValue))) {
      nValue = '0';
      this.ExpandLevels.n = 0;
      return;
    } else if (nValue.startsWith('.')) {
      nValue = '0' + nValue;
    }
    const [integerPart, decimalPart] = nValue.split('.');
    let intValue = parseInt(integerPart, 10);
    const centralAreaHeight = rect.height * 0.2;
    if (y < rect.height / 2 - centralAreaHeight / 2) {
      if (this.firstIncrement) {
        this.firstIncrement = false;
      }
      intValue += 1;
    } else if (y > rect.height / 2 + centralAreaHeight / 2 && intValue > 0) {
      intValue = Math.max(0, intValue - 1);
    }
    this.ExpandLevels.n = decimalPart
      ? `${intValue}.${decimalPart}`
      : `${intValue}`;
    this.onExpandChange(this.ExpandLevels.n); // Send updated value
  }

  expandLevel(value: any) {
    const nValue = value || '0';
    const [integerPart, decimalPart] = nValue.toString().split('.');

    this.ExpandLevels.n = decimalPart
      ? `${integerPart}.${decimalPart}`
      : `${integerPart}`;
    this.onExpandChange(this.ExpandLevels.n);
  }

  onExpandChange(ExpandLevelss: string) {
    setTimeout(() => {
      this.widthDirective?.adjustWidth();
    }, 0);
    const level = this.parseExpandLevel(ExpandLevelss);
    this.mainService.Expand_Visibilt.next(level);
    let obj1 = { pageExpand: level };
    this.mainService.pageFormate.next(obj1);
  }

  parseExpandLevel(level: string): {
    n: number;
    x: number | null;
    y: number | null;
    z: number | null;
  } {
    let nValue = this.ExpandLevels.n
      ? parseInt(this.ExpandLevels.n.toString().split('.')[0])
      : 0;

    const match = level.match(
      /(\d+)(?:\.(1))?(?:(2))?(?:(3))?(?:\.(12|13|23|123))?/
    );
    if (!match) return { n: nValue, x: null, y: null, z: null }; // Default return if no match

    nValue = parseInt(match[1]); // Captures 'n'
    let xValue = match[2] ? 1 : null; // x is only 1 if matched
    let yValue = match[3] ? 2 : null; // y is only 2 if matched
    let zValue = match[4] ? 3 : null; // z is only 3 if matched

    // Handle combined cases for y and z
    if (match[5]) {
      if (match[5].includes('1')) xValue = 1; // If part of "12", "13", or "123"
      if (match[5].includes('2')) yValue = 2; // If part of "12", "23", or "123"
      if (match[5].includes('3')) zValue = 3; // If part of "13", "23", or "123"
    }

    return {
      n: nValue,
      x: xValue,
      y: yValue,
      z: zValue,
    };
  }

  toggleFreeze(event: MouseEvent): void {
    const target = event.currentTarget as HTMLElement;
    const { top, height } = target.getBoundingClientRect();
    const clickY = event.clientY - top;

    const centralAreaHeight = height * 0.2;
    const upperBound = (height - centralAreaHeight) / 2;
    const lowerBound = (height + centralAreaHeight) / 2;

    // Adjust the page freeze column based on click position
    if (clickY < upperBound) {
      this.updateFreezeColumn(this.pageFreezeColumn + 1);
    } else if (clickY > lowerBound && this.pageFreezeColumn > 0) {
      this.updateFreezeColumn(this.pageFreezeColumn - 1);
    }
  }

  private updateFreezeColumn(value: number): void {
    this.pageFreezeColumn = Math.max(0, value);
    const update = { pageFreezeColumn: this.pageFreezeColumn };
    this.mainService.pageFormate.next(update);
  }

  get expandLevelN(): string {
    return this.ExpandLevels.n !== null ? this.ExpandLevels.n.toString() : '';
  }

  set expandLevelN(value: string) {
    this.ExpandLevels.n = value !== '' ? value : '';
    this.onInputChange(this.ExpandLevels.n);
  }

  setLanguage(language: string | null) {
    const bodyElement = document.body;
    bodyElement.classList.remove('notranslate');
    this.selectedLanguage = {
      language: language || 'English',
      type: 'language',
    };
    this.tooltip = Object.values(this.selectedLanguage)[0];
    if (language) {
      this.translatePage(language);
    } else {
      this.translatePage('en');
    }
  }

  ngAfterViewInit() {
    const selectElement = document.querySelector(
      '.goog-te-combo'
    ) as HTMLSelectElement;
    if (selectElement) {
      this.filteredLanguages.forEach((lang) => {
        const optionElement = Array.from(selectElement.options).find(
          (option) => option.text === lang
        );
        if (optionElement) {
          optionElement.style.display = 'block';
        }
      });
    }
    this.edition = this.mainService?.getEdition();
    this.pageId$?.subscribe((pageId) => {
      this.isEditionPg = pageId === All_Pages_ID ? true : false;
      this.cdr.detectChanges();
    });
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    this.pageMenu = false;
  }

  translatePage(language: string) {
    const selectElement = document.querySelector(
      '.goog-te-combo'
    ) as HTMLSelectElement;
    if (selectElement) {
      const langValue = this.getLanguageValue(language);
      selectElement.value = langValue;
      selectElement.dispatchEvent(new Event('change'));
    }
  }

  getLanguageValue(language: string): string {
    const languageMap: { [key: string]: string } =
      this.mainService.languageListKeys;
    return languageMap[language] || 'en';
  }

  getAllGoogleLanguages(): string[] {
    return this.mainService.languageList;
  }

  onRightClick(event: MouseEvent) {
    event.preventDefault(); // Prevent the default right-click menu

    if (this.menuTrigger) {
      this.menuTrigger.openMenu(); // Open the context menu
    }
  }

  handleCheckboxChange(event: any) {
    this.EditionCheckbox = event.target.checked;
    this.mainService.handleCheckboxChange(event);
  }

  setCurrency(currency: string) {
    this.uiCurrency = currency;
  }

  openDialog() {
    const dialogRef = this.dialog.open(editDdDialogMainComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The shared dialog was closed');
    });
  }
  toggleAutoTranslate(event: Event) {
    if (this.isChecked) {
      const bodyElement = document.body;
      bodyElement.classList.remove('notranslate');
      const selectElement = document.querySelector(
        '.goog-te-combo'
      ) as HTMLSelectElement;
      // Check if the element is found and is an HTMLSelectElement
      if (selectElement) {
        // Get the selected value
        const selectedLanguage = selectElement.value;
        // Output the selected value to the console (or use it as needed)
        Object.values(this.mainService.languageListKeys).map((code, index) => {
          if (selectedLanguage == code) {
            this.tooltip = Object.keys(this.mainService.languageListKeys)[
              index
            ];
          }
        });
      } else {
        console.error('Select element not found.');
      }
    }
  }

  async showCountryRegion() {
    await this.frozen$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.frozen = data;
    });

    await this.expandWidth$
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data != undefined || data != null) {
          this.expandWidth = data;
        }
      });

    const dialogRef = this.dialog.open(editDdDialogMainComponent, {
      width: 'auto',
      panelClass: 'pick-ddl',
      data: {
        data: { frozen: this.frozen, expandWidth: this.expandWidth },
        selectedId: Countries,
      },
    });

    dialogRef.afterClosed().subscribe((result: DDResponse) => {
      if (result) {
        if (!result.regionRowSelected && result.clicked) {
          this.tooltipContent = 'Current Region : Null';
          this.tooltipService.triggerTooltip(this.tooltipContent);
          this.selectedMenuBar = {
            ...this.selectedMenuBar,
            currentRegion : this.tooltipContent
          }
        } else if (result.regionRowSelected) {
          const results = result;
          this.updateSelectRegion(result.regionRowSelected);
        }
      }
      this.store.dispatch(new Sheet.SetFrozen(99));
      let obj3 = { pageFreezeColumn: 0 };
      this.mainService.pageFormateFreeze.next(obj3);
    });
  }
  async showLanguageDialog() {
    await this.frozen$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.frozen = data;
    });

    await this.expandWidth$
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data != undefined || data != null) {
          this.expandWidth = data;
        }
      });

    const dialogRef = this.dialog.open(editDdDialogMainComponent, {
      width: 'auto',
      panelClass: 'pick-ddl',
      data: {
        data: { frozen: this.frozen, expandWidth: this.expandWidth },
        selectedId: DDL_Languages,
      },
    });

    dialogRef.afterClosed().subscribe((result: DDResponse) => {
      if (result != undefined) {
        if (result.languageRowSelected) {
          const results = result.languageRowSelected;
          this.updateSelectLanguage(results);
        } else if (!result.languageRowSelected && result.clicked) {
          this.tooltipContent = 'Current Language : Null';
          this.tooltipService.triggerTooltip(this.tooltipContent);
          this.selectedMenuBar = {
            ...this.selectedMenuBar,
            currentLanguage : this.tooltipContent
          }
      }
      let obj3 = { pageFreezeColumn: 0 };
      this.mainService.pageFormateFreeze.next(obj3);
      this.store.dispatch(new Sheet.SetFrozen(99));
    }});
  }
  async showCurrencyDialog() {
    await this.frozen$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.frozen = data;
    });

    await this.expandWidth$
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data != undefined || data != null) {
          this.expandWidth = data;
        }
      });

    const dialogRef = this.dialog.open(editDdDialogMainComponent, {
      width: 'auto',
      panelClass: 'pick-ddl',
      data: {
        data: { frozen: this.frozen, expandWidth: this.expandWidth },
        selectedId: DDL_Currencies,
      },
    });

    dialogRef.afterClosed().subscribe((result: DDResponse) => {
      if (result) {
        if (result.currencyRowSelected) {
          const results = result.currencyRowSelected;
          this.updateSelectCurrency(results);
        } else if (!result.currencyRowSelected && result.clicked) {
          this.tooltipContent = 'Current Currency : Null';
          this.tooltipService.triggerTooltip(this.tooltipContent);
          this.selectedMenuBar = {
            ...this.selectedMenuBar,
            currentCurrency : this.tooltipContent
          }
      }}
      let obj3 = { pageFreezeColumn: 0 };
      this.mainService.pageFormateFreeze.next(obj3);
      this.store.dispatch(new Sheet.SetFrozen(99));
    });
  }
  async showEditionDialog() {
    await this.frozen$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.frozen = data;
    });

    await this.expandWidth$
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data != undefined || data != null) {
          this.expandWidth = data;
        }
      });

    const dialogRef = this.dialog.open(editDdDialogMainComponent, {
      width: 'auto',
      panelClass: 'pick-ddl',
      data: {
        data: { frozen: this.frozen, expandWidth: this.expandWidth },
        selectedId: DDL_Page_Edition,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      const rowSelected = result.pageRowSelected;
      let obj3 = { pageFreezeColumn: 0 };
      this.mainService.pageFormateFreeze.next(obj3);
      this.store.dispatch(new Sheet.SetFrozen(99));
      this.store.dispatch(
        new AddPgTab({
          page_id: rowSelected.page_id,
          page_name: rowSelected.page_name,
          page_edition: rowSelected.page_edition,
          page_comment: rowSelected.page_comment,
        })
      );
    });
  }

  async showPageDialog() {
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu(); // Open the context menu
    }

    await this.frozen$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.frozen = data;
    });

    await this.expandWidth$
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data != undefined || data != null) {
          this.expandWidth = data;
        }
      });

    const dialogRef = this.dialog.open(editDdDialogMainComponent, {
      width: 'auto',
      panelClass: 'pick-ddl',
      data: {
        data: { frozen: this.frozen, expandWidth: this.expandWidth },
        selectedId: DDL_All_Pages,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      const rowSelected = result.pageRowSelected;
      let obj3 = { pageFreezeColumn: 0 };
      this.mainService.pageFormateFreeze.next(obj3);
      this.store.dispatch(new Sheet.SetFrozen(99));
      this.store.dispatch(
        new AddPgTab({
          page_id: rowSelected.page_id,
          page_name: rowSelected.page_name,
          page_edition: rowSelected.page_edition,
          page_comment: rowSelected.page_comment,
        })
      );
    });
  }
  async showViewDialog() {
    await this.frozen$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.frozen = data;
    });

    await this.expandWidth$
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data != undefined || data != null) {
          this.expandWidth = data;
        }
      });

    const dialogRef = this.dialog.open(editDdDialogMainComponent, {
      width: 'auto',
      panelClass: 'pick-ddl',
      data: {
        data: { frozen: this.frozen, expandWidth: this.expandWidth },
        selectedId: DDL_Page_Modes,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        if (result.parentRows != undefined) {
          const results = result;
          this.updateSelectView(results.parentRows);
        } else if (result.parentRows == null && result.clicked == true) {
          this.tooltipContent = `Current Page Mode: Null`;
        }
      }
      let obj3 = { pageFreezeColumn: 0 };
      this.mainService.pageFormateFreeze.next(obj3);
      this.store.dispatch(new Sheet.SetFrozen(99));
    });
  }
  updateSelectRegion(rowSelected: RowRegionData) {
    if (rowSelected.region && Array.isArray(rowSelected.region)) {
      const parentRegions = rowSelected.region
        .map((region: string) => `<u>${region}</u>`)
        .join(' <span class="unicode-separator">\u220B</span> ');
      this.tooltipContent = `Current Region: ${parentRegions})`;
    } else {
      this.tooltipContent = `Current Region: ${rowSelected.region}`;
    }
    this.selectedMenuBar = {
      ...this.selectedMenuBar,
      currentRegion: this.tooltipContent,
    };
    this.tooltipService.triggerTooltip(this.tooltipContent);
  }
  updateSelectLanguage(rowSelected: RowLanguageData) {
    if (rowSelected.language && Array.isArray(rowSelected.language)) {
      const parentLanguage = rowSelected.language
        .map((language: string) => `<u>${language}</u>`)
        .join('<span class="unicode-separator">\u220B</span>');
      this.tooltipContent = `Current Language: ${parentLanguage})`;
    } else {
      this.tooltipContent = `Current Language: ${rowSelected.language}`;
    }
    this.selectedMenuBar = {
      ...this.selectedMenuBar,
      currentLanguage: this.tooltipContent,
    };
    this.tooltipService.triggerTooltip(this.tooltipContent);
  }

  updateSelectCurrency(rowSelected: RowCurrencyData) {
    if (rowSelected.unit && Array.isArray(rowSelected.unit)) {
      const parentLanguage = rowSelected.unit
        .map((language: string) => `<u>${language}</u>`)
        .join(' <span class="unicode-separator">\u220B</span>');
      this.tooltipContent = `Current Currency: ${parentLanguage})`;
    } else {
      this.tooltipContent = `Current Currency: ${rowSelected.unit}`;
    }
    this.selectedMenuBar = {
      ...this.selectedMenuBar,
      currentCurrency: this.tooltipContent,
    };
    this.tooltipService.triggerTooltip(this.tooltipContent);
    this.setCurrency(rowSelected.unit);
  }
  updateSelectView(parentRows: any[]) {
    if (parentRows && parentRows.length > 0) {
      parentRows.splice(0, 1);
      const pages = parentRows.map((row) => row.page);
      const lastPage = pages.pop();
      const parentPage = pages
        .map((page) => `<u>${page}</u>`)
        .join(' <span class="unicode-separator">\u220B</span> ');
      if (parentPage != undefined && parentPage != null && parentPage != '') {
        this.tooltipContent = `Current Page Mode: ${lastPage} (${parentPage})`;
      } else {
        this.tooltipContent = `Current Page Mode: ${lastPage}`;
      }
    }
    this.selectedMenuBar = {
      ...this.selectedMenuBar,
      currentPage: this.tooltipContent,
    };
    this.tooltipService.triggerTooltip(this.tooltipContent);
  }
  currentPgMode: 'View' | 'Format' | 'Edit' = 'View';
  contextMenuItems: ContextMenuItem[] = [
    { name: 'Format', mode: 'Format' },
    { name: 'Edit', mode: 'Edit' },
    { name: 'View', mode: 'View' },
  ];

  setViewMode(mode: 'View' | 'Format' | 'Edit') {
    this.currentPgMode = mode;
    this.updateContextMenuColors();
  }

  updateContextMenuColors() {
    this.contextMenuItems.forEach((item) => {
      const isDimmed = this.currentPgMode !== item.mode;
      item.isDimmed = isDimmed;
    });
  }

  toggleSort() {
    this.mainService.toggleSort();
  }

  toggleFilter() {
    this.mainService.toggleFilter();
  }

  selectedEdition: Edition | null = null;

  openEditionDialog(): void {
    const dialogRef = this.dialog.open(PGEditionDialogComponent, {
      width: '400px',
      data: { selectedEdition: this.selectedEdition },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedEdition = result;
      }
    });
  }
  openColDialog() {
    const dialogRef = this.dialog.open(PGColDialogComponent, {
      width: '750px',
      data: { selectedId: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  selectedUserOption: User | null = null;
  openUserDialog(): void {
    const dialogRef = this.dialog.open(PGUserDialogComponent, {
      width: '400px',
      data: { selectedUserOption: this.selectedUserOption },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedUserOption = result;
      }
    });
  }

  formatPage() {
    const dialogRef = this.dialog.open(FormatePageComponent, {
      data: {
        data: { title: 'Formal Page' },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new FontStyle.SetFontStyle(result));
        this.expandLevel(result?.pageExpand);
        this.pageFreezeColumn = result?.pageFreezeColumn;
        this.mainService.pageFormate.next(result);
      }
    });
  }

  deletePage() {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        data: "shall be Deleted? Click 'Delete' to confirm ",
        name: 'Page',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'Page') {
        this.store.dispatch(new Sheet.DeletePage());
      }
    });
  }
  celldata: any;

  viewPage() {
    let formatData = {
      formatUser: 'User ABC',
      pageNestedColumn: 'Nested Column Data',
      pageFreezeColumn: 'Freeze Column Data',
      pageExpand: 'Expand Data',
      pageSort: 'Sort Data',
      pageFilter: 'Filter Data',
      pageLevelSet: 'Level Set Data',
      pageSearchSet: 'Search Set Data',
      pageFontStyle: 'Font Style Data',
      pageComment: 'Comment Data',
      pageTransactions: 'Transaction Data',
    };
    const dialogRef = this.dialog.open(DialogViewComponent, {
      data: {
        clicked: 'Page',
        cell: this.celldata,
        format: formatData,
        columns: AllPages_columns,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
      }
    });
  }

  openContextMenu(event: MouseEvent, menuId: string): void {
    event.preventDefault();
    if (event && menuId == 'pageMenu') {
      this.pageMenu = !this.pageMenu;
    }
  }

  OpenAmountDialoag() {
    let dialogRef = this.dialog.open(EditAmountDialogComponent, {
      data: { value: 'this.currentCell.getValue()' },
    });
  }
  OpenJSONDialog() {
    let dialogRef = this.dialog.open(EditJSONDialogComponent, {
      data: { value: 'this.currentCell.getValue()' },
    });
  }
  OpenTextDialog() {
    let dialogRef = this.dialog.open(AddEditTextComponent, {
      data: { value: 'this.currentCell.getValue()' },
    });
  }
  OpenFormulaDialog() {
    let dialogRef = this.dialog.open(AddEditFormulaComponent, {
      data: { value: 'this.currentCell.getValue()' },
    });
  }
  handleKeydown(event: KeyboardEvent): boolean {
    const inputElement = event.target as HTMLInputElement;
    const key = event.key;

    // Allow numbers, backspace, dot, and arrow keys
    if (
      (key >= '0' && key <= '9') ||
      key === 'Backspace' ||
      key === '.' ||
      key === 'ArrowLeft' ||
      key === 'ArrowRight' ||
      key === 'ArrowUp' ||
      key === 'ArrowDown'
    ) {
      return true;
    }
    event.preventDefault();
    return false;
  }
}
