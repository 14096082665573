import { Alignment, Bold, EditorConfig, Essentials, Font, FontBackgroundColor, FontColor, FontFamily, FontSize, Heading, Italic, Paragraph, Strikethrough, Subscript, Superscript, TextPartLanguage, Underline } from "ckeditor5";
import { environment } from "../../../envirnoments/environment";

export const CK_EDITOR_DEFAULT_TEXT: string = '"The quick brown fox jumped over the lazy dog"';

export const CKEditorConfig: EditorConfig = {
    licenseKey: environment.ckEditorLicenseKey,
    plugins: [
        Paragraph, Bold, Italic, Underline, Strikethrough,
        Font, FontSize, FontColor, FontBackgroundColor,
        Heading, Alignment, FontFamily, Essentials,
        Subscript, Superscript, TextPartLanguage
    ],
    toolbar: [
        'undo', 'redo', '|', 'heading', '|', 'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript', '|',
        'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', '|',
        'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify', '|',
        'highlight', '|', 'textPart', '|',
        'wrapText'
    ],
    fontFamily: {
        supportAllValues: true
    },
    fontSize: {
        options: [10, 12, 14, 16, 18, 20, 22, 24, 28, 32],
        supportAllValues: true
    },
    fontColor: {
        colors: [
            { color: 'black', label: 'Black' },
            { color: 'red', label: 'Red' },
            { color: 'blue', label: 'Blue' }
        ]
    },
    fontBackgroundColor: {
        colors: [
            { color: 'yellow', label: 'Yellow' },
            { color: 'lightblue', label: 'Light Blue' },
            { color: 'green', label: 'Green' }
        ]
    },
    alignment: {
        options: ['left', 'center', 'right', 'justify']
    },
};