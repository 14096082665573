import { Pipe, PipeTransform } from '@angular/core';
import { PgObject } from '../../store/pg-tab/pg-tab.model';
import { MainService } from '../../core/services/main-service/main.service';

@Pipe({
  name: 'getValueFormObject',
  standalone: true
})
export class GetValueFormObjectPipe implements PipeTransform {

  transform(value: PgObject | string): string {
     if (typeof value ==='object') {
        return value[MainService.currentLanguage().langID] ?? Object.values(value)[0] as string
      }
      return value as string;
  }

}
