import { Injectable } from '@angular/core';
import { DEFAULT_LANGUAGE } from '../../interfaces/language.interface';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  public constructor() {
    const bodyElement = document.body;
    bodyElement.removeAttribute('style'); 
  }

  public setLanguage(language: string | null): void {
    const bodyElement = document.body;
    bodyElement.classList.remove('notranslate');
    bodyElement.removeAttribute('style');
    if (language) {
      if(language != DEFAULT_LANGUAGE.code){
        this.translatePage(language);
        this.translatePage(language);
      }
    } else {
      bodyElement.classList.add('notranslate');
      this.translatePage(DEFAULT_LANGUAGE.code);
      this.translatePage(DEFAULT_LANGUAGE.code);
    }
  }

  private translatePage(language: string): void {
    const selectElement = document.querySelector(
      '.goog-te-combo'
    ) as HTMLSelectElement;
    if (selectElement) {
      const langValue = language;
      selectElement.value = langValue;
      selectElement.dispatchEvent(new Event('change'));
    }
  }
}
