import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { UNICODE_MULTIPLY } from '../../core/constants/app.contants';

@Component({
  selector: 'app-pg-user-dialog',
  templateUrl: './pguser-dialog.component.html',
  styleUrls: ['./pguser-dialog.component.css']
})
export class PGUserDialogComponent {
  closeResult: string = '';
  @ViewChild('content', { static: true }) content: any;
  private modalRef!: NgbModalRef;
  closeTab=UNICODE_MULTIPLY;
  constructor(
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<PGUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.open(this.content);

  
  }

  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onClose();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onClose();
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  login() {
    this.modalRef.dismiss();
    this.dialogRef.close();
  }

  openProfile() {
    this.modalRef.dismiss();
    this.dialogRef.close();
  }

  logout() {
    this.modalRef.dismiss();
    this.dialogRef.close();
  }

  register() {
    this.modalRef.dismiss();
    this.dialogRef.close();
  }

  closeDialog() {
    this.modalRef.dismiss();
    this.dialogRef.close();
  }
  onClose(): void {
    this.modalRef.dismiss();
    this.dialogRef.close();
  }
}
