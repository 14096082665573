import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CopyChildRow, CopyNextRow, MoveChildRow, MoveNextRow, ShareChildRow, ShareNextRow } from '../../../store/row/row.action';
import { CopyRow, MoveRow, ShareRow } from '../../../shared/models/row/row.models';
import { RowComponent, Tabulator } from 'tabulator-tables';
import { RowPageData } from '../../../shared/models/edit-dd-dailogs/dd-dailog.models';
@Injectable({
  providedIn: 'root'
})
export class RowFacadeService {
  constructor(private store : Store) {}
  copyNextRow(tabulator : Tabulator, selectedRow : RowComponent, activePgId : number){
    const rows = tabulator.getSelectedData().map((res)=> Number(res.row));
    const currentRow= selectedRow.getData() as RowPageData;
    const data : CopyRow = {
      Row: rows.length > 0 ? rows : [],
      RowLevel: currentRow.RowLevel,
      Pg: activePgId,
      ParentRow: null,
      SiblingRow: Number(currentRow.row),

    }
    this.store.dispatch(new CopyNextRow(data));
  }

  copyChildRow(tabulator : Tabulator, selectedRow : RowComponent,activePgId : number){
    const rows = tabulator.getSelectedData().map((res)=> Number(res.row));
    const currentRow= selectedRow.getData() as RowPageData;
    const data : CopyRow = {
      Row: rows.length > 0 ? rows : [],
      RowLevel: currentRow.RowLevel + 1,
      Pg: activePgId,
      ParentRow:  Number(currentRow.row),
      SiblingRow: null,

    }
    this.store.dispatch(new CopyChildRow(data));
  }

  moveChildRow(tabulator : Tabulator, selectedRow : RowComponent,activePgId : number){
    const rows = tabulator.getSelectedData().map((res)=> Number(res.row));
    const currentRow= selectedRow.getData() as RowPageData;
    const data : CopyRow = {
      Row: rows.length > 0 ? rows : [],
      RowLevel: currentRow.RowLevel + 1,
      Pg: activePgId,
      ParentRow: Number(currentRow.row),
      SiblingRow: null,
    }
    this.store.dispatch(new MoveChildRow(data))
  }

  moveNextRow(tabulator : Tabulator, selectedRow : RowComponent,activePgId : number){
    const rows = tabulator.getSelectedData().map((res)=> Number(res.row));
    const currentRow= selectedRow.getData() as RowPageData;
    const data : CopyRow = {
      Row: rows.length > 0 ? rows : [],
      RowLevel: currentRow.RowLevel,
      Pg: activePgId,
      ParentRow: null,
      SiblingRow: Number(currentRow.row),

    }
    this.store.dispatch(new MoveNextRow(data))
  }

  shareChildRow(tabulator : Tabulator, selectedRow : RowComponent,activePgId : number){
    const rows = tabulator.getSelectedData().map((res)=> Number(res.row));
    const currentRow= selectedRow.getData() as RowPageData;
    const data : CopyRow = {
      Row: rows.length > 0 ? rows : [],
      RowLevel: currentRow.RowLevel + 1,
      Pg: activePgId,
      ParentRow: Number(currentRow.row),
      SiblingRow: null,

    }
    this.store.dispatch(new ShareChildRow(data))
  }

  shareNextRow(tabulator : Tabulator, selectedRow : RowComponent,activePgId : number){
    const rows = tabulator.getSelectedData().map((res)=> Number(res.row));
    const currentRow= selectedRow.getData() as RowPageData;
    const data : CopyRow = {
      Row: rows.length > 0 ? rows : [],
      RowLevel: currentRow.RowLevel,
      Pg: activePgId,
      ParentRow: null,
      SiblingRow: Number(currentRow.row),

    }
    this.store.dispatch(new ShareNextRow(data))
  }
}

