import { Injectable } from '@angular/core';
import { EditItemStateModel, ItemStateModel } from './item.model';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tPgService } from '../../core/services/tPg.service';
import { tap } from 'rxjs';
import { AddItemDataInRow, AddItemDataInRowFailure, AddItemDataInRowSuccess, AddItemThroughDropdown, EditItemData, EditItemDataInRow, EditItemDataInRowFailure, EditItemDataInRowSuccess, GetItemDropdown, UpdateItemThroughDropdown } from './item.action';
import { ItemStoreState } from './item.state';
import { tItemService } from '../../core/services/tItem.service';
import { FormatResponse } from '../../core/interfaces/response.interface';
import { SheetState } from '../page/page.store';
import { MainService } from '../../core/services/main-service/main.service';
import { Sheet } from '../page/page.actions';
import { PAGE_ID_DATA_TYPE } from '../../core/constants/menu-bar/page/page';
import { SystemMessage } from '../message/message.actions';
import { Actions, DbObjects, Notify, Reason, Message } from '../../core/enums/tokens/tokens.enum';

@State<ItemStateModel>({
  name: 'item',
  defaults: ItemStoreState,
})
@Injectable()
export class ItemState {
  constructor(
    private _tPgService: tPgService,
    private tItem : tItemService,
    private store : Store,
    private mainService : MainService
  ) {}

  @Selector()
  static isLoading(state: ItemStateModel) {
    return state.isLoading;
  }

  @Action(GetItemDropdown)
  getItemDropdown(
    ctx: StateContext<ItemStateModel>,
    action: GetItemDropdown
  ) {
    return this._tPgService.getItemDropdown(action.payload).pipe(
      tap((data: any) => {
        const state = ctx.getState();
      })
    );
  }

  @Action(UpdateItemThroughDropdown)
  updateItemThroughDropdown(
    ctx: StateContext<ItemStateModel>,
    action: UpdateItemThroughDropdown
  ) {
    return this._tPgService.updateItemThroughDropdown(action.payload).pipe(
      tap((data: any) => {
        const state = ctx.getState();
      })
    );
  }

  @Action(AddItemThroughDropdown)
  addItemThroughDropdown(
    ctx: StateContext<ItemStateModel>,
    action: AddItemThroughDropdown
  ) {
    return this._tPgService.addItemThroughDropdown(action.payload).pipe(
      tap((data: any) => {
        const state = ctx.getState();
      })
    );
  }

  @Action(EditItemData)
  editItemData(
    ctx: StateContext<EditItemStateModel>,
    action: EditItemData
  ) {
    let payload = action.payload;
    if(action.payload.DataType === Number(PAGE_ID_DATA_TYPE)){
      payload = {
        ...payload,
        Row : Number(action.payload.CRow),
      }     
    }
    return this._tPgService.editItem(payload).pipe(
      tap((data: any) => {
        const state = ctx.getState();
        const stateModel: EditItemStateModel = {
          editItemData: data,
        };
        // Update the existing state
        ctx.setState({
          ...state,
          ...stateModel,
        });
      })
    );
  }

  @Action(EditItemDataInRow)
  editItemDataInRow(ctx: StateContext<ItemStateModel>, action: EditItemDataInRow) {
    ctx.patchState({
      isLoading : true,
    })
    return this.tItem.editItem(action.payload).pipe(
      tap((res : FormatResponse<any>)=>{
        if(res.success){
          let payload = action.payload;
          let message = '';
          if(action.text){
            payload = {
              ...payload,
              Object : action.text
            }
          }

          message = this.mainService.getMessage({
            Notify: Notify.Success,
            Action: Actions.Edit,
            Object: DbObjects.LocalItem,
            ID: Number(res.data.updatedItem.Item),
            Reason: Reason.Actioned,
            Message: Message.ResponseMessage
          });
          
          this.store.dispatch(new SystemMessage.HandleSuccess(message));
          ctx.dispatch(new EditItemDataInRowSuccess(payload, res.data.updatedItem.Item));
          
        }else{
          ctx.dispatch( new EditItemDataInRowFailure(res.error));
          this.store.dispatch(new SystemMessage.HandleSuccess(res.error));
        }
      })
    );
  }

  @Action(EditItemDataInRowSuccess)
  editItemDataInRowSuccess(ctx: StateContext<ItemStateModel>, action: EditItemDataInRowSuccess) {
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
    const modifiedTabsData = this.mainService.addItem(allTabsData,action.payload);
    const modifiedFlatTabData = this.mainService.addFlatDataItem(allTabsFlatData,action.payload,action.itemItem,);

    ctx.patchState({
      isLoading : false,
    })
    this.store.dispatch(
      new Sheet.UpdatePageData({
        allTabsData: modifiedTabsData,
        allTabsFlatData: modifiedFlatTabData,
        defaultPageId : action.payload.Pg
      })
    );
  }


  @Action(EditItemDataInRowFailure)
  editItemDataInRowFailure(ctx: StateContext<ItemStateModel>, action: EditItemDataInRowFailure) {
    ctx.patchState({
      isLoading : false,
      error : action.error
    })
  }

  @Action(AddItemDataInRow)
  addItemDataInRow(ctx: StateContext<ItemStateModel>, action: AddItemDataInRow) {
    ctx.patchState({
      isLoading : true,
    })
    let payload = action.payload;
    return this.tItem.addItem(payload).pipe(
      tap((res : FormatResponse<any>)=>{
        if(res.success){
          let payload = action.payload;
          let message = '';
          if(action.payload.DataType === Number(PAGE_ID_DATA_TYPE)){
            payload = {
              ...payload,
              Row : Number(res.data?.updatedCell?.['Row']),
              CRow : Number(res.data?.updatedCell?.['Row']),
            }
          }
          if(action.text){
            payload = {
              ...payload,
              Object : action.text
            }
          }

          message = this.mainService.getMessage({
            Notify: Notify.Success,
            Action: Actions.Add,
            Object: DbObjects.LocalItem,
            ID: Number(res.data.createdItem.Item),
            Reason: Reason.Actioned,
            Message: Message.ResponseMessage
          });
          
          this.store.dispatch(new SystemMessage.HandleSuccess(message));
          ctx.dispatch(new AddItemDataInRowSuccess(payload,  res.data.createdItem.Item, action.targetRow));
        }else{
          ctx.dispatch( new AddItemDataInRowFailure(res.error));
          this.store.dispatch(new SystemMessage.HandleError(res.error));
        }
      })
    );
  }

  @Action(AddItemDataInRowSuccess)
  addItemDataInRowSuccess(ctx: StateContext<ItemStateModel>, action: AddItemDataInRowSuccess) {
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
    const modifiedTabsData = this.mainService.addItem(allTabsData,action.payload);
    const modifiedFlatTabData = this.mainService.addFlatDataItem(allTabsFlatData,action.payload,action.itemItem,action.targetRow);
    ctx.patchState({
      isLoading : false,
    })
    this.store.dispatch(
      new Sheet.UpdatePageData({
        allTabsData: modifiedTabsData,
        allTabsFlatData: modifiedFlatTabData,
        defaultPageId : action.payload.Pg
      })
    );
  }

  @Action(AddItemDataInRowFailure)
  AddItemDataInRowFailure(ctx: StateContext<ItemStateModel>, action: AddItemDataInRowFailure) {
    ctx.patchState({
      isLoading : false,
      error : action.error
    })
  }
}
