<div class="edit-dd-container" #popupContainer>
  <div class="dialog-header" (mousedown)="onDragStart($event)">
  <div class="header-text">Edit-DD-Dialog</div>
  <div type="button" class="close-icon" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">{{ closeTab }}</span>
  </div>
  </div>
    <edit-dd-dialog-menu-bar class="menu-bar-container" id="ddi-menu-bar"></edit-dd-dialog-menu-bar>
    <edit-dd-dialog-content class="tabulator-container"></edit-dd-dialog-content>
  <div class="resizer" (mousedown)="onResizeStart($event)">
    <span [innerHTML]="expandIcon"></span>
  </div>
</div>

