import { LabelsStateModel } from "./labels.model";

export const LabelsState : LabelsStateModel = {
  isLoading: false,
  error: '',
  currentObjects: {},
  currentObjectsDefinition: [],
  systemObjectsDefinition: [],
  currentObjectsLabelsWithTab: {},
  currentObjectsLabelsWithSession : {},
  currentObjectsLabelsWithUser : {}
};
