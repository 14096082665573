import { Injectable } from '@angular/core';
import { Observable, withLatestFrom } from 'rxjs';
import { ApiService } from '../api.service';
import { ApiResponse } from '../../interfaces/response.interface';

@Injectable({
  providedIn: 'root'
})
export class MenuBarService {
  private apiUrl = 'tPg';
  constructor(private apiService : ApiService,) {}
  getMenuBar(DDL: any): Observable<ApiResponse> {
    return this.apiService.get<ApiResponse>(`${this.apiUrl}/DDLs/${DDL}`);
  }
}
