<div class="menu-bar-container">
  <!-- Refresh Chip -->
  @for (menu of menuBarFacade.getMenuBar$ | async; track $index; let i = $index) {
    <div class="menu-item-container">
      <div [ngSwitch]="menu.token">
        <!-- Case: menu.token is '↻' -->
        <div class="Chip" *ngSwitchCase="'↻'">
          <div
            [appTooltip]="currentObjectWithLabels[menu.token_formula]"
            [name]="menu.row"
            (mouseenter)="tooltipService.triggerTooltipFor(menu.row)"
            id="{{ menu.row }}"
            class="dropdown-button-container">
            <div
              class="language-Icon"
              alt="reload-Icon"
              [class.blink]="false">
              {{ menu.token }}
            </div>
          </div>
        </div>
        <div class="Chip" *ngSwitchCase="'🌎'">
          <div
            [appTooltip]="currentObjectWithLabels[menu.token_formula]"
            [name]="menu.row"
            (mouseenter)="tooltipService.triggerTooltipFor(menu.row)"
            id="{{ menu.row }}"
            class="dropdown-button-container"
            (click)="showCountryRegion()">
            {{ menu.token }}
          </div>
        </div>
        <div class="Chip" *ngSwitchCase="'文'">
          <div
            [appTooltip]="currentObjectWithLabels[menu.token_formula]"
            [name]="menu.row"
            (mouseenter)="tooltipService.triggerTooltipFor(menu.row)"
            id="{{ menu.row }}"
            class="dropdown-button-container">
            <div class="tooltip-container" (click)="showLanguageDialog()">
              {{ menu.token }}
            </div>
            <input
              type="checkbox"
              id="languageCheckbox"
              name="languageCheckbox"
              class="checkbox"
              [checked]="this.mainService.isAutoTranslationChecked()"
              (change)="toggleAutoTranslate(currentObjectWithLabels[menu.token_formula])"
              [ngStyle]="{ height: height + 'em', width: width + 'em' }"/>
          </div>
        </div>
        <div class="Chip" *ngSwitchCase="'¤'" (click)="showCurrencyDialog()">
          <div
            [appTooltip]="currentObjectWithLabels[menu.token_formula]"
            [name]="menu.row"
            (mouseenter)="tooltipService.triggerTooltipFor(menu.row)"
            id="{{ menu.row }}">
            {{ menu.token }}
          </div>
        </div>
        <div class="Chip" (click)="openUserDialog()" *ngSwitchCase="'User'">
          <div class="user-custom-label">{{ menu.token }}</div>
        </div>
        <div
          class="Chip"
          *ngSwitchCase="'Page'"
          (contextmenu)="onRightClick($event)"
          [matMenuTriggerFor]="pageList"
          (click)="showPageDialog()"
          #menuTrigger="matMenuTrigger">
          <span
            [appTooltip]="currentObjectWithLabels[menu.token_formula]"
            [name]="menu.row"
            (mouseenter)="tooltipService.triggerTooltipFor(menu.row)"
            id="{{ menu.row }}">
            {{ menu.token }}
          </span>
        </div>

        <mat-menu #pageList="matMenu" class="custom-menu-panel page-list">
          <div mat-menu-item (click)="formatPage()">Format Page</div>
          <div
            mat-menu-item
            [ngClass]="{disabled: isAllPages}"
            (click)="deletePage()">
            Delete Page
          </div>
          <div mat-menu-item (click)="viewPage()">View Page</div>
        </mat-menu>

        <div class="Chip" *ngSwitchCase="'Edition'">
          <div
            *ngIf="isEditionPg"
            class="dropdown-button-container">
            <div
              [appTooltip]="currentObjectWithLabels[menu.token_formula]"
              [name]="menu.row"
              (click)="showEditionDialog()"
              (mouseenter)="tooltipService.triggerTooltipFor(menu.row)"
              class="tooltip-container">
              {{ menu.token }}
            </div>
          </div>
          <div *ngIf="!isEditionPg" class="dropdown-button-container">
            <div
              [appTooltip]="currentObjectWithLabels[menu.token_formula]"
              [name]="menu.row"
              (mouseenter)="tooltipService.triggerTooltipFor(menu.row)"
              class="tooltip-container">
              Edition
            </div>
            <input
              type="checkbox"
              id="editionCheckbox"
              name="editionCheckbox"
              class="checkbox"
              (change)="handleCheckboxChange($event)"
              [disabled]="false"
              [(ngModel)]="EditionCheckbox"
              [ngStyle]="{ height: height + 'em', width: width + 'em' }"
            />
          </div>
        </div>
        <div
          class="Chip"
          [appTooltip]="currentObjectWithLabels[menu.token_formula]"
          [name]="menu.row"
          (mouseenter)="tooltipService.triggerTooltipFor(menu.row)"
          (click)="showViewDialog()"
          *ngSwitchCase="'Mode'">
          {{ menu.token }}
        </div>
        <div class="Chip" (click)="openColDialog()" *ngSwitchCase="'Cols'">
          {{ menu.token }}
        </div>
        <div class="Chip" *ngSwitchCase="'Freeze'">
          <div class="dropdown-button-container">
            <div class="tooltip-container">Freeze:</div>
            <app-freeze-field
              [columns]="columns$ | async"
              context="menu-bar">
            </app-freeze-field>
          </div>
        </div>
        <div class="Chip" *ngSwitchCase="'Expand'">
          <div class="dropdown-button-container">
            <div class="tooltip-container">
              Expand:
              <app-decimal-field
                [minValue]="isSectionExist ? 0 : 1">
              </app-decimal-field>
            </div>
          </div>
        </div>
        <div class="Chip" *ngSwitchCase="'Sort'">
          <div class="dropdown-button-container">
            <div class="tooltip-container">{{ menu.token }}</div>
            <input
              type="checkbox"
              id="sortCheckbox"
              name="sortCheckbox"
              class="checkbox"
              (change)="toggleSort()"
              [ngStyle]="{ height: height + 'em', width: width + 'em' }"
            />
          </div>
        </div>
        <div class="Chip" *ngSwitchCase="'Filter'">
          <div class="dropdown-button-container">
            <div class="tooltip-container">{{ menu.token }}</div>
            <input
              type="checkbox"
              id="filterCheckbox"
              name="filterCheckbox"
              class="checkbox"
              (change)="toggleFilter()"
              [ngStyle]="{ height: height + 'em', width: width + 'em' }"
            />
          </div>
        </div>
        <div class="Chip" *ngSwitchCase="'Clear'">
          <div class="dropdown-button-container">
            <div class="tooltip-container">Clear</div>
          </div>
        </div>
        <div class="Chip" *ngSwitchCase="'LevelSet'">
          <div class="dropdown-button-container">
            <div class="tooltip-container">LevelSet</div>
            <input
              type="checkbox"
              id="showSetCheckbox"
              name="showSetCheckbox"
              class="checkbox"
              [ngStyle]="{ height: height + 'em', width: width + 'em' }"
            />
          </div>
        </div>
        <div class="Chip" *ngSwitchCase="'RowSet'">
          <div class="dropdown-button-container">
            <div class="tooltip-container">RowSet</div>
            <input
              type="checkbox"
              id="showSetCheckbox"
              name="showSetCheckbox"
              class="checkbox"
              [ngStyle]="{ height: height + 'em', width: width + 'em' }"
            />
          </div>
        </div>
        <div class="Chip" *ngSwitchCase="'Cats'">
          <div class="dropdown-button-container">Cats</div>
        </div>
        <div class="Chip" *ngSwitchCase="'Compare'">
          <div class="dropdown-button-container">
            <div class="tooltip-container">Compare</div>
            <input
              type="checkbox"
              id="compareCheckbox"
              name="compareCheckbox"
              class="checkbox"
              [ngStyle]="{ height: height + 'em', width: width + 'em' }"
            />
          </div>
        </div>
        <div class="Chip" *ngSwitchCase="'Timer'">
          <div class="dropdown-button-container">
            <div class="tooltip-container">Timer</div>
            <input
              type="checkbox"
              id="timerCheckbox"
              name="timerCheckbox"
              class="checkbox"
              [ngStyle]="{ height: height + 'em', width: width + 'em' }"
            />
          </div>
        </div>
      </div>
    </div>
  }
