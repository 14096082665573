import { Component, ViewChild, OnInit, Inject, ViewEncapsulation, ChangeDetectorRef, AfterViewInit, HostListener, ElementRef, inject, signal, WritableSignal } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UNICODE_MULTIPLY, ASCII_EXPAND_ICON, DDL_Languages } from '../../../core/constants/app.contants';
import { DragService } from '../../../core/services/drag-service/drag.service';
import { ResizeService } from '../../../core/services/resize-service/resize.service';
import { JSONPayload } from '../../models/item/item.model';
import { Store } from '@ngxs/store';
import { AddItemDataInRow, EditItemDataInRow } from '../../../store/item/item.action';
import { Observable } from 'rxjs';
import { ItemState } from '../../../store/item/item.store';
import { ContextMenu } from '../../../core/constants/menu-bar/page/page';
import { FormMode } from '../../../core/enums/forms/form';
import { SystemInitials } from '../../../constant';
import { CKEditorResponse } from '../../../core/interfaces/ck-editor.interface';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { MessageStore } from '../../../store/message/message.store';
import { RowLanguageData } from '../../models/edit-dd-dailogs/dd-dailog.models';
import { LabelsKey } from '../../../core/interfaces/labels/labels.iterface';
import { editDdDialogMainComponent } from '../edit-dd-dialog/edit-dd-dialog-main/edit-dd-dialog-main.component';
import { Message, Actions } from '../../../core/enums/tokens/tokens.enum';
import { MainService } from '../../../core/services/main-service/main.service';
@Component({
  selector: 'dialog-edit',
  templateUrl: './dialog-edit.component.html',
  styleUrls: ['./dialog-edit.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogEditComponent implements OnInit,AfterViewInit {
  public defaultEditorValues: WritableSignal<CKEditorResponse[]> = signal<CKEditorResponse[]>([{text: '', style: ''}]);
  form!: FormGroup;
  private modalRef!: NgbModalRef;
  expandIcon = ASCII_EXPAND_ICON;
  @ViewChild('editor',{ static: false }) editorComponent!: any;
  heightClass={value:false,index:0};
  textEditor:any={index:0,textLength:0};
  editorConfig: any = {};
  closeIcon=UNICODE_MULTIPLY;
  selectedLanguages: {[key : string] : string} = {};
  languageOptions : any[] = [];
  public isLayoutReady = false;
  headerTextWidth: number = 0;
  public mode = FormMode.ADD;

  isLoading$: Observable<boolean> = inject(Store).select(ItemState.isLoading);
  successMessage$ = inject(Store).select(MessageStore.getSuccessMessage);
  errorMessage$ = inject(Store).select(MessageStore.getErrorMessage);

  constructor(
    public dialogRef: MatDialogRef<DialogEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dragService: DragService,
    private resizeService: ResizeService,
    private changeDetector: ChangeDetectorRef,
    private store : Store,
    private dialog: MatDialog,
    private mainService: MainService,
  ){}

  ngOnInit() {
    this.defaultEditorValues()[0].text = this.data?.value ?? '';
    this.languageOptions = this.data?.language?.data[DDL_Languages]?.DDLContent ?? [];
    this.selectedLanguages[0] = this.languageOptions[0].row;
    this.form = this.fb.group({
      languageGroups: this.fb.array([this.createLanguageGroup(this.selectedLanguages[0], this.languageOptions[0].language,{text: this.data?.value ?? '', style: ''})])
    });
    
    this.mode = this.data.mode;
  }

  private handleDialog<T>(
      index: number,
      dialogData: any,
      selectedId: string,
      Key : string,
      updateMethod: (rowSelected: T) => void
    ): void {
      const title = this.mainService.getMenuBarDialogMessage({
        Action: Actions.Edit,
        ValueFormulaKey: Key,
        Message: Message.TitleMessage
      });
  
      const dialogRef = this.dialog.open(editDdDialogMainComponent, {
        width: 'auto',
        panelClass: 'pick-ddl',
        data: {
          title,
          token: selectedId,
          data: { selectedLanguages: this.languageGroups.value,  frozen: 0, expandWidth: 0 },
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
         this.onSelect(result.languageRowSelected.row, result.languageRowSelected.language, index);
        }
      });

  }

  private onSelect(value: string, name: string, index: number): void {
    this.selectedLanguages[index] = value;
    this.languageGroupsFields.controls[index].patchValue({
      language: value,
      languageName: name,
    })
    
    this.checkValues(index);
    this.updateRichTextField(index);
  }

  public showLanguageDialog(index: number): void {
    this.handleDialog<RowLanguageData>(
         index,
        'Language',
        DDL_Languages,
        LabelsKey.CurrentLanguage,
        (()=>{})
      );
    }


   @ViewChild('headerText') header!: ElementRef;

  get languageGroupsFields(): FormArray {
    return this.form.get('languageGroups') as FormArray;
  }

    @ViewChild('editdialogcontainer', { static: true })
    popupContainer!: ElementRef<HTMLDivElement>; // to get the reference of the popup container for further manipulaiton

    // Function to handle drag start
    onDragStart(event: MouseEvent) {
      this.dragService.onDragStart(event, this.popupContainer);
    }
    // Function to handle mouse movement
    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
      this.dragService.onMouseMove(event, this.popupContainer);
    }

    // Function to handle drag end
    @HostListener('document:mouseup')
    onMouseUp() {
      this.dragService.onMouseUp(); // Use the service method
    }
    // Resizing functionality
    onResizeStart(event: MouseEvent) {
      this.resizeService.onResizeStart(event, this.popupContainer);
    }

    public ngAfterViewInit(): void {
    this.headerTextWidth = this.header.nativeElement.offsetWidth;
      this.isLayoutReady = true;
      this.changeDetector.detectChanges();
   }

  closeDialog(){
    this.heightClass={value:false,index:0};
  }

  onValueChange({ editor }: ChangeEvent, index: number): void {
    this.checkValues(index);
  }

  checkValues(index: number): void {
    if (this.isCurrentGroupValid()) {
      this.textEditor={value:true,index:0}
      this.addLanguageGroup();
    }
  }

  get languageGroups(): FormArray {
    return this.form.get('languageGroups') as FormArray;
  }

  createLanguageGroup(language:string, languageName:string, value: CKEditorResponse): FormGroup {
    const group = this.fb.group({
      language: [language, Validators.required],
      languageName: [languageName, Validators.required],
      richText: [{ value: value.text, disabled: false }, Validators.required] // Initialize as disabled
    });
    // Update richText field based on language value
    group.get('language')?.valueChanges.subscribe(value => {
      if (value) {
        group.get('richText')?.enable();
      } else {
        group.get('richText')?.enable();
      }
    });

    return group;
  }

  isCurrentGroupValid(): boolean {
    const currentGroup = this.languageGroups.at(this.languageGroups.length - 1) as FormGroup;
    return currentGroup.valid;
  }

  addLanguageGroup() {
    if (this.isCurrentGroupValid()) {
      this.defaultEditorValues().push({text: '', style: ''})
      this.languageGroups.push(this.createLanguageGroup('', '',{text: '', style: ''}));
    }
  }

  removeLanguageGroup(index: number) {
    if (this.languageGroups.length > 1) {
      this.languageGroups.removeAt(index);
      this.defaultEditorValues().splice(index, 1);
    }
  }

  updateRichTextField(index: number) {
    const group = this.languageGroups.at(index) as FormGroup;
    const languageValue = group.get('language')?.value;
    if (languageValue) {
      group.get('richText')?.enable();
    } else {
      group.get('richText')?.disable();
    }
  }

  onSave(): void {
    const context = this.data.context;
    const payload : JSONPayload = {
      Pg : Number(this.data.page_id),
      Col: Number(this.data.columnId),
      Row: Number(this.data.row_id),
      DataType: Number(this.data.dataTypeId),
      JSON: {},
    }

    if(this.languageGroups.value && this.languageGroups.length > 0){
      this.languageGroups.value.forEach((value : { language : string , richText : CKEditorResponse}) => {
        payload.JSON[value.language] = this.transFormText(value.richText.text);
      });
    }

    if (context === ContextMenu.FormatLocalColumn) {
      this.dialogRef.close(payload.JSON);
    } else {
      const action = this.mode === FormMode.EDIT
       ? new EditItemDataInRow(payload) :
       new AddItemDataInRow(payload, this.data.row);
      this.store.dispatch(action)
      this.mode = FormMode.EDIT;
    }


    this.resetSelectedLanguages();

    this.form.markAsPristine();
  }

  onCancel(): void {
    this.dialogRef.close();
    this.resetSelectedLanguages();
  }

  resetSelectedLanguages(){
    this.selectedLanguages = {};
  }

  updateLanguageOptions(){
    this.languageOptions = [...this.data.language.data.DDLContent];
  }

  transFormText(text : string){
    const withoutTags = text.replace(/<\/?[^>]+(>|$)/g, '');

    const withoutEntities = withoutTags.replace(/&nbsp;/g, ' ').replace(/&[a-z]+;/g, '');

    return withoutEntities
      .trim()
      .split(' ')
      .map(word => word.charAt(0) + word.slice(1))
      .join(' ');
  }
}
