<div class="format-page-container" #formatPageContainer>
  <div class="dialog-header" (mousedown)="onDragStart($event)">
    <div class="header-text">Edit-DD-Dialog</div>
  </div>
  <div class="form-card" style="overflow: auto;">
    <app-formate-page-content [childData]="data" (formDataChange)="onFormDataChange($event)"></app-formate-page-content>
    <div class="button-group">
      <div class="button-group-container">
        <div type="button" class="Chip" (click)="onSave();">Save</div>
      </div>
      <div type="button" class="Chip" (click)="onCancel()">Cancel</div>
    </div>
  </div>
  <div class="resizer" (mousedown)="onResizeStart($event)">
    <div class="line1">
      <img src="../../../../../assets/svg/handle-corner.svg" alt="resize-icon">
    </div>
  </div>
</div>