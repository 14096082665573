<div *ngIf="((isLoading$ | async))" class="loader-overlay">
  <div class="loader"></div>
</div>
<form [formGroup]="form" class="form-width">
  <div class="form-group" *ngFor="let field of formData.FormatData">
    <label ngClass="field.Label?.includes('*') ? 'required' : ''">
      {{ field.Label }}
    </label>
    <ng-container>
      <textarea class="custom-input fixed-width"
        *ngIf="field.Name === FORM.Comment"
        [formControlName]="field.Name"
        (click)="openMlTextDialog()">
      </textarea>
      <div class="form-group" *ngIf="field.Name === FORM.Status">
        <div class="custom-input chip-container fixed-width" (click)="openEditDdDialog()">
          <div class="Chip margin-2px" 
            *ngFor="let status of transformKeyValue(field.Value); trackBy: trackByStatus"
            (click)="handleEditDD($event, status.key)">
            {{ status.value }}
          </div>
        </div>
      </div>
      <input
        *ngIf="
          !field.Label?.toLowerCase()?.includes('comment') &&
          field.Name !== FORM.Status && 
          field.Name !== undefined"
        type="text"
        [formControlName]="field.Name"
        class="custom-input"
        [ngClass]="{ 'error': form.controls[field.Name].invalid && (form.controls[field.Name].touched) }"
        (click)="field.Name === FORM.FontStyle ? openFontDialog() 
          : ( field.Name === FORM.PgNestedCol ? openColDialog() : null )"
       #textFieldReference/>
    </ng-container>
  </div>
</form>