<div class="menu-item-container" *ngFor="let menu of menuOptions">
  <ng-container [ngSwitch]="menu">
    <!-- Default for 'col' -->
    <div *ngSwitchCase="'Cols'" class="Chip" (click)="openColDialog()">
      <div class="dropdown-button-container">
        <span>{{menu}}</span>
      </div>
    </div>

    <!-- Content for 'freeze' -->
    <div *ngSwitchCase="'Freeze'" class="Chip">
      <div class="tooltip-container">{{ menu }}:</div>
      <div class="tooltip-container">
        <u>{{ pageFreezeColumn }}</u>
      </div>
      <div class="expand-icon" (click)="toggleFreeze($event)">
        {{ expand }}
      </div>
    </div>

    <!-- Content for 'expand' -->
    <div *ngSwitchCase="'Expand'" class="Chip">
      <div class="dropdown-button-container">
        <div class="tooltip-container">{{ menu }}:</div>
        <div class="tooltip-container">
          <input
            tabindex="-1"
            type="text"
            class="expand-input"
            id="expand-level-input"
            autocomplete="off"
            appAutoAdjustWidth
            mask="99"
            (keydown)="handleKeydown($event)"
            [(ngModel)]="expandLevelN"
            (input)="onInputChange($event)"
          />
        </div>
        <div class="expand-icon" (click)="onExpandToggle($event)">
          {{ expand }}
        </div>
      </div>
    </div>

    <!-- Content for 'sort' -->
    <div *ngSwitchCase="'Sort'" class="Chip">
      <div class="dropdown-button-container">
        <div class="tooltip-container">
          <span>{{ menu }}</span>
        </div>
        <input
          type="checkbox"
          id="sortCheckbox"
          name="sortCheckbox"
          [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          class="checkbox"
          (change)="toggleSort()"
        />
      </div>
    </div>

    <!-- Content for 'filter' -->
    <div *ngSwitchCase="'Filter'" class="Chip">
      <div class="dropdown-button-container">
        <div class="tooltip-container">
          <span>{{ menu }}</span>
        </div>
        <input
          (change)="toggleFilter()"
          [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          type="checkbox"
          id="filterCheckbox"
          name="filterCheckbox"
          class="checkbox"
        />
      </div>
    </div>

    <!-- Content for 'clear' -->
    <div *ngSwitchCase="'Clear'" class="Chip" (click)="clearFilters()">
      <div class="dropdown-button-container">
        <span style="color: red">Clear</span>
      </div>
    </div>
    <!-- Default case (if no match) -->
    <div *ngSwitchDefault class="Chip">
      <div>{{ menu }}</div>
    </div>
  </ng-container>
</div>
