import { Action, Selector, State, StateContext } from "@ngxs/store";
import { MenuBar, MenuBarStateModel } from "./menu-bar.model";
import { MenuBarState } from "./menu-bar.state";
import { Injectable } from "@angular/core";
import { GetMenuBar, GetMenuBarFailure, GetMenuBarSuccess } from "./menu-bar.actions";
import { MenuBarService } from "../../core/services/menu-bar/menu-bar.service";
import { tap } from "rxjs";
import { ApiResponse } from "../../core/interfaces/response.interface";

@State<MenuBarStateModel>({
  name: 'menuBar',
  defaults: MenuBarState,
})
@Injectable()
export class MenuBarStore {
  constructor(private menuBarService : MenuBarService){}

 @Selector()
  static getMenuBar(state: MenuBarStateModel) {
    return state.menuBar;
  }

  @Selector()
  static getMenuBarLoading(state: MenuBarStateModel) {
    return state.isLoading;
  }

  @Action(GetMenuBar)
  getMenuBar(ctx: StateContext<MenuBarStateModel>, action: GetMenuBar) {
      ctx.patchState({
          isLoading : true,
      })
      return this.menuBarService.getMenuBar(action.token).pipe(
        tap((res : ApiResponse)=>{
          if(res.success){
            ctx.dispatch(new GetMenuBarSuccess(res.data[action.token].DDLContent as MenuBar[]));
          }else{
            ctx.dispatch(new GetMenuBarFailure(res.errorMsg));
          }
        })
      )
  }

  @Action(GetMenuBarSuccess)
  getMenuBarSuccess(ctx: StateContext<MenuBarStateModel>, action: GetMenuBarSuccess) {
    ctx.patchState({
      menuBar : action.menuBar
    })
  }

  @Action(GetMenuBarFailure)
  getMenuBarfailure(ctx: StateContext<MenuBarStateModel>, action: GetMenuBarFailure) {
    ctx.patchState({
      error : action.error
    })
  }
}
