import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tPgService } from '../../core/services/tPg.service';
import { Injectable } from '@angular/core';
import { MainService } from '../../core/services/main-service/main.service';
import { MessageStateModel } from './message.model';
import { MessageStateInitial } from './message.state';
import { SystemMessage } from './message.actions';


@State<MessageStateModel>({
  name: 'message',
  defaults: MessageStateInitial,
})
@Injectable()
export class MessageStore {
  constructor(
    private _tPgService: tPgService,
    private store : Store,
    private mainService : MainService
  ) { }

  @Selector()
  static getSuccessMessage(state: MessageStateModel) {
    return state.successMsg;
  }

  @Selector()
  static getErrorMessage(state: MessageStateModel) {
    return state.errorMsg;
  }
  
  @Action(SystemMessage.HandleSuccess)
  HandleSuccess(ctx: StateContext<MessageStateModel>, action: SystemMessage.HandleSuccess) {
    ctx.patchState({
      isLoading : false,
      success: true,
      successMsg: action.message
    })
  }

  @Action(SystemMessage.HandleError)
  HandleError(ctx: StateContext<MessageStateModel>, action: SystemMessage.HandleError) {
    ctx.patchState({
      isLoading : false,
      success: false,
      errorMsg: action.message
    })
  }
}
