import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AddRowModel, CopyRow, CopyRowResponse, MoveRow, ShareRow } from '../../../shared/models/row/row.models';
import { FormatResponse, RowResponse } from '../../interfaces/response.interface';

@Injectable({
  providedIn: 'root'
})
export class RowService {
  private apiUrl = 'tRow';
  constructor(private apiService: ApiService, private http: HttpClient) {}
  addPgRow(row: AddRowModel): Observable<FormatResponse<any>> {
    return this.apiService.post<FormatResponse<any>>(`${this.apiUrl}/pgRow`,row);
  }
  addSystemPgRow(row: AddRowModel): Observable<FormatResponse<any>> {
    return this.apiService.post<FormatResponse<any>>(`${this.apiUrl}`,row);
  }
  copyRow(payload : CopyRow){
    return this.apiService.post<RowResponse>(`${this.apiUrl}/CopyRow`,payload);
  }
  moveRow(payload : MoveRow){
    return this.apiService.post<RowResponse>(`${this.apiUrl}/MoveRow`,payload);
  }
  shareRow(payload : ShareRow){
    return this.apiService.post<RowResponse>(`${this.apiUrl}/ShareRow`,payload);
  }
}
