import { Pipe, PipeTransform } from '@angular/core';
import { CellData } from '../models/edit-dd-dailogs/dd-dailog.models';

@Pipe({
  name: 'getValueFromKey'
})
export class GetValueFromKeyPipe implements PipeTransform {

  transform(data: CellData): string {
    const val = data?.cell?.items[0]?.item || data?.format?.items[0]?.item || '';
    if (val) {
      const text = typeof val === 'object' ? Object.values(val)[0] : val;
      return text as string;
    }
    return '';
  }
}
