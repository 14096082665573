
<div *ngIf="editDdTabBar.length > 0" class="d-flex flex-nowrap tabs-container">
    @for (tab of editDdTabBar; track tab; let i = $index) {
    <div 
    class="chip flex-shrink-0-1-auto"
    [ngClass]="{ 'ecllipses' : i >= 14, 'active flex-shrink-0' : i === currentSheetIndex}"
    (click)="onSelectEditDDTab(tab, i)">
        <span>{{tab}}<sup class="cross" [innerHTML]="cross_icon" (click)="onDeleteTab(tab, i)"></sup></span>
    </div>
    }
</div>
