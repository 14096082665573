import { Injectable, ElementRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResizeService {
  private isResizing = false;
  private initialMouseX = 0;
  private initialMouseY = 0;
  private initialWidth = 0;
  private initialHeight = 0;
  scrollbarWidth = this.getScrollbarWidth(); // Getting the side scrollbar width

  private newWidth = 0;
  private newHeight = 0;
  // Function to calculate the side scrollbar width
  getScrollbarWidth(): number {
    return window.innerWidth - document.documentElement.clientWidth;
  }
  private onMouseMoveHandler!: (event: MouseEvent) => void;
  private onMouseUpHandler!: () => void;

  public onResizeStart(event: MouseEvent, popup: ElementRef) {
    this.isResizing = true;
    this.initialMouseX = event.clientX;
    this.initialMouseY = event.clientY;

    const popupElement = popup.nativeElement;
    this.initialWidth = popupElement.offsetWidth;
    this.initialHeight = popupElement.offsetHeight;

    document.body.classList.add('no-select');
    event.preventDefault();

    // Assign the handlers
    this.onMouseMoveHandler = (e) => this.onResize(popup, e);
    this.onMouseUpHandler = () => this.onResizeEnd();

    document.addEventListener('mousemove', this.onMouseMoveHandler!);
    document.addEventListener('mouseup', this.onMouseUpHandler!);
  }

  private onResize(popup: ElementRef, event: MouseEvent) {
    if (this.isResizing) {
      event.preventDefault();
      // Check if the mouse is within the viewport bounds
      if (event.clientX < 0 || event.clientY < 0 || event.clientX > window.innerWidth - this.scrollbarWidth || event.clientY > window.innerHeight) {
        return; // Stop resizing if the mouse is outside the viewport
      }

      const dx = event.clientX - this.initialMouseX;
      const dy = event.clientY - this.initialMouseY;

      const popup1 = popup.nativeElement;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Get the current position and dimensions of the popup
      const popupRect = popup1.getBoundingClientRect();
      // Calculate the new width and height based on mouse movement
      this.newWidth = Math.max(100, this.initialWidth + dx);
      this.newHeight = Math.max(100, this.initialHeight + dy);

      // Check if the dialog is near the left boundary and allow shrinking
      if (popupRect.left <= 0) {
        if (dx < 0) {
          // Allow shrinking when resizing to the left (dx < 0)
          this.newWidth = Math.max(100, popupRect.width - Math.abs(dx));
        } else {
          // Prevent increasing size if moving to the right
          this.newWidth = popupRect.width + popupRect.left;
        }
      }

      // Check if the dialog is near the top boundary and allow shrinking
      if (popupRect.top <= 0) {
        if (dy < 0) {
          // Allow shrinking when resizing upwards (dy < 0)
          this.newHeight = Math.max(100, popupRect.height - Math.abs(dy));
        } else {
          // Prevent increasing size if moving downwards
          this.newHeight = popupRect.height + popupRect.top;
        }
      }

      // Stop resizing if the popup is touching or crossing the right boundary
      if (popupRect.left + this.newWidth >= viewportWidth - this.scrollbarWidth) {
        this.newWidth = viewportWidth - popupRect.left - this.scrollbarWidth;
      }

      // Stop resizing if the popup is touching or crossing the bottom boundary
      if (popupRect.top + this.newHeight >= viewportHeight) {
        this.newHeight = viewportHeight - popupRect.top;
      }

      // Apply the constrained width and height to the popup element
      popup1.style.width = `${this.newWidth}px`;
      popup1.style.height = `${this.newHeight}px`;
    }
  }

  private onResizeEnd() {
    this.isResizing = false;
    document.body.classList.remove('no-select');

    // Remove event listeners
    document.removeEventListener('mousemove', this.onMouseMoveHandler!);
    document.removeEventListener('mouseup', this.onMouseUpHandler!);
  }
}
