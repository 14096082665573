import { PgTab } from './pg-tab.model';

export class LoadPgTabs {
  static readonly type = '[PgTabs] Load PgTabs';
  constructor(public page_id: any) {}
}

export class LoadPgTabsSuccess {
  static readonly type = '[PgTab] Load PgTabs Success';
  constructor(public pgTabs: PgTab[]) {}
}

export class LoadPgTabsFailure {
  static readonly type = '[PgTab] Load PgTabs Failure';
  constructor(public error: string) {}
}

export class AddPgTab {
  static readonly type = '[PgTabs] Add PgTab';
  constructor(public pgTab: PgTab) {}
}

export class SelectPgTab {
  static readonly type = '[PgTab] Select PgTab';
  constructor(public pgTab: PgTab) {}
}

export class LoadMenuBar {
  static readonly type = '[MenuBar] Load MenuBar';
  constructor(public DDL: string) {}
}
