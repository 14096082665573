import { TabCurrentLabels } from "../../core/interfaces/session.interface";
import { CurrentObjectDefinitions, SystemObjectDefinitions } from "./labels.model";

export enum ActionTypes {
  GET_CURRENT_OBJECTS_DEFINITIONS = '[MenuBar] Get Current Objects',
  GET_CURRENT_OBJECTS_DEFINITIONS_SUCCESS = '[MenuBar] Get Current Objects Success',
  GET_CURRENT_OBJECTS_DEFINITIONS_FAILURE = '[MenuBar] Get Current Objects Failure',

    GET_SYSTEM_OBJECTS_DEFINITIONS_SUCCESS = '[ContextMenus] Get System Objects Success',
    GET_SYSTEM_OBJECTS_DEFINITIONS_FAILURE = '[ContextMenus] Get System Objects Failure',

  SET_CURRENT_OBJECTS_ON_TABS = '[MenuBar] Set Current Objects On Tabs',
  SET_CURRENT_OBJECTS_ON_USERS = '[MenuBar] Set Current Objects On User',
  SET_CURRENT_OBJECTS_ON_SESSION = '[MenuBar] Set Current Objects Session',
}

export class GetCurrentObjectDefinitions {
  static readonly type = ActionTypes.GET_CURRENT_OBJECTS_DEFINITIONS;
  constructor() {}
}

export class GetCurrentObjectDefinitionsSuccess {
  static readonly type = ActionTypes.GET_CURRENT_OBJECTS_DEFINITIONS_SUCCESS;
  constructor(public currentObjectsDefinition : CurrentObjectDefinitions[]) {}
}

export class GetSystemObjectDefinitionsSuccess {
  static readonly type = ActionTypes.GET_SYSTEM_OBJECTS_DEFINITIONS_SUCCESS;
  constructor(public systemObjectsDefinition : SystemObjectDefinitions[]) {}
}

export class GetCurrentObjectDefinitionsFailure {
  static readonly type = ActionTypes.GET_CURRENT_OBJECTS_DEFINITIONS_FAILURE;
  constructor(public error : any) {}
}

export class SetCurrentObjectsOnTabs {
  static readonly type = ActionTypes.SET_CURRENT_OBJECTS_ON_TABS;
  constructor(public currentObjects : TabCurrentLabels) {}
}

export class SetCurrentObjectsOnUser {
  static readonly type = ActionTypes.SET_CURRENT_OBJECTS_ON_USERS;
  constructor(public currentObjects : TabCurrentLabels) {}
}

export class SetCurrentObjectsOnSession {
  static readonly type = ActionTypes.SET_CURRENT_OBJECTS_ON_SESSION;
  constructor(public currentObjects : TabCurrentLabels) {}
}



