<div class="menu-bar-container">
  <!-- Refresh Chip -->
  <div class="menu-item-container" *ngFor="let menu of menuBar; let i = index">
    <div [ngSwitch]="menu.item">
      <!-- Case: menu.item is '↻' -->
      <div class="Chip" *ngSwitchCase="'↻'">
        <div
          [appTooltip]="selectedMenuBar.session"
          [name]="menu.rowId"
          (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
          id="{{menu.rowId}}"
          class="dropdown-button-container">
          <div
            class="language-Icon"
            src="./../../../../../../assets/Icons/reload-Icon.svg"
            alt="reload-Icon"
            [class.blink]="false">
            {{ refresh }}
          </div>
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'🌐'">
        <div
          [appTooltip]="selectedMenuBar.currentRegion"
          [name]="menu.rowId"
          (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
          id="{{menu.rowId}}"
          class="dropdown-button-container"
          (click)="showCountryRegion()">
          {{ globe }}
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'文'">
        <div
          [appTooltip]="selectedMenuBar.currentLanguage"
          [name]="menu.rowId"
          (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
          id="{{menu.rowId}}"
          class="dropdown-button-container">
          <div class="tooltip-container" (click)="showLanguageDialog()">
            {{ language }}
          </div>
          <input
            type="checkbox"
            id="languageCheckbox"
            name="languageCheckbox"
            class="checkbox"
            [(ngModel)]="isChecked"
            (change)="toggleAutoTranslate($event)"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'＄'" (click)="showCurrencyDialog()">
        <div
          [appTooltip]="selectedMenuBar.currentCurrency"
          [name]="menu.rowId"
          (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
          id="{{menu.rowId}}">
          {{ uiCurrency }}
        </div>
      </div>
      <div class="Chip" (click)="openUserDialog()" *ngSwitchCase="'User'">
        <div class="user-custom-label">User</div>
      </div>
      <div
        class="Chip"
        *ngSwitchCase="'Page'"
        (contextmenu)="onRightClick($event)"
        [matMenuTriggerFor]="pageList"
        (click)="showPageDialog()"
        #menuTrigger="matMenuTrigger">
         <span
            [appTooltip]="selectedMenuBar.currentPage"
            [name]="menu.rowId"
            (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
            id="{{menu.rowId}}">
            Page
         </span>
      </div>

      <mat-menu #pageList="matMenu" class="custom-menu-panel page-list">
        <div mat-menu-item (click)="formatPage()">Format Page</div>
        <div mat-menu-item (click)="deletePage()">Delete Page</div>
        <div mat-menu-item (click)="viewPage()">View Page</div>
      </mat-menu>

      <div class="Chip" *ngSwitchCase="'Edition'">
        <div
          *ngIf="isEditionPg"
          (click)="showEditionDialog()"
          class="dropdown-button-container">
          <div
            [appTooltip]="selectedMenuBar.currentEdition"
            [name]="menu.rowId"
            (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)">
            Edition
          </div>
          <input
            type="checkbox"
            id="editionCheckbox"
            name="editionCheckbox"
            class="checkbox"
            (change)="handleCheckboxChange($event)"
            [disabled]="false"
            [(ngModel)]="EditionCheckbox"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
        <div *ngIf="!isEditionPg" class="dropdown-button-container">
          <div class="tooltip-container">Edition</div>
          <input
            type="checkbox"
            id="editionCheckbox"
            name="editionCheckbox"
            class="checkbox"
            (change)="handleCheckboxChange($event)"
            [disabled]="false"
            [(ngModel)]="EditionCheckbox"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div
        [appTooltip]="selectedMenuBar.currentMode"
        [name]="menu.rowId"
        (mouseenter)="tooltipService.triggerTooltipFor(menu.rowId)"
        class="Chip"
        (click)="showViewDialog()"
        *ngSwitchCase="'Page Mode'">
        Page Mode
      </div>
      <div
        class="Chip"
        (click)="openColDialog()"
        *ngSwitchCase="'Cols'">
        Cols
      </div>
      <div class="Chip" *ngSwitchCase="'Freeze'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">Freeze:</div>
          <div class="tooltip-container">
            <u>{{ pageFreezeColumn }}</u>
          </div>
          <div (click)="toggleFreeze($event)" class="expand-icon">
            {{ expand }}
          </div>
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'Expand'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">Expand:</div>
          <div class="tooltip-container">
            <input
              tabindex="-1"
              type="text"
              class="expand-input"
              id="expand-level-input"
              autocomplete="off"
              appAutoAdjustWidth
              mask="99"
              (keydown)="handleKeydown($event)"
              [(ngModel)]="expandLevelN"
              (input)="onInputChange($event)"
            />
          </div>
          <div class="expand-icon" (click)="onExpandToggle($event)">
            {{ expand }}
          </div>
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'Sort'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">Sort</div>
          <input
            type="checkbox"
            id="sortCheckbox"
            name="sortCheckbox"
            class="checkbox"
            (change)="toggleSort()"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'Filter'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">Filter</div>
          <input
            type="checkbox"
            id="filterCheckbox"
            name="filterCheckbox"
            class="checkbox"
            (change)="toggleFilter()"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div class="Chip" *ngSwitchCase="'Clear'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">Clear</div>
        </div>
      </div>
      <div
        class="Chip"
        (click)="OpenAmountDialoag()"
        *ngSwitchCase="'LevelSet'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">LevelSet</div>
          <input
            type="checkbox"
            id="showSetCheckbox"
            name="showSetCheckbox"
            class="checkbox"
            (change)="handleCheckboxChange($event)"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div class="Chip" (click)="OpenAmountDialoag()" *ngSwitchCase="'RowSet'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">RowSet</div>
          <input
            type="checkbox"
            id="showSetCheckbox"
            name="showSetCheckbox"
            class="checkbox"
            (change)="handleCheckboxChange($event)"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div class="Chip" (click)="OpenJSONDialog()" *ngSwitchCase="'Cats'">
        <div class="dropdown-button-container">Cats</div>
      </div>
      <div class="Chip" (click)="OpenTextDialog()" *ngSwitchCase="'Compare'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">Compare</div>
          <input
            type="checkbox"
            id="compareCheckbox"
            name="compareCheckbox"
            class="checkbox"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
      <div class="Chip" (click)="OpenFormulaDialog()" *ngSwitchCase="'Timer'">
        <div class="dropdown-button-container">
          <div class="tooltip-container">Timer</div>
          <input
            type="checkbox"
            id="timerCheckbox"
            name="timerCheckbox"
            class="checkbox"
            [ngStyle]="{ height: height + 'em', width: width + 'em' }"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- language -->
  <mat-menu class="dropdown" #languageMenu="matMenu">
    <div
      [ngStyle]="{ 'background-color': tooltip == lang ? '#eeeef2' : '#fff' }"
      class="button-language notranslate"
      mat-menu-item
      *ngFor="let lang of filteredLanguages"
      (click)="setLanguage(lang)">
      {{ lang }}
    </div>
    <div
      class="button-language notranslate"
      mat-menu-item
      (click)="setLanguage(null)">
      None
    </div>
  </mat-menu>
</div>
