import { AddRowModel, CopyRow, CopyRowResponse, MoveRow, ShareRow } from "../../shared/models/row/row.models";
export enum ActionTypes {
  ADD_NEXT_ROW = '[ROW] Add Next Row',
  ADD_NEXT_ROW_SUCCESS = '[ROW] Add Next Row Success',
  ADD_NEXT_ROW_FAILURE = '[ROW] Add Next Row Failure',

  ADD_PREV_ROW = '[ROW] Add Prev Row',
  ADD_PREV_ROW_SUCCESS = '[ROW] Add Prev Row Success',
  ADD_PREV_ROW_FAILURE = '[ROW] Add Prev Row Failure',

  ADD_CHILD_ROW = '[ROW] Add Child Row',
  ADD_CHILD_ROW_SUCCESS = '[ROW] Add Child Row Success',
  ADD_CHILD_ROW_FAILURE = '[ROW] Add Child Row Failure',

  ADD_NEXT_SYSTEM_ROW = '[ROW] Add Next System Row',
  ADD_NEXT_SYSTEM_ROW_SUCCESS = '[ROW] Add Next System Row Success',
  ADD_NEXT_SYSTEM_ROW_FAILURE = '[ROW] Add Next System Row Failure',

  ADD_PREV_SYSTEM_ROW = '[ROW] Add Prev System Row',
  ADD_PREV_SYSTEM_ROW_SUCCESS = '[ROW] Add Prev System Row Success',
  ADD_PREV_SYSTEM_ROW_FAILURE = '[ROW] Add Prev System Row Failure',

  ADD_CHILD_SYSTEM_ROW = '[ROW] Add Child System Row',
  ADD_CHILD_SYSTEM_ROW_SUCCESS = '[ROW] Add Child Row System Success',
  ADD_CHILD_SYSTEM_ROW_FAILURE = '[ROW] Add Child Row System Failure',

  COPY_NEXT_ROW = '[ROW] Copy Next Row',
  COPY_NEXT_ROW_SUCCESS = '[ROW] Copy Next Row Success',
  COPY_NEXT_ROW_FAILURE = '[ROW] Copy Next Row Failure',

  COPY_CHILD_ROW = '[ROW] Copy Child Row',
  COPY_CHILD_ROW_SUCCESS = '[ROW] Copy Child Row Success',
  COPY_CHILD_ROW_FAILURE = '[ROW] Copy Child Row Failure',

  MOVE_NEXT_ROW = '[ROW] Move Next Row',
  MOVE_NEXT_ROW_SUCCESS = '[ROW] Move Next Row Success',
  MOVE_NEXT_ROW_FAILURE = '[ROW] Move Next Row Failure',

  MOVE_CHILD_ROW = '[ROW] Move Child Row',
  MOVE_CHILD_ROW_SUCCESS = '[ROW] Move Child Row Success',
  MOVE_CHILD_ROW_FAILURE = '[ROW] Move Child Row Failure',

  
  SHARE_NEXT_ROW = '[ROW] Share Next Row',
  SHARE_NEXT_ROW_SUCCESS = '[ROW] Share Next Row Success',
  SHARE_NEXT_ROW_FAILURE = '[ROW] Share Next Row Failure',

  SHARE_CHILD_ROW = '[ROW] Share Child Row',
  SHARE_CHILD_ROW_SUCCESS = '[ROW] Share Child Row Success',
  SHARE_CHILD_ROW_FAILURE = '[ROW] Share Child Row Failure'

}

export class AddNextRow {
  static readonly type = ActionTypes.ADD_NEXT_ROW;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null,
      currentRowId : number | null,
    }
  ) {}
}

export class AddNextRowSuccess {
  static readonly type = ActionTypes.ADD_NEXT_ROW_SUCCESS;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null
      currentRowId : number | null,
    }
  ) {}
}


export class AddNextRowFailure {
  static readonly type = ActionTypes.ADD_NEXT_ROW_FAILURE;
  constructor(public error? : any) {}
}

export class AddPrevRow {
  static readonly type = ActionTypes.ADD_PREV_ROW;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null
      currentRowId : number | null,
    }) {}
}

export class AddPrevRowSuccess {
  static readonly type = ActionTypes.ADD_PREV_ROW_SUCCESS;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null,
      currentRowId : number | null,
    }) {}
}

export class AddPrevRowFailure{
  static readonly type = ActionTypes.ADD_PREV_ROW_FAILURE;
  constructor(public error : any) {}
}

export class AddChildRow {
  static readonly type = ActionTypes.ADD_CHILD_ROW;
  constructor(
    public data : {
    row: AddRowModel,
    prevRowId : number | null,
    nextRowId : number | null,
    currentRowId : number | null,
  }) {}
}

export class AddChildRowSuccess {
  static readonly type = ActionTypes.ADD_CHILD_ROW_SUCCESS;
  constructor(
    public data : {
    row: AddRowModel,
    prevRowId : number | null,
    nextRowId : number | null,
    currentRowId : number | null,
  }) {}
}

export class AddChildRowFailure {
  static readonly type = ActionTypes.ADD_CHILD_ROW_FAILURE
  constructor(public error : any) {}
}

export class AddNextSystemRow {
  static readonly type = ActionTypes.ADD_NEXT_SYSTEM_ROW;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null,
      currentRowId : number | null,
    }
  ) {}
}

export class AddNextSystemRowSuccess {
  static readonly type = ActionTypes.ADD_NEXT_SYSTEM_ROW_SUCCESS;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null
      currentRowId : number | null,
    }
  ) {}
}


export class AddNextSystemRowFailure {
  static readonly type = ActionTypes.ADD_NEXT_SYSTEM_ROW_FAILURE;
  constructor(public error? : any) {}
}

export class AddPrevSystemRow {
  static readonly type = ActionTypes.ADD_PREV_SYSTEM_ROW;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null
      currentRowId : number | null,
    }) {}
}

export class AddPrevSystemRowSuccess {
  static readonly type = ActionTypes.ADD_PREV_SYSTEM_ROW_SUCCESS;
  constructor(
    public data : {
      row: AddRowModel,
      prevRowId : number | null,
      nextRowId : number | null,
      currentRowId : number | null,
    }) {}
}

export class AddPrevSystemRowFailure{
  static readonly type = ActionTypes.ADD_PREV_SYSTEM_ROW_FAILURE;
  constructor(public error : any) {}
}

export class AddChildSystemRow {
  static readonly type = ActionTypes.ADD_CHILD_SYSTEM_ROW;
  constructor(
    public data : {
    row: AddRowModel,
    prevRowId : number | null,
    nextRowId : number | null,
    currentRowId : number | null,
  }) {}
}

export class AddChildSystemRowSuccess {
  static readonly type = ActionTypes.ADD_CHILD_SYSTEM_ROW_SUCCESS;
  constructor(
    public data : {
    row: AddRowModel,
    prevRowId : number | null,
    nextRowId : number | null,
    currentRowId : number | null,
  }) {}
}

export class AddChildSystemRowFailure {
  static readonly type = ActionTypes.ADD_CHILD_SYSTEM_ROW_FAILURE
  constructor(public error : any) {}
}


export class CopyNextRow {
  static readonly type = ActionTypes.COPY_NEXT_ROW;
  constructor(
    public data : CopyRow) {}
}

export class CopyNextRowSuccess {
  static readonly type = ActionTypes.COPY_NEXT_ROW_SUCCESS;
  constructor(public data : CopyRowResponse, public currentRowSelected : number) {}
}

export class CopyNextRowFailure {
  static readonly type = ActionTypes.COPY_NEXT_ROW_FAILURE;
  constructor(
    public error : string    
  ) {}
}

export class CopyChildRow {
  static readonly type = ActionTypes.COPY_CHILD_ROW;
  constructor(
    public data : CopyRow) {}
}


export class CopyChildSuccess {
  static readonly type = ActionTypes.COPY_CHILD_ROW_SUCCESS;
  constructor(
    public data : CopyRowResponse) {}
}

export class CopyChildFailure {
  static readonly type = ActionTypes.COPY_CHILD_ROW_FAILURE;
  constructor(
    public error : string    
  ) {}
}

export class MoveNextRow {
  static readonly type = ActionTypes.MOVE_NEXT_ROW;
  constructor(
    public data : MoveRow) {}
}

export class MoveNextRowSuccess {
  static readonly type = ActionTypes.MOVE_NEXT_ROW_SUCCESS;
  constructor(
    public data : CopyRowResponse) {}
}

export class MoveNextRowFailure {
  static readonly type = ActionTypes.MOVE_NEXT_ROW_FAILURE;
  constructor(
    public error : string    
  ) {}
}

export class MoveChildRow {
  static readonly type = ActionTypes.MOVE_CHILD_ROW;
  constructor(
    public data : MoveRow) {}
}

export class MoveChildRowSuccess {
  static readonly type = ActionTypes.MOVE_CHILD_ROW_SUCCESS;
  constructor(public data : CopyRowResponse) {}
}

export class MoveChildRowFailure {
  static readonly type = ActionTypes.MOVE_CHILD_ROW_FAILURE;
  constructor(
    public error : string    
  ) {}
}

export class ShareNextRow {
  static readonly type = ActionTypes.SHARE_NEXT_ROW;
  constructor(
    public data : ShareRow) {}
}


export class ShareNextRowSuccess {
  static readonly type = ActionTypes.SHARE_NEXT_ROW_SUCCESS;
  constructor(
    public data : CopyRowResponse) {}
}

export class ShareNextRowFailure {
  static readonly type = ActionTypes.SHARE_NEXT_ROW_FAILURE;
  constructor(
    public error : string    
  ) {}
}

export class ShareChildRow {
  static readonly type = ActionTypes.SHARE_CHILD_ROW;
  constructor(
    public data : ShareRow) {}
}


export class ShareChildRowSuccess {
  static readonly type = ActionTypes.SHARE_NEXT_ROW_SUCCESS;
  constructor(
    public data : CopyRowResponse) {}
}

export class ShareChildRowFailure {
  static readonly type = ActionTypes.SHARE_NEXT_ROW_FAILURE;
  constructor(
    public error : string    
  ) {}
}