import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Edition } from '../../../modals/page.modal';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  private sessionId$ = new BehaviorSubject<string>('User:ABC.Tab:5');
  private sortStatus$ = new BehaviorSubject<string>('Sort is Off');
  private sortStatusDD$ = new BehaviorSubject<string>('Sort is Off');
  private filterStatus$ = new BehaviorSubject<string>('Filter is Off');
  private filterStatusDD$ = new BehaviorSubject<string>('Filter is Off');
  pageFormate = new BehaviorSubject<any>('');
  pageFormateReg = new BehaviorSubject<any>('');
  pageFormateFreeze = new BehaviorSubject<any>('');
  Expand_Visibilt = new BehaviorSubject<any>({
    n: 0,
    x: null,
    y: null,
    z: null,
  });
  Expand_Visibilt_Reg = new BehaviorSubject<any>({
    n: 0,
    x: null,
    y: null,
    z: null,
  });
  pgRow = new BehaviorSubject<any>('');
  selectedId = new BehaviorSubject<any>('');
  languageListKeys = {
    Afrikaans: 'af',
    Albanian: 'sq',
    Amharic: 'am',
    Arabic: 'ar',
    Armenian: 'hy',
    Assamese: 'as',
    Aymara: 'ay',
    Azerbaijani: 'az',
    Bambara: 'bm',
    Basque: 'eu',
    Belarusian: 'be',
    Bengali: 'bn',
    Bhojpuri: 'bho',
    Bosnian: 'bs',
    Bulgarian: 'bg',
    Catalan: 'ca',
    Cebuano: 'ceb',
    Chichewa: 'ny',
    'Chinese (Simplified)': 'zh-CN',
    'Chinese (Traditional)': 'zh-TW',
    Corsican: 'co',
    Croatian: 'hr',
    Czech: 'cs',
    Danish: 'da',
    Dhivehi: 'dv',
    Dogri: 'doi',
    Dutch: 'nl',
    English: 'en',
    Esperanto: 'eo',
    Estonian: 'et',
    Ewe: 'ee',
    Filipino: 'fil',
    Finnish: 'fi',
    French: 'fr',
    Frisian: 'fy',
    Galician: 'gl',
    Georgian: 'ka',
    German: 'de',
    Greek: 'el',
    Guarani: 'gn',
    Gujarati: 'gu',
    'Haitian Creole': 'ht',
    Hausa: 'ha',
    Hawaiian: 'haw',
    Hebrew: 'he',
    Hindi: 'hi',
    Hmong: 'hmn',
    Hungarian: 'hu',
    Icelandic: 'is',
    Igbo: 'ig',
    Ilocano: 'ilo',
    Indonesian: 'id',
    Irish: 'ga',
    Italian: 'it',
    Japanese: 'ja',
    Javanese: 'jv',
    Kannada: 'kn',
    Kazakh: 'kk',
    Khmer: 'km',
    Kinyarwanda: 'rw',
    Konkani: 'gom',
    Korean: 'ko',
    Krio: 'kri',
    'Kurdish (Kurmanji)': 'ku',
    'Kurdish (Sorani)': 'ckb',
    Kyrgyz: 'ky',
    Lao: 'lo',
    Latin: 'la',
    Latvian: 'lv',
    Lingala: 'ln',
    Lithuanian: 'lt',
    Luganda: 'lg',
    Luxembourgish: 'lb',
    Macedonian: 'mk',
    Maithili: 'mai',
    Malagasy: 'mg',
    Malay: 'ms',
    Malayalam: 'ml',
    Maltese: 'mt',
    Maori: 'mi',
    Marathi: 'mr',
    'Meiteilon (Manipuri)': 'mni-Mtei',
    Mizo: 'lus',
    Mongolian: 'mn',
    'Myanmar (Burmese)': 'my',
    Nepali: 'ne',
    Norwegian: 'no',
    'Odia (Oriya)': 'or',
    Oromo: 'om',
    Pashto: 'ps',
    Persian: 'fa',
    Polish: 'pl',
    Portuguese: 'pt',
    Punjabi: 'pa',
    Quechua: 'qu',
    Romanian: 'ro',
    Russian: 'ru',
    Samoan: 'sm',
    Sanskrit: 'sa',
    'Scots Gaelic': 'gd',
    Sepedi: 'nso',
    Serbian: 'sr',
    Sesotho: 'st',
    Shona: 'sn',
    Sindhi: 'sd',
    Sinhala: 'si',
    Slovak: 'sk',
    Slovenian: 'sl',
    Somali: 'so',
    Spanish: 'es',
    Sundanese: 'su',
    Swahili: 'sw',
    Swedish: 'sv',
    Tajik: 'tg',
    Tamil: 'ta',
    Tatar: 'tt',
    Telugu: 'te',
    Thai: 'th',
    Tigrinya: 'ti',
    Tsonga: 'ts',
    Turkish: 'tr',
    Turkmen: 'tk',
    Twi: 'ak',
    Ukrainian: 'uk',
    Urdu: 'ur',
    Uyghur: 'ug',
    Uzbek: 'uz',
    Vietnamese: 'vi',
    Welsh: 'cy',
    Xhosa: 'xh',
    Yiddish: 'yi',
    Yoruba: 'yo',
    Zulu: 'zu',
  };
  languageList = [
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Assamese',
    'Aymara',
    'Azerbaijani',
    'Bambara',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bhojpuri',
    'Bosnian',
    'Bulgarian',
    'Catalan',
    'Cebuano',
    'Chichewa',
    'Chinese (Simplified)',
    'Chinese (Traditional)',
    'Corsican',
    'Croatian',
    'Czech',
    'Danish',
    'Dhivehi',
    'Dogri',
    'Dutch',
    'English',
    'Esperanto',
    'Estonian',
    'Ewe',
    'Filipino',
    'Finnish',
    'French',
    'Frisian',
    'Galician',
    'Georgian',
    'German',
    'Greek',
    'Guarani',
    'Gujarati',
    'Haitian Creole',
    'Hausa',
    'Hawaiian',
    'Hebrew',
    'Hindi',
    'Hmong',
    'Hungarian',
    'Icelandic',
    'Igbo',
    'Ilocano',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Kinyarwanda',
    'Konkani',
    'Korean',
    'Krio',
    'Kurdish (Kurmanji)',
    'Kurdish (Sorani)',
    'Kyrgyz',
    'Lao',
    'Latin',
    'Latvian',
    'Lingala',
    'Lithuanian',
    'Luganda',
    'Luxembourgish',
    'Macedonian',
    'Maithili',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Meiteilon (Manipuri)',
    'Mizo',
    'Mongolian',
    'Myanmar (Burmese)',
    'Nepali',
    'Norwegian',
    'Odia (Oriya)',
    'Oromo',
    'Pashto',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Quechua',
    'Romanian',
    'Russian',
    'Samoan',
    'Sanskrit',
    'Scots Gaelic',
    'Sepedi',
    'Serbian',
    'Sesotho',
    'Shona',
    'Sindhi',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Somali',
    'Spanish',
    'Sundanese',
    'Swahili',
    'Swedish',
    'Tajik',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Tigrinya',
    'Tsonga',
    'Turkish',
    'Turkmen',
    'Twi',
    'Ukrainian',
    'Urdu',
    'Uyghur',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Xhosa',
    'Yiddish',
    'Yoruba',
    'Zulu',
  ];
  edition: string = '';
  fontStyles: string = '';
  
  constructor() {}

  buildNestedDataIterative(
    inputData: any,
    hasChildrenInAnyRow: { value: boolean }
  ) {
    hasChildrenInAnyRow.value = false;
    const map: { [key: string]: any } = {};
    const structuredData: any[] = [];

    inputData.forEach((item: any) => {
      const newItem = {
        ...item,
      };

      if (item?.ParentRow?.Row === null || item?.ParentRow === null) {
        structuredData.push(newItem);
      } else {
        if (item?.ParentRow?.Row !== null) {
          hasChildrenInAnyRow.value = true;
        }
        const parentRow = map[item?.ParentRow?.Row];
        if (parentRow) {
          if (!parentRow._children) {
            parentRow._children = [];
          }
          parentRow._children.push(newItem);
        }
      }

      map[item?.row] = newItem;
    });

    return structuredData;
  }

  getSessionId(): Observable<string> {
    return this.sessionId$.asObservable();
  }

  get sortStatus(): Observable<string> {
    return this.sortStatus$.asObservable();
  }
  get sortStatusDD(): Observable<string> {
    return this.sortStatusDD$.asObservable();
  }
  get filterStatus(): Observable<string> {
    return this.filterStatus$.asObservable();
  }
  get filterStatusDD(): Observable<string> {
    return this.filterStatusDD$.asObservable();
  }

  refreshFilterCheckbox() {
    this.filterStatus$.next('Filter is Off');
  }

  handleCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    console.log(
      `${checkbox.name} is now ${checkbox.checked ? 'checked' : 'unchecked'}`
    );
  }

  toggleSort() {
    const currentStatus = this.sortStatus$.getValue();
    this.sortStatus$.next(
      currentStatus === 'Sort is Off' ? 'Sort is On' : 'Sort is Off'
    );
  }
  toggleSortDialog() {
    const currentStatus = this.sortStatusDD$.getValue();
    this.sortStatusDD$.next(
      currentStatus === 'Sort is Off' ? 'Sort is On' : 'Sort is Off'
    );
  }

  toggleFilter() {
    const currentStatus = this.filterStatus$.getValue();
    this.filterStatus$.next(
      currentStatus === 'Filter is Off' ? 'Filter is On' : 'Filter is Off'
    );
  }
  toggleFilterDialog() {
    const currentStatus = this.filterStatusDD$.getValue();
    this.filterStatusDD$.next(
      currentStatus === 'Filter is Off' ? 'Filter is On' : 'Filter is Off'
    );
  }

  getEditionList(): Observable<any[]> {
    return of(this.editions);
  }

  openEdition(editionId: any): Observable<any> {
    const edition = this.editions.find((ed) => ed.id === editionId);
    return of(edition);
  }

  createNewEdition(pageName: string, pageEdition: string): Observable<any> {
    const newEdition = {
      id: (this.editions.length + 1).toString(),
      name: pageName,
      pageEdition: pageEdition,
    };
    this.editions.push(newEdition);
    return of(newEdition);
  }

  private editions: any[] = [
    {
      id: 1,
      name: 'Default',
      description: 'Default Edition',
      isPrivate: false,
    },
    { id: 2, name: 'Vendor', description: 'Vendor Edition', isPrivate: false },
    {
      id: 3,
      name: 'Consumer',
      description: 'Consumer Edition',
      isPrivate: true,
    },
  ];

  getEditions(): Observable<Edition[]> {
    return of(this.editions);
  }

  saveEdition(edition: Edition): Observable<Edition> {
    const existingEdition = this.editions.find((e) => e.name === edition.name);
    if (existingEdition) {
      Object.assign(existingEdition, edition);
    } else {
      edition.id = this.editions.length + 1;
      this.editions.push(edition);
    }
    return of(edition);
  }

  public pgRows: EventEmitter<any> = new EventEmitter();

  setPageEdition(edition: any) {
    this.edition = edition;
    console.log('From Set Page Edition', edition);
  }
  getEdition() {
    console.log('From Set Page Edition get', this.edition);
    return this.edition;
  }
  setFontStyles(fontStyles: string) {
    this.fontStyles = fontStyles;
  }
}
