import { MenuBar } from "./menu-bar.model";

export enum ActionTypes {
    GET_MENU_BAR = '[MenuBar] Get Menu Bar',
    GET_GET_MENU_BAR_SUCCESS = '[MenuBar] Get Menu Bar Success',
    GET_GET_MENU_BAR_FAILURE = '[MenuBar] Get Menu Bar Failure',
}

export class GetMenuBar {
    static readonly type = ActionTypes.GET_MENU_BAR;
    constructor(public token : string) {}
}

export class GetMenuBarSuccess {
    static readonly type = ActionTypes.GET_GET_MENU_BAR_SUCCESS;
    constructor(public menuBar : MenuBar[]) {}
}

export class GetMenuBarFailure {
    static readonly type = ActionTypes.GET_GET_MENU_BAR_FAILURE;
    constructor(public error : any) {}
}
