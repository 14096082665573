import { Component, Inject, OnInit } from '@angular/core';
import { MainService } from '../../../../core/services/main-service/main.service';
import { UNICODE_LEFT_RIGHT_ARROW } from '../../../../core/constants/app.contants';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { PGColDialogComponent } from '../../../../modals/pgcol-dialog/pgcol-dialog.component';
import { Select, Store } from '@ngxs/store';
import { SheetState } from '../../../../store/page/sheet.store';
import { debounceTime, distinctUntilChanged, Observable, Subject } from 'rxjs';
import { Sheet } from '../../../../store/page/sheet.actions';
import { AutoAdjustWidthDirective } from '../../../directives/auto-adjust-width.directive';
@Component({
  selector: 'edit-dd-dialog-menu-bar',
  templateUrl: './edit-dd-dialog-menu-bar.component.html',
  styleUrls: ['./edit-dd-dialog-menu-bar.component.scss'],
})
export class editDdDialogMenuBarComponent implements OnInit {
  menuOptions = ['Cols', 'Freeze', 'Expand', 'Sort', 'Filter', 'Clear'];
  pageFreezeColumn: any = 0;
  expand = UNICODE_LEFT_RIGHT_ARROW;
  isFilter: boolean = false;
  previousFontSize: number;
  currentFontSize: number;
  parentRows: any[] = [];
  firstIncrement: boolean = true;
  @Select(SheetState.getFrozen) frozen$: Observable<any> | undefined;
  ExpandLevels: any = { n: '99.', x: null, y: null, z: null };
  private expandChangeSubject = new Subject<string>();
  height: any = 0;
  width: any = 0;
  clickedRowData: any;
  public sortStatus = false;
  public sorted = false;
  level: any;
  selectedId: any;
  widthDirective!: AutoAdjustWidthDirective;
  constructor(
    private store: Store,
    private mainService: MainService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<editDdDialogMenuBarComponent>
  ) {
    this.previousFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.currentFontSize = this.previousFontSize;
  }
  ngOnInit(): void {
    this.store.dispatch(new Sheet.SetFrozen(this.data?.data?.frozen));
    if (this.data?.data?.frozen != undefined) {
      this.ExpandLevels.n = this.data?.data?.frozen;
    }
    this.expandChangeSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged() // Prevent processing the same value multiple times
      )
      .subscribe((value) => {
        this.processExpandChange(value);
      });
    this.adjustCheckboxSize();
    window.addEventListener('resize', this.adjustCheckboxSize.bind(this));
  }
  private adjustCheckboxSize() {
    const bodyFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.height = bodyFontSize / this.previousFontSize - 0.2;
    this.width = bodyFontSize / this.previousFontSize - 0.2;
  }
  openColDialog() {
    const dialogRef = this.dialog.open(PGColDialogComponent, {
      width: '750px',
      data: { selectedId: this.data?.selectedId,dialog:true },
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  toggleFreeze(event: MouseEvent): void {
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    const y = event.clientY - rect.top;
    const centralAreaHeight = rect.height * 0.2;
    if (y < rect.height / 2 - centralAreaHeight / 2) {
      this.pageFreezeColumn += 1;
      let obj1 = { pageFreezeColumn: this.pageFreezeColumn };

      this.mainService.pageFormateFreeze.next(obj1);
    } else if (
      y > rect.height / 2 + centralAreaHeight / 2 &&
      this.pageFreezeColumn >= 0
    ) {
      this.pageFreezeColumn = Math.max(0, this.pageFreezeColumn - 1);
      let obj2 = { pageFreezeColumn: this.pageFreezeColumn };
      this.mainService.pageFormateFreeze.next(obj2);
    }
  }
  get expandLevelN(): any {
      return this.ExpandLevels.n !== null ? this.ExpandLevels.n.toString() : '';
  }

  set expandLevelN(value: string) {
    this.ExpandLevels.n = value !== '' ? value : '';
    this.onInputChange(this.ExpandLevels.n);
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const newValue = input?.value || '';
    this.expandChangeSubject.next(newValue);
  }

  clearFilters() {
    this.parentRows.pop();
    this.parentRows.push(null);
    this.clickedRowData = null;
    this.sortStatus = false;
    this.sorted = false;
    let obj3 = { pageFreezeColumn: 0 };
    this.mainService.pageFormateFreeze.next(obj3);
    this.expandLevelN = '99';
    this.onExpandChange('99');
    this.dialogRef.close({ parentRows: null, clicked: true });
  }
  onExpandChange(value: string) {
    // Emit the new value to the subject for debouncing
    this.expandChangeSubject.next(value);
  }
  onExpandToggle(event: MouseEvent): void {
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    const y = event.clientY - rect.top;
    let nValue =
      this.ExpandLevels.n !== null ? this.ExpandLevels.n.toString() : '0';
    if (!nValue || isNaN(parseFloat(nValue))) {
      nValue = '0';
      this.ExpandLevels.n = 0;
      return;
    } else if (nValue.startsWith('.')) {
      nValue = '0' + nValue;
    }
    const [integerPart, decimalPart] = nValue.split('.');
    let intValue = parseInt(integerPart, 10);
    const centralAreaHeight = rect.height * 0.2;
    if (y < rect.height / 2 - centralAreaHeight / 2) {
      if (this.firstIncrement) {
        this.firstIncrement = false;
      }
      intValue += 1;
    } else if (y > rect.height / 2 + centralAreaHeight / 2 && intValue > 0) {
      intValue = Math.max(0, intValue - 1);
    }
    if(intValue<100){
      this.ExpandLevels.n = decimalPart
        ? `${intValue}.${decimalPart}`
        : `${intValue}`;
      this.onExpandChange(this.ExpandLevels.n); // Send updated value
    }else{
      this.ExpandLevels.n = decimalPart
      ? `0.${decimalPart}`
      : `0`;
      this.onExpandChange('0');
    }
  }
  handleKeydown(event: KeyboardEvent): boolean {
    const inputElement = event.target as HTMLInputElement;
    const key = event.key;

    // Allow numbers, backspace, dot, and arrow keys
    if (
      (key >= '0' && key <= '9') ||
      key === 'Backspace' ||
      key === '.' ||
      key === 'ArrowLeft' ||
      key === 'ArrowRight' ||
      key === 'ArrowUp' ||
      key === 'ArrowDown'
    ) {
      return true;
    }
    event.preventDefault();
    return false;
  }
  private processExpandChange(value: string) {
    // Handle adjustments and dispatch actions here
    setTimeout(() => {
      this.widthDirective?.adjustWidth();
    }, 0);

    this.store.dispatch(new Sheet.SetFrozen(value));
    if (value.length <= 7) {
      this.level = this.parseExpandLevel(value);
      if (Number.isNaN(this.level.n)) {
        this.mainService?.Expand_Visibilt_Reg?.next({
          n: 0,
          x: null,
          y: null,
          z: null,
        });
        this.mainService?.pageFormateReg?.next({
          pageExpand: { n: 0, x: null, y: null, z: null },
        });
      } else {
        if (this.level.n.toString().length <= 2) {
          this.mainService?.Expand_Visibilt_Reg?.next(this.level);
          this.mainService?.pageFormateReg?.next({ pageExpand: this.level });
        }
      }
    }
  }
  parseExpandLevel(level: string): {
    n: number;
    x: number | null;
    y: number | null;
    z: number | null;
  } {
    let nValue = this.ExpandLevels.n
      ? parseInt(this.ExpandLevels.n.toString().split('.')[0])
      : 0;

    const match = level.match(
      /(\d+)(?:\.(1))?(?:(2))?(?:(3))?(?:\.(12|13|23|123))?/
    );
    if (!match) return { n: nValue, x: null, y: null, z: null }; // Default return if no match

    nValue = parseInt(match[1]); // Captures 'n'
    let xValue = match[2] ? 1 : null; // x is only 1 if matched
    let yValue = match[3] ? 2 : null; // y is only 2 if matched
    let zValue = match[4] ? 3 : null; // z is only 3 if matched

    // Handle combined cases for y and z
    if (match[5]) {
      if (match[5].includes('1')) xValue = 1; // If part of "12", "13", or "123"
      if (match[5].includes('2')) yValue = 2; // If part of "12", "23", or "123"
      if (match[5].includes('3')) zValue = 3; // If part of "13", "23", or "123"
    }
    if (level.includes('.12') || level.includes('.2')) {
      xValue = 1;
      yValue = 2;
      zValue = null;
    }

    if (level.includes('.123')) {
      xValue = 1;
      yValue = 2;
      zValue = 3;
    }
    if (level.includes('.13')) {
      xValue = 1;
      yValue = null;
      zValue = 3;
    }
    if (level.includes('.23')) {
      xValue = null;
      yValue = 2;
      zValue = 3;
    }
    if (level.includes('.3')) {
      xValue = null;
      yValue = null;
      zValue = 3;
    }

    return {
      n: nValue,
      x: xValue,
      y: yValue,
      z: zValue,
    };
  }
  toggleSort() {
    this.mainService.toggleSortDialog();
  }
  toggleFilter() {
    this.mainService.toggleFilterDialog();
    this.dialogRef.afterClosed().subscribe((result)=>{
      this.mainService.toggleFilterDialog();
    })
  }
}
