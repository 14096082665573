import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ModalDismissReasons,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { UNICODE_MULTIPLY } from '../../core/constants/app.contants';

@Component({
  selector: 'app-view-page-dialog',
  templateUrl: './view-page-dialog.component.html',
  styleUrls: ['./view-page-dialog.component.css'],
})
export class ViewPageDialogComponent {
  closeResult: string = '';
  @ViewChild('content', { static: true }) content: any;
  private modalRef!: NgbModalRef;
  closeTab=UNICODE_MULTIPLY;
  public processedCellKeys: { [key: string]: string };
  public processedFormatKeys: { [key: string]: string };

  constructor(
    public dialogRef: MatDialogRef<ViewPageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: NgbModal
  ) {
    this.processedCellKeys = this.processKeys(data.cell);
    this.processedFormatKeys = this.processKeys(data.format);
  }

  ngOnInit() {
    this.open(this.content);
  }

  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onCancel();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onCancel();
      return 'by clicking on a backdrop';
    } else {
      // this.onCancel();
      return `with: ${reason}`;
    }
  }

  processKeys(obj: any): { [key: string]: string } {
    const processedKeys: { [key: string]: string } = {};
    Object.keys(obj).forEach((key) => {
      processedKeys[key] = key.replace(/_/g, ' ');
    });
    return processedKeys;
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  getFieldValue(obj: any, field: string): any {
    return obj[field];
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }
}
