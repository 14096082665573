import { PgTab, PgTabStateModel } from "./pg-tab.model";
export const DefaultPageState : PgTab = {
  page_id: 0,
  page_name: "",
  page_edition: "",
  page_comment: ""
}
export const PageTabState: PgTabStateModel = {
  pgTabs: [],
  selectedPgTab: DefaultPageState,
  defaultPgTab: DefaultPageState,
  loading: false,
  error: null, 
  sheetData: [],
  sheetColumns: [],
  menuBars: [],
};
