import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { tPgService } from '../../core/services/tPg.service';
import { catchError, EMPTY, tap, throwError, } from 'rxjs';
import { Injectable } from '@angular/core';
import { Sheet } from './page.actions';
import { LoadPgTabs } from '../pg-tab/pg-tab.actions';
import { CellData, ItemData, RowPageData } from '../../shared/models/edit-dd-dailogs/dd-dailog.models';
import { SetAppLoading } from '../root/root.action';
import { findMaxRowLevel } from '../../utils/utils';
import { DDLTabData, PageStateModel, PageTabState } from './page.statemodel';
import { MainService } from '../../core/services/main-service/main.service';
import { All_Pages_ID } from '../../core/constants/app.contants';


@State<PageStateModel>({
  name: 'sheet',
  defaults: PageTabState,
})
@Injectable()
export class SheetState {
  constructor(
    private _tPgService: tPgService,
    private store : Store,
    private mainService : MainService
  ) {}

  @Selector([SheetState])
  static getSheetData(state: PageStateModel) {
    return state.sheetData;
  }

  @Selector([SheetState])
  static getSheetFlatData(state: PageStateModel) {
    return state.allTabsFlatData;
  }

  @Selector([SheetState])
  static getAllTabsData(state: PageStateModel) {
    return state.allTabsData;
  }

  @Selector()
  static getSheetDataById(state: PageStateModel): RowPageData[] {
    if(state.allTabsData && state.allTabsData.length > 0){
      const key : number = Number(state.defaultPageId);
      const obj =  state.allTabsData.filter(obj => key && obj[key] !== undefined)[0];
      return obj ? [...obj[key].pageData] : [];
    }else{
      return [];
    }
  }

  @Selector()
  static getPgRows(state: PageStateModel): RowPageData[] {
    if(state.allTabsFlatData && state.allTabsFlatData.length > 0){
      const key : number = Number(All_Pages_ID);
      const obj =  state.allTabsFlatData.filter(obj => key && obj[key] !== undefined)[0];
      return obj ? [...obj[key].pageData] : [];
    }else{
      return [];
    }
  }

  @Selector()
  static getSheetFlatDataById(state: PageStateModel): any {
    if(state.allTabsFlatData && state.allTabsFlatData.length > 0){
      const key : number = Number(state.defaultPageId);
      const obj =  state.allTabsFlatData.filter(obj => key && obj[key] !== undefined)[0];
      return obj ? [...obj[key].pageData] : [];
    }else{
      return [];
    }
  }

  @Selector([SheetState])
  static getVisitedData(state: PageStateModel) {
    return state.visitedPages;
  }

  @Selector()
  static getSheetColumns(state: PageStateModel) {
    return state.sheetColumns;
  }

  @Selector()
  static getSheetColumnsById(state: PageStateModel): any {
    if(state.allTabsData && state.allTabsData.length > 0){
      const key : number = Number(state.defaultPageId);
      const obj =  state.allTabsData.filter(obj => key && obj[key] !== undefined)[0]
      return obj ? [...obj[key].pageColumns] : [];
    }else{
      return [];
    }
  }

  @Selector()
  static getSheetFlatColumnsById(state: PageStateModel): any {
    if(state.allTabsFlatData && state.allTabsFlatData.length > 0){
      const key : number = Number(state.defaultPageId);
      const obj =  state.allTabsFlatData.filter(obj => key && obj[key] !== undefined)[0]
      return obj ? [...obj[key].pageColumns] : [];
    }else{
      return [];
    }
  }

  @Selector()
  static getContextMenus(state: PageStateModel): any {
    if(state.contextMenus && state.contextMenus.length > 0){
      // const parentRow: any = state.contextMenus.find((menu: any) => menu.token === token);
      // const menu =  state.contextMenus.filter((menu: any) => menu.ParentRow.Row === parentRow.Row);
      return state.contextMenus;
    }else{
      return [];
    }
  }

  @Selector([SheetState])
  static PickDdiData(state: PageStateModel) {
    return state.PickDdiData;
  }

  @Selector([SheetState])
  static PickDdiColumns(state: PageStateModel) {
    return state.PickDdiColumns;
  }

  @Selector([SheetState])
  static regions(state: PageStateModel) {
    return state.regions;
  }

  @Selector([SheetState])
  static addRow(state: PageStateModel) {
    return state.addRow;
  }
  @Selector([SheetState])
  static filterDDSPageData(state: PageStateModel) {
    return state.filteredSheetData;
  }
  @Selector([SheetState])
  static formatLocalCol(state: PageStateModel) {
    return state.formatLocalCol;
  }
  @Selector([SheetState])
  static deleteItemData(state: PageStateModel) {
    return state.deleteItemData;
  }

  @Selector([SheetState])
  static getFrozen(state: PageStateModel) {
    return state.frozen;
  }
  @Selector([SheetState])
  static getExpandWidth(state: PageStateModel) {
    return state.expandWidth;
  }

  @Selector()
  static isLoading(state: PageStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static getPageId(state: PageStateModel) {
    return state.defaultPageId;
  }

  @Selector()
  static getRowLevel(state: PageStateModel){
    return state.rowLevel;
  }

  @Selector([SheetState])
  static getPageFormatStyleById(state: PageStateModel) {
    if (state.allTabsFlatData && state.allTabsFlatData.length > 0) {
      const key: number = Number(state.defaultPageId);
      const foundObject = state.allTabsFlatData.find(obj => Object.keys(obj)[0] === key.toString());
      return foundObject ? foundObject[key].pageFormat : [];
    } else {
      return [];
    }
  }

  @Selector([SheetState])
  static getDdlTypesData(state: PageStateModel){
    return state.ddlTypesData;
  }

  @Action(Sheet.UpdatePageData)
  UpdatePageData(ctx: StateContext<PageStateModel>, action: Sheet.UpdatePageData) {
    const state = ctx.getState();
    const pageId = state.defaultPageId ?? action.payload.defaultPageId;
    const currentPageData = action.payload.allTabsData?.find((obj: any) => obj[pageId]);
    let maxRowLevel = 0;
    if (currentPageData) {
      const pageData = currentPageData[pageId].pageData;
      maxRowLevel = pageData.reduce((max : number, row : RowPageData) => Math.max(max, findMaxRowLevel(row)), 0);
    }
  
    if (action?.payload?.refresh) {
      this.store.dispatch(new LoadPgTabs(state.defaultPageId, true));
    } else {
      ctx.patchState({
        allTabsData: action.payload.allTabsData,
        allTabsFlatData: action.payload.allTabsFlatData,
        defaultPageId: action.payload.defaultPageId,
        rowLevel: {
          page: maxRowLevel,
          ddl: 0,
        },
      });
    }
  }
  

  @Action(Sheet.PickDdiData)
  PickDdiData(ctx: StateContext<PageStateModel>, action: Sheet.PickDdiData) {
    const { DDL, IsEdition } = action;
    const state = ctx.getState();
    this.store.dispatch(new SetAppLoading(true));
  
    ctx.patchState({
      PickDdiColumns: [],
      PickDdiData: [],
      rowLevel: {
        ddl: 0,
        page: state.rowLevel.page || 0
      },
    });
  
    if (IsEdition) {
      const pageId = state.defaultPageId as number;
      const tabData = state.allTabsData.find(tab => tab[pageId])?.[pageId];
  
      if (tabData) {
        ctx.patchState({
          PickDdiData: tabData.pageData?.[0]?._children || [],
          PickDdiColumns: tabData.pageColumns || [],
          rowLevel: {
            ddl: state.rowLevel.ddl || 0,
            page: state.rowLevel.page || 0
          },
        });
      }
      ctx.dispatch(new SetAppLoading(false));
      return EMPTY;
    } else {
      // Fetch DDL data from the service
      return this._tPgService.getDDL(DDL).pipe(
        tap((response: any) => {
          const { success, data } = response;
  
          if (success && Array.isArray(Object.values(data)[0])) {
            const { DDLContent, DDLColumns } = data;
  
            const maxRowLevel = (DDLContent || []).reduce((max : any, item : any) => {
              return Math.max(max, findMaxRowLevel(item));
            }, 0);
  
            ctx.patchState({
              PickDdiData: DDLContent || [],
              PickDdiColumns: DDLColumns || [],
              rowLevel: {
                ddl : maxRowLevel,
                page : ctx.getState().rowLevel.page || 0
              },
            });
          } else if (success && !Array.isArray(Object.values(data)[0])){
            const { DDLContent, DDLColumns } = Object.values(data)[0] as DDLTabData;
            const maxRowLevel = (DDLContent || []).reduce((max : any, item : any) => {
              return Math.max(max, findMaxRowLevel(item));
            }, 0);
  
            ctx.patchState({
              ddlTypesData: data,
              PickDdiData: DDLContent || [],
              PickDdiColumns: DDLColumns || [],
              rowLevel: {
                ddl : maxRowLevel,
                page : ctx.getState().rowLevel.page || 0
              },
            });
          }
  
          // Stop loading indicator
          ctx.dispatch(new SetAppLoading(false));
          return EMPTY;
        }),
        catchError(() => {
          // Handle errors (e.g., log or show a message)
          ctx.dispatch(new SetAppLoading(false));
          return EMPTY;
        })
      );
    }
  }
  
  @Action(Sheet.PickDdlTypeData)
  PickDdlTypeData(ctx: StateContext<PageStateModel>, action: Sheet.PickDdlTypeData) {
    const ddlTypesData = this.store.selectSnapshot(SheetState.getDdlTypesData);
    const { DDLColumns, DDLContent } = ddlTypesData[action.payload];
    const maxRowLevel = (DDLContent || []).reduce((max: any, item: any) => {
      return Math.max(max, findMaxRowLevel(item));
    }, 0);

    ctx.patchState({
      PickDdiData: DDLContent || [],
      PickDdiColumns: DDLColumns || [],
      rowLevel: {
        ddl: maxRowLevel,
        page: ctx.getState().rowLevel.page || 0
      },
    });
  }
  @Action(Sheet.DeleteDdlTabData)
  DeleteDdlTabData(ctx: StateContext<PageStateModel>, action: Sheet.PickDdlTypeData) {
    const ddlData = this.store.selectSnapshot(SheetState.getDdlTypesData);
  
    // Create a new object excluding the deleted key
    const updatedDdlData = { ...ddlData };
    delete updatedDdlData[action.payload];
  
    const keys = Object.keys(updatedDdlData);
    let lastKey = keys[keys.length - 1];
  
    const { DDLColumns, DDLContent } = updatedDdlData[lastKey] || {}; // Handle case if lastKey is undefined
    const maxRowLevel = (DDLContent || []).reduce((max: any, item: any) => {
      return Math.max(max, findMaxRowLevel(item));
    }, 0);
  
    ctx.patchState({
      ddlTypesData: updatedDdlData, // Update state with new object
      PickDdiData: DDLContent || [],
      PickDdiColumns: DDLColumns || [],
      rowLevel: {
        ddl: maxRowLevel,
        page: ctx.getState().rowLevel.page || 0
      },
    });
  }
  @Action(Sheet.ResetDdlTabData)
  ResetDdlTabData(ctx: StateContext<PageStateModel>) {
    ctx.patchState({
      ddlTypesData: [],
    });
  }
  
  

  @Action(Sheet.UpdatePickDdiColumns)
  UpdatePickDdiColumn(
    ctx: StateContext<PageStateModel>,
    action: Sheet.UpdatePickDdiColumns
  ) {
    ctx.patchState({
      PickDdiColumns: action.payload,
    });
  }

  @Action(Sheet.PageButtonPopupData)
  PageButtonPopupData(
    ctx: StateContext<PageStateModel>,
    action: Sheet.PageButtonPopupData
  ) {
    return this._tPgService.getPages(action.payload).pipe(
      tap((data: any) => {
        if (!data || !data.data) {
          console.error('Invalid data received from API:', data);
          return;
        }
        // Filter columns to include 'DDS-Col' or 'Share' but exclude 'Hidden'
        const ddsFields = data.data.pageColumns
          .filter((column: { status: string | string[] }) => {
            return (
              column.status &&
              column.status.includes('DDS-Col') &&
              !column.status.includes('Hidden')
            );
          })
          .map((column: { field: any }) => column.field);
        // Filter the page data based on the presence of required fields
        const filteredData = data.data.pageData
          .map((page: any) => {
            let filteredPage: any = {};

            ddsFields.forEach((field: string) => {
              if (page.hasOwnProperty(field)) {
                filteredPage[field] = page[field];
              }
            });

            return filteredPage;
          })
          .filter((page: any) => Object.keys(page).length > 0); // Remove empty pages here

        ctx.patchState({
          filteredSheetData: filteredData,
        });
      })
    );
  }

  @Action(Sheet.updateColumns)
  UpdateColumn(ctx: StateContext<PageStateModel>, action: Sheet.updateColumns) {
    const updatedData = this.mainService.updatePageColumns(ctx.getState().allTabsData, ctx.getState().defaultPageId as number, action.payload)
    ctx.patchState({
      allTabsData: updatedData,
    });
  }

  @Action(Sheet.UpdatePageFormatStyle)
  updatePageFormatStyle(
    ctx: StateContext<PageStateModel>,
    action: Sheet.UpdatePageFormatStyle
  ) {
    const currentPageId = ctx.getState().defaultPageId as number;
    const allTabsFlatData = ctx.getState().allTabsFlatData;
    const updatedTabs = allTabsFlatData.map(tab => {
      const key = Object.keys(tab)[0];
      if (key === currentPageId?.toString()) {
        return {
          [key]: {
            ...(tab as any)[key],
            pageFormat: {
              ...(tab as any)[key].pageFormat,
              font_style: action.payload
            }
          }
        };
      }
      return tab;
    });
    ctx.patchState({
      allTabsFlatData: updatedTabs,
    });
  }

  @Action(Sheet.OrderColumns)
  OrderColumns(ctx: StateContext<PageStateModel>, action: Sheet.OrderColumns) {
    return this._tPgService.orderColumns(action.pageId, action.payload).pipe(
      tap((data: any) => {
        const state = ctx.getState();
      })
    );
  }

  @Action(Sheet.AddRow)
  AddRow(ctx: StateContext<PageStateModel>, action: Sheet.AddRow) {
    ctx.patchState({
      addRow: [], // Set to an empty array to match the expected type
    });
    return this._tPgService.addRow(action.payload).pipe(
      tap((resp: any) => {
        ctx.patchState({
          addRow: resp,
        });
      })
    );
  }

  @Action(Sheet.addColumn)
  addColumn(ctx: StateContext<PageStateModel>, action: Sheet.addColumn) {
    ctx.patchState({
      addRow: [], // Set to an empty array to match the expected type
    });
    return this._tPgService.addColumn(action.payload).pipe(
      tap((resp: any) => {
        ctx.patchState({
          addRow: resp,
        });
      })
    );
  }

  @Action(Sheet.DeleteRow)
  DeleteRow(ctx: StateContext<PageStateModel>, action: Sheet.DeleteRow) {
    return this._tPgService.deleteRow(action.payload).pipe();
  }

  @Action(Sheet.addUrl)
  addUrl(ctx: StateContext<PageStateModel>, action: Sheet.addUrl) {}

  @Action(Sheet.DeleteColumn)
  DeleteColumn(ctx: StateContext<PageStateModel>, action: Sheet.DeleteColumn) {
    const state = ctx.getState();

    return this._tPgService.deleteColumn(action.payload).pipe();
  }

  @Action(Sheet.FormatLocalColData)
  formatLocalCol(
    ctx: StateContext<PageStateModel>,
    action: Sheet.FormatLocalColData
  ) {
    return this._tPgService.formatLocalCol(action.payload, action.page_id).pipe(
      tap((data: any) => {
        ctx.patchState({
          formatLocalCol: data,
        });
      })
    );
  }
  @Action(Sheet.DeleteItemData)
  deleteItemData(ctx: StateContext<PageStateModel>, action: Sheet.DeleteItemData) {
    return this._tPgService.deleteItemData(action.payload, action.page_id).pipe(
      tap((resp: any) => {
        ctx.patchState({
          deleteItemData: resp,
        });
      })
    );
  }
  @Action(Sheet.SetFrozen)
  SetFrozen(ctx: StateContext<PageStateModel>, action: Sheet.SetFrozen) {
    ctx.patchState({
      frozen: action.payload,
    });
  }
  @Action(Sheet.SetWidthExpand)
  SetWidthExpand(ctx: StateContext<PageStateModel>, action: Sheet.SetWidthExpand) {
    ctx.patchState({
      expandWidth: action.payload,
    });
  }

  @Action(Sheet.GetContextMenus)
  GetContextMenus(ctx: StateContext<PageStateModel>, action: Sheet.GetContextMenus) {
    ctx.patchState({ loading: true });
    return this._tPgService.getContextMenus().pipe(
      tap((data: any) => {
        ctx.patchState({
          contextMenus: data.data?.DDLContent,
          loading: false,
        });
      }),
      catchError((error) => {
        ctx.patchState({ loading: false });
        return throwError(() => error);
      })
    );
  }
  
  @Action(Sheet.UpdateRowFormat)
  UpdateRowFormat(ctx: StateContext<PageStateModel>, action: Sheet.UpdateRowFormat) {
    const state = ctx.getState();
    const currentPageId = state.defaultPageId as number;
    const updatedTabsData = state.allTabsFlatData.map(tab => {
      if (tab[currentPageId]) {
        const pageData = tab[currentPageId];
        const updatedPageData = pageData.pageData.map(row => 
          Number(row.row) === Number(action.payload.rowId)
            ? { 
                ...row, 
                RowFormat: { 
                  ...row.RowFormat, 
                  font_style: action.payload.format
                }
              }
            : row
        );
        return {
          ...tab,
          [currentPageId]: { 
            ...pageData,
            pageData: updatedPageData
          },
        };
      }
      return tab;
    });
    ctx.patchState({ allTabsFlatData: updatedTabsData });
  }

  @Action(Sheet.UpdateColFormat)
  UpdateColFormat(ctx: StateContext<PageStateModel>, action: Sheet.UpdateColFormat) {
    const state = ctx.getState();
    const currentPageId = state.defaultPageId as number;
    const updatedTabsFlatData = state.allTabsFlatData.map((tab) => {
      if (tab[currentPageId]) {
        const pageData = tab[currentPageId];
        const updatedPageColumns = pageData.pageColumns.map((col: any) =>
          col.col === action.payload.colId
            ? {
              ...col,
              format: {
                ...col.format, 
                font_style: action.payload.format, 
              },
          }
            : col
        );
        return {
          ...tab,
          [currentPageId]: {
            ...pageData,
            pageColumns: updatedPageColumns,
          },
        };
      }
      return tab;
    });
    ctx.patchState({ allTabsFlatData: updatedTabsFlatData });
  }

  @Action(Sheet.UpdateCellFormat)
  UpdateCellFormat(ctx: StateContext<PageStateModel>, action: Sheet.UpdateCellFormat) {
      const state = ctx.getState();
      const currentPageId = state.defaultPageId as number;
      const cell: CellData = this.mainService.getCell(currentPageId, action.payload.colId, action.payload.rowId);
      if (!cell) return;
      const updatedTabsFlatData = state.allTabsFlatData.map(tab => {
          if (tab[currentPageId]) {
              const pageData = tab[currentPageId];
              const updatedPageData = pageData.pageData.map(row => {
                  if (Number(row.row) === Number(action.payload.rowId)) {
                      const updatedRowObjects = Object.keys(row).reduce<Record<string, any>>((acc, key) => {
                          const currentObject = row[key];
                          if (currentObject && currentObject.cell && Number(currentObject.cell.id) === Number(cell.cell.id)) {
                              acc[key] = {
                                  ...currentObject,
                                  cell: {
                                      ...currentObject.cell,
                                      format: {
                                          ...currentObject.cell.format,
                                          ...action.payload.format
                                      }
                                  }
                              };
                          } else {
                              acc[key] = currentObject;
                          }
                          return acc;
                      }, {} as Record<string, any>);
                      return { ...row, ...updatedRowObjects };
                  }
                  return row;
              });
              return {
                  ...tab,
                  [currentPageId]: {
                      ...pageData,
                      pageData: updatedPageData
                  }
              };
          }
          return tab;
      });

      ctx.patchState({ allTabsFlatData: updatedTabsFlatData });
  }

  @Action(Sheet.UpdateItemFormat)
  UpdateItemFormat(ctx: StateContext<PageStateModel>, action: Sheet.UpdateItemFormat) {
      const state = ctx.getState();
      const currentPageId = state.defaultPageId as number;
      const cell: CellData = this.mainService.getCell(currentPageId, action.payload.colId, action.payload.rowId);
      let targetItems: ItemData[] = [];
      if (cell?.hasOwnProperty('cell')) {
        targetItems = cell.cell.items;
      }
      if (cell?.hasOwnProperty('format') && cell.format) {
        targetItems = cell.format.items;
      }
      const targetItem = targetItems.find(cellItem => 
          Number(cellItem.id) === Number(action.payload.itemId)
      ) as ItemData | undefined;
      if (!targetItem) {
          throw new Error(`Target item not found: ${action.payload.itemId}`);
      }
      targetItem.format = {
          ...targetItem.format,
          ...action.payload.format
      };
      ctx.patchState({ allTabsFlatData: [...state.allTabsFlatData] });
  }

}
