export enum LabelsKey {
    CurrentPage = 'currentPage',
    CurrentEdition = 'currentEdition',
    CurrentLanguage = 'currentLanguage',
    CurrentCurrency = 'currentCurrency',
    CurrentMode = 'currentMode',
    CurrentRegion = 'currentRegion',
    CurrentExpand = 'currentExpand'
  }
  

  