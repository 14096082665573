export class SheetModel {
  visitedPages: any[] = []; // Initialized as an empty array
  // Make type more explicit
  addRow: any[] = [];
  sheetData: [] = [];
  tabData: [] = [];
  loading: boolean = false;
  PickDdiData: [] = [];
  PickDdiColumns: [] = [];
  sheetColumns: [] = [];
  sheetPageId: number | undefined;
  filteredSheetData: [] = [];
  regions = [];
  formatLocalCol = null;
  deleteItemData = null;
  frozen = null;
  expandWidth = null;
  constructor() {}
}
