import { Pipe, PipeTransform } from '@angular/core';
import { MainService } from '../../core/services/main-service/main.service';

@Pipe({
  name: 'pageName',
})
export class PageNamePipe implements PipeTransform {
  pageName : string = "";
  transform(sheet: any): string {
    if (!sheet?.page_name) return '';
    let value = sheet?.page_name;
    if (typeof value ==='object') {
      value = (sheet.page_name[MainService.currentLanguage().langID] ?? Object.values(sheet.page_name)[0]) as string
    }
    const withoutTags = value.replace(/<\/?[^>]+(>|$)/g, '');
    const withoutEntities = withoutTags.replace(/&nbsp;/g, ' ').replace(/&[a-z]+;/g, '');

    return withoutEntities
      .trim()
      .split(' ')
      .map((word : any) => word.charAt(0) + word.slice(1))
      .join(' ');
  }
}