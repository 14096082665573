export namespace Sheet {
  export class FetchSheetData {
    static readonly type = '[Sheet] FetchSheetData';
    constructor(public sheetId: any) {}
  }
  export class UpdatePgTabData {
    static readonly type = '[Sheet] UpdatePgTabData';
    constructor(public sheetId: any) {}
  }

  export class PickDdiData {
    static readonly type = '[Sheet] PickDdiData';
    constructor(public DDL: any, public IsEdition? : boolean) {}
  }
  export class PickDdlTypeData {
    static readonly type = '[Sheet] PickDdlTypeData';
    constructor(public payload: any) {}
  }
  export class DeleteDdlTabData {
    static readonly type = '[Sheet] DeleteDdlTabData';
    constructor(public payload: any) {}
  }
  export class ResetDdlTabData {
    static readonly type = '[Sheet] ResetDdlTabData';
    constructor() {}
  }

  export class SetSheetData {
    static readonly type = '[Sheet] SetSheetData';
    constructor(public payload: any) {}
  }

  export class FetchSheetColumns {
    static readonly type = '[Sheet] FetchSheetColumns';
    constructor(public sheetId: any) {}
  }
  export class updateColumns {
    static readonly type = '[Sheet] UpdateColumns';
    constructor(public payload: any) {}
  }

  export class UpdatePageFormatStyle {
    static readonly type = '[Sheet] UpdatePageFormatStyle';
    constructor(public payload: any) {}
  }
  export class UpdatePickDdiColumns {
    static readonly type = '[Sheet] UpdatePickDdiColumns';
    constructor(public payload: any) {}
  }

  export class UpdatePageData {
    static readonly type = '[Sheet] UpdatePageData';
    constructor(public payload: any) {}
  }

  export class OrderColumns {
    static readonly type = '[Sheet] OrderColumns';
    constructor(public pageId: number, public payload: any) {}
  }

  export class SetSheetColumns {
    static readonly type = '[Sheet] SetSheetColumns';
    constructor(public payload: any) {}
  }
  export class AddRow {
    static readonly type = '[Sheet] AddRow';
    constructor(public payload: any) {}
  }

  export class addColumn {
    static readonly type = '[Sheet] addColumn';
    constructor(public payload: any) {}
  }
  export class DeleteRow {
    static readonly type = '[Sheet] DeleteRow';
    constructor(public payload: any) {}
  }

  export class DeleteColumn {
    static readonly type = '[Sheet] DeleteColumn';
    constructor(public payload: any) {}
  }

  export class addUrl {
    static readonly type = '[Sheet] addUrl';
    constructor(public payload: any) {}
  }

  export class PageButtonPopupData {
    static readonly type = '[Sheet] PageButtonPopupData';
    constructor(public payload: any) {}
  }
  export class FormatLocalColData {
    static readonly type = '[Sheet] FormatLocalColData';
    constructor(public payload: any, public page_id: any) {}
  }
  export class DeleteItemData {
    static readonly type = '[Sheet] DeleteItemData';
    constructor(public payload: any, public page_id: any) {}
  }

  export class SetFrozen {
    static readonly type = '[Sheet] SetFrozen';
    constructor(public payload: any) {}
  }
  export class SetWidthExpand {
    static readonly type = '[Sheet] SetWidthExpand';
    constructor(public payload: any) {}
  }
  export class UpdateExpandLevel {
    static readonly type = '[Sheet] Update Expand Level PgTabs';
    constructor(public level: number) {}
  }
  export class FetchDDL {
    static readonly type = '[FetchDDL] Load FetchDDL';
    constructor(public DDL: string) {}
  }
  export class GetContextMenus {
    static readonly type = '[FetchDDL] GetContextMenus';
    constructor() {}
  }
  export class UpdateRowFormat {
    static readonly type = '[Page] Update Row Format';
    constructor(public payload: { rowId: number; format: string }) {}
  }  
  export class UpdateColFormat {
    static readonly type = '[Page] Update Col Format';
    constructor(public payload: { colId: number; format: any }) {} 
  }  
  export class UpdateCellFormat {
    static readonly type = '[Page] Update Cell Format';
    constructor(public payload: { rowId: number; colId: number; format: any }) {}
  }  
  export class UpdateItemFormat {
    static readonly type = '[Page] Update Item Format';
    constructor(public payload: { colId: number; rowId: number; itemId: string; format: any }) {}
  }
  
}
