import { Component, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DragService } from '../../../../core/services/drag-service/drag.service';
import { ResizeService } from '../../../../core/services/resize-service/resize.service';
import { FontSelectServiceService } from '../../../../services/font-select-service/font-select-service.service';
import { UNICODE_MULTIPLY } from '../../../../core/constants/app.contants';
@Component({
  selector: 'app-formate-page-main',
  templateUrl: './formate-page-main.component.html',
  styleUrls: ['./formate-page-main.component.css']
})
export class FormatePageComponent {
  headerText: string = 'Format Page';
  height: any = 0;
  width: any = 0;
  newWidth: number = 0;
  newHeight: number = 0;
  formatId!: string; // Assuming this is populated
  pageId!: string; // Assuming this is populated
  formatPageForm!: FormGroup;
  closeResult: string = '';
  closeTab = UNICODE_MULTIPLY;
  onFormDataChange(updatedFormData: any): void {
    // Saved the updated form data in the `formatPageForm` variable
    this.formatPageForm = { ...this.formatPageForm, ...updatedFormData };
  }

  private modalRef!: NgbModalRef;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FormatePageComponent>,
    public dialog: MatDialog,
    private dragService: DragService,
    private resizeService: ResizeService,
    public service: FontSelectServiceService,
    private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onCancel();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onCancel();
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onSave(): void {
    this.dialogRef.close(this.formatPageForm);
    console.log(this.formatPageForm);
    this.modalRef.dismiss('Save click');
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }
  @ViewChild('formatPageContainer', { static: true })
  popupContainer!: ElementRef<HTMLDivElement>; // to get the reference of the popup container for further manipulaiton

  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContainer);
  }
  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContainer);
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragService.onMouseUp(); // Use the service method
  }
  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContainer);
  }
}
