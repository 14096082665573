import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetMenuBar } from '../../../store/menu-bar/menu-bar.actions';
import { map, Observable, tap } from 'rxjs';
import { MenuBar } from '../../../store/menu-bar/menu-bar.model';
import { MenuBarStore } from '../../../store/menu-bar/menu-bar.store';

@Injectable({
  providedIn: 'root'
})
export class MenuBarFacadeService {
  constructor(private store : Store) {}
  
  public getMenuBar$: Observable<MenuBar[]> = inject(Store).select(MenuBarStore.getMenuBar)
  .pipe(
    map((menu)=>this.transformTokenFormula(menu)),
  );
  public getMenuBarLoading$: Observable<boolean> = inject(Store).select(MenuBarStore.getMenuBarLoading);

  getMenuBar(token : string){
    this.store.dispatch(new GetMenuBar(token));
  }

  transformTokenFormula(data : MenuBar[]) {
    return data.map((item : MenuBar) => {
      const match = item?.token_formula?.match(/\{(.*?)\}/) || '';
      if (match) {
          const extracted = match[1]; // Get the text inside {}
          const transformed = `{${extracted.charAt(0).toLowerCase()}${extracted.slice(1)}}`; // Make first letter lowercase
          return { ...item, token_formula: transformed };
      }
      return item;
    });
  }
}
