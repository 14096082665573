import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SystemInitials } from '../../../constant';
import { CellComponent, ColumnComponent, RowComponent } from 'tabulator-tables';

@Injectable({
  providedIn: 'root',
})
export class FormatUIService {
  constructor(private store: Store) {}

  formatLocalCell(
    pageId: number,
    columnId: number,
    rowId: number,
    formatLocalColForm: any
  ) {
    // Prepare payload
    const payload = {
      userId: 3000000099,
      colId: columnId,
      rowId: rowId,
      pageId: pageId,
      status: formatLocalColForm.status,
      minWidth: formatLocalColForm.minWidth,
      fontStyle:
        formatLocalColForm.fontStyle != ''
          ? JSON.parse(formatLocalColForm.fontStyle)
          : null,
      comment: formatLocalColForm.comment,
      txList: formatLocalColForm.transactions,
    };
  }

  applyFormatLocaCell(cell: any, parsedStyle: any) {
    // Apply styling
    cell.getElement().style.backgroundColor =
      parsedStyle.FontStyle['Background Color'];
    cell.getElement().style.color = parsedStyle.FontStyle['Font Color'];
    cell.getElement().style.fontSize = parsedStyle.FontStyle['Size'];
    cell.getElement().style.textAlign = parsedStyle.FontStyle['Text Alignment'];

    if (parsedStyle.FontStyle['Effects'] == '0') {
      cell.getElement().style.fontStyle =
        parsedStyle.FontStyle['Effects'] == '0' ? 'normal' : '';
    } else if (parsedStyle.FontStyle['Effects'] == '2') {
      cell.getElement().style.fontWeight =
        parsedStyle.FontStyle['Effects'] == '2' ? 'bold' : '';
    } else if (parsedStyle.FontStyle['Effects'] == '1') {
      cell.getElement().style.fontStyle =
        parsedStyle.FontStyle['Effects'] == '1' ? 'italic' : '';
    }

    if (parsedStyle.FontStyle['Font Style'] == 'italicText') {
      cell.getElement().style.fontStyle = 'italic';
    } else if (parsedStyle.FontStyle['Font Style'] == 'boldText') {
      cell.getElement().style.fontWeight = 'bold';
    } else if (parsedStyle.FontStyle['Font Style'] == 'strikeThrough') {
      cell.getElement().classList.add('text-linethrough-class');
    } else if (parsedStyle.FontStyle['Font Style'] == 'underline') {
      cell.getElement().classList.add('text-underline-class');
    } else if (parsedStyle.FontStyle['Font Style'] == 'textWrap') {
      cell.getElement().classList.add('text-wrap-class');
    } else if (parsedStyle.FontStyle['Font Style'] == 'superScript') {
      const element = cell.getElement();
      const originalText = element.innerHTML;
      element.innerHTML = originalText.replace(
        originalText,
        '<sup>' + originalText + '</sup>'
      );
    } else if (parsedStyle.FontStyle['Font Style'] == 'subScript') {
      const element = cell.getElement();
      const originalText = element.innerHTML;
      element.innerHTML = originalText.replace(
        originalText,
        '<sub>' + originalText + '</sub>'
      );
    } else if (parsedStyle.FontStyle['Font Style'] == 'textBorder') {
      cell.getElement().style.border = '1px solid';
    }

    // Set font family
    cell.getElement().style.fontFamily = parsedStyle.FontStyle['Font'];
  }

  foramtLocalItem(cell: CellComponent, inlineFontStyle: string, clickedChip: HTMLElement) {
    if (clickedChip) {
      const currentStyle = clickedChip.style;
      const width = `width: ${currentStyle.width}`;
      const height = `height: ${currentStyle.height}`;
      clickedChip.style.cssText = `${inlineFontStyle}; ${height}; ${width};`;
    }
  }

  foramtLocalCell(cell: CellComponent, inlineFontStyle: string) {
    const currentStyle = cell.getElement().style;
    const width = `width: ${currentStyle.width}`;
    const height = `heigth: ${currentStyle.height}`;
    cell.getElement().style.cssText =  `${inlineFontStyle} ;${height} ;${width} ;`;
  }

  foramtLocalRow(row: RowComponent, inlineFontStyle: string) {
    const rowElement = row.getElement();
    const currentStyle = rowElement.style;

    const width = `width: ${currentStyle.width}`;
    const height = `heigth: ${currentStyle.height}`;
    rowElement.style.cssText =  `${inlineFontStyle}`;
  }

  formatLocalColumn(column: ColumnComponent, inlineFontStyle: string){
    const columnElement = column.getElement();
    const currentStyle = columnElement.style;

    const width =  `width: ${currentStyle.width}`;
    const height =  `height: ${currentStyle.height}`;
    columnElement.style.cssText = `${inlineFontStyle}; ${width};${height}`;
  }
}
