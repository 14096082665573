import { Injectable } from '@angular/core';
import { ElementRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DragService {
  private isDragging = false;
  private offsetX = 0;
  private offsetY = 0;
  private scrollbarWidth = this.getScrollbarWidth(); // Getting the side scrollbar width
 // Function to calculate the side scrollbar width
 getScrollbarWidth(): number {
  return window.innerWidth - document.documentElement.clientWidth;
}
  // Function to start dragging
  public onDragStart(event: MouseEvent, popupContainer: ElementRef) {
    this.isDragging = true;
    this.offsetX = event.clientX - popupContainer.nativeElement.getBoundingClientRect().left;
    this.offsetY = event.clientY - popupContainer.nativeElement.getBoundingClientRect().top;
  }

  // Function to handle mouse movement
  public onMouseMove(event: MouseEvent, popupContainer: ElementRef) {
    if (this.isDragging) {
      this.moveDialog(event, popupContainer);
    }
  }

  // Function to stop dragging
  public onMouseUp() {
    this.isDragging = false;
  }

  // Move the dialog within the viewport
  private moveDialog(event: MouseEvent, popupContainer: ElementRef) {
    const dialogElement = popupContainer.nativeElement;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculate the new position
    let newLeft = event.clientX - this.offsetX;
    let newTop = event.clientY - this.offsetY;

    // Limiting the window for the dialog to be draggable within the viewport
    newLeft =
      Math.max(0, Math.min(newLeft, viewportWidth - dialogElement.offsetWidth - (this.scrollbarWidth + 10))) +
      dialogElement.offsetWidth / 1.85;
    newTop =
      Math.max(0, Math.min(newTop, viewportHeight - dialogElement.offsetHeight)) +
      dialogElement.offsetHeight / 1.97;

    // Define the new position for the dialog
    dialogElement.style.left = `${newLeft}px`;
    dialogElement.style.top = `${newTop}px`;
  }
}
