import { Component, ViewChild, OnInit, Inject, ElementRef, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { ResizeService } from '../../../core/services/resize-service/resize.service';
import { DragService } from '../../../core/services/drag-service/drag.service';
import { UNICODE_MULTIPLY } from '../../../core/constants/app.contants';


@Component({
  selector: 'app-dialog-view',
  templateUrl: './dialog-view.component.html',
  styleUrls: ['./dialog-view.component.css']
})
export class DialogViewComponent implements OnInit {
  form!: FormGroup;
  closeResult: string = '';
  @ViewChild('content', { static: true }) content: any;
  private modalRef!: NgbModalRef;
  data: any;
  clicked:any;
  closeTab=UNICODE_MULTIPLY;
  formatLocalColForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<DialogViewComponent>,
    @Inject(MAT_DIALOG_DATA) public injectedData: any,
    private fb: FormBuilder,
    private resizeService: ResizeService,
    private dragService: DragService,
  ) {
    this.data = injectedData.data;
    this.clicked = injectedData.clicked;
    this.formatLocalColForm = this.fb.group({
      page_id: [{ value: '', disabled: true }],
    });
  }

  ngOnInit() {


    this.form = new FormGroup({
      edit_input: new FormControl(this.data?.value),
    });
  }
  @ViewChild('viewPageContainer', { static: true })
  popupContainer!: ElementRef<HTMLDivElement>; // to get the reference of the popup container for further manipulaiton
  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContainer);
  }
    // Function to handle drag start
    onDragStart(event: MouseEvent) {
      this.dragService.onDragStart(event, this.popupContainer);
    }
    // Function to handle mouse movement
    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
      this.dragService.onMouseMove(event, this.popupContainer);
    }
  
    // Function to handle drag end
    @HostListener('document:mouseup')
    onMouseUp() {
      this.dragService.onMouseUp(); // Use the service method
    }
  onDelete(): void {
    // this.dialogRef.close(this.editFormControlName.value);
    this.dialogRef.close(true);
    this.modalRef.dismiss('Save click');
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }

  get editFormControlName(): FormControl {
    return this.form.get('edit_input') as FormControl;
  }
}
