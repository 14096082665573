import {
  Component,
  OnInit,
  Inject,
  ViewChildren,
  QueryList,
  ElementRef,
  ViewChild,
  HostListener,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Sheet } from '../../store/page/sheet.actions';
import { SheetState } from '../../store/page/sheet.store';
import { Observable } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UNICODE_MULTIPLY } from '../../core/constants/app.contants';
import { COL_STATUSES } from '../../constant';
import {
  ASCII_EXPAND_ICON,
  UNCICODE_CROSS_ICON,
} from '../../core/constants/app.contants';

@Component({
  selector: 'app-pg-col-dialog',
  templateUrl: './pgcol-dialog.component.html',
  styleUrls: ['./pgcol-dialog.component.css'],
})
export class PGColDialogComponent implements OnInit {
  closeResult: string = '';
  columns: any[] = [];
  columnsDDI: any[] = [];
  height: any = 0;
  width: any = 0;
  previousFontSize: number = 0;
  currentFontSize: number = 0;
  newFontSize: number = 0;
  closeTab = UNCICODE_CROSS_ICON;
  @ViewChildren('checkboxElement') checkboxItems!: QueryList<ElementRef>;
  @ViewChild('popupContainer') popupContainer!: ElementRef;

  @Select(SheetState.getSheetColumns) columns$!: Observable<any>;
  @Select(SheetState.PickDdiColumns) columnsDDI$!: Observable<any>;
  expandIcon = ASCII_EXPAND_ICON;

  constructor(
    public dialogRef: MatDialogRef<PGColDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store
  ) {
    this.previousFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.currentFontSize = this.previousFontSize;
  }
  isDragging: boolean = false;
  isResizing: boolean = false;
  initialMouseX: number = 0;
  initialMouseY: number = 0;
  initialWidth: number = 0;
  initialHeight: number = 0;
  initialPopupX: number = 0; // Initial X position of the popup
  initialPopupY: number = 0; // Initial Y position of the popup\

  newWidth: number = 0;
  newHeight: number = 0;

  offsetX = 0;
  offsetY = 0;
  scrollbarWidth = this.getScrollbarWidth(); // Getting the side scrollbar width
  // Function to calculate the side scrollbar width
  getScrollbarWidth(): number {
    return window.innerWidth - document.documentElement.clientWidth;
  }
  ngOnInit() {
    if (this.data?.dialog != true || this.data?.dialog == undefined) {
      this.columns$.subscribe((columns) => {
        if (columns == undefined) {
          this.store.dispatch(new Sheet.FetchSheetData(this.data?.selectedId));
          this.columns$.subscribe((columns) => {
            this.columns = columns.map((col: any) => ({ ...col }));
          });
        } else {
          this.columns = columns.map((col: any) => ({ ...col }));
        }
      });
    } else {
      this.columnsDDI$.subscribe((columns) => {
        if (columns == undefined) {
          this.store.dispatch(new Sheet.PickDdiData(this.data?.selectedId));
          this.columnsDDI$.subscribe((columns) => {
            this.columnsDDI = columns.map((col: any) => ({ ...col }));
          });
        } else {
          this.columnsDDI = columns.map((col: any) => ({ ...col }));
        }
      });
    }
    this.adjustCheckboxSize();
    window.addEventListener('resize', this.adjustCheckboxSize.bind(this));
  }
  changeFontSize(newSize: number): void {
    // Update previous font size before changing it
    this.previousFontSize = this.currentFontSize;

    // Change the current font size
    this.currentFontSize = newSize;
    document.documentElement.style.fontSize = `${newSize}px`;
  }
  private adjustCheckboxSize() {
    const bodyFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.height = bodyFontSize / this.previousFontSize - 0.2;
    this.width = bodyFontSize / this.previousFontSize - 0.2;
  }
  // Dragging functionality
  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.isDragging = true;
    this.offsetX =
      event.clientX -
      this.popupContainer.nativeElement.getBoundingClientRect().left;
    this.offsetY =
      event.clientY -
      this.popupContainer.nativeElement.getBoundingClientRect().top;
  }

  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      this.moveDialog(event);
    }
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.isDragging = false;
  }
  // Move the dialog within the viewport
  moveDialog(event: MouseEvent) {
    const dialogElement = this.popupContainer.nativeElement;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    // Calculating the new position
    let newLeft = event.clientX - this.offsetX;
    let newTop = event.clientY - this.offsetY;

    // Limitizing the window for dialog to be draggable within the viewport
    newLeft =
      Math.max(
        0,
        Math.min(
          newLeft,
          viewportWidth - dialogElement.offsetWidth - (this.scrollbarWidth + 10)
        )
      ) +
      dialogElement.offsetWidth / 1.85;
    newTop =
      Math.max(
        0,
        Math.min(newTop, viewportHeight - dialogElement.offsetHeight)
      ) +
      dialogElement.offsetHeight / 1.97;
    // Defining new posotion for the dialog
    dialogElement.style.left = `${newLeft}px`;
    dialogElement.style.top = `${newTop}px`;
  }
  onResizeStart(event: MouseEvent) {
    this.isResizing = true;
    this.initialMouseX = event.clientX;
    this.initialMouseY = event.clientY;

    const popup = this.popupContainer.nativeElement;
    this.initialWidth = popup.offsetWidth;
    this.initialHeight = popup.offsetHeight;

    document.body.classList.add('no-select');
    event.preventDefault();

    document.addEventListener('mousemove', this.onResize);
    document.addEventListener('mouseup', this.onResizeEnd);
  }
  onResize = (event: MouseEvent) => {
    if (this.isResizing) {
      event.preventDefault();
      // Check if the mouse is within the viewport bounds
      if (
        event.clientX < 0 ||
        event.clientY < 0 ||
        event.clientX > window.innerWidth - this.scrollbarWidth ||
        event.clientY > window.innerHeight
      ) {
        return; // Stop resizing if the mouse is outside the viewport
      }

      const dx = event.clientX - this.initialMouseX;
      const dy = event.clientY - this.initialMouseY;

      const popup = this.popupContainer.nativeElement;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      // Get the current position and dimensions of the popup
      const popupRect = popup.getBoundingClientRect();
      // Calculate the new width and height based on mouse movement
      this.newWidth = Math.max(100, this.initialWidth + dx);
      this.newHeight = Math.max(100, this.initialHeight + dy);

      // Check if the dialog is near the left boundary and allow shrinking
      if (popupRect.left <= 0) {
        if (dx < 0) {
          // Allow shrinking when resizing to the left (dx < 0)
          this.newWidth = Math.max(100, popupRect.width - Math.abs(dx));
        } else {
          // Prevent increasing size if moving to the right
          this.newWidth = popupRect.width + popupRect.left;
        }
      }

      // Check if the dialog is near the top boundary and allow shrinking
      if (popupRect.top <= 0) {
        if (dy < 0) {
          // Allow shrinking when resizing upwards (dy < 0)
          this.newHeight = Math.max(100, popupRect.height - Math.abs(dy));
        } else {
          // Prevent increasing size if moving downwards
          this.newHeight = popupRect.height + popupRect.top;
        }
      }

      // Stop resizing if the popup is touching or crossing the right boundary
      if (
        popupRect.left + this.newWidth >=
        viewportWidth - this.scrollbarWidth
      ) {
        this.newWidth = viewportWidth - popupRect.left - this.scrollbarWidth;
      }

      // Stop resizing if the popup is touching or crossing the bottom boundary
      if (popupRect.top + this.newHeight >= viewportHeight) {
        this.newHeight = viewportHeight - popupRect.top;
      }

      // Apply the constrained width and height to the popup element
      popup.style.width = `${this.newWidth}px`;
      popup.style.height = `${this.newHeight}px`;
    }
  };
  onResizeEnd = () => {
    this.isResizing = false;
    document.body.classList.remove('no-select');
    document.removeEventListener('mousemove', this.onResize);
    document.removeEventListener('mouseup', this.onResizeEnd);
  };

  onClose(): void {
    this.dialogRef.close();
  }

  toggleColumnVisibility(column: any) {
    if (this.data?.dialog == true) {
      if (column.status.includes('Nested')) {
        return;
      }
      if (this.isVisibleDD(column)) {
        const index = column.status.indexOf(COL_STATUSES.DDL_COL);
        if (index !== -1) {
          column.status.splice(index, 1);
        }
      } else {
        column.status.push(COL_STATUSES.DDL_COL);
      }
      this.store.dispatch(new Sheet.UpdatePickDdiColumns(this.columnsDDI));
    } else {
      if (column.status.includes('Nested')) {
        return;
      }
      if (this.isVisible(column)) {
        column.status.push('Hidden');
      } else {
        const index = column.status.indexOf('Hidden');
        if (index !== -1) {
          column.status.splice(index, 1);
        }
      }
      this.store.dispatch(new Sheet.updateColumns(this.columns));
    }
  }

  isVisible(column: any): boolean {
    return !column.status.includes('Hidden');
  }
  isVisibleDD(column: any): boolean {
    return column.status.includes(COL_STATUSES.DDL_COL) ? true : false;
  }
  drop(event: CdkDragDrop<any[]>): void {
    if (this.data?.dialog == true) {
      moveItemInArray(this.columnsDDI, event.previousIndex, event.currentIndex);
      this.store.dispatch(new Sheet.UpdatePickDdiColumns(this.columnsDDI));
    } else {
      moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
      this.store.dispatch(new Sheet.updateColumns(this.columns));
    }
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.onKeydown);
  }

  onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.onClose();
    }
  };

  ngAfterViewInit() {
    document.addEventListener('keydown', this.onKeydown);
  }
}
