import {
  Component,
  OnInit,
  Inject,
  ViewChildren,
  QueryList,
  ElementRef,
  ViewChild,
  HostListener,
  inject,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Sheet } from '../../store/page/page.actions';
import { SheetState } from '../../store/page/page.store';
import { Observable } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { COL_STATUSES } from '../../constant';
import { ASCII_EXPAND_ICON, UNICODE_CROSS_ICON, UNICODE_EXPAND_ICON,  } from '../../core/constants/app.contants';
import { DragService } from '../../core/services/drag-service/drag.service';
import { ResizeService } from '../../core/services/resize-service/resize.service';
import { ColState } from '../../store/cols/cols.state';
import { MainService } from '../../core/services/main-service/main.service';

@Component({
  selector: 'app-pg-col-dialog',
  templateUrl: './pgcol-dialog.component.html',
  styleUrls: ['./pgcol-dialog.component.scss'],
})
export class PGColDialogComponent implements OnInit {
  closeResult: string = '';
  columns: any[] = [];
  columnsDDI: any[] = [];
  height: any = 0;
  width: any = 0;
  previousFontSize: number = 0;
  currentFontSize: number = 0;
  newFontSize: number = 0;
  closeTab = UNICODE_CROSS_ICON;
  @ViewChildren('checkboxElement') checkboxItems!: QueryList<ElementRef>;
  @ViewChild('popupContainer') popupContainer!: ElementRef;

  @Select(SheetState.getSheetColumnsById) columns$!: Observable<any>;
  @Select(SheetState.PickDdiColumns) columnsDDI$!: Observable<any>;
  expandIcon = UNICODE_EXPAND_ICON;

  constructor(
    public dialogRef: MatDialogRef<PGColDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store,
    private dragService: DragService,
    private resizeService: ResizeService,
    private mainService: MainService,
  ) {
    this.previousFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.currentFontSize = this.previousFontSize;
  }
  isDragging: boolean = false;
  isResizing: boolean = false;
  initialMouseX: number = 0;
  initialMouseY: number = 0;
  initialWidth: number = 0;
  initialHeight: number = 0;
  initialPopupX: number = 0; // Initial X position of the popup
  initialPopupY: number = 0; // Initial Y position of the popup\

  newWidth: number = 0;
  newHeight: number = 0;

  offsetX = 0;
  offsetY = 0;
  scrollbarWidth = this.getScrollbarWidth(); // Getting the side scrollbar width
  // Function to calculate the side scrollbar width
  getScrollbarWidth(): number {
    return window.innerWidth - document.documentElement.clientWidth;
  }
  ngOnInit() {
    if (this.data?.dialog != true || this.data?.dialog == undefined) {
      this.columns$.subscribe((columns) => {
        if (columns == undefined) {
          this.store.dispatch(new Sheet.FetchSheetData(this.data?.selectedId));
          this.columns$.subscribe((columns) => {
            this.columns = columns.map((col: any) => ({ ...col }));
          });
        } else {
          this.columns = columns.map((col: any) => ({ ...col }));
        }
      });
    } else {
      this.columnsDDI$.subscribe((columns) => {
        if (columns == undefined) {
          this.store.dispatch(new Sheet.PickDdiData(this.data?.selectedId));
          this.columnsDDI$.subscribe((columns) => {
            this.columnsDDI = columns.map((col: any) => ({ ...col }));
          });
        } else {
          this.columnsDDI = columns.map((col: any) => ({ ...col }));
        }
      });
    }
    this.adjustCheckboxSize();
    window.addEventListener('resize', this.adjustCheckboxSize.bind(this));
  }
  changeFontSize(newSize: number): void {
    // Update previous font size before changing it
    this.previousFontSize = this.currentFontSize;

    // Change the current font size
    this.currentFontSize = newSize;
    document.documentElement.style.fontSize = `${newSize}px`;
  }
  private adjustCheckboxSize() {
    const bodyFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.height = bodyFontSize / this.previousFontSize - 0.2;
    this.width = bodyFontSize / this.previousFontSize - 0.2;
  }
  // Dragging functionality
  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.dragService.onDragStart(event, this.popupContainer);
  }


  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.dragService.onMouseMove(event, this.popupContainer);
  }


  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.dragService.onMouseUp(); // Use the service method
  }
  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.resizeService.onResizeStart(event, this.popupContainer);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  toggleColumnVisibility(event : any, column: any) {
    if (this.data?.dialog == true) {
      if (column.status.includes('Nested')) {
        return;
      }
      if (this.isVisibleDD(column)) {
        const index = column.status.indexOf(COL_STATUSES.DDL_COL);
        if (index !== -1) {
          column.status.splice(index, 1);
        }
      } else {
        column.status.push(COL_STATUSES.DDL_COL);
      }
      this.columnsDDI = this.columnsDDI.map((col) => {
        if (col.field === column.field) {
          return {
            ...col,
            status: event.currentTarget.checked 
              ? col.status.filter((c : any) => c !== COL_STATUSES.HIDDEN)
              : col.status.includes(COL_STATUSES.HIDDEN) 
                ? col.status 
                : [...col.status, COL_STATUSES.HIDDEN]
          };
        }
        return col;
      });
      this.store.dispatch(new Sheet.UpdatePickDdiColumns(this.columnsDDI));
    } else {
      if (column.status.includes('Nested')) {
        return;
      }
      if (this.isVisible(column)) {
        column.status.push(COL_STATUSES.HIDDEN);
      } else {
        const index = column.status.indexOf(COL_STATUSES.HIDDEN);
        if (index !== -1) {
          column.status.splice(index, 1);
        }
      }
      this.store.dispatch(new Sheet.updateColumns(this.columns));
    }
  }

  isVisible(column: any): boolean {
    return !column.status.includes(COL_STATUSES.HIDDEN);
  }

  isVisibleDD(column: any): boolean {
    return column.status.includes(COL_STATUSES.DDL_COL) ? true : false;
  }

  setDialogContainerWidth(){
    this.mainService.setWidth$.next('fit-content');
  }

  drop(event: CdkDragDrop<any[]>): void {
    if (this.data?.dialog == true) {
      moveItemInArray(this.columnsDDI, event.previousIndex, event.currentIndex);
      this.store.dispatch(new Sheet.UpdatePickDdiColumns(this.columnsDDI));
    } else {
      moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
      this.store.dispatch(new Sheet.updateColumns(this.columns));
    }
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.onKeydown);
  }

  onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.onClose();
    }
  };

  ngAfterViewInit() {
    document.addEventListener('keydown', this.onKeydown);
  }
}
