import { Tabulator } from 'tabulator-tables';
import {
  CellData,
  RowPageData,
} from '../shared/models/edit-dd-dailogs/dd-dailog.models';
import { MainService } from '../core/services/main-service/main.service';
import { PgObject } from '../store/pg-tab/pg-tab.model';
import { take, timer } from 'rxjs';

export function findMaxRowLevel(obj: RowPageData) {
  let maxRowLevel = obj.RowLevel || 0;

  if (obj._children && Array.isArray(obj._children)) {
    for (const child of obj._children) {
      maxRowLevel = Math.max(maxRowLevel, findMaxRowLevel(child));
    }
  }

  return maxRowLevel;
}

export function executeAfterDelay(ftn: () => void, time: number = 0): void {
  timer(time).pipe(take(1)).subscribe(() => ftn());
};

//helper methods for rows selection tabulator
export function toggleSelection(tabulatorInstance: Tabulator) {
  const parentElement = document.getElementsByClassName('tabulator-col-title')[0];

    if (parentElement) {
      const children = parentElement.children as any;

      for (let child of children) {
        if (child.checked) {
          if (tabulatorInstance.getRows().length > 0) {
            tabulatorInstance.getRows().forEach((row: any) => {
              row.select();
              selectChildRows(row);
            });
          }
        }
      }
    }
}

export function selectChildRows(row: any) {
    const childRows = row.getTreeChildren();
    if (childRows && childRows.length > 0) {
      childRows.forEach((child: any) => {
        child.select();
        selectChildRows(child);
      });
    }
}

export function getKeyFromValue(data: CellData): object | string {
  const val = data?.cell?.items[0]?.item || data?.format?.items[0]?.item || '';
  return val;
}


export const extractFromFormulas = (formulas: string[]) => {
 const result : any = {};

 formulas.forEach((formula) => {
     const regex = /{current\w+}/;
     const match = formula.match(regex);

     if (match) {
         const key = match[0].replace(/[{}]/g, "");
         result[key] = match[0];
     }
  })
  
  return result;
}

// Function to get the string width according to window size
export function getStringWidth(str: string) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    throw new Error('Canvas context is not supported.');
  }
  
  // Get current font size and family from window
  const computedStyle = window.getComputedStyle(document.body);
  const fontSize = computedStyle.fontSize;
  const fontFamily = computedStyle.fontFamily;
  context.font = `${fontSize} ${fontFamily}`;
  
  return context.measureText(str).width;
}

export function getLanguageCodeFromLanguage(language: string): string {
  const match = language?.match(/\(([^)]+)\)/);
  return match ? match[1] : '';
}
export function getValueFromObject(value: PgObject | string): string {
  if (typeof value ==='object') {
    return value[MainService.currentLanguage().langID] ?? Object.values(value)[0] as string
  }
  return value as string;
  
}

