import { DestroyRef, EventEmitter, Injectable, WritableSignal, inject, signal } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Edition } from '../../../modals/page.modal';
import { CellData, ItemData, KeyValue, RowFormat, RowPageData } from '../../../shared/models/edit-dd-dailogs/dd-dailog.models';
import { ContextActions, FormFieldsChip, Page, SelectMenuOptions } from '../../constants/menu-bar/page/page';
import { AddItemPayload } from '../../../shared/models/item/item.model';
import { ColumnDef } from '../../../shared/models/columns/column.model';
import { COL_IDS, FONT_STYLE, FORMAT_FORM } from '../../constants/app.contants';
import { GetCellPaylaod, GetItemPaylaod, UpdateItemPayload } from '../../../shared/models/format/format.models';
import { SheetState } from '../../../store/page/page.store';
import { Store } from '@ngxs/store';
import { SystemInitials } from '../../../constant';
import { LabelsFacadeService } from '../labels/labels-facade.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CurrentObjectDefinitions, SystemObjectDefinitions } from '../../../store/labels/labels.model';
import { MessageMenuPayload, MessagePayload } from '../../interfaces/messages/messages.interface';
import { DbObjects, SystemObjects } from '../../enums/tokens/tokens.enum';
import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import { PgTab } from '../../../store/pg-tab/pg-tab.model';
import { LabelsStore } from '../../../store/labels/labels.store';
import { DEFAULT_LANGUAGE, Language } from '../../interfaces/language.interface';


@Injectable({
  providedIn: 'root',
})
export class MainService {
  public isTabSwitch$: Subject<void> = new Subject<void>();
  public isAutoTranslation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public static currentLanguage: WritableSignal<Language> = signal<Language>(DEFAULT_LANGUAGE);
  public isAutoTranslationChecked: WritableSignal<boolean> = signal<boolean>(false);
  private sessionId$ = new BehaviorSubject<string>('User:ABC.Tab:5');
  private sortStatus$ = new BehaviorSubject<string>('Sort is Off');
  public setWidth$ = new BehaviorSubject<string>('');
  public sortStatusDD$ = new BehaviorSubject<string>('Sort is Off');
  private filterStatus$ = new BehaviorSubject<string>('Filter is Off');
  private filterStatusDD$ = new BehaviorSubject<string>('Filter is Off');
  public pageName$  = new BehaviorSubject<string>('All Pages'); // Default value is "All Pages"
  public openPage$ = new BehaviorSubject<any>('');
  destroyRef = inject(DestroyRef);
  pageFormate = new BehaviorSubject<any>('');
  pageFormateReg = new BehaviorSubject<any>('');
  pageFormateFreeze = new BehaviorSubject<any>('');
  Expand_Visibilt = new BehaviorSubject<any>({
    n: 0,
    x: null,
    y: null,
    z: null,
  });
  Expand_Visibilt_Reg = new BehaviorSubject<any>({
    n: 0,
    x: null,
    y: null,
    z: null,
  });
  pgRow = new BehaviorSubject<any>('');
  selectedId = new BehaviorSubject<any>('');
  CurrentItemId$ = new BehaviorSubject<any>('');
  languageListKeys = {
    Afrikaans: 'af',
    Albanian: 'sq',
    Amharic: 'am',
    Arabic: 'ar',
    Armenian: 'hy',
    Assamese: 'as',
    Aymara: 'ay',
    Azerbaijani: 'az',
    Bambara: 'bm',
    Basque: 'eu',
    Belarusian: 'be',
    Bengali: 'bn',
    Bhojpuri: 'bho',
    Bosnian: 'bs',
    Bulgarian: 'bg',
    Catalan: 'ca',
    Cebuano: 'ceb',
    Chichewa: 'ny',
    'Chinese (Simplified)': 'zh-CN',
    'Chinese (Traditional)': 'zh-TW',
    Corsican: 'co',
    Croatian: 'hr',
    Czech: 'cs',
    Danish: 'da',
    Dhivehi: 'dv',
    Dogri: 'doi',
    Dutch: 'nl',
    English: 'en',
    Esperanto: 'eo',
    Estonian: 'et',
    Ewe: 'ee',
    Filipino: 'fil',
    Finnish: 'fi',
    French: 'fr',
    Frisian: 'fy',
    Galician: 'gl',
    Georgian: 'ka',
    German: 'de',
    Greek: 'el',
    Guarani: 'gn',
    Gujarati: 'gu',
    'Haitian Creole': 'ht',
    Hausa: 'ha',
    Hawaiian: 'haw',
    Hebrew: 'he',
    Hindi: 'hi',
    Hmong: 'hmn',
    Hungarian: 'hu',
    Icelandic: 'is',
    Igbo: 'ig',
    Ilocano: 'ilo',
    Indonesian: 'id',
    Irish: 'ga',
    Italian: 'it',
    Japanese: 'ja',
    Javanese: 'jv',
    Kannada: 'kn',
    Kazakh: 'kk',
    Khmer: 'km',
    Kinyarwanda: 'rw',
    Konkani: 'gom',
    Korean: 'ko',
    Krio: 'kri',
    'Kurdish (Kurmanji)': 'ku',
    'Kurdish (Sorani)': 'ckb',
    Kyrgyz: 'ky',
    Lao: 'lo',
    Latin: 'la',
    Latvian: 'lv',
    Lingala: 'ln',
    Lithuanian: 'lt',
    Luganda: 'lg',
    Luxembourgish: 'lb',
    Macedonian: 'mk',
    Maithili: 'mai',
    Malagasy: 'mg',
    Malay: 'ms',
    Malayalam: 'ml',
    Maltese: 'mt',
    Maori: 'mi',
    Marathi: 'mr',
    'Meiteilon (Manipuri)': 'mni-Mtei',
    Mizo: 'lus',
    Mongolian: 'mn',
    'Myanmar (Burmese)': 'my',
    Nepali: 'ne',
    Norwegian: 'no',
    'Odia (Oriya)': 'or',
    Oromo: 'om',
    Pashto: 'ps',
    Persian: 'fa',
    Polish: 'pl',
    Portuguese: 'pt',
    Punjabi: 'pa',
    Quechua: 'qu',
    Romanian: 'ro',
    Russian: 'ru',
    Samoan: 'sm',
    Sanskrit: 'sa',
    'Scots Gaelic': 'gd',
    Sepedi: 'nso',
    Serbian: 'sr',
    Sesotho: 'st',
    Shona: 'sn',
    Sindhi: 'sd',
    Sinhala: 'si',
    Slovak: 'sk',
    Slovenian: 'sl',
    Somali: 'so',
    Spanish: 'es',
    Sundanese: 'su',
    Swahili: 'sw',
    Swedish: 'sv',
    Tajik: 'tg',
    Tamil: 'ta',
    Tatar: 'tt',
    Telugu: 'te',
    Thai: 'th',
    Tigrinya: 'ti',
    Tsonga: 'ts',
    Turkish: 'tr',
    Turkmen: 'tk',
    Twi: 'ak',
    Ukrainian: 'uk',
    Urdu: 'ur',
    Uyghur: 'ug',
    Uzbek: 'uz',
    Vietnamese: 'vi',
    Welsh: 'cy',
    Xhosa: 'xh',
    Yiddish: 'yi',
    Yoruba: 'yo',
    Zulu: 'zu',
  };
  languageList = [
    'Afrikaans',
    'Albanian',
    'Amharic',
    'Arabic',
    'Armenian',
    'Assamese',
    'Aymara',
    'Azerbaijani',
    'Bambara',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bhojpuri',
    'Bosnian',
    'Bulgarian',
    'Catalan',
    'Cebuano',
    'Chichewa',
    'Chinese (Simplified)',
    'Chinese (Traditional)',
    'Corsican',
    'Croatian',
    'Czech',
    'Danish',
    'Dhivehi',
    'Dogri',
    'Dutch',
    'English',
    'Esperanto',
    'Estonian',
    'Ewe',
    'Filipino',
    'Finnish',
    'French',
    'Frisian',
    'Galician',
    'Georgian',
    'German',
    'Greek',
    'Guarani',
    'Gujarati',
    'Haitian Creole',
    'Hausa',
    'Hawaiian',
    'Hebrew',
    'Hindi',
    'Hmong',
    'Hungarian',
    'Icelandic',
    'Igbo',
    'Ilocano',
    'Indonesian',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Kinyarwanda',
    'Konkani',
    'Korean',
    'Krio',
    'Kurdish (Kurmanji)',
    'Kurdish (Sorani)',
    'Kyrgyz',
    'Lao',
    'Latin',
    'Latvian',
    'Lingala',
    'Lithuanian',
    'Luganda',
    'Luxembourgish',
    'Macedonian',
    'Maithili',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maltese',
    'Maori',
    'Marathi',
    'Meiteilon (Manipuri)',
    'Mizo',
    'Mongolian',
    'Myanmar (Burmese)',
    'Nepali',
    'Norwegian',
    'Odia (Oriya)',
    'Oromo',
    'Pashto',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Quechua',
    'Romanian',
    'Russian',
    'Samoan',
    'Sanskrit',
    'Scots Gaelic',
    'Sepedi',
    'Serbian',
    'Sesotho',
    'Shona',
    'Sindhi',
    'Sinhala',
    'Slovak',
    'Slovenian',
    'Somali',
    'Spanish',
    'Sundanese',
    'Swahili',
    'Swedish',
    'Tajik',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Tigrinya',
    'Tsonga',
    'Turkish',
    'Turkmen',
    'Twi',
    'Ukrainian',
    'Urdu',
    'Uyghur',
    'Uzbek',
    'Vietnamese',
    'Welsh',
    'Xhosa',
    'Yiddish',
    'Yoruba',
    'Zulu',
  ];
  edition: string = '';
  fontStyles: string = '';
  systemObjects : {[key:string] : string}= {};
  
  constructor(
    private store: Store,
    private labelsFacadeService: LabelsFacadeService,
  ) { }

  buildNestedDataIterative(
    inputData: any,
    hasChildrenInAnyRow: { value: boolean }
  ) {
    hasChildrenInAnyRow.value = false;
    const map: { [key: string]: any } = {};
    const structuredData: any[] = [];

    inputData.forEach((item: any) => {
      const newItem = {
        select : false,
        ...item,
      };

      if (item?.ParentRow?.Row === null || item?.ParentRow === null) {
        structuredData.push(newItem);
      } else {
        if (item?.ParentRow?.Row !== null) {
          hasChildrenInAnyRow.value = true;
        }
        const parentRow = map[item?.ParentRow?.Row];
        if (parentRow) {
          if (!parentRow._children) {
            parentRow._children = [];
          }
          parentRow._children.push(newItem);
        }
      }

      map[item?.row] = newItem;
    });

    return structuredData;
  }

  mapAllPagesData(data : any){
    if(data && data.data){
      const allKeys = Object.keys(data.data);
      const allTabs = data.data;
      let mappedValue: any[] = [];
      const updateddata = Object.keys(allTabs).reduce((acc, key) => {
        let updated = allTabs[key] = {
          ...allTabs[key],
          pageData: this.buildNestedDataIterative(allTabs[key].pageData, {value : false}),
        };
        mappedValue.push({[key] : updated});
        return acc;
      }, {});
      return {
        defaultPageId : allKeys[0],
        allTabsData : mappedValue
      }
    }
     return {
      defaultPageId : null,
      allTabsData : []
     }
  }

  mapAllPagesFlatData(data : any){
    if(data && data.data){
      const allKeys = Object.keys(data.data);
      const allTabs = data.data;
      let mappedValue: any[] = [];
      const updateData = Object.keys(allTabs).reduce((acc, key) => {
        let updated = allTabs[key] = {
          ...allTabs[key],
          pageData: allTabs[key].pageData,
        };
        mappedValue.push({[key] : updated});
        return acc;
      }, {});
      return {
        defaultPageId : allKeys[0],
        allTabsData : mappedValue
      }
     }
     return {
      defaultPageId : null,
      allTabsData : []
     }
  }

  // Function to delete a specific page by page_id
  mapDeletePageDataById(pgId: number, data: any) {
    data.forEach((obj:any) => {
      Object.keys(obj).forEach(key => {
        const pageData = obj[key as keyof typeof obj].pageData;
        obj[key as keyof typeof obj].pageData = pageData.filter((page:any) => Number(page.page_id) !== Number(pgId));
      });
    });

    return data
  };


  // Function to delete a specific page by page_id
  mapDeletePageFlatDataById(pgId: number, data: any) {
    data.forEach((obj:any) => {
      Object.keys(obj).forEach(key => {
        const pageData = obj[key as keyof typeof obj].pageData;
        obj[key as keyof typeof obj].pageData = pageData.filter((page:any) => Number(page.page_id) !== Number(pgId));
      });
    });

    return data
  };

  // Function to delete a specific page column
  deletePageColumnById(pgId: number, colId: number, data: any) {
    data.forEach((page:any) => {
      if (page[pgId]) {
        page[pgId].pageColumns = page[pgId].pageColumns.filter(
          (column:any) => Number(column.col) !== Number(colId)
        );
      }
    });

    return data
  };

  // Function to delete a specific page row
  deleteRow(pgId: number, rowId: number, allTabsData: any[]) {
    const atIndex: number = allTabsData.findIndex((res) => res[pgId]);
    const { row: targetRow, parent } = this.findRow(allTabsData[atIndex][pgId].pageData, rowId)
    const parentList = parent?._children || allTabsData[atIndex][pgId].pageData;
    const index = parentList.indexOf(targetRow);
    parentList.splice(index, 1);

    return allTabsData
  };

  // Function to delete a specific page row
  deleteItem(pgId: number, colId: number, rowId: number, allTabsData: any[]) {
    const atIndex: number = allTabsData.findIndex((res) => res[pgId]);
    const { row: targetRow, parent } = this.findRow(allTabsData[atIndex][pgId].pageData, rowId)
    const parentList = parent?._children || allTabsData[atIndex][pgId].pageData;
    const colDef: ColumnDef | undefined = allTabsData[atIndex][pgId].pageColumns.find(
      (column: ColumnDef) => Number(column.col) === Number(colId)
    );
    if (!colDef) {
      console.error(`Column "${colId}" not found.`);
      return;
    }

    const fieldName: string = colDef.field;
    if (targetRow && fieldName in targetRow) {
      (targetRow as any)[fieldName] = '';
    }

    return allTabsData
  };

  getCell(pgId: number, colId: number, rowId: number) {
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    if (!allTabsData) return;
    const atIndex: number = allTabsData.findIndex((res) => res[pgId]);
    const { row: targetRow, parent } = this.findRow(allTabsData[atIndex][pgId].pageData, rowId);
    const parentList = parent?._children || allTabsData[atIndex][pgId].pageData;
    const colDef: ColumnDef | undefined = allTabsData[atIndex][pgId].pageColumns.find(
      (column: ColumnDef) => Number(column.col) === Number(colId)
    );
    if (!colDef) {
      console.error(`Column "${colId}" not found.`);
      return;
    }

    const fieldName: string = colDef.field;
    if (targetRow && fieldName in targetRow) {
      return (targetRow as any)[fieldName];
    }
  }

  updateCell(payload: GetCellPaylaod, data: any[]) {
    const cell: CellData = this.getCell(Number(payload.pgId), Number(payload.colId), Number(payload.rowId));

    let targetItems: ItemData[] = [];
    if (cell.hasOwnProperty('cell')) targetItems = cell.cell.items;
    if (cell.hasOwnProperty('format') && cell.format) targetItems = cell.format.items;

    const updatedItems: ItemData[] = payload.value.map((value, index) => ({
      id: targetItems[index]?.id,
      item: value,
      format: targetItems[index]?.format
    }));

    if (cell?.cell) cell.cell.items = updatedItems;
    if (cell?.format) cell.format.items = updatedItems;

    return data;
  }

  updateCellFormatStatus(payload: GetCellPaylaod, data: any[]) {
    const cell: CellData = this.getCell(Number(payload.pgId), Number(payload.colId), Number(payload.rowId));
    if (cell?.cell?.format) {
      cell.cell.format.status = payload.value;
    }

    return data;
  }

  updateItemFormatStatus(payload: UpdateItemPayload, data: any[]) {
    const cell: CellData = this.getCell(Number(payload.pgId), Number(payload.colId), Number(payload.rowId));

    let targetItems: ItemData[] = [];
    if (cell.hasOwnProperty('cell')) targetItems = cell.cell.items;

    const targetItem = targetItems.find((item: ItemData) => Number(item.id) === payload.itemId);
    if (targetItem?.format) targetItem.format.status = payload.value;
    if (cell?.cell) cell.cell.items = targetItems;

    return data;
  }

  deleteCellItem(payload: UpdateItemPayload, data: any[]) {
    const cell: CellData = this.getCell(Number(payload.pgId), Number(payload.colId), Number(payload.rowId));

    let targetItems: ItemData[] = [];
    if (cell.hasOwnProperty('cell')) targetItems = cell.cell.items;

    const updatedItems = targetItems.filter((item: ItemData) => Number(item.id) !== payload.itemId);
    if (cell?.cell) cell.cell.items = updatedItems;

    return data;
  }

  addNewRow(
    pageId: any,
    newRow: any,
    allTabsData: any[],
    currentRowId: number | null,
    key: ContextActions,
    collId? : string,
  ) {
    if(SelectMenuOptions.includes(key)){
      this.handelCopyShareMove(pageId, newRow,allTabsData,currentRowId,key,collId);
      return
    }
    let mappedValue  =  {
      page_id:  newRow.Pg ? newRow.Pg.toString() : pageId.toString(),
      col_id : '',
      RowLevel: newRow.RowLevel,
      row: newRow.Row.toString(),
      ParentRow: newRow.ParentRow,
      row_type : newRow?.RowType  && newRow?.RowType[0].length > 0  ? newRow?.RowType[0] : [],
      page_comment: '',
      page_url: '',
      page_edition: '',
      page_type: '',
      page_name: '',
      page_seo: '',
      row_status: '',
      row_comment: '',
      page_status: '',
      page_owner: '',
    };

    if(pageId === Page.ALL_COLS){
    mappedValue= {
      ...mappedValue,
      col_id : collId ?? '',
      page_id : null,
    }
  }

    const atIndex: number = allTabsData.findIndex((res) => res[pageId]);
    const updatedData = [...allTabsData];

    if (atIndex !== -1) {
      const findRow = (
        rows: RowPageData[],
        targetRowId: number | null,
        parentRow?: RowPageData
      ): { row: RowPageData | null; parent: RowPageData | null } => {
        for (const row of rows) {
          if (Number(row.row) === targetRowId) {
            return { row, parent: parentRow || null };
          }
          if (row._children && row._children.length > 0) {
            const result = findRow(row._children, targetRowId, row);
            if (result.row) return result;
          }
        }
        return { row: null, parent: null };
      };

      const { row: targetRow, parent } = findRow(
        updatedData[atIndex][pageId].pageData,
        currentRowId
      );

      if (targetRow) {
        if (key === ContextActions.ADD_NEXT_ROW) {
          const parentList = parent?._children || updatedData[atIndex][pageId].pageData;
          const index = parentList.indexOf(targetRow);
          parentList.splice(index + 1, 0, mappedValue);
        } else if (key === ContextActions.ADD_PREV_ROW) {
          const parentList = parent?._children || updatedData[atIndex][pageId].pageData;
          const index = parentList.indexOf(targetRow);
          parentList.splice(index, 0, mappedValue);
        } else if (key === ContextActions.ADD_CHILD_ROW) {
          targetRow._children = targetRow._children || [];
          targetRow._children.push();
          mappedValue = {
              ...mappedValue,
              RowLevel: targetRow.RowLevel + 1,
              ParentRow: {
                    Row: targetRow.row,
                    RowLevel: 1,
              },
          }
          targetRow._children.push(mappedValue as any);
        }
      }
    }

    return updatedData;
  }
  
  handelCopyShareMove(
    pageId: any,
    newRows: any,
    allTabsData: any[],
    currentRowId: number | null,
    key: ContextActions,
    createdPageId: any,
    collId?: string
  ) {
    const updatedData = JSON.parse(JSON.stringify(allTabsData));
    const dataKey = 'pageData';
  
    const targetPage = updatedData.find((item : any) => Object.keys(item).includes(pageId.toString()));
  if (!targetPage || !Array.isArray(targetPage[pageId.toString()][dataKey])) return updatedData;
  
    const pageData = targetPage[pageId.toString()][dataKey];
  
    function extractRowIds(rows: any[]): number[] {
      return rows.map((row) => row.Row.Row);
    }
    const newRowIds = extractRowIds(newRows.SelectedRow);
  
    function findMatchingRows(rows: any[]): any[] {
      let matchedRows: any[] = [];
  
      function traverse(rows: any[]) {
        for (const row of rows) {
          if (newRowIds.includes(Number(row.row))) {
            matchedRows.push({ ...row, page_id: createdPageId }); // Map pgId
          }
  
          if (row._children && Array.isArray(row._children)) {
            traverse(row._children);
          }
        }
      }
  
      traverse(rows);
      return matchedRows;
    }
  
    const matchedRows = findMatchingRows(pageData);
  
    function insertAfter(rows: any[], rowId: number | null, newEntries: any[]) {
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].row === rowId) {
          rows.splice(i + 1, 0, ...newEntries);
          return true;
        }
  
        if (rows[i]._children && Array.isArray(rows[i]._children)) {
          if (insertAfter(rows[i]._children, rowId, newEntries)) {
            return true;
          }
        }
      }
      return false;
    }
  
    insertAfter(pageData, currentRowId, matchedRows);
  
    return updatedData;
  }
  

  updatePageColumns(allTabsData : any[],pageId : number, updatedColumns: any[]){
    const atIndex: number = allTabsData.findIndex((res) => res[pageId]);
    const updatedPageData = allTabsData;
    if (atIndex!==-1){
      updatedPageData[atIndex][pageId].pageColumns = updatedColumns;
    } 
    return updatedPageData;
  }

  addItem(allTabsData: any[], payload: AddItemPayload) {
    const updatedTabsData = [...allTabsData];

    const tabIndex: number = updatedTabsData.findIndex(tab => tab[payload.Pg]);
    if (tabIndex === -1) {
        console.warn(`Page "${payload.Pg}" not found.`);
        return;
    }

    const currentTab = updatedTabsData[tabIndex][payload.Pg];

    const columnDefinition: ColumnDef | undefined = currentTab.pageColumns.find(
        (column: ColumnDef) => column.col === payload.Col.toString()
    );
    if (!columnDefinition) {
        console.warn(`Column "${payload.Col}" not found.`);
        return;
    }

    const fieldName = columnDefinition.field;

    const updateRowData = (rows: RowPageData[], rowKey: string, value: any): boolean => {
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            if (row.row === rowKey) {
                rows[i] = {
                    ...row,
                    [fieldName]: value  ??  payload.CRow?.toString(),
                };
                return true;
            }

            if (row._children && Array.isArray(row._children)) {
                const found = updateRowData(row._children, rowKey, value);
                if (found) return true;
            }
        }
        return false;
    };

    const row = payload.Row.toString();

    const updated = updateRowData(currentTab.pageData, row, Object.values(payload.JSON || {})[0] ?? payload.Object);

    if (!updated) {
        console.warn(`Row "${payload.Row}" not found.`);
        return;
    }

    return updatedTabsData;
  }
  
  addFlatDataItem(allTabsData: any[], payload: AddItemPayload,itemId : string, targetRow? : any) {
    const updatedTabsData = [...allTabsData];

    const tabIndex: number = updatedTabsData.findIndex(tab => tab[payload.Pg]);
    if (tabIndex === -1) {
        console.warn(`Page "${payload.Pg}" not found.`);
        return;
    }

    const currentTab = updatedTabsData[tabIndex][payload.Pg];

    const columnDefinition: ColumnDef | undefined = currentTab.pageColumns.find(
        (column: ColumnDef) => column.col === payload.Col.toString()
    );
    if (!columnDefinition) {
        console.warn(`Column "${payload.Col}" not found.`);
        return;
    }

    const fieldName = columnDefinition.field;

    const updateRowData = (rows: RowPageData[], rowKey: string, value: any): boolean => {
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i] as any;
            if (row.row === rowKey) {
                rows[i] = {
                    ...row,
                    [fieldName]: {
                      cell: {
                        ...row[fieldName].cell,
                        items : row[fieldName]?.cell?.items.map((item : any)=>{
                          if(item.id === itemId.toString()){
                            return {
                              ...item,
                              item : payload.JSON ?  {
                                [Object.keys(payload.JSON)[0]] : value  ??  payload.CRow?.toString(),
                              } :  {
                                [SystemInitials.English] : value  ??  payload.CRow?.toString(),
                              }

                            }
                          }
                          return item;
                        })
                      }
                  }
                };
                return true;
            }else if(targetRow){
                rows.push({
                  ...targetRow,
                  [fieldName]: {
                    cell: {
                      items : [
                          {
                            id : itemId.toString(),
                            item : payload.JSON ?  {
                              [Object.keys(payload.JSON)[0]] : value  ??  payload.CRow?.toString(),
                            } : payload.CRow

                          }
                      ]
                    }
                }
              });
              return true;
            }

            if (row._children && Array.isArray(row._children)) {
                const found = updateRowData(row._children, rowKey, value);
                if (found) return true;
            }
        }
        return false;
    };

    const row = payload.Row.toString();

    const updated = updateRowData(currentTab.pageData, row, Object.values(payload.JSON || {})[0] ?? payload.Object);

    if (!updated) {
        console.warn(`Row "${payload.Row}" not found.`);
        return;
    }

    return updatedTabsData;
  }

findRow(
  rows: RowPageData[],
  targetRowId: number | null, parentRow?: RowPageData
): { row: RowPageData | null; parent: RowPageData | null } {
    for (const row of rows) {
      if (Number(row.row) === Number(targetRowId)) {
        return { row, parent: parentRow || null };
      }
      if (row._children && row._children.length > 0) {
        const result = this.findRow(row._children, targetRowId, row);
        if (result.row) return result;
      }
    }
    return { row: null, parent: null };
}

updateLocalColumnFormat(form: any, allTabsData: any[], pgId: number) {
  const colCommentColId = COL_IDS.COL_COMMENT;
  const colStatusColId = COL_IDS.COL_STATUS;

  const commentPayload: AddItemPayload = {
    Pg : pgId,
    Col: Number(colCommentColId),
    Row: Number(form[FORMAT_FORM.RowId]),
    DataType: 0,
    Object: form[FORMAT_FORM.Comment]
  };

  const statusPayload: AddItemPayload = {
    Pg : pgId,
    Col: Number(colStatusColId),
    Row: Number(form[FORMAT_FORM.RowId]),
    DataType: 0,
    Object: form[FORMAT_FORM.Status].map((status:KeyValue) => 
      status.value
    ).join(';')
  };

  this.addItem(allTabsData, commentPayload);
  this.addItem(allTabsData, statusPayload);
}

updateLocalRowFormat(form: any, allTabsData: any[], pgId: number) {
  const rowCommentcolId = COL_IDS.ROW_COMMENT;
  const rowStatusColId = COL_IDS.ROW_STATUS;

  const commentPayload: AddItemPayload = {
    Pg : pgId,
    Col: Number(rowCommentcolId),
    Row: Number(form[FORMAT_FORM.RowId]),
    DataType: 0,
    Object: form[FORMAT_FORM.Comment]
  };

  const statusPayload: AddItemPayload = {
    Pg : pgId,
    Col: Number(rowStatusColId),
    Row: Number(form[FORMAT_FORM.RowId]),
    DataType: 0,
    Object: form[FORMAT_FORM.Status].map((status:KeyValue) => 
      status.value
    ).join(';')
  };

  this.addItem(allTabsData, commentPayload);
  this.addItem(allTabsData, statusPayload);

  return allTabsData;
}


  getSessionId(): Observable<string> {
    return this.sessionId$.asObservable();
  }
  get sortStatus(): Observable<string> {
    return this.sortStatus$.asObservable();
  }
  get sortStatusDD(): Observable<string> {
    return this.sortStatusDD$.asObservable();
  }
  get setWidth(): Observable<string> {
    return this.setWidth$.asObservable();
  }
  get filterStatus(): Observable<string> {
    return this.filterStatus$.asObservable();
  }
  get filterStatusDD(): Observable<string> {
    return this.filterStatusDD$.asObservable();
  }

  refreshFilterCheckbox() {
    this.filterStatus$.next('Filter is Off');
  }

  handleCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    console.log(
      `${checkbox.name} is now ${checkbox.checked ? 'checked' : 'unchecked'}`
    );
  }

  toggleSort() {
    const currentStatus = this.sortStatus$.getValue();
    this.sortStatus$.next(
      currentStatus === 'Sort is Off' ? 'Sort is On' : 'Sort is Off'
    );
  }
  toggleSortDialog() {
    const currentStatus = this.sortStatusDD$.getValue();
    this.sortStatusDD$.next(
      currentStatus === 'Sort is Off' ? 'Sort is On' : 'Sort is Off'
    );
  }

  toggleFilter() {
    const currentStatus = this.filterStatus$.getValue();
    this.filterStatus$.next(
      currentStatus === 'Filter is Off' ? 'Filter is On' : 'Filter is Off'
    );
  }

  emitCurrentFilter() {
    const currentStatus = this.filterStatus$.getValue();
    this.filterStatus$.next(
      currentStatus === 'Filter is Off' ? 'Filter is Off' : 'Filter is On'
    );
  }

  toggleFilterDialog() {
    const currentStatus = this.filterStatusDD$.getValue();
    this.filterStatusDD$.next(
      currentStatus === 'Filter is Off' ? 'Filter is On' : 'Filter is Off'
    );
  }

  getEditionList(): Observable<any[]> {
    return of(this.editions);
  }

  openEdition(editionId: any): Observable<any> {
    const edition = this.editions.find((ed) => ed.id === editionId);
    return of(edition);
  }

  createNewEdition(pageName: string, pageEdition: string): Observable<any> {
    const newEdition = {
      id: (this.editions.length + 1).toString(),
      name: pageName,
      pageEdition: pageEdition,
    };
    this.editions.push(newEdition);
    return of(newEdition);
  }

  private editions: any[] = [
    {
      id: 1,
      name: 'Default',
      description: 'Default Edition',
      isPrivate: false,
    },
    { id: 2, name: 'Vendor', description: 'Vendor Edition', isPrivate: false },
    {
      id: 3,
      name: 'Consumer',
      description: 'Consumer Edition',
      isPrivate: true,
    },
  ];

  getEditions(): Observable<Edition[]> {
    return of(this.editions);
  }

  saveEdition(edition: Edition): Observable<Edition> {
    const existingEdition = this.editions.find((e) => e.name === edition.name);
    if (existingEdition) {
      Object.assign(existingEdition, edition);
    } else {
      edition.id = this.editions.length + 1;
      this.editions.push(edition);
    }
    return of(edition);
  }

  public pgRows: EventEmitter<any> = new EventEmitter();

  setPageEdition(edition: any) {
    this.edition = edition;
    console.log('From Set Page Edition', edition);
  }
  getEdition() {
    console.log('From Set Page Edition get', this.edition);
    return this.edition;
  }
  setFontStyles(fontStyles: string) {
    this.fontStyles = fontStyles;
  }

  checkColStatus(statuses: []): boolean {
    const rules = ['Item#≥0', '≥1', '≤2', '=2', '≥2']; // TODO: should be replaced with constants

    // Find the first status that includes 'Item'
    const status = statuses.find(
      (status: string) => typeof status === 'string' && status.includes('Item')
    );
    if (status && rules.includes(status)) return true;
    return false;
  }

  isValidRegex(pattern: string) {
    try {
      // Extract the pattern and flags

      var regexMatch = pattern.match(/^\/(.+)\/([igmuy]?)$/);
      if (regexMatch) {
        new RegExp(regexMatch[1], regexMatch[2]);
      } else {
        return false;
      }
      return true; // If no error is thrown, the regex is valid
    } catch (e) {
      return false; // If an error is thrown, the regex is invalid
    }
  }

  transformTokenFormula(formula: string) {
    const text: string = formula.toString();
    const beforeColonMatch = text.match(/^(.*?):/);
    const afterTFormatTCellMatch = text.match(/(tFormat|tCell)\.(\w+)/);

    // Extracted values
    const beforeColon = beforeColonMatch ? beforeColonMatch[1].trim() : null;
    const afterTFormatTCell = afterTFormatTCellMatch ? afterTFormatTCellMatch[2] : null;

    return [ beforeColon, afterTFormatTCell ];
  }

  transformKeyValue(keyValueArray: any[]): { key: string; value: string }[] {
    if (!keyValueArray) return [];
    return keyValueArray.map((obj) => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      return { key, value };
    });
  }

  checkChip(fieldName: string | undefined): boolean {
    if (!fieldName) return false;
    return Object.values(FormFieldsChip).includes(fieldName as FormFieldsChip);
  }

  extractItemValue(items: ItemData[] = []): string {
    return items
      ?.map((item: any) => {
        const value = (item?.item?.[MainService.currentLanguage().langID] ?? item?.item?.[SystemInitials.English]) || item?.item?.[SystemInitials.OriginalUrl] || item?.item;  
        if (typeof value === 'object' && !value?.[SystemInitials.English]) {
          return '';
        } else { return value }
      })
      .join(';') || '';
  }

  getDbObject(parentObjects: SystemObjectDefinitions[], msgPayload: MessagePayload): string {
    const systemObjects = this.store.selectSnapshot(LabelsStore.getSystemObjectDefinitions);

    // Define parent token mapping
    const parentTokenMap: Record<string, string> = {
      [DbObjects.Page]: DbObjects.Page,
      [DbObjects.LocalRow]: DbObjects.Row,
      [DbObjects.LocalColumn]: DbObjects.Column,
      [DbObjects.LocalCell]: DbObjects.Cell,
      [DbObjects.LocalItem]: DbObjects.Item,
    };

    // Get the corresponding parent token
    const parentToken = parentTokenMap[msgPayload.Object];
    if (!parentToken) return '';

    // Find parent object
    const parentObj = parentObjects.find(o => o.token.includes(parentToken));
    if (!parentObj) return '';

    if (msgPayload.Object === DbObjects.Page) return parentObj?.token ?? ''; 

    // Filter objects based on parent row
    const objects: SystemObjectDefinitions[] = systemObjects.filter(obj => obj.ParentRow?.Row === parentObj.row);

    // Find and return the matched object token
    return objects.find(o => o.token.includes(msgPayload.Object))?.token ?? '';
  }

  getMessage(payload: MessagePayload): string {
    const notifications: SystemObjectDefinitions[] = this.labelsFacadeService.getSystemObjects(SystemObjects.Notify);
    const actions: SystemObjectDefinitions[] = this.labelsFacadeService.getSystemObjects(SystemObjects.ActionObjects);
    const dbObjects: SystemObjectDefinitions[] = this.labelsFacadeService.getSystemObjects(SystemObjects.DbObjects);
    const reasons: SystemObjectDefinitions[] = this.labelsFacadeService.getSystemObjects(SystemObjects.Reason);
    const messages: SystemObjectDefinitions[] = this.labelsFacadeService.getSystemObjects(SystemObjects.Message);

    const notify: string = payload.Notify 
      ? notifications.find(n => payload.Notify && n.token.includes(payload.Notify))?.token ?? ''
      : '';
    const reason: string = payload.Reason
      ? reasons.find(r => payload.Reason && r.row.includes(payload.Reason.toString()))?.token ?? ''
      : '';
    const action: string = actions.find(action => action.token.includes(payload.Action))?.token || '';
    const object: string = this.getDbObject(dbObjects, payload);
    const message: string = messages.find(m => m.row.includes(payload.Message.toString()))?.token || '';
    const actioned: SystemObjectDefinitions[] = this.labelsFacadeService.getSystemObjects(action);
    
    return message
      .replace('{Notify}', notify)
      .replace('{Action}', action)
      .replace('{Object}', object)
      .replace('{1}', payload.ID ? payload.ID.toString() : '')
      .replace('{Reason}', reason.replace('{Actioned}', actioned.length > 0 ? actioned[0].token : ''));
  }

  getMenuBarDialogMessage(payload: MessageMenuPayload): string {
    const actions: SystemObjectDefinitions[] = this.labelsFacadeService.getSystemObjects(SystemObjects.ActionObjects);
    const messages: SystemObjectDefinitions[] = this.labelsFacadeService.getSystemObjects(SystemObjects.Message);

    const currentObject: CurrentObjectDefinitions | undefined = this.labelsFacadeService
      .getCurrentObjectDefination(payload.ValueFormulaKey);

    const action: string = actions.find(action => action.token.includes(payload.Action))?.token || '';
    const object: string = currentObject?.labels || '';
    const message: string = messages.find(m => m.row.includes(payload.Message.toString()))?.token || '';
    
    return message
      .replace('{Action}', action)
      .replace('{Object}', object)
      .replace('(ID: {1})', '');
  }

  getDeleteReason(reasonId: number): string {
    const reasons: SystemObjectDefinitions[] = this.labelsFacadeService.getSystemObjects(SystemObjects.Reason);
    const reason: string = reasonId
      ? reasons.find(r => reasonId && r.row.includes(reasonId.toString()))?.token ?? ''
      : '';
    
    return reason;
  }

  getItemId(paylaod: GetItemPaylaod): number | undefined {
    const pgTab = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as PgTab;
    const pgId = Number(pgTab.page_id);
    const colId: number = Number(paylaod.colId);
    const rowId: number = Number(paylaod.rowId);
    const item: string = paylaod.value;
    
    const cell: CellData = this.getCell(pgId, colId, rowId);
    if (!cell) return;
    let targetItems: ItemData[] = [];
    if (cell.hasOwnProperty('cell')) targetItems = cell.cell.items;
    if (cell.hasOwnProperty('format') && cell.format) targetItems = cell.format.items;

    const targetItem = targetItems.find(cellItem => 
      Object.values(cellItem.item)[0]?.trim() === item.trim().replace(/-$/, "")
    ) as ItemData | undefined;
    if (!targetItem) throw new Error(`Target item not found: ${item}`);

    return Number(targetItem.id);
  }
}
