<div class="view-page-container" #viewPageContainer>
  <div class="modal-header" (mousedown)="onDragStart($event)">
    <span class="heading">{{dialogData.title}}</span>
    <div class="close-icon" aria-label="Close" (click)="dialogRef.close()">
      <span aria-hidden="true">{{closeTab}}</span>
    </div>
  </div>
  <div class="edit-item-container enable-scroll" #scrollContainer>
    <div class="minWidth">
      <div *ngIf="((isLoading$ | async))" class="loader-overlay">
        <div class="loader"></div>
      </div>
      <!-- Dynamic Forms -->
      <form [formGroup]="dynamicForm">
        <!-- Input Sections -->
        <div *ngIf="formData.PageData">
          <div class="item-field-container" *ngFor="let field of formData.PageData">
            <div>
              <label class="field-label" for="item-input font-style">{{field.Label}}</label>
            </div>
            <div *ngIf="!checkChip(field.Name)">
              <input type="text" [id]="field" [readonly]="true" [formControlName]="field.Name ?? ''" class="View-field-item">
            </div>
            <div *ngIf="checkChip(field.Name)" class="form-group">
              <div class="custom-input chip-container fixed-width">
                <div class="Chip margin-2px" *ngFor="let value of field.Value?.split(';')">
                  {{ value }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="formData.FormatData">
          <div class="form-group">
            <label class="main-label-item" for="item-input font-style"></label>
          </div>
          <div class="cell-value-container" *ngFor="let field of formData.FormatData">
            <div>
              <label class="field-label" for="item-input font-style">{{ field.Label }}</label>
            </div>
            <div *ngIf="!checkChip(field.Name)">
              <input type="text" [formControlName]="field.Name ?? ''" [readonly]="true" class="View-field-item">
            </div>
            <div *ngIf="checkChip(field.Name)" class="form-group">
              <div class="custom-input chip-container fixed-width">
                <div class="Chip margin-2px" *ngFor="let status of field.Value?.split(';')">
                  {{ status }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="resizer" (mousedown)="onResizeStart($event)">
    <span [innerHTML]="expandIcon"></span>
  </div>
</div>
