@if(pgTabs$ | async; as pgTabs){
  <div class="d-flex tabs-container">
    @for (tab of pgTabs; track $index; let i = $index) {
      @let text = 'Page Name: ' + tab.page_name + '\n' + 'Page Edition: ' + (tab?.page_edition || 'N/A') + '\n' + 'Page Comment: ' + (tab?.page_comment || 'N/A' );
      <div
        class="chip"
        [class.active]="i === currentSheetIndex"
        [appTooltip]="text"
        [name]="tab.page_name"
        (mouseenter)="tooltipService.triggerTooltipFor(tab.page_name)"
        tabindex="{{i}}"
        (keydown.Shift.Tab)="onShiftTabKeyPress($event,tab, i)"
        (keydown.Tab)="onKeyDown($event,tab, i)"
        (click)="onSelectPgTab(tab, i)">
          <span>
            {{ tab | pageName }}
            <sup
              class="cross"
              [innerHTML]="cross_icon" 
              (click)="deleteSheet(i)">
            </sup>
          </span>
      </div>
    }
    <div 
      class="add-sheet d-flex justify-center align-center" 
      (click)="addSheet()">
      <div>
        {{circle}}
      </div>
      <div class="plusIcon">
        {{plusIcon}}
      </div>
    </div>
  </div>
}
