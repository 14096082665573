
<div class="edit-col-container" cdkDropList (cdkDropListDropped)="drop($event)" #popupContainer>
  <div class="modal-header" (mousedown)="onDragStart($event)">
    <div class="modal-title text-nowrap">Column Header</div>
    <div class="close-icon" (click)="onClose()">
      {{ closeTab }}
    </div>
  </div>
  <div class="checkbox-item">
    <div cdkDropList *ngIf="!data.dialog" (cdkDropListDropped)="drop($event)">
      <div *ngFor="let col of columns; let i = index"  cdkDrag class="overflowHidden">
        <div class="col-container">
        <div class="col-item">
          <span class="drag-icon">&#9776;</span> <!-- Unicode character -->
          <input type="checkbox" [checked]="isVisible(col)" (change)="toggleColumnVisibility($event,col)" 
          [disabled]="col.status.includes('Nested')"  class="checkbox-style" tabindex="-1" [ngStyle]="{height: height+'em',width:width+'em'}"/>
          <span class="text-nowrap">{{ col?.title }}</span>
        </div>
        </div>
      </div>
    </div>
    <div cdkDropList *ngIf="data.dialog" (cdkDropListDropped)="drop($event)">
      <div *ngFor="let coldd of columnsDDI; let i = index"  cdkDrag class="overflowHidden">
        <div class="col-container">
        <div class="col-item">
          <span class="drag-icon">&#9776;</span> <!-- Unicode character -->
          <input type="checkbox" [checked]="isVisibleDD(coldd)" (change)="toggleColumnVisibility($event, coldd)" (click)="setDialogContainerWidth()"
          [disabled]="coldd.status.includes('Nested')"  class="checkbox-style" tabindex="-1" [ngStyle]="{height: height+'em',width:width+'em'}"/>
          <span class="text-nowrap">{{ coldd?.title }}</span>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div class="resizer" (mousedown)="onResizeStart($event)">
    <span [innerHTML]="expandIcon"></span>
  </div>
</div>
