import { FormatStateModel } from './format.model';

export const defaultFormatState: FormatStateModel = {
  isLoading: false,
  viewPageData: null,
  viewColumnData: null,
  viewRowData: null,
  viewCellData: null,
  viewItemData: null,
  pageFormatData: null,
  localColumnFormat: null,
  localRowFormat: null,
  localCellFormat: null,
  localItemFormat: null,
  apiStatus: null,
  inputForm: null,
};

export const FormatStateInitial: FormatStateModel = { ...defaultFormatState };
