export const MenuItems : string[] = [
  'Edit Row',
  'Add Prev Row',
  'Add Next Row',
  'Add Child Row',
  'Format Local-Row',
  'Format Shared-Row',
  'Select Row(s)',
  'View Row',
];

export const SelectMenuOptions : string[] = [
  'Format Row(s)',
  'Copy \u2794 Next Row(s)',	
  'Copy \u2794 Child Row(s)',	
  'Move \u2794 Next Row(s)',	
  'Move \u2794 Child Row(s)',	
  'Share \u2794 Next Row(s)',	
  'Share \u2794 Child Row(s)',	
  'Delete Row(s)'
]

export enum ContextActions {
  EDIT_ROW = 'Edit Row',
  ADD_NEXT_ROW = 'Add Next Row',
  ADD_PREV_ROW = 'Add Prev Row',
  ADD_CHILD_ROW = 'Add Child Row',
  DELETE_ROW = 'Delete Row(s)',
  FORMAT_LOCAL_ROW = 'Format Local-Row',
  FORMAT_SHARED_ROW = 'Format Shared-Row',
  SELECT_ROW = 'Select Row(s)',
  VIEW_ROW = 'View Row',

  COPY_NEXT_ROW = 'Copy \u2794 Next Row(s)',	
  COPY_CHILD_ROW = 'Copy \u2794 Child Row(s)',	
  MOVE_NEXT_ROW = 'Move \u2794 Next Row(s)',	
  MOVE_CHILD_ROW = 'Move \u2794 Child Row(s)',	
  SHARE_NEXT_ROW = 'Share \u2794 Next Row(s)',	
  SHARE_CHILD_ROW = 'Share \u2794 Child Row(s)',


}

export enum Page {
  ALL_PAGES = 1000000010,
  ALL_COLS = 1000000060,
}

export enum ROLES {
  Admin = 'System',
  Formator = 'Format',
  Editor = 'Edit'
}

export enum ContextMenu {
  FormatPage = 'Format Page',
  DeletePage = 'Delete Page',
  ViewPage = 'View Page',
  FormatLocalColumn = 'Format Local-Column',
  DeleteColumn = 'Delete Column',
  ViewColumn = 'View Column',
  FormatLocalRow = 'Format Local-Row',
  ViewRow = 'View Row',
  ViewCell = 'View Cell',
  FormatLocalCell = 'Format Local-Cell',
  DeleteItem = 'Delete Item',
  ViewItem = 'View Item',
  FormatLocalItem = 'Format Local-Item',
  
  AddPrevRow = 'Add Previous Row',
  AddChildRow = 'Add Child Row',
  AddNextRow = 'Add Next Row',

  CopyNextRow = 'Copy \u2794 Next Row(s)',	
  CopyChildRow = 'Copy \u2794 Child Row(s)',	
  MoveNextRow = 'Move \u2794 Next Row(s)',	
  MoveChildRow = 'Move \u2794 Child Row(s)',	
  ShareNextRow = 'Share \u2794 Next Row(s)',	
  ShareChildRow = 'Share \u2794 Child Row(s)',

  PageMenu = 'Page Menu',
  ColumnMenu = 'Column Menu',
  RowMenu = 'Row Menu',
  ItemMenu = 'Item Menu'
}

export enum DataTypes {
 ML_TEXT =  "MLText",
 PAGE_ID =  "PageID",
 DROPDOWN =  "DropDown"
}

export enum FormFieldsChip {
  Status = 'Status',
  RowType = 'RowType',
  PageSeo = 'PageSeo',
  PageStatus = 'PageStatus',
  RowStatus = 'RowStatus',
  ColStatus = 'ColStatus'
}

export enum PageItem {
  PAGE_ID = 'page_id',
  PAGE_TYPE = 'page_type'
}

export enum RowTypes {
  COL_ROW = 'Col-Row',
  PG_ROW = 'Pg-Row',
}

export const DEFAULT_CELLDATA = {
  cell: {
    id: '',
    row: '',
    col_id: '',
    col_name: '',
    items: [{
      id : null,
      item : {},
      format : null
    }],
    format: null
  }
}


export const DEFAULT_FORMATDATA = {
  format: {
    items: [{
      id : null,
      item : {},
      format : null
    }],
    format: null
  }
}

export const PAGE_TOKEN = 'All Pages';
export const PAGE_ID_DATA_TYPE = '3000090160';
export const SECTION = 'Section'
