
<div class="editDialog-container" #editdialogcontainer [style.minWidth.px]="headerTextWidth">
    <div class="modal-header" (mousedown)="onDragStart($event)">
      <div class="heading" #headerText>{{data.title}}</div>
    </div>
    <div class="edit-item-container dialog-overflow-container">
      <form [formGroup]="form">
        <div class="fields-container" formArrayName="languageGroups">
        @for(group of languageGroups.controls; track group; let i = $index){
          <div [formGroupName]="i" class="language-group"
            [class.selected-row]="i<languageGroups.controls.length-1">
            <div class="form-group select-language">
              <div class="language-field-container">
              <label for="language{{i}}" class="font-style">Language(s)</label>
              <input 
                (click)="showLanguageDialog(i)"
                formControlName="languageName"
                type="text" 
                id="language{{i}}">
            </div>
            </div>
            <div class="form-group rich-text-editor">
              <div class="text-editor-container">
              <label for="richText{{i}}" class="font-style">Language-specific Rich-Text(s)</label>
              <div>
                <div class="main-container">
                  <div class="editor-container editor-container_classic-editor" #editorContainerElement>
                    <div class="editor-container__editor">
                      <div #editorElement>
                        <app-ck-editor 
                          (onChangeValue)="onValueChange($event,i)" 
                          placeholder="Enter Rich Text"  
                          id="richText{{i}}" 
                          formControlName="richText" 
                          [defaultValue]="defaultEditorValues()[i]"></app-ck-editor>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
            <button
              [ngStyle]="{display: languageGroups.controls.length==1?'none':'flex'}"
              type="button"
              class="close"
              aria-label="Close">
              <div class="cross-icon" (click)="removeLanguageGroup(i)">
                {{closeIcon}}
              </div>
            </button>
          </div>
        }
        <div *ngIf="successMessage$ | async as success" class="custom-success-message">{{ success }}</div>
        <div *ngIf="errorMessage$ | async as error" class="custom-error-message">{{ error }}</div>
        </div>
          <div class="button-group">
            <div class="button-container">
              <button
                class="Chip"
                [disabled]="this.form.pristine
                  || this.form.invalid
                  || (isLoading$ | async)"
                (click)="onSave()">
                Save
              </button>
              </div>
            <div type="button" class="Chip" (click)="onCancel()" >Cancel</div>
          </div>
      </form>
    </div>
    <div class="resizer" (mousedown)="onResizeStart($event)">
      <span [innerHTML]="expandIcon"></span>
    </div>
</div>
@if(isLoading$ | async){
  <div class="loader-overlay">
    <div class="loader">
    </div>
  </div>
}
