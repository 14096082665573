<div class="font-page-container" #fontPageContainer>
  <div class="modal-header" (mousedown)="onDragStart($event)">
    <p class="heading">{{ injectedData.title }}</p>
  </div>
  <div class="font-dialog-container">
    <form class="inner-container" [formGroup]="fontForm" (ngSubmit)="onSave()">
      <app-ck-editor [readOnly]="true" [isMML]="false" [defaultValue]="defaultEditorValues()" formControlName="edit_input"></app-ck-editor>
      <div class="button-group">
        <div class="button-group-container">
          <button type="submit" class="Chip cursor-pointer" >Save</button>
        </div>
        <button class="Chip cursor-pointer" (click)="onSave(dialogActionEnum().CancelClick)">Cancel</button>
      </div>
    </form>
  </div>
  <div class="resizer" (mousedown)="onResizeStart($event)">
    <span [innerHTML]="expandIcon()"></span>
  </div>
</div>
