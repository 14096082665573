import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'labels'
})
export class LabelsPipe implements PipeTransform {
  constructor(){}
  transform(value: string): string {
    if (!value) return '';
    let cleanedString = value.replace(/\{.*?\}/g, '');
    let formattedString = cleanedString.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    return formattedString.trim();
  }
}
