import { MessageStateModel } from './message.model';

export const defaultMessageState: MessageStateModel = {
  isLoading: false,
  success: false,
  statusCode: 0,
  successMsg: '',
  errorMsg: '',
};

export const MessageStateInitial: MessageStateModel = { ...defaultMessageState };