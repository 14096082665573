import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private readonly storageKey = 'pageData'; // Key used in sessionStorage

  constructor() {}

  // Save data for a specific page
  savePageData(tabId: string, data: any): void {
    const pageData = this.getAllAageData();
    pageData[tabId] = data; // Add or update page data
    sessionStorage.setItem(this.storageKey, JSON.stringify(pageData));
  }

  // Get data for a specific page by pageId
  getPageData(tabId: string): any | null {
    const pageData = this.getAllAageData();
    return pageData[tabId] || null; // Return data or null if not found
  }

  // Clear data for a specific page by pageId
  clearPageData(tabId: string): void {
    const pageData = this.getAllAageData();
    delete pageData[tabId]; // Remove page data
    sessionStorage.setItem(this.storageKey, JSON.stringify(pageData));
  }

  // Clear all tab data
  clearAllPages(): void {
    sessionStorage.removeItem(this.storageKey);
  }

  // Get all page data
  private getAllAageData(): Record<string, any> {
    const data = sessionStorage.getItem(this.storageKey);
    return data ? JSON.parse(data) : {};
  }
}
