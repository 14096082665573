import { Component, Inject, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogFontComponent } from '../../../../modals/dialog-font/dialog-font.component';
import { MatDialog } from '@angular/material/dialog';
import { FontSelectServiceService } from '../../../../services/font-select-service/font-select-service.service';
import { PAGE_FORMAT } from '../../../../core/constants/app.contants';
import { PGColDialogComponent } from '../../../../modals/pgcol-dialog/pgcol-dialog.component';

@Component({
  selector: 'app-formate-page-content',
  templateUrl: './format-page-form.component.html',
  styleUrls: ['./format-page-form.component.css'],
})
export class FormatePageFormComponent {
  @Output() formDataChange = new EventEmitter<any>(); // To emit form data to parent
  formatPageForm!: FormGroup;
  formatId!: string; // Assuming this is populated
  pageId!: string; // Assuming this is populated
  @Input() childData: any;
  // Form fields configuration array
  formFields: Array<any> = PAGE_FORMAT;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public service: FontSelectServiceService
  ) {
    this.formatPageForm = this.fb.group({});
  }

  ngOnInit() {
    // Dynamically adding form controls to the form group
    let formGroup: Record<string, any> = {};

    this.formFields.forEach((field) => {
      formGroup[field.label] = [
        { value: field.value ?? '', disabled: field.readonly },
        field.required ? [Validators.required] : [],
      ];
    });

    this.formatPageForm = this.fb.group(formGroup);

    // Setting initial values for formatId and pageId if necessary
    this.formatPageForm.patchValue({
      formatId: this.formatId,
      pageId: this.pageId,
    });

    // Listening for form value changes and emit them to the parent
    this.formatPageForm.valueChanges.subscribe((updatedValues) => {
      this.formDataChange.emit(updatedValues);
    });
  }
  openFontDialog() {
    const dialogRef = this.dialog.open(DialogFontComponent, {
      width: '400px',
      data: this.childData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.formatPageForm = this.fb.group({
        formatUser: [''],
        pageNestedColumn: [''],
        pageFreezeColumn: [''],
        pageExpand: [''],
        pageSort: [''],
        pageFilter: [''],
        pageLevelSet: [''],
        pageSearchSet: [''],
        pageFontStyle: [''],
        pageComment: [''],
        pageTransactions: [''], // For handling transaction list
      });
    });
  }
  openColDialog() {
    const dialogRef = this.dialog.open(PGColDialogComponent, {
      width: '750px',
      data: {selectedId: '1000000001'},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
