import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, shareReplay, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  private activeTooltipSubject = new Subject<string | null>();
  activeTooltip$ = this.activeTooltipSubject.asObservable();

  private triggerTooltip$ = new Subject();
  getTriggerTooltip$ = this.triggerTooltip$.asObservable().pipe(shareReplay());

  private saveLastHTMLElement$ = new Subject();
  getLastHTMLElement$ = this.saveLastHTMLElement$.asObservable();

  private triggerTooltipFor$ = new Subject();
  getIdFOrToolTip$ = this.triggerTooltipFor$.asObservable();

  private destroyToolTip$ =  new Subject<void>();
  destroyTooltip$ = this.destroyToolTip$.asObservable().pipe(shareReplay());;

  triggerTooltipFor(id : string) {
    this.triggerTooltipFor$.next(id);
  }

  destroyToolTip() {
    this.destroyToolTip$.next();
  }


  saveHtmlElement(value : any){
    this. saveLastHTMLElement$.next(value);
  }

  triggerTooltip(value : any){
    this.triggerTooltip$.next(value);
  }

  showTooltip(tooltipId: string) {
    this.activeTooltipSubject.next(tooltipId);
  }

  hideTooltip() {
    this.activeTooltipSubject.next(null);
  }
}
