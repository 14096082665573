import { Injectable } from '@angular/core';
import { ObjectFilterKey } from '../enums/pg-tabs/pg-tabs.enum';
import { TabCurrentLabels } from '../interfaces/session.interface';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {

  constructor() {}

  saveTabsCurrentObjects(data : TabCurrentLabels){
    sessionStorage.setItem(ObjectFilterKey.TAB, JSON.stringify(data));
  }

  saveSessionCurrentObjects(data : TabCurrentLabels){
    sessionStorage.setItem(ObjectFilterKey.SESSION, JSON.stringify(data));
  }

  saveUserCurrentObjects(data : TabCurrentLabels){
    sessionStorage.setItem(ObjectFilterKey.USER, JSON.stringify(data));
  }

  isDataInSession(key : ObjectFilterKey){
    return sessionStorage.getItem(key);
  }

  getData(key : ObjectFilterKey){
    const data = sessionStorage.getItem(key);
    return data ? JSON.parse(data) : [];
  }

  clearData(key: string){
    sessionStorage.removeItem(key);
  }

  clearAll(){
    sessionStorage.clear();
  }
}
