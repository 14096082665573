import { CopyRowResponse } from "../../shared/models/row/row.models";

export interface FormatResponse<T = Record<string, any>> {
  success: boolean;
  data: {
    createdPage?: any;
    UpdatedFormatRecord: T;
    createdRow? : any;
    createdCol? : any;
    updatedCell? : {
      [key : string] : string
    };
    createdItem? :  any;
    updatedItem? : any;
  };
  error: string;
  message: string;
  statusCode: number;
}

export interface ApiResponse {
  success : boolean,
  data : any,
  errorMsg: string,
  statusCode: number
}

export interface RowResponse {
  success : boolean,
  data : CopyRowResponse,
  errorMsg: string,
  statusCode: number
}

export enum AllObjects {
  CurrentObjects = 'Current Objects',
  SystemObjects = 'System Objects'
}

