import { All_Pages_ID } from "../../core/constants/app.contants";
import { PgTab, PgTabStateModel } from "./pg-tab.model";
export const DefaultPageState : PgTab = {
  page_id: "",
  page_name: "",
  page_edition: "",
  page_comment: ""
}
export const PageTabState: PgTabStateModel = {
  pgTabs: [],
  selectedPgTab: DefaultPageState,
  defaultPgTab: DefaultPageState,
  allTabsData : [],
  defaultPageId : Number(All_Pages_ID),
  loading: false,
  error: null,
  sheetData: [],
  sheetColumns: [],
  fontStyle: '',
  languages: [],
};

