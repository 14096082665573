import { TabulatorEvents } from "../enums/tabulator-events/events";

export const UNICODE_RELOAD = '\u21BB';
export const UNICODE_LANGUAGE = '\u6587';
export const UNICODE_GLOBE = '\uD83C\uDF10';
export const UNICODE_LEFT_RIGHT_ARROW = '\u2B0D';
export const UNICODE_MULTIPLY = '\u00D7';
export const UNICODE_PLUS = '\uFF0B';
export const UNICODE_CIRCLE = '\u25EF';
export const UNICODE_DASH = '\u2212';
export const UNICODE_PLUS_CODE = '\u002B';
export const UNICODE_UP_ARROW = '\u2BC5';
export const UNICODE_DOWN_ARROW = '\u2BC6';
export const ASCII_SPACE = '&nbsp;';
export const ASCII_PLUS = '&#43;';
export const ASCII_DASH = '&#45;';
export const ARROW_UP_DOWN = '\u2191\u2193';

export const All_Pages_ID = '1000000010';
export const All_Labels_ID = '1000000100';
export const DDL_All_Pages = 'All Pages';
export const DDL_All_DDL_Pages = 'All DDL Pages';
export const DDL_Languages = 'All Languages';
export const DDL_Regions = 'All Regions';
export const Countries = 'Countries';
export const DDL_Currencies = 'Currencies';
export const DDL_Statuses = 'Statuses';
export const DDL_Page_Edition = 'Page Editions';
export const DDL_Page_Modes = 'Page Modes';

export const COL_IDS = {
  COL_COMMENT: 2000000610,
  PAGE_COMMENT: 2000000430,
  ROW_COMMENT: 2000000060,
  COL_STATUS: 2000000590,
  ROW_STATUS: 2000000050
}

export const FORMAT_FORM = {
  Format: 'Format',
  User: 'User',
  PgId: 'PgId',
  PgNestedCol: 'PgNestedCol',
  PgFreezCol: 'PgFreezeCol',
  PgExpand: 'PgExpand',
  PgSort: 'PgSort',
  PgFilter: 'PgFilter',
  PgLevelSet: 'PgLevelSet',
  PgSearchSet: 'PgSearchSet',
  ColId: 'ColId',
  RowId: 'RowId',
  Row: 'Row',
  PageId: 'PageId',
  CellId: 'CellId',
  Default: 'Default',
  CellDDL: 'CellDDL',
  Object: 'Object',
  ObjectType: 'ObjectType',
  ItemId: 'ItemId',
  Status: 'Status',
  Formula: 'Formula',
  FontStyle: 'FontStyle',
  ColMinWidth: 'ColMinWidth',
  Comment: 'Comment',
  CommentObject: 'CommentObject',
  TxList: 'TxList',
};

export const SELECT_COL = {
    col: "1",
    datatype: {
        "1": "SelectType"
    },
    status: [
        "System",
        "Share",
        "Unique",
        "DDL-Col",
        "Item#=1"
    ],
    headerSort:false,
    resizable: false,
    frozen:false,
    field: 'select',
    cssClass: 'select-box',
    formatter: TabulatorEvents.RowSelection,
    titleFormatter: TabulatorEvents.RowSelection,
  }

export const ObjectType = {
  DataObject: {
    Page: 'Page',
    Column: 'Column',
    Row: 'Row',
    Cell: 'Cell',
    Item: 'Item',
  },
};

export const ASCII_CROSS_ICON = '&#120;';
export const UNICODE_CROSS_ICON = '\u{1F5D9}';
export const UNICODE_ADD_ICON = '&#8853';
export const ASCII_EXPAND_ICON = '&#x25E2;';
export const UNICODE_EXPAND_ICON = '&#9698;';
export const ENGLISH = '3000000100';

export const FontStyle = {
  Normal: 'normal',
  Italic: 'italic'
}
export const FontWeight = {
  Bold: 'bold'
}
export const TextDecoration = {
  LineThrough: 'line-through',
  Underline: 'underline'
}
export const TextAlign = {
  Left: 'left',
  Rigth: 'right',
  Justify: 'justify'
}
export const FONT_STYLE = {
  AsianFontFamily: 'asian-font-family',
  FontStyle: 'font-style',
  FontWeight: 'font-weight',
  FontSize: 'font-size',
  FontFamily: 'font-family',
  FontColor: 'color',
  BackgroundColor: 'background-color',
  TextAlign: 'text-align',
  TextDecoration: 'text-decoration',
  TextWrap: 'text-wrap',
  Border: 'border',
  Effects: {
    LineThrough: TextDecoration.LineThrough,
    Underline: TextDecoration.Underline,
    Superscript: 'sup',
    Subscript: 'sub',
    Italic: FontStyle.Italic,
    Bold: FontWeight.Bold,
    TextWrap: 'wrap',
    Border: '1px solid'
  }
}
