import { AfterViewInit, DestroyRef, Directive, ElementRef, HostListener, inject, Input, OnInit, Renderer2 } from '@angular/core';
import { TooltipService } from '../../services/tooltip-service/tooltip.service';
import { combineLatest, distinctUntilChanged, forkJoin, last, of, switchMap, takeLast, withLatestFrom } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnInit {
  destroyRef = inject(DestroyRef);
  private _id: string = '';
  @Input('appTooltip') tooltipContent: string = '';
   @Input()
   get name(): string {
     return this._id;
   }
   set name(value: string) {
     this._id = value;
   }
  private tooltipElement: HTMLElement | null = null;
  private showTimeout: any;
  private hideTimeout: any;
  constructor(private elementRef: ElementRef, private renderer: Renderer2, private tooltipService: TooltipService) {}
  ngOnInit(): void {
    this.tooltipService.getTriggerTooltip$.pipe(
      takeUntilDestroyed(this.destroyRef),
      distinctUntilChanged(),
      withLatestFrom(this.tooltipService.getIdFOrToolTip$),
      switchMap(([toolTip, id]) => {
        if (id === this._id) {
          this.createTooltip(toolTip);
          this.handleTimeout(1000)
        } else {
          this.destroyTooltip();
        }
        return of([toolTip, id]);
      })
    ).subscribe();

    this.tooltipService.destroyTooltip$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(()=>this.destroyTooltip())
  }

  @HostListener('mouseenter') onMouseEnter() {
    clearTimeout(this.hideTimeout);
    this.showTimeout = setTimeout(() => this.createTooltip(), 500);
  }

  @HostListener('mouseleave') onMouseLeave() {
    clearTimeout(this.showTimeout);
    if (!this.isMouseOverTooltip()) {
      this.destroyTooltip();
    }
  }

  private createTooltip(message?: any) {
    if (this.tooltipElement || !this.tooltipContent) return;

    this.tooltipElement = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltipElement, 'custom-tooltip');

    const preElement = this.renderer.createElement('p');
    this.renderer.appendChild(preElement, this.renderer.createText(`${message ?? this.tooltipContent}`));
    this.renderer.appendChild(this.tooltipElement, preElement);

    const hostPosition = this.elementRef.nativeElement.getBoundingClientRect();
    this.renderer.setStyle(this.tooltipElement, 'top', `${hostPosition.bottom + window.scrollY}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${hostPosition.left + window.scrollX}px`);

    this.renderer.setStyle(this.tooltipElement, 'opacity', '1');

    if (this.tooltipElement) {
      document.body.appendChild(this.tooltipElement);

      this.renderer.listen(this.tooltipElement, 'mouseenter', () => {
        clearTimeout(this.hideTimeout);
      });

      this.renderer.listen(this.tooltipElement, 'mouseleave', () => {
        this.hideTimeout = setTimeout(() => this.destroyTooltip(), 500);
      });
    }
  }


  private isMouseOverTooltip() {
    return this.tooltipElement && document.querySelector('.custom-tooltip:hover') !== null;
  }

  private destroyTooltip() {
    if (this.tooltipElement) {
      this.renderer.setStyle(this.tooltipElement, 'opacity', '0');
      setTimeout(() => {
        if (this.tooltipElement) {
          document.body.removeChild(this.tooltipElement);
          this.tooltipElement = null;
        }
      }, 300);
    }
  }

  private async handleTimeout(delay: number) {
    await this.waitForTimeout(delay);
    this.destroyTooltip();
  }

  private waitForTimeout(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

}
