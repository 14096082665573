import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  FormatStateModel,
  ViewColumnData,
  ViewRowData,
  ViewCellData,
  ViewItemData,
  ViewPageData,
  LocalFormatData,
} from './format.model';
import { FormatStateInitial } from './format.state';
import { Format } from './format.actions';
import { catchError, of, tap, throwError } from 'rxjs';
import { FormatService } from '../../core/services/format.service';
import { PgTabStore } from '../pg-tab/pg-tab.store';
import { PgTab } from '../pg-tab/pg-tab.model';
import { SheetState } from '../page/page.store';
import { MainService } from '../../core/services/main-service/main.service';
import { Sheet } from '../page/page.actions';
import { UpdatePgTabs } from '../pg-tab/pg-tab.actions';
import { GetCellPaylaod } from '../../shared/models/format/format.models';
import { COL_IDS, FORMAT_FORM } from '../../core/constants/app.contants';
import { SystemInitials } from '../../constant';

@State<FormatStateModel>({
  name: 'format',
  defaults: FormatStateInitial,
})
@Injectable()
export class FormatStore {
  constructor(
    public formatService: FormatService, 
    private store: Store,
    private mainService: MainService
  ) {}

  @Selector()
  static viewPageData(state: FormatStateModel): ViewPageData | null {
    return state.viewPageData;
  }

  @Selector()
  static viewColumnData(state: FormatStateModel): ViewColumnData | null {
    return state.viewColumnData;
  }

  @Selector()
  static viewRowData(state: FormatStateModel): ViewRowData | null {
    return state.viewRowData;
  }

  @Selector()
  static viewCellData(state: FormatStateModel): ViewCellData | null {
    return state.viewCellData;
  }

  @Selector()
  static viewItemData(state: FormatStateModel): ViewItemData | null {
    return state.viewItemData;
  }

  @Selector()
  static isLoading(state: FormatStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static getPageFormat(state: FormatStateModel): LocalFormatData | null {
    return state.pageFormatData;
  }

  @Selector()
  static getLocalColumnFormat(state: FormatStateModel): LocalFormatData | null {
    return state.localColumnFormat;
  }

  @Selector()
  static getLocalRowFormat(state: FormatStateModel): LocalFormatData | null {
    return state.localRowFormat;
  }

  @Selector()
  static getLocalCellFormat(state: FormatStateModel): LocalFormatData | null {
    return state.localCellFormat;
  }

  @Selector()
  static getLocalItemFormat(state: FormatStateModel): LocalFormatData | null {
    return state.localItemFormat;
  }

  @Action(Format.ViewPage)
  ViewPage(ctx: StateContext<FormatStateModel>, action: Format.ViewPage) {
    ctx.patchState({ isLoading: true });
    const pageId = this.store.selectSnapshot(PgTabStore.getCurrentPgId) as any;
    const id = typeof pageId === 'object' ? Number(pageId.page_id) : pageId;
    return this.formatService.viewPage(id).pipe(
      tap((data: any) => {
        ctx.patchState({
          viewPageData: data.data,
          isLoading: false,
        });
      }),
      catchError((error) => {
        ctx.patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(Format.ViewColumn)
  GetColumn(ctx: StateContext<FormatStateModel>, action: Format.ViewColumn) {
    ctx.patchState({ isLoading: true });
    return this.formatService.viewColumn(action.col).pipe(
      tap((data: any) => {
        ctx.patchState({
          viewColumnData: data.data,
          isLoading: false,
        });
      }),
      catchError((error) => {
        ctx.patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(Format.ViewRow)
  GetRow(ctx: StateContext<FormatStateModel>, action: Format.ViewRow) {
    ctx.patchState({ isLoading: true });
    return this.formatService.viewRow(action.rowId).pipe(
      tap((data: any) => {
        ctx.patchState({
          viewRowData: data.data,
          isLoading: false,
        });
      }),
      catchError((error) => {
        ctx.patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(Format.ViewCell)
  GetCell(ctx: StateContext<FormatStateModel>, action: Format.ViewCell) {
    ctx.patchState({ isLoading: true });
    return this.formatService.viewCell(action.colId, action.rowId).pipe(
      tap((data: any) => {
        ctx.patchState({
          viewCellData: data.data,
          isLoading: false,
        });
      }),
      catchError((error) => {
        ctx.patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(Format.ViewItem)
  GetItem(ctx: StateContext<FormatStateModel>, action: Format.ViewItem) {
    ctx.patchState({ isLoading: true });
    return this.formatService.viewItem(action.itemId).pipe(
      tap((data: any) => {
        ctx.patchState({
          viewItemData: data.data,
          isLoading: false,
        });
      }),
      catchError((error) => {
        ctx.patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(Format.UpdatePage)
  UpdatePage(ctx: StateContext<FormatStateModel>, action: Format.UpdatePage) {
    ctx.patchState({ isLoading: true });
    const pgId: number = this.getPgId();
    if (!pgId) throw new Error('page_id is not accessible in the state');

    return this.formatService.updatePage(pgId, action.payload).pipe(
      tap((data: any) => {
        this.setApiStatus(data, ctx);
        catchError((error) => {
          ctx.patchState({
            isLoading: false,
            apiStatus: {
              success: false,
              message: 'Operation faild!',
            }
          });
          return throwError(() => error);
        });
      })
    );
  }

  @Action(Format.UpdateColumn)
  UpdateColumn(
    ctx: StateContext<FormatStateModel>,
    action: Format.UpdateColumn
  ) {
    ctx.patchState({ isLoading: true });
    const pgId: number = this.getPgId();
    if (!pgId) throw new Error('page_id is not accessible in the state');

    return this.formatService.updateColumn(pgId, action.colId, action.payload)
      .pipe(
        tap((data: any) => {
          this.setApiStatus(data, ctx);
          catchError((error) => {
            ctx.patchState({
              isLoading: false,
              apiStatus: {
                success: false,
                message: 'Operation faild!',
              },
            });
            return throwError(() => error);
          });
        })
      );
  }

  @Action(Format.UpdateRow)
  UpdateRow(ctx: StateContext<FormatStateModel>, action: Format.UpdateRow) {
    ctx.patchState({ isLoading: true });
    const pgId: number = this.getPgId();
    if (!pgId) throw new Error('page_id is not accessible in the state');

    return this.formatService.updateRow(pgId, action.rowId, action.payload)
      .pipe(
        tap((data: any) => {
          this.setApiStatus(data, ctx);
          catchError((error) => {
            ctx.patchState({
              isLoading: false,
              apiStatus: {
                success: false,
                message: 'Operation faild!',
              },
            });
            return throwError(() => error);
          });
        })
      );
  }

  @Action(Format.UpdateCell)
  UpdateCell(ctx: StateContext<FormatStateModel>, action: Format.UpdateCell) {
    ctx.patchState({ isLoading: true });
    const pgId: number = this.getPgId();
    const colId = action.colId;
    const rowId = action.rowId;
    if (!pgId) throw new Error('Page ID is not accessible in the state');
    if (!colId) throw new Error('Column ID is not accessible in the state');
    if (!rowId) throw new Error('Row ID is not accessible in the state');

    return this.formatService
      .updateCell(
        pgId,
        action.colId,
        action.rowId,
        action.payload
      )
      .pipe(
        tap((data: any) => {
          this.setApiStatus(data, ctx);
          catchError((error) => {
            ctx.patchState({
              isLoading: false,
              apiStatus: {
                success: false,
                message: 'Operation faild!',
              },
            });
            return throwError(() => error);
          });
        })
      );
  }

  @Action(Format.UpdateItem)
  UpdateItem(ctx: StateContext<FormatStateModel>, action: Format.UpdateItem) {
    ctx.patchState({ isLoading: true });
    if (!action.itemId) throw new Error('Item ID is not accessible in the state');

    return this.formatService.updateItem(action.itemId, action.payload)
      .pipe(
        tap((data: any) => {
          this.setApiStatus(data, ctx);
          catchError((error) => {
            ctx.patchState({
              isLoading: false,
              apiStatus: {
                success: false,
                message: 'Operation faild!',
              },
            });
            return throwError(() => error);
          });
        })
      );
  }

  @Action(Format.GetPage)
  async GetPage(ctx: StateContext<FormatStateModel>, action: Format.GetPage) {
    ctx.patchState({ isLoading: true });
    const pgId: number = this.getPgId();
    if (!pgId) throw new Error('page_id is not accessible in the state');

    return this.formatService.getPageFormat(pgId).pipe(
      tap((data: any) => {
        ctx.patchState({
          pageFormatData: data.data,
          isLoading: false,
        });
      }),
      catchError((error) => {
        ctx.patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(Format.GetLocalColumn)
  async GetLocalColumn(
    ctx: StateContext<FormatStateModel>,
    action: Format.GetLocalColumn
  ) {
    ctx.patchState({ isLoading: true });
    const colId = action.colId;
    if (!colId) throw new Error('Column ID is not accessible in the state');

    return this.formatService.getLocalColumn(colId).pipe(
      tap((data: any) => {
        ctx.patchState({
          localColumnFormat: data.data,
          isLoading: false,
        });
      }),
      catchError((error) => {
        ctx.patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(Format.GetLocalRow)
  async GetLocalRow(
    ctx: StateContext<FormatStateModel>,
    action: Format.GetLocalRow
  ) {
    ctx.patchState({ isLoading: true });
    const pgId: number = this.getPgId();
    const rowId = action.rowId;
    if (!pgId) throw new Error('Page ID is not accessible in the state');
    if (!rowId) throw new Error('Row ID is not accessible in the state');

    return this.formatService.getLocalRow(rowId).pipe(
      tap((data: any) => {
        ctx.patchState({
          localRowFormat: data.data,
          isLoading: false,
        });
      }),
      catchError((error) => {
        ctx.patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(Format.GetLocalCell)
  async GetLocalCell(
    ctx: StateContext<FormatStateModel>,
    action: Format.GetLocalCell
  ) {
    ctx.patchState({ isLoading: true });
    const pgId: number = this.getPgId();
    const colId = action.colId;
    const rowId = action.rowId;
    if (!pgId) throw new Error('Page ID is not accessible in the state');
    if (!colId) throw new Error('Column ID is not accessible in the state');
    if (!rowId) throw new Error('Row ID is not accessible in the state');

    return this.formatService.getLocalCell(colId, rowId).pipe(
      tap((data: any) => {
        ctx.patchState({
          localCellFormat: data.data,
          isLoading: false,
        });
      }),
      catchError((error) => {
        ctx.patchState({ isLoading: false });
        return throwError(() => error);
      })
    );
  }

  @Action(Format.GetLocalItem)
  async GetLocalItem(
    ctx: StateContext<FormatStateModel>,
    action: Format.GetLocalItem
  ) {
    ctx.patchState({ isLoading: true });
    const itemId = action.itemId;
    if (!itemId) {
      ctx.patchState({ isLoading: false });
      throw new Error('Item ID is not accessible in the state');
    }
    return this.formatService.getLocalItem(itemId).pipe(
      tap((data: any) => {
        ctx.patchState({
          localItemFormat: data.data,
          isLoading: false,
        });

        catchError((error) => {
          ctx.patchState({ isLoading: false });
          return throwError(() => error);
        })
      })
    );
  }

  @Action(Format.DeleteColumn)
  DeleteColumn(
    ctx: StateContext<FormatStateModel>,
    action: Format.DeleteColumn
  ) {
    ctx.patchState({ isLoading: true });
    const pgId: number = this.getPgId();
    const colId = action.colId;
    const payload = action.payload;
    if (!pgId) throw new Error('Page ID is not accessible in the state');
    if (!colId) throw new Error('Column ID is not accessible in the state');

    return this.formatService.deleteColumn(pgId, colId, payload).pipe(
      tap((data: any) => {
        this.setApiStatus(data, ctx);
        ctx.patchState({isLoading: false}),

        catchError((error) => {
          ctx.patchState({ isLoading: false });
          return throwError(() => error);
        })
      })
    );
  }

  @Action(Format.DeleteRow)
  DeleteRow(ctx: StateContext<FormatStateModel>, action: Format.DeleteRow) {
    ctx.patchState({ isLoading: true });
    const pgId: number = this.getPgId();
    const rowId = action.rowId;
    const payload = action.payload;
    if (!pgId) throw new Error('Page ID is not accessible in the state');
    if (!rowId) throw new Error('Row ID is not accessible in the state');

    return this.formatService.deleteRow(pgId, rowId, payload).pipe(
      tap((data: any) => {
        this.setApiStatus(data, ctx);
        ctx.patchState({isLoading: false}),

        catchError((error) => {
          ctx.patchState({ isLoading: false });
          return throwError(() => error);
        })
      })
    );
  }

  @Action(Format.DeleteItem)
  DeleteItem(ctx: StateContext<FormatStateModel>, action: Format.DeleteItem) {
    ctx.patchState({ isLoading: true });
    const pgId: number = this.getPgId();
    const itemId: number = action.itemId;
    const payload = action.payload;
    if (!pgId) throw new Error('Page ID is not accessible in the state');

    return this.formatService.deleteItem(itemId, payload).pipe(
      tap((data: any) => {
        this.setApiStatus(data, ctx);
        ctx.patchState({isLoading: false}),

        catchError((error) => {
          ctx.patchState({ isLoading: false });
          return throwError(() => error);
        })
      })
    );
  }

  @Action(Format.DeletePageSuccess)
  DeletePageSuccess(ctx: StateContext<FormatStateModel>, action: Format.DeletePageSuccess) {
    const state = ctx.getState();

    const pgId: number = action.pgId;
    const pgTabs = this.store.selectSnapshot(PgTabStore.getPgTabs);
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    const allTabsFlatData = this.store.selectSnapshot(
      SheetState.getSheetFlatData
    );

    // Find the index of the tab to delete and determine the next tab
    const currentTabIndex = pgTabs.findIndex((tab) => Number(tab.page_id) === pgId);
    const nextTabIndex = pgTabs.findIndex((tab, index) => index === currentTabIndex + 1)
    const nextTab: PgTab | undefined = nextTabIndex !== -1 ? pgTabs.at(nextTabIndex) : pgTabs.at(0);

    // Update pg tab state
    ctx.dispatch(
      new UpdatePgTabs(
        pgTabs.filter((tab) => Number(tab.page_id) !== pgId),
        Number(nextTab?.page_id),
      )
    );

    // Update page state
    const allTabsUpdatedData = this.mainService.mapDeletePageDataById(pgId, allTabsData);
    const allTabsUpdatedFlatData = this.mainService.mapDeletePageDataById(pgId, allTabsData);
    ctx.dispatch(
      new Sheet.UpdatePageData({
        allTabsData: allTabsUpdatedData,
        allTabsFlatData: allTabsUpdatedFlatData,
        defaultPageId: Number(nextTab?.page_id),
      })
    );

    // Get updated state
    const updatedState = ctx.getState();
  }

  @Action(Format.UpdatePageSuccess)
  UpdatePageSuccess(ctx: StateContext<FormatStateModel>, action: Format.UpdatePageSuccess) {
    const getCellPayload: GetCellPaylaod = action.payload;
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);

    if (getCellPayload.value) {
      const updatedAllTabsData = this.mainService.updateCell(getCellPayload, allTabsData);
      this.store.dispatch(new Sheet.UpdatePageData({
        allTabsData: updatedAllTabsData,
        defaultPageId: getCellPayload.pgId,
      }));
    }
  }

  @Action(Format.UpdateRowSuccess)
  UpdateRowSuccess(ctx: StateContext<FormatStateModel>, action: Format.UpdateRowSuccess) {
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
    let updatedAllTabsData = this.mainService.updateCell(action.comment, allTabsData);
    if (updatedAllTabsData) {
      this.mainService.updateCell(action.status, updatedAllTabsData);
    }

    this.store.dispatch(new Sheet.UpdatePageData({
      allTabsData: updatedAllTabsData,
      allTabsFlatData: allTabsFlatData,
      defaultPageId: action.comment.pgId,
    }));
  }

  @Action(Format.UpdateColumnSuccess)
  updateColumnSuccess(ctx: StateContext<FormatStateModel>, action: Format.UpdateColumnSuccess) {
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);
    let updatedAllTabsData = this.mainService.updateCell(action.comment, allTabsData);
    if (updatedAllTabsData) {
      this.mainService.updateCell(action.status, updatedAllTabsData);
    }
    
    this.store.dispatch(new Sheet.UpdatePageData({
      allTabsData: updatedAllTabsData,
      allTabsFlatData: allTabsFlatData,
      defaultPageId: this.getPgId(),
    }));
  }

  @Action(Format.UpdateCellSuccess)
  UpdateCellSuccess(ctx: StateContext<FormatStateModel>, action: Format.UpdateCellSuccess) {
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    let updatedAllTabsData = this.mainService.updateCellFormatStatus(action.status, allTabsData);
    
    this.store.dispatch(new Sheet.UpdatePageData({
      allTabsData: updatedAllTabsData,
      defaultPageId: this.getPgId(),
    }));
  }

  @Action(Format.UpdateItemSuccess)
  UpdateItemSuccess(ctx: StateContext<FormatStateModel>, action: Format.UpdateItemSuccess) {
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    let updatedAllTabsData = this.mainService.updateItemFormatStatus(action.status, allTabsData);
    
    this.store.dispatch(new Sheet.UpdatePageData({
      allTabsData: updatedAllTabsData,
      defaultPageId: this.getPgId(),
    }));
  }

  @Action(Format.DeleteRowSuccess)
  DeleteRowSuccess(ctx: StateContext<FormatStateModel>, action: Format.DeleteRowSuccess) {
    const selectedPgTab = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as PgTab;
    const selectedPgId = Number(selectedPgTab.page_id);
    const targetRowPgId = action.pgId;
    const rowId = action.rowId;
    const pgTabs = this.store.selectSnapshot(PgTabStore.getPgTabs);
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);
    const allTabsFlatData = this.store.selectSnapshot(SheetState.getSheetFlatData);

    // Update pg tab state
    ctx.dispatch(
      new UpdatePgTabs(
        pgTabs.filter((tab) => Number(tab.page_id) !== Number(targetRowPgId)),
        Number(targetRowPgId),
      )
    );

    this.store.dispatch(
      new Sheet.UpdatePageData({
        allTabsData: this.mainService.deleteRow(selectedPgId, rowId, allTabsData),
        defaultPageId: Number(selectedPgId),
      })
    );

    // Delete page column from store
    //this.mainService.deleteRow(pgId, action.rowId, allTabsData);
  }

  @Action(Format.DeleteItemSuccess)
  DeleteItemSuccess(ctx: StateContext<FormatStateModel>, action: Format.DeleteItemSuccess) {
    const selectedPgTab = this.store.selectSnapshot(PgTabStore.getSelectedPgTab) as PgTab;
    const selectedPgId = Number(selectedPgTab.page_id);
    const allTabsData = this.store.selectSnapshot(SheetState.getAllTabsData);

    this.store.dispatch(
      new Sheet.UpdatePageData({
        allTabsData: this.mainService.deleteCellItem(action.paylaod, allTabsData),
        defaultPageId: Number(selectedPgId),
      })
    );
  }

  @Action(Format.DeletePage)
  DeletePage(ctx: StateContext<FormatStateModel>, action: Format.DeletePage) {
    ctx.patchState({ isLoading: true });
    const pgId: number = this.getPgId();
    const payload = action.payload;
    if (!pgId) throw new Error('Page ID is not accessible in the state');

    return this.formatService.deletePage(pgId, payload).pipe(
      tap((data: any) => {
        this.setApiStatus(data, ctx);
        ctx.patchState({isLoading: false}),

        catchError((error) => {
          ctx.patchState({ isLoading: false });
          return throwError(() => error);
        })
      })
    );
  }

  async setApiStatus(data: any, ctx: StateContext<FormatStateModel>) {
    if (data.success) {
      ctx.patchState({
        isLoading: false,
        apiStatus: {
          success: true,
          message: 'Operation completed successfully',
        },
      });
    } else {
      ctx.patchState({
        isLoading: false,
        apiStatus: {
          success: false,
          message: data.errorMsg,
        },
      });
    }
  }

  getPgId(): number {
    // select the snapshot state from PgTabStore
    const pgTab = this.store.selectSnapshot(
      PgTabStore.getSelectedPgTab
    ) as PgTab;
    const pgId = Number(pgTab.page_id);

    return pgId;
  }
}
