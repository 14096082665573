import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Input,
  AfterViewInit,
  HostListener,
  ChangeDetectorRef,
  inject,
  DestroyRef,
  WritableSignal,
  signal,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../../../modals/page.modal';
import { MainService } from '../../../core/services/main-service/main.service';
import { FormatPageComponent } from '../format-page-dialog/format-page/format-page-main.component';
import { DialogDeleteComponent } from '../dialog-delete/dialog-delete.component';
import { PGColDialogComponent } from '../../../modals/pgcol-dialog/pgcol-dialog.component';
import { PGUserDialogComponent } from '../../../modals/pguser-dialog/pguser-dialog.component';
import { Select, Store } from '@ngxs/store';
import { SheetState } from '../../../store/page/page.store';
import { filter } from 'rxjs/operators';
import { Observable, Subject, takeUntil, map} from 'rxjs';
import { Sheet } from '../../../store/page/page.actions';
import { AutoAdjustWidthDirective } from '../../../shared/directives/auto-adjust-width.directive';
import { editDdDialogMainComponent } from '../../../shared/components/edit-dd-dialog/edit-dd-dialog-main/edit-dd-dialog-main.component';

import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import {
  All_Pages_ID,
  DDL_All_Pages,
  DDL_Currencies,
  DDL_Languages,
  DDL_Page_Edition,
  DDL_Page_Modes,
  DDL_Regions,
  ObjectType,
  UNICODE_GLOBE,
  UNICODE_LANGUAGE,
  UNICODE_LEFT_RIGHT_ARROW,
  UNICODE_RELOAD,
} from '../../../core/constants/app.contants';
import { DialogViewComponent } from '../dialog-view/dialog-view.component';
import {
  DDResponse,
  RowCurrencyData,
  RowLanguageData,
  RowPageDataWithoutFormat,
  RowRegionData,
} from '../../models/edit-dd-dailogs/dd-dailog.models';
import { TooltipService } from '../../../core/services/tooltip.service';
import {
  AddPgTab,
  AutoTranslation,
  SetFontStyle,
} from '../../../store/pg-tab/pg-tab.actions';
import { PgTab } from '../../../store/pg-tab/pg-tab.model';
import { FormBuilder } from '@angular/forms';
import { PAGE_IDS } from '../../../constant';
import { ContextMenu, SECTION } from '../../../core/constants/menu-bar/page/page';
import { GetValueFromKeyPipe } from '../../pipes/get-value-from-key.pipe';
import { MenuBarFacadeService } from '../../../core/services/menu-bar/menu-bar-facade.service';
import { LabelsFacadeService } from '../../../core/services/labels/labels-facade.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LabelsKey } from '../../../core/interfaces/labels/labels.iterface';
import { Actions, DbObjects, Message, Reason } from '../../../core/enums/tokens/tokens.enum';
import { getLanguageCodeFromLanguage, getValueFromObject } from '../../../utils/utils';
import { DEFAULT_LANGUAGE, Language } from '../../../core/interfaces/language.interface';
@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
  providers : [GetValueFromKeyPipe]
})
export class MenuBarComponent implements OnInit, AfterViewInit {
  destroyRef = inject(DestroyRef);
  @Input() sheetSelected: any;
  @Output() dataExported: EventEmitter<void> = new EventEmitter<void>();
  @Output() columnFreezeToggled: EventEmitter<void> = new EventEmitter<void>();
  @Output() dataSorted: EventEmitter<void> = new EventEmitter<void>();
  @Input() currentSheetIndex!: number;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger | undefined; // Access matMenuTrigger via ViewChild
  firstIncrement: boolean = true;
  @Select(SheetState.regions) regions$!: Observable<[]>;
  selectPgTab$: Observable<PgTab> = inject(Store).select(
    PgTabStore.getSelectedPgTab
  )
  currentPgId$: Observable<number> = inject(Store).select(
    PgTabStore.getCurrentPgId
  );
  currentPgId: number = 0;
  sortStatus: string = 'Sort is Off';
  filterStatus: string = 'Filter is Off';
  sessionId!: string;
  uiCurrency: string = 'US$';
  isAllPages: boolean = true;
  columns: any[] = [];
  sheetData: any[] = [];
  pageFreezeColumn: any = 0;
  filteredLanguages: string[] = [];
  selectedLanguage: {} = { language: 'English', type: 'language' };
  tooltip: any = '';
  pageMenu: boolean = false;
  EditionCheckbox: boolean = false; // Tracks the state of the checkbox
  edition: string = '';
  refresh = UNICODE_RELOAD;
  language = UNICODE_LANGUAGE;
  globe = UNICODE_GLOBE;
  expand = UNICODE_LEFT_RIGHT_ARROW;
  frozen: any = 0;
  expandWidth: any = null;
  @Select(SheetState.getFrozen) frozen$!: Observable<any>;
  @Select(SheetState.getExpandWidth) expandWidth$!: Observable<any>;
  @Select(SheetState.getPageId) pageId$!: Observable<any>;
  @Select(SheetState.getSheetColumnsById) columns$: Observable<any> | undefined;
  private unsubscribe$ = new Subject<void>();
  @ViewChild(AutoAdjustWidthDirective)
  widthDirective!: AutoAdjustWidthDirective;
    tooltipContent: string = '';
  height: any = 0;
  width: any = 0;
  previousFontSize: number = 0;
  currentFontSize: number = 0;
  newFontSize: number = 0;
  @Input() set sheet(data: any) {
    this.sheetData = data;
  }
  isEditionPg: boolean = false;
  currentObjectWithLabels : {[key:string] : string} = {};
  currentLabels : {[key:string] : string}= {};
  private destroy$ = new Subject<void>();
  isSectionExist = false;
  pageChanged$: Observable<any> = inject(Store).select(
    SheetState.getSheetDataById
  );

  getLabels$ = this.labelsFacadeService.getLabelsForObject$.
    pipe(
      takeUntilDestroyed(this.destroyRef),
      filter((x)=> Object.keys(x).length > 0)
    )
    .subscribe((labels)=>{
      this.currentObjectWithLabels = labels;
    })

  getCurrentObjects$ = this.labelsFacadeService.getCurrentObjects$.pipe(
    takeUntilDestroyed(this.destroyRef),
    filter((x)=> Object.keys(x).length > 0)
  ).subscribe((labels: {})=>{
    this.currentLabels = labels;
  })

  private languagesList: WritableSignal<Language[]> = signal<Language[]>([]);
  private languages$: Observable<any> = inject(Store).select(PgTabStore.getLanguages);

  constructor(
    public mainService: MainService,
    public dialog: MatDialog,
    private store: Store,
    private cdr: ChangeDetectorRef,
    public tooltipService: TooltipService,
    public fb: FormBuilder,
    private GetValueFromKeyPipe : GetValueFromKeyPipe,
    public menuBarFacade : MenuBarFacadeService,
    public labelsFacadeService : LabelsFacadeService,){
    this.initializeFontSize();
  }

  ngOnInit(): void {
    this.fetchAllLanguages();
    this.initializeSubscriptions();
    this.setupInitialConditions();
    this.adjustCheckboxSize();
    this.setupResizeListener();
    this.pageChanged$.pipe(takeUntil(this.destroy$),map((res) => {
      this.isSectionExist = res?.filter((x: any) => this.GetValueFromKeyPipe.transform(x?.row_type) === SECTION)?.length > 0;
    })).subscribe();
    this.frozen$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => (this.frozen = data));
    this.expandWidth$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => (this.expandWidth = data));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeFontSize(): void {
    this.previousFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
  }

  private initializeSubscriptions(): void {
    this.currentPgId$.subscribe((res: any) => {
      this.isAllPages =
        Number(res.page_id) || Number(res) === PAGE_IDS.ALL_PAGES
          ? true
          : false;
      this.currentPgId = typeof res === 'object' ? Number(res.page_id) : res;
    });

    this.mainService.sortStatus
      .pipe(takeUntil(this.destroy$))
      .subscribe((sortStatus) => (this.sortStatus = sortStatus));

    this.mainService.filterStatus
      .pipe(takeUntil(this.destroy$))
      .subscribe((filterStatus) => (this.filterStatus = filterStatus));

    if (!this.mainService.isAutoTranslationChecked()) {
      const bodyElement = document.body;
      bodyElement.classList.add('notranslate');
    }
    this.tooltip = Object.values(this.selectedLanguage)[0];
    let obj1 = { pageExpand: 1 };
    this.mainService.pageFormate.next(obj1);
    this.mainService.pageFormateReg.next(obj1);
  }

  private setupInitialConditions(): void {
    if (!this.mainService.isAutoTranslationChecked()) {
      document.body.classList.add('notranslate');
    }
    this.tooltip = Object.values(this.selectedLanguage)[0] || 'Default Tooltip'; // Provide a default tooltip
  }

  private setupResizeListener(): void {
    window.addEventListener('resize', this.adjustCheckboxSize.bind(this));
  }

  private adjustCheckboxSize() {
    const bodyFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    if (bodyFontSize && this.previousFontSize) {
      this.height = bodyFontSize / this.previousFontSize - 0.2;
      this.width = bodyFontSize / this.previousFontSize - 0.2;
    } else {
      // Fallback to default sizes
      this.height = 16;
      this.width = 16;
    }
  }


  ngAfterViewInit() {
    const selectElement = document.querySelector(
      '.goog-te-combo'
    ) as HTMLSelectElement;
    if (selectElement) {
      this.filteredLanguages.forEach((lang) => {
        const optionElement = Array.from(selectElement.options).find(
          (option) => option.text === lang
        );
        if (optionElement) {
          optionElement.style.display = 'block';
        }
      });
    }
    this.edition = this.mainService?.getEdition();
    this.pageId$?.subscribe((pageId) => {
      this.isEditionPg = pageId === All_Pages_ID ? true : false;
    });
    this.setupResizeListener();
    this.cdr.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    this.pageMenu = false;
  }



  getLanguageValue(language: string): string {
    const languageMap: { [key: string]: string } =
      this.mainService.languageListKeys;
    return languageMap[language] || 'en';
  }

  getAllGoogleLanguages(): string[] {
    return this.mainService.languageList;
  }

  onRightClick(event: MouseEvent) {
    event.preventDefault(); // Prevent the default right-click menu

    if (this.menuTrigger) {
      this.menuTrigger.openMenu(); // Open the context menu
    }
  }

  handleCheckboxChange(event: any) {
    this.EditionCheckbox = event.target.checked;
    this.mainService.handleCheckboxChange(event);
  }

  setCurrency(currency: string) {
    this.uiCurrency = currency;
  }

  private fetchAllLanguages(): void {
    this.languages$
    .pipe(takeUntilDestroyed(this.destroyRef))
    .subscribe( (result) =>{
      if(result?.data){
        const value: Language[] = result?.data[DDL_Languages]?.DDLContent.map( (elm:any) => {
          const lang: Language = {
            langID: +elm.row,
            language: elm.language,
            code: getLanguageCodeFromLanguage(elm.language)
          };
          return lang;
        });
        this.languagesList.set(value);
      }
    })
  }

  public toggleAutoTranslate(languageString: string): void {
    this.mainService.isAutoTranslationChecked.set(!this.mainService.isAutoTranslationChecked())
    MainService.currentLanguage.set(DEFAULT_LANGUAGE);
    if (this.mainService.isAutoTranslationChecked()) {
      const currentLanguage: string = languageString.replace('Current Language :','').trim();
      const language: Language | undefined = this.languagesList().find( lan => lan.language.includes(currentLanguage));
      if(language){
        MainService.currentLanguage.set(language);
      }
    }
    this.mainService.isAutoTranslation$.next(this.mainService.isAutoTranslationChecked());
    this.store.dispatch(new AutoTranslation(MainService.currentLanguage().langID));
  }

  handleDialog<T>(
    dialogData: any,
    selectedId: string,
    Key : string,
    updateMethod: (rowSelected: T) => void
  ): void {
    const title = this.mainService.getMenuBarDialogMessage({
      Action: Actions.Edit,
      ValueFormulaKey: Key,
      Message: Message.TitleMessage
    });

    const dialogRef = this.dialog.open(editDdDialogMainComponent, {
      width: 'auto',
      panelClass: 'pick-ddl',
      data: {
        title,
        token: selectedId,
        data: { frozen: this.frozen, expandWidth: this.expandWidth },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if(result?.languageRowSelected){
            this.mainService.isAutoTranslationChecked.set(false)
            this.mainService.isAutoTranslation$.next(false);
          }
          
        const rowSelected =
          result[
            Object.keys(result).find((key) =>
              key.endsWith('RowSelected')
            ) as keyof DDResponse
          ];
        const labelsKeyToRowKeyMap  : {[key : string] : string}= {
          [LabelsKey.CurrentPage]: 'page_id',
          [LabelsKey.CurrentEdition]: 'page_edition',
          [LabelsKey.CurrentLanguage]: 'language',
          [LabelsKey.CurrentCurrency]: 'unit',
          [LabelsKey.CurrentMode]: 'token',
          [LabelsKey.CurrentRegion]: 'region',
        };
        const getKey = this.currentLabels[Key];
        const valKey = labelsKeyToRowKeyMap[Key];
        if (rowSelected) {
          updateMethod(rowSelected as T);
          this.currentObjectWithLabels[getKey] = this.labelsFacadeService.updateLabels(this.currentObjectWithLabels[getKey], rowSelected[valKey]);
          this.tooltipContent = this.currentObjectWithLabels[getKey];
          this.tooltipService.triggerTooltip(this.tooltipContent);
        } else {
          this.currentObjectWithLabels[getKey] = this.labelsFacadeService.updateLabels(this.currentObjectWithLabels[getKey], 'Null');
          this.tooltipContent = this.currentObjectWithLabels[getKey];
          this.tooltipService.triggerTooltip(this.tooltipContent);
        }
        this.labelsFacadeService.updateCurrentObjectInStoreSession(getKey,this.currentObjectWithLabels[getKey]);
        this.resetFreezeSettings();
      }
    });
  }

  private resetFreezeSettings(): void {
    let obj3 = { pageFreezeColumn: 0 };
    this.mainService.pageFormateFreeze.next(obj3);
    this.store.dispatch(new Sheet.SetFrozen(99));
    this.mainService.pageFormateFreeze.next({ pageFreezeColumn: 0 });
  }

   showCountryRegion(): void{
    this.handleDialog<RowRegionData>(
      'Region',
      DDL_Regions,
      LabelsKey.CurrentRegion,
      (()=>{})
    );
  }

  showLanguageDialog(): void {
    this.handleDialog<RowLanguageData>(
      'Language',
      DDL_Languages,
      LabelsKey.CurrentLanguage,
      (()=>{})
    );
  }

  showCurrencyDialog(): void {
    this.handleDialog<RowCurrencyData>(
      'Currency',
      DDL_Currencies,
      LabelsKey.CurrentCurrency,
      this.updateSelectCurrency.bind(this)
    );
  }

 showEditionDialog(): void {
    this.handleDialog<RowPageDataWithoutFormat>(
      'Edition',
      DDL_Page_Edition,
      LabelsKey.CurrentEdition,
      (rowSelected) => {
        this.store.dispatch(
          new AddPgTab({
            page_id: rowSelected.page_id,
            page_name: rowSelected.page_name,
            page_edition: rowSelected.page_edition,
            page_comment: rowSelected.page_comment,
          })
        );
      }
    );
  }

 showPageDialog(): void {
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu(); // Open the context menu
    }
    this.handleDialog<RowPageDataWithoutFormat>(
      'Page',
      DDL_All_Pages,
      LabelsKey.CurrentPage,
      (rowSelected) => {
        this.store.dispatch(
          new AddPgTab({
            page_id: rowSelected.page_id,
            page_name: rowSelected.page_name,
            page_edition: rowSelected.page_edition,
            page_comment: rowSelected.page_comment
          })
        );
      }
    );
  }

  async showViewDialog(): Promise<void> {
    await this.handleDialog<any[]>(
      'View',
      DDL_Page_Modes,
      LabelsKey.CurrentMode,
      (()=>{})
    );
  }




  updateSelectCurrency(rowSelected: RowCurrencyData): void {
    this.setCurrency(rowSelected.unit);
  }


  toggleSort() {
    this.mainService.toggleSort();
  }

  toggleFilter() {
    this.mainService.toggleFilter();
  }

  openColDialog() {
    const dialogRef = this.dialog.open(PGColDialogComponent, {
      width: '750px',
      data: { selectedId: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  selectedUserOption: User | null = null;
  openUserDialog(): void {
    const dialogRef = this.dialog.open(PGUserDialogComponent, {
      width: '400px',
      data: { selectedUserOption: this.selectedUserOption },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedUserOption = result;
      }
    });
  }

  /**
   * Opens the FormatePageComponent dialog to format the page.
   * Once the dialog is closed, if a result is returned:
   */
  formatPage(): void {
    // Open the dialog and pass the title as data
    const title = this.mainService.getMessage({
      Action: Actions.Format,
      Object: DbObjects.Page,
      ID: this.currentPgId,
      Message: Message.TitleMessage
    });

    const dialogRef = this.dialog.open(FormatPageComponent, {
      data: {
        title,
        objectId: this.currentPgId,
        context: ContextMenu.FormatPage,
      },
    });

    // Handle the dialog close event
    dialogRef.componentInstance.dialogData$.subscribe((result) => {
      if (!result) return; // Exit if no result is returned

      this.store.dispatch(new Sheet.UpdatePageFormatStyle(result?.fontStyleParsed))
      // Dispatch the result to the store to update font style
      this.store.dispatch(new SetFontStyle(result));

      // Update local property: pageFreezeColumn
      this.pageFreezeColumn = result.pageFreezeColumn;

      // Notify other components via a service
      this.mainService.pageFormate.next(result);
    });
  }

  deletePage() {
    if (!this.isAllPages) {
      this.menuTrigger?.closeMenu();

      const title = this.mainService.getMessage({
        Action: Actions.Delete,
        Object: DbObjects.Page,
        ID: this.currentPgId,
        Message: Message.TitleMessage
      });

      const dialogRef = this.dialog.open(DialogDeleteComponent, {
        data: {
          title,
          pgId: this.currentPgId,
          data: this.mainService.getDeleteReason(Reason.Delete),
          name: ObjectType.DataObject.Page,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
  }

  viewPage() {
    const title = this.mainService.getMessage({
      Action: Actions.View,
      Object: DbObjects.Page,
      ID: this.currentPgId,
      Message: Message.TitleMessage
    });

    const dialogRef = this.dialog.open(DialogViewComponent, {
      data: {
        title,
        context: ContextMenu.ViewPage,
      },
    });
  }
}