<div class="edit-dd-container" #popupContainer>
  <div class="dialog-header" (mousedown)="onDragStart($event)">
  <div class="header-text">{{ dialogData.title }}</div>
  <div type="button" class="close-icon" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">{{ closeTab }}</span>
  </div>
  </div>
    <app-edit-dd-dialog-tab-bar [(tabCounts)]="tabCounts"></app-edit-dd-dialog-tab-bar>
    <edit-dd-dialog-menu-bar class="menu-bar-container" id="ddi-menu-bar"></edit-dd-dialog-menu-bar>
    <edit-dd-dialog-content [(isLoading)]="isLoading" [(tabCounts)]="tabCounts" class="tabulator-container" (isGetData)="handleBooleanEvent($event)"></edit-dd-dialog-content>
  <div class="resizer" (mousedown)="onResizeStart($event)">
    <span [innerHTML]="expandIcon"></span>
  </div>
</div>
@if((isLoading$ | async) || isLoading()){
  <div class="loader-overlay">
    <div class="loader"></div>
  </div>
}