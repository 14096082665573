<div *ngIf="((isLoading$ | async))" class="loader-overlay">
  <div class="loader"></div>
</div>
<div class="delete-page-container" #content >
  <div class="dialog-header" (mousedown)="onDragStart($event)">
    <span class="header-text">{{ dialogData.title }}</span>
  </div>
  <div class="edit-item-container" style="height: 100%;" >
    <div class="form-group2" style="overflow: auto; flex-direction: column;">
      <label for="item-input font-style">{{dialogData?.data}}</label>
      <div *ngIf="!isFormValid" class="custom-error-message">{{ failureMessage }}</div>
      <div *ngIf="isFormValid && successMessage" class="custom-success-message">{{ successMessage }}</div>
      <div class="button-group">
        <div class="delete-button-container">
          <div type="button" [ngClass]="{'disabled': apiSuccessFlag}" class="Chip" (click)="onDelete()">Delete</div>
        </div>        
        <div *ngIf="!apiSuccessFlag" type="button" class="Chip" (click)="onCancel()">Cancel</div>
        <div *ngIf="apiSuccessFlag" type="button" class="Chip" (click)="onCancel()">Close</div>
      </div>
    </div>
  </div>
  <div class="resizer" (mousedown)="onResizeStart($event)">
    <span [innerHTML]="expandIcon"></span>
  </div>
</div>