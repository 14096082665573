import { Injectable, OnDestroy } from '@angular/core';
import { Observable, takeUntil, combineLatest } from 'rxjs';
import { SheetState } from '../../../store/page/page.store';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { RowComponent } from 'tabulator-tables';
import { FormatPageStyle, PageColumnData } from "./format-ui-interface";

@Injectable({
  providedIn: 'root'
})
export class FormatUiFacadeService implements OnDestroy {
  private destroy$ = new Subject<void>();

  pageStyleData$: Observable<FormatPageStyle> = this.store.select(SheetState.getPageFormatStyleById);
  columnStyleDataById$: Observable<PageColumnData[]> = this.store.select(SheetState.getSheetFlatColumnsById);
  pageDataById$: Observable<any> = this.store.select(SheetState.getSheetFlatDataById);

  constructor(private store: Store) {

  }

  private pageFormatStyling(row: RowComponent, styleData: string) {
    if (styleData && styleData.trim() !== '' && row.getElement()) {
      const rowElement = row.getElement();
      rowElement.style.cssText = styleData;
    }
  }

  private applyStylesToColumn(cellElement: any, styleData: string) {
    if (styleData && styleData.trim() !== '') {
      // Getting the current inline width and height
      const currentWidth = cellElement.offsetWidth;
      const currentHeight = cellElement.offsetHeight;
      cellElement.style.cssText = `width: ${currentWidth}px; height: ${currentHeight}px; ${styleData}`;
    }
  }

  public applyFormatting(row: RowComponent): void {
    combineLatest([this.pageStyleData$, this.columnStyleDataById$, this.pageDataById$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(async ([pageStyle, columnData, pageData]) => {
        // Applying Page Format
        if (pageStyle?.font_style) {
          this.pageFormatStyling(row, pageStyle?.font_style);
        }

        await columnData.forEach((obj: any) => {
          const cell = row.getCell(obj.field);
          if (cell) {
            const cellElement = cell.getElement();
            if (obj?.format?.font_style) {
              this.applyStylesToColumn(cellElement, obj.format.font_style);
            }
          }
        });

        const rowData = row.getData();
        const rowId = rowData['row'];
        const matchingRow = pageData.find((obj: any) => obj.row === rowId);
        if (matchingRow) {
          // to apply rowStyle
          if (matchingRow?.RowFormat?.font_style) {
            row.getElement().style.cssText = matchingRow?.RowFormat?.font_style;
          }

          // to apply cellStyle
          const cells = row.getCells();
          cells.forEach((cell: any) => {
            const fieldName = cell.getColumn().getField();
            const cellElement = cell.getElement();
            const cellStyle = matchingRow[fieldName]?.cell?.format?.font_style;
            if (cellStyle) {
              const currentWidth = cellElement.offsetWidth;
              const currentHeight = cellElement.offsetHeight;
              cellElement.style.cssText = `width: ${currentWidth}px; height: ${currentHeight}px; ${cellStyle}`;
            }

            // to apply itemStyle
            const chips = cellElement.querySelectorAll('.Chip');
            const items = matchingRow[fieldName]?.cell?.items || matchingRow[fieldName]?.format?.items;
            if (items) {
              chips.forEach((chip: any) => {
                let chipText = chip.innerText.trim();
                let matchedData = items.find((obj: any) => {
                  if (!obj?.item || typeof obj.item !== 'object' || Object.keys(obj.item).length === 0) {
                    return false;
                  }
                  let dynamicKey = Object.keys(obj.item)[0];
                  return obj.item[dynamicKey].trim() === chipText;
                });

                if (matchedData && matchedData.format?.font_style) {
                  chip.style.cssText = matchedData.format.font_style;
                }
              });
            }
          });
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
