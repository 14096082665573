import { Action, Selector, State, StateContext } from "@ngxs/store";
import { CurrentObjectDefinitions, LabelsStateModel, SystemObjectDefinitions } from "./labels.model";
import { Injectable } from "@angular/core";
import { LabelsState } from "./labels.state";
import { LabelsService } from "../../core/services/labels/labels.service";
import { tap } from "rxjs";
import { AllObjects, ApiResponse } from "../../core/interfaces/response.interface";
import { GetAllDataTypesFailure } from "../root/root.action";
import { extractFromFormulas } from "../../utils/utils";
import { GetCurrentObjectDefinitions, GetCurrentObjectDefinitionsFailure, GetCurrentObjectDefinitionsSuccess, GetSystemObjectDefinitionsSuccess, SetCurrentObjectsOnSession, SetCurrentObjectsOnTabs, SetCurrentObjectsOnUser } from "./labels.action";

@State<LabelsStateModel>({
  name: 'labels',
  defaults: LabelsState,
})

@Injectable()
export class LabelsStore {
    constructor(
      private labelsService : LabelsService,
    ){}

    @Selector()
    static getCurrentObjects(state: LabelsStateModel) {
      return state.currentObjects;
    }
    
    @Selector()
    static getSystemObjectDefinitions(state: LabelsStateModel) {
      return state.systemObjectsDefinition;
    }

    @Selector()
    static currentObjectsLabelsWithTab(state: LabelsStateModel) {
      return state.currentObjectsLabelsWithTab;
    }

    @Selector()
    static currentObjectsLabelsWithUser(state: LabelsStateModel) {
      return state.currentObjectsLabelsWithUser;
    }

    @Selector()
    static currentObjectsLabelsWithSession(state: LabelsStateModel) {
      return state.currentObjectsLabelsWithSession;
    }

    @Selector()
    static getCurrentObjectsDefinitions(state: LabelsStateModel) {
      return state.currentObjectsDefinition;
    }

    @Selector()
    static getLabelsLoading(state: LabelsStateModel) {
      return state.isLoading;
    }

    @Action(GetCurrentObjectDefinitions)
    getCurrentObject(ctx: StateContext<LabelsStateModel>, action: GetCurrentObjectDefinitions) {
      ctx.patchState({
          isLoading : true,
      })

      return this.labelsService.getCurrentObjects().pipe(
        tap((res : ApiResponse)=>{
            if(res.success){
              ctx.dispatch(new GetCurrentObjectDefinitionsSuccess(res.data[AllObjects.CurrentObjects].DDLContent as CurrentObjectDefinitions[]));
              ctx.dispatch(new GetSystemObjectDefinitionsSuccess(res.data[AllObjects.SystemObjects].DDLContent as SystemObjectDefinitions[]));
            }else{
              ctx.dispatch(new GetAllDataTypesFailure(res.errorMsg));
            }
        })
      )
    }

    @Action(GetCurrentObjectDefinitionsSuccess)
    getCurrentObjectSuccess(ctx: StateContext<LabelsStateModel>, action: GetCurrentObjectDefinitionsSuccess) {
      ctx.patchState({
        isLoading : false,
      })
      ctx.patchState({
        currentObjectsDefinition : action.currentObjectsDefinition,
        currentObjects : extractFromFormulas(action.currentObjectsDefinition.map((res)=> res.value_formula)),
      })
    }

    @Action(GetSystemObjectDefinitionsSuccess)
    getSystemObjectSuccess(ctx: StateContext<LabelsStateModel>, action: GetSystemObjectDefinitionsSuccess) {
      ctx.patchState({
        isLoading : false,
      })
      ctx.patchState({
        systemObjectsDefinition : action.systemObjectsDefinition,
      })
    }

    @Action(GetCurrentObjectDefinitionsFailure)
    getCurrentObjectFailure(ctx: StateContext<LabelsStateModel>, action: GetCurrentObjectDefinitionsFailure) {
      ctx.patchState({
        isLoading : false,
        error : action.error
      })
    }

    @Action(SetCurrentObjectsOnTabs)
    setCurrentObjectsOnTabs(ctx: StateContext<LabelsStateModel>, action: SetCurrentObjectsOnTabs) {
      ctx.patchState({
        currentObjectsLabelsWithTab : action.currentObjects
      })
    }

    @Action(SetCurrentObjectsOnSession)
    setCurrentObjectsOnSession(ctx: StateContext<LabelsStateModel>, action: SetCurrentObjectsOnSession) {
      ctx.patchState({
        currentObjectsLabelsWithSession : action.currentObjects
      })
    }


    @Action(SetCurrentObjectsOnUser)
    setCurrentObjectsOnUser(ctx: StateContext<LabelsStateModel>, action: SetCurrentObjectsOnUser) {
      ctx.patchState({
        currentObjectsLabelsWithUser : action.currentObjects
      })
    }
}
