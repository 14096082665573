export const constant = {
    original_Url: '3000081280',
    backup_Url: '3000081290'
}
export const pagesId = {
    All_Pages : 1000000010,
    AllCols: 1000000060,
    ALLTokens: 1000000080,
    ALL_Units: 1000000090,
}
export const PAGE_IDS = {
    ALL_REGIONS: 1000000140,
    ALL_PAGES: 1000000010,
    ALL_COLS: 1000000060
}
export const COL_STATUSES = {
    HIDDEN: 'Hidden',
    DDL_COL: 'DDL-Col',
    NESTED: 'Nested'
}
export const FILTER_TYPES = {
    REGEX: 'REGEX',
    R: 'R',
    LIKE: 'LIKE',
    NOT_LIKE: '!LIKE',
    L: 'L',
    NOT_L: '!L',
    MATCH: 'MATCH',
    NOT_MATCH: '!MATCH',
    M: 'M',
    NOT_M: '!M',
    START: 'START',
    NOT_START: '!START',
    S: 'S',
    NOT_S: '!S',
    END: 'END',
    NOT_END: '!END',
    E: 'E',
    NOT_E: '!E',
    AND: 'AND',
    SYMBOL_AND: '&',
    OR: 'OR',
    SYMBOL_OR: '|',
};
export const SystemInitials = {
    MinWidth: 100,
    RecycledUser: 3000009999,
    English: 3000010000,
    OriginalUrl: 3000081280,
}

export enum TabulatorRenderMessage {
  NoData = "No Page Data Found",
  Loading = "Loading Data..."
}

export const NO_EDITABLE_DATATYPES: string[] = [
    'PageID',
    'RowID',
    'ColID',
    'CellID',
    'ItemID',
    'FormatID',
    'ProfileID',
    'UserID',
]