import { PgTab } from './pg-tab.model';

export class LoadPgTabs {
  static readonly type = '[PgTabs] Load PgTabs';
  constructor(public page_id: any, public refresh? : boolean) {}
}
export class LoadPgTabsSuccess {
  static readonly type = '[PgTab] Load PgTabs Success';
  constructor(public pgTabs: PgTab[]) {}
}

export class LoadPgTabsFailure {
  static readonly type = '[PgTab] Load PgTabs Failure';
  constructor(public error: string) {}
}

export class AddPgTab {
  static readonly type = '[PgTabs] Add PgTab';
  constructor(public pgTab: PgTab) {}
}

export class UpdatePgTabs {
  static readonly type = '[PgTabs] Update PgTab';
  constructor(public pgTabs: PgTab[], public defaultPgTabId: number) {}
}

export class DeletePgCol {
  static readonly type = '[PgTabs] Delete PgCol';
  constructor(public colId: number) {}
}

export class SelectPgTab {
  static readonly type = '[PgTab] Select PgTab';
  constructor(public pgTab: PgTab) {}
}

export class LoadMenuBar {
  static readonly type = '[MenuBar] Load MenuBar';
  constructor(public DDL: string) {}
}
export class AutoTranslation {
  static readonly type = '[Page] Page AutoTranslation';
  constructor(public langID: number) {}
}

export class IsLoadingMenuBar {
  static readonly type = '[MenuBar] Loading MenuBar';
  constructor(public loading: boolean) {}
}
export class SetFontStyle {
  static readonly type = '[Font] SetFontStyle';
  constructor(public payload: any) {}
}
export class FetchLanguages {
  static readonly type = '[Language] FetchLanguages';
  constructor() {}
}
