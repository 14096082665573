
<ng-template #content let-modal>
    <angular-window class="add-item-modal" style="background-color:white"  [dragHolder]="header">
      <div class="modal-header" #header>
        <span class="heading"> Add/Edit-Text-dialog</span>
      </div>
      <div class="add-item-container">
        <form [formGroup]="form">
            <div class="fields-container" *ngIf="isEmpty">
                <div   class="language-group" [class.selected-row]="!isEmpty"
                 >
                    <div class="text-editor-container">
                    <label for="richText1" class="font-style">Language-neutral Rich-Text</label>
                     <!-- TODO: Uncomment Code FOr Input Field -->
                    <!-- <input type="text" id="richText{{i}}" (ngModelChange)="onValueChange($event,i)" formControlName="richText" class="font-style" > -->
                    <!-- TODO: Uncomment Code FOr CK-Editor -->
                    <div>
                      <div class="main-container" >
                            <div class="empty-container" (click)="isEmpty = false"></div>
                      </div>
                    </div>    
                    </div>         
                </div>
              </div>
          <div class="fields-container" *ngIf="!isEmpty">
            <div   class="language-group" [class.selected-row]="!isEmpty"
             >
              
              <div class="form-group">
                <div class="text-editor-container">
                <label for="richText1" class="font-style">Language-neutral Rich-Text</label>
                 <!-- TODO: Uncomment Code FOr Input Field -->
                <!-- <input type="text" id="richText{{i}}" (ngModelChange)="onValueChange($event,i)" formControlName="richText" class="font-style" > -->
                <!-- TODO: Uncomment Code FOr CK-Editor -->
                <div>
                  <div class="main-container">
                    <div class="editor-container editor-container_classic-editor" #editorContainerElement>
                      <div class="editor-container__editor">
                        <div #editorElement>
                          <app-ck-editor formControlName="richText"></app-ck-editor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>    
                </div>          
              </div>
              <button
               
              type="button"
              class="close"
              aria-label="Close"
              (click)="modal.dismiss('Cross click'); dialogRef.close()"
            >
              <div class="cross-icon" >
               {{closeTab}}
              </div>
            </button>
            </div>
          </div>
          <div class="button-group">
            <div class="button-container">
            <div  class="Chip" (click)="onSave()">Save</div>
            </div>
            <div type="button" class="Chip" (click)="onCancel()" >Cancel</div>
          </div>
        </form>
      </div>
    </angular-window>
  </ng-template>
  