export const UNICODE_RELOAD = '\u21BB';
export const UNICODE_LANGUAGE = '\u6587';
export const UNICODE_GLOBE = '\uD83C\uDF10';
export const UNICODE_LEFT_RIGHT_ARROW = '\u2B0D';
export const UNICODE_MULTIPLY = '\u00D7';
export const UNICODE_PLUS = '\uFF0B';
export const UNICODE_CIRCLE = '\u25EF';
export const UNICODE_DASH = '\u2212';
export const UNICODE_PLUS_CODE = '\u002B';
export const UNICODE_UP_ARROW = '\u2BC5';
export const UNICODE_DOWN_ARROW = '\u2BC6';
export const ASCII_SPACE = '&nbsp;';
export const ASCII_PLUS = '&#43;';
export const ASCII_DASH = '&#45;';


export const All_Pages_ID = '1000000001';
export const DDL_All_Pages = 'All Pages';
export const DDL_All_DDL_Pages = 'All DDL Pages';
export const DDL_Languages = 'Languages';
export const DDL_Regions = 'Regions';
export const Countries = 'Countries';
export const DDL_Currencies = 'Currencies';
export const DDL_Page_Edition = 'Page Edition';
export const DDL_Page_Modes = 'Page Modes';

export const EXCLUDED_PAGE_IDS = [
  1000000002, 1000000003, 1000000004, 1000000005, 1000000007, 1000000008,
].map(String);

export const PAGE_FORMAT = [
  { label: 'Format ID',readonly:true,required:true},
  { label: 'Format User'},
  { label: 'Page ID',readonly:true,required:true},
  { label: 'Page Nested-Column',required:true,clickAction:true},
  { label: 'Page Freeze-Column(s)'},
  { label: 'Page Expand-Level'},
  { label: 'Page Sort-Formula'},
  { label: 'Page Filter-Formula'},
  { label: 'Page Level-Set'},
  { label: 'Page Search-Set'},
  { label: 'Page FontStyle',clickAction:true},
  { label: 'Page Comment'},
  { label: 'Page Transactions',},
];
export const ASCII_CROSS_ICON = '&#120;';
export const UNCICODE_CROSS_ICON = '\u{1F5D9}';
export const ASCII_EXPAND_ICON = '&#x25E2;';
