export namespace Sheet {
  export class FetchSheetData {
    static readonly type = '[Sheet] FetchSheetData';
    constructor(public sheetId: any) {}
  }
  export class UpdatePgTabData {
    static readonly type = '[Sheet] UpdatePgTabData';
    constructor(public sheetId: any) {}
  }

  export class PickDdiData {
    static readonly type = '[Sheet] PickDdiData';
    constructor(public DDL: any) {}
  }

  export class SetSheetData {
    static readonly type = '[Sheet] SetSheetData';
    constructor(public payload: any) {}
  }

  export class FetchSheetColumns {
    static readonly type = '[Sheet] FetchSheetColumns';
    constructor(public sheetId: any) {}
  }
  export class updateColumns {
    static readonly type = '[Sheet] UpdateColumns';
    constructor(public payload: any) {}
  }

  export class UpdatePickDdiColumns {
    static readonly type = '[Sheet] UpdatePickDdiColumns';
    constructor(public payload: any) {}
  }

  export class UpdatePageData {
    static readonly type = '[Sheet] UpdatePageData';
    constructor(public payload: any) {}
  }

  export class OrderColumns {
    static readonly type = '[Sheet] OrderColumns';
    constructor(public pageId: number, public payload: any) {}
  }

  export class SetSheetColumns {
    static readonly type = '[Sheet] SetSheetColumns';
    constructor(public payload: any) {}
  }
  export class AddRow {
    static readonly type = '[Sheet] AddRow';
    constructor(public payload: any) {}
  }

  export class addColumn {
    static readonly type = '[Sheet] addColumn';
    constructor(public payload: any) {}
  }
  export class DeleteRow {
    static readonly type = '[Sheet] DeleteRow';
    constructor(public payload: any) {}
  }

  export class DeleteColumn {
    static readonly type = '[Sheet] DeleteColumn';
    constructor(public payload: any) {}
  }

  export class addUrl {
    static readonly type = '[Sheet] addUrl';
    constructor(public payload: any) {}
  }

  export class DeletePage {
    static readonly type = '[Sheet] DeletePage';
    constructor() {}
  }
  export class PageButtonPopupData {
    static readonly type = '[Sheet] PageButtonPopupData';
    constructor(public payload: any) {}
  }
  export class FormatLocalColData {
    static readonly type = '[Sheet] FormatLocalColData';
    constructor(public payload: any, public page_id: any) {}
  }
  export class DeleteItemData {
    static readonly type = '[Sheet] DeleteItemData';
    constructor(public payload: any, public page_id: any) {}
  }

  export class SetFrozen {
    static readonly type = '[Sheet] SetFrozen';
    constructor(public payload: any) {}
  }
  export class SetWidthExpand {
    static readonly type = '[Sheet] SetWidthExpand';
    constructor(public payload: any) {}
  }
  export class FetchDDL {
    static readonly type = '[FetchDDL] Load FetchDDL';
    constructor(public DDL: string) {}
  }
}
