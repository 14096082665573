import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import { map, Observable, skip, tap, withLatestFrom } from 'rxjs';
import { LoadPgTabs, SelectPgTab } from '../../../store/pg-tab/pg-tab.actions';
import { PgTab } from '../../../store/pg-tab/pg-tab.model';
import { Sheet } from '../../../store/page/sheet.actions';
import { All_Pages_ID, ASCII_CROSS_ICON, UNCICODE_CROSS_ICON, UNICODE_CIRCLE, UNICODE_MULTIPLY, UNICODE_PLUS } from '../../../core/constants/app.contants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TooltipService } from '../../../services/tooltip-service/tooltip.service';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
})
export class TabBarComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  pgTabs$: Observable<PgTab[]> = inject(Store).select(PgTabStore.getPgTabs);
  defaultPgTab$ : Observable<PgTab> = inject(Store).select(PgTabStore.getDefaultPgTab)
  tabBars: PgTab[] = [];
  currentSheetIndex = 0;
  closeTab = UNICODE_MULTIPLY;
  plusIcon = UNICODE_PLUS;
  circle = UNICODE_CIRCLE;
  cross_icon = UNCICODE_CROSS_ICON;
  constructor(
    private store: Store,
    public tooltipService : TooltipService,
  ) {}

  ngOnInit(): void {
    this.defaultPgTab$.pipe(
      skip(2),
      takeUntilDestroyed(this.destroyRef),
      withLatestFrom(this.pgTabs$),
    ).subscribe(([activeTab, allTabs])=>{
      this.onSelectPgTab(activeTab, allTabs.length - 1);
    });
    this.initializeTabs();
  }

  private initializeTabs(): void {
    this.store.dispatch(new LoadPgTabs(All_Pages_ID));
    this.pgTabs$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((tabs) => (this.tabBars = tabs));
  }

  async onSelectPgTab(pgTab: PgTab, index: number): Promise<void> {
    this.store.dispatch(new Sheet.FetchSheetData(pgTab.page_id));
    this.store.dispatch(new SelectPgTab(pgTab));
    this.currentSheetIndex = index;
  }

  deleteSheet(index: number): void {
    if (this.tabBars.length <= 1) {
      alert("Can't close tab");
      return;
    }
    this.tabBars.splice(index, 1);

    if (index >= this.tabBars.length) {
      const previousTab = this.tabBars[index - 1];
      this.onSelectPgTab(previousTab, index - 1);
    } else {
      const nextTab = this.tabBars[index];
      this.onSelectPgTab(nextTab, index);
    }
    this.tooltipService.destroyToolTip()
  }

  addSheet(): void {
    this.defaultPgTab$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((defaultPage) => {
        if (!defaultPage) return;
        const newTab = { ...defaultPage };
        this.tabBars.push(newTab);
        this.onSelectPgTab(newTab, this.tabBars.length - 1);
      });
  }

  onKeyDown(event: any, pgTab: PgTab, index: number): void {
    if (!this.tabBars[index]) {
      event.preventDefault();
      return;
    }

    if (event.key === 'Tab') {
      this.onSelectPgTab(pgTab, index);
    }
  }

  onShiftTabKeyPress(event: any, pgTab: PgTab, index: number): void {
    if (!this.tabBars[index - 1]) {
      event.preventDefault();
      return;
    }

    this.onSelectPgTab(pgTab, index - 1);
  }
}
