import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { LabelsStore } from '../../../store/labels/labels.store';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { PgObject, PgTab } from '../../../store/pg-tab/pg-tab.model';
import { TabCurrentLabels } from '../../interfaces/session.interface';
import { CurrentObjectDefinitions, SystemObjectDefinitions } from '../../../store/labels/labels.model';
import { GetCurrentObjectDefinitions, SetCurrentObjectsOnSession, SetCurrentObjectsOnTabs, SetCurrentObjectsOnUser } from '../../../store/labels/labels.action';
import { PgTabStore } from '../../../store/pg-tab/pg-tab.store';
import { ObjectFilterKey } from '../../enums/pg-tabs/pg-tabs.enum';
import { SessionStorageService } from '../session-storage.service';
import { getValueFromObject } from '../../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class LabelsFacadeService {
  constructor(
    private store : Store,
    private sessionStorage : SessionStorageService
  ){}
  public labelsInSession :TabCurrentLabels = {};
  public labelsInUsers :TabCurrentLabels = {};
  public labelsInTabs :TabCurrentLabels = {};
  public currentTabId = '';
  public getCurrentObjects$: Observable<{[key:string] : string}> = inject(Store).select(LabelsStore.getCurrentObjects);
  public getSystemObjectDefinitions$: Observable<SystemObjectDefinitions[]> = inject(Store).select(LabelsStore.getSystemObjectDefinitions);
  public getCurrentObjectDefinitions$: Observable<CurrentObjectDefinitions[]> = inject(Store).select(LabelsStore.getCurrentObjectsDefinitions);
  public currentObjectsLabelsWithTab$: Observable<TabCurrentLabels> = inject(Store).select(LabelsStore.currentObjectsLabelsWithTab);
  public currentObjectsLabelsWithUser$: Observable<TabCurrentLabels> = inject(Store).select(LabelsStore.currentObjectsLabelsWithUser);
  public currentObjectsLabelsWithSession$: Observable<TabCurrentLabels> = inject(Store).select(LabelsStore.currentObjectsLabelsWithSession);
  public getLabelsLoading$: Observable<boolean> = inject(Store).select(LabelsStore.getLabelsLoading);
  getSelectedPgTab$: Observable<PgTab> = inject(Store).select(PgTabStore.getSelectedPgTab);
  public getLabelsForObject$ = combineLatest([
    this.currentObjectsLabelsWithTab$,
    this.currentObjectsLabelsWithSession$,
    this.currentObjectsLabelsWithUser$,
    this.getSelectedPgTab$
  ]).pipe(
    tap(([labelsFromTabs,labelsFromSession,labelsFromUser,pgTab])=>{
      this.labelsInSession = labelsFromSession;
      this.labelsInUsers = labelsFromUser;
      this.labelsInTabs = labelsFromTabs;
    }),
    map(([labelsFromTabs,labelsFromSession,labelsFromUser,pgTab] ) => {
      if(pgTab.tab_id && labelsFromSession && labelsFromUser && labelsFromTabs){
        this.currentTabId = getValueFromObject(pgTab.tab_id);
        return {
          ...labelsFromTabs[getValueFromObject(pgTab.tab_id)],
          ...labelsFromSession[ObjectFilterKey.SESSION],
          ...labelsFromUser[ObjectFilterKey.USER]
        }
      }
      return {}
    })
  );

  getCurrentObjects(){
    this.store.dispatch(new GetCurrentObjectDefinitions());
  }

  mapDataFromCurrentObjects(pgTabs : PgTab[], key : ObjectFilterKey) : TabCurrentLabels{
    let allTabs = pgTabs;
    const currentObjectsDefinition  : CurrentObjectDefinitions[] =
    this.store.selectSnapshot(LabelsStore.getCurrentObjectsDefinitions)
    .filter(item =>item.value_formula.includes(key));
    const objectDefinitions = this.store.selectSnapshot(LabelsStore.getCurrentObjects);
    const result: Record<string, string> = {};

    Object.entries(objectDefinitions).forEach(([key, placeholder]) => {
      const matchingObjects = currentObjectsDefinition.filter(item =>
        item.value_formula.includes(placeholder as string) && item.value_formula.includes(key)
      );

      if (matchingObjects.length > 0) {
        const [firstMatch] = matchingObjects;
        result[placeholder as string] = `${firstMatch.labels} : ${firstMatch.value_defaultdata || 'N/A'}`;
      }
    });
    const tabsWithLabels : TabCurrentLabels = {};

    if(key === ObjectFilterKey.TAB){
      allTabs.forEach((tab : PgTab)=>{
        const id =  getValueFromObject(tab['tab_id'] as (string | PgObject));
        tabsWithLabels[id] = {...result}
      })
    }else if(key === ObjectFilterKey.USER){
      tabsWithLabels[ObjectFilterKey.USER] = result;
    }else{
      tabsWithLabels[ObjectFilterKey.SESSION] = result;
    }

    return tabsWithLabels;
  }

  setCurrentObjectsOnTabs(
    mapDataFromCurrentObjectsOnTab : TabCurrentLabels,
    mapDataFromCurrentObjectsOnUsers : TabCurrentLabels,
    mapDataFromCurrentObjectsOnSession : TabCurrentLabels,
    withOutSession : boolean
  ){
    if(!withOutSession){
      this.sessionStorage.saveTabsCurrentObjects(mapDataFromCurrentObjectsOnTab);
      this.sessionStorage.saveUserCurrentObjects(mapDataFromCurrentObjectsOnUsers);
      this.sessionStorage.saveSessionCurrentObjects(mapDataFromCurrentObjectsOnSession);
    }
    this.store.dispatch(new SetCurrentObjectsOnTabs(mapDataFromCurrentObjectsOnTab));
    this.store.dispatch(new SetCurrentObjectsOnSession(mapDataFromCurrentObjectsOnSession));
    this.store.dispatch(new SetCurrentObjectsOnUser(mapDataFromCurrentObjectsOnUsers));
  }

  addCurrentObjectsOnTab(mapDataFromCurrentObjectsOnTab : TabCurrentLabels){
    this.sessionStorage.saveTabsCurrentObjects(mapDataFromCurrentObjectsOnTab);
    this.store.dispatch(new SetCurrentObjectsOnTabs(mapDataFromCurrentObjectsOnTab));
  }

  addCurrentObjectsOnUser(mapDataFromCurrentObjectsOnUsers : TabCurrentLabels){
    this.sessionStorage.saveUserCurrentObjects(mapDataFromCurrentObjectsOnUsers);
    this.store.dispatch(new SetCurrentObjectsOnUser(mapDataFromCurrentObjectsOnUsers));
  }

  addCurrentObjectsOnSession(mapDataFromCurrentObjectsOnSession : TabCurrentLabels){
    this.sessionStorage.saveSessionCurrentObjects(mapDataFromCurrentObjectsOnSession);
    this.store.dispatch(new SetCurrentObjectsOnSession(mapDataFromCurrentObjectsOnSession));
  }

  addNewTabWithLabels(pgTab : PgTab){
    const tabs = this.store.selectSnapshot(PgTabStore.getPgTabs);
    const updatedCurrentObjects = this.mapDataFromCurrentObjects([...tabs,pgTab], ObjectFilterKey.TAB);
    const currentLabelsInTab = this.getCurrentObject(ObjectFilterKey.TAB)
    const newTabLabelKey = updatedCurrentObjects[Number(pgTab.tab_id)];
    this.addCurrentObjectsOnTab({
      ...currentLabelsInTab,
      [Number(pgTab.tab_id)] : newTabLabelKey
    });
  }

  deleteTabWithLabels(pgTab : PgTab){
    let currentLabelsInTab = this.getCurrentObject(ObjectFilterKey.TAB);
    delete currentLabelsInTab[Number(pgTab.tab_id)];
    this.addCurrentObjectsOnTab({...currentLabelsInTab});
  }

  getCurrentObject(key : ObjectFilterKey){
    return this.sessionStorage.getData(key) as TabCurrentLabels;
  }

  getData(key : ObjectFilterKey){
    return this.sessionStorage.getData(key);
  }

  updateCurrentObjectInStoreSession(updatedKey : string, val : string){
    const labelsInUsersKeys = Object.keys(this.labelsInUsers[ObjectFilterKey.USER]);
    const labelsInUsersSession = Object.keys(this.labelsInSession[ObjectFilterKey.SESSION]);
    if(labelsInUsersKeys.includes(updatedKey)){
      this.labelsInUsers[ObjectFilterKey.USER][updatedKey] = val;
      this.addCurrentObjectsOnUser(this.labelsInUsers);
    }else if(labelsInUsersSession.includes(updatedKey)){
      this.labelsInSession[ObjectFilterKey.SESSION][updatedKey] = val;
      this.addCurrentObjectsOnSession(this.labelsInSession);
    }else{
      this.labelsInTabs[this.currentTabId][updatedKey] = val;
      this.addCurrentObjectsOnTab(this.labelsInTabs);
    }
  }

  updateLabels(currentString : string,newMode: string): string {
    return currentString.replace(/: .*/, `: ${newMode}`);
  }

  getSystemObjects(token: string): SystemObjectDefinitions[] {
    const systemObjects = this.store.selectSnapshot(LabelsStore.getSystemObjectDefinitions);
    const actionObject: SystemObjectDefinitions | undefined = systemObjects
      .find((obj: SystemObjectDefinitions) => obj.token == token);
    
    if (actionObject) {
      return systemObjects.filter((obj: SystemObjectDefinitions) => obj.ParentRow?.Row == actionObject.row);
    }

    return [];
  }

  getCurrentObjectDefination(valueFormula: string): CurrentObjectDefinitions | undefined {
    const currentObjects = this.store.selectSnapshot(LabelsStore.getCurrentObjectsDefinitions);
    const currentObject: CurrentObjectDefinitions | undefined = currentObjects
      .find((obj: CurrentObjectDefinitions) => obj.value_formula.includes(valueFormula));
    
    return currentObject;
  }
}
